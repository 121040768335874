import {t} from '@lingui/macro'
import {useState} from 'react'

import {Upgrade} from '@pleo-io/telescope-pictograms'

import {SupportedLanguage} from '@product-web/shared--i18n'
import type {MinorCurrency} from '@product-web/shared--locale/currency'
import {PreApprovedOverdraftInfoModal} from '@product-web/shared--paywall/overdraft/components/overdraft-info-modal/pre-approved-overdraft-info-modal'
import {usePreApprovedOverdraftInfo} from '@product-web/shared--paywall/overdraft/use-pre-approved-overdraft-info'
import {formatMinorNumberToCurrency} from '@product-web/shared--plan-presentation/currency-formatter'
import {useUser} from '@product-web/shared--user'
import type {BillingInfoCurrency} from '@shared/bff--moons/generated/beyond'

import PromoCard from '../components/promo-card'
import {trackPromoCardActioned} from '../lib/tracking'

const PreApprovedOverdraftPromoCard = () => {
    const user = useUser()
    const locale = user?.language ?? SupportedLanguage.EN
    const preApprovedOverdraftInfo = usePreApprovedOverdraftInfo()

    const [isPreApprovedOverdraftModalOpen, setIsPreApprovedOverdraftModalOpen] = useState(false)

    if (!preApprovedOverdraftInfo?.isEligible) {
        return null
    }

    const overdraftAmountLimitAmount = formatMinorNumberToCurrency({
        locale,
        value: preApprovedOverdraftInfo.maxLimit as MinorCurrency,
        options: {currency: preApprovedOverdraftInfo.currency},
    })

    const message = t`Unlock your ${overdraftAmountLimitAmount} overdraft today`

    const handleOpenPreApprovedOverdraftModal = () => {
        setIsPreApprovedOverdraftModalOpen(true)
        trackPromoCardActioned({
            action: 'viewed',
            message,
        })
    }

    const handleDismissPreApprovedOverdraftModal = () => {
        setIsPreApprovedOverdraftModalOpen(false)
        trackPromoCardActioned({
            action: 'abandoned',
            message,
        })
    }

    return (
        <>
            <PromoCard
                message={message}
                icon={<Upgrade size="48" />}
                ctaText={t`Read more`}
                onCardClicked={handleOpenPreApprovedOverdraftModal}
            />
            <PreApprovedOverdraftInfoModal
                pageOrigin="promo"
                pageSection="promo-card-overdraft"
                onUpgrade={() => setIsPreApprovedOverdraftModalOpen(false)}
                isOpen={isPreApprovedOverdraftModalOpen}
                onDismiss={handleDismissPreApprovedOverdraftModal}
                overdraftAmount={preApprovedOverdraftInfo?.maxLimit ?? 0}
                currency={preApprovedOverdraftInfo?.currency as BillingInfoCurrency}
                activationFeePercent={preApprovedOverdraftInfo?.activationFeePercent ?? 0}
            />
        </>
    )
}

export default PreApprovedOverdraftPromoCard
