import type {Hash} from 'history'
import qs from 'qs'
import React, {useEffect} from 'react'
import useSWR from 'swr'

import type {
    Department,
    Employee as ExpenseEmployee,
    EmployeeRole,
    GetAllEmployeeRequestFilters,
    SuccessResponse,
} from '@pleo-io/deimos'
import {EmployeePermission, EmployeeType} from '@pleo-io/deimos'
import type {
    EmployeeStatsResponse,
    UpdateEmployeePayload,
    WellnessSpendOverride,
} from '@pleo-io/deimos/dist/contracts/v1/employee'

import {request} from '@product-web/shared--api'
import {RequestAuth, RequestScope} from '@product-web/shared--api'
import type {Reviewer} from '@product-web/shared--api-types/legacy-core'
import type {Limit, LimitGroup, LimitType} from '@product-web/shared--api-types/limits'
import {forceSessionRefresh, getSessionData} from '@product-web/shared--auth--session/operations'
import config from '@product-web/shared--config'
import {reportError} from '@product-web/shared--error/report'
import type {SupportedLanguage} from '@product-web/shared--i18n'
import {useCompanyUser, useUser} from '@product-web/shared--user'
import {sort} from '@product-web/shared--utils'
import yup from '@product-web/shared--validation/yup'

import {useActiveCardOrders, usePreviousCardOrders} from './card-orders'
import {getDeimos} from './helpers'

const baseUrl = config.endpoints.api

const isEmail = (value: string) => yup.string().email().isValidSync(value)

// TODO: move to deimos client
export const EMBOSS_LINE_CHAR_LIMIT = 20

// TODO: move to deimos client
// https://linear.app/pleo/issue/ORION-1350/move-employee-type-to-deimos-client
export interface Employee extends ExpenseEmployee {
    limits?: Limit[]
    departments: Department[]
    emailChange?: string | null
    acceptedTerms: boolean
    verified: boolean
    active: boolean
    reviewer: Array<Reviewer>
    companyId: string
    companyName?: string | null
    userId: string
    permissions?: EmployeePermission[]
    deletedAt?: string
    isResetable?: boolean
    wellnessSpendOverride?: WellnessSpendOverride
    hasCardAccess?: boolean
}

// TODO: move to deimos client
interface OnboardingEmployeeData {
    email: string
    userExists: boolean
    userId: string
    employeeId: string
    resourceName?: string
    companyName?: string
    firstName?: string
    companyCountry?: string
    language?: SupportedLanguage
    location: {
        hash: Hash
    }
}

export interface EmployeeSummaryResponse {
    member: number
    owner: number
    bookkeeperBasic: number
    bookkeeperExtended: number
    bookkeeperManaged: number
}

export type UpdateLimitRequest = {
    group: LimitGroup
    type: LimitType
    value: {
        value: number | string
        date?: string
    }
}

export type EmployeeForm = Employee & {
    currentName: string
    isChecked: boolean
}

export enum ReviewType {
    CARD_REVIEW = 'cardReview',
    POCKET_REVIEW = 'pocketReview',
    INVOICE_REVIEW = 'invoiceReview',
}

interface UpdateEmployeeRolePayload {
    role: Omit<EmployeeRole, 'bookkeeper-managed'>
}

// Serialize and export the enum value as a type for use in the BFF
export type CardAccessPermission = `${EmployeePermission.CARD_ACCESS}`

export async function sendEmployeeOnboardingSms({
    employeeId,
    verifyToken,
    phoneNumber,
    appLanguage,
}: {
    employeeId?: string | null
    verifyToken: string
    phoneNumber: string
    appLanguage: SupportedLanguage
}): Promise<{success: boolean}> {
    return request(`${baseUrl}/rest/v1/employees/${employeeId}/sendOnboardingSms`, {
        method: 'POST',
        body: {
            verifyToken,
            phoneNumber,
            locale: appLanguage.toLocaleLowerCase(),
        },
    })
}

interface VerifyEmployeeRequest {
    verifyToken: string
    userId: string
    passcode: string
    otp: string
    otpType?: string
    trust?: boolean
}

export async function verifyEmployee(
    employeeId: string,
    accessToken: string,
    payload: VerifyEmployeeRequest,
    forceValidation = false,
) {
    return request(`${baseUrl}/rest/v1/employees/${employeeId}/verify`, {
        method: 'POST',
        auth: RequestAuth.USER,
        bearer: accessToken,
        body: payload,
        query: {forceValidation},
    })
}

async function getCompanyAccessToken(companyId: string) {
    //Allow cross-entities mutations
    const {
        data: {accessToken: companyAccessToken},
    } = await getSessionData({companyId})

    return companyAccessToken
}

async function updateEmployee(
    companyId: string,
    employeeId: string,
    payload: UpdateEmployeePayload,
) {
    const accessToken = await getCompanyAccessToken(companyId)
    return request(`${baseUrl}/rest/v1/employees/${employeeId}`, {
        auth: RequestAuth.USER,
        method: 'POST',
        body: payload,
        ...(accessToken && {bearer: accessToken}),
    })
}

async function updateEmployeeRole(employeeId: string, payload: UpdateEmployeeRolePayload) {
    return request(`${baseUrl}/rest/v1/employees/${employeeId}/role`, {
        auth: RequestAuth.ELEVATED,
        scope: RequestScope.PERMISSION,
        method: 'POST',
        body: payload,
    })
}

async function deleteEmployee(employeeId: string, email: string) {
    return request(`${baseUrl}/rest/v1/employees/${employeeId}`, {
        auth: 'elevated',
        scope: RequestScope.CARD,
        forcePIN: true,
        method: 'DELETE',
        query: {email},
    })
}

export function useCompanyEmployees(query?: GetAllEmployeeRequestFilters, companyId?: string) {
    const user = useUser()
    const id = companyId || user?.companyId
    const queryParams = qs.stringify(query)
    const result = useSWR<Employee[], Error>(
        !id ? null : `/rest/v1/companies/${id}/employees?${queryParams}`,
        getDeimos,
    )
    const add = async (newEmployee: Employee) => {
        if (!result.data || !newEmployee) {
            return
        }
        result.mutate([...result.data, newEmployee])
    }

    const updateMutation = async (employeeId: string, updatedEmployee: Employee) => {
        if (!result.data || !employeeId || !updatedEmployee) {
            return
        }
        const employeeIndex = result.data.findIndex((employee) => employee.id === employeeId)
        result.data.splice(employeeIndex, 1, updatedEmployee)
        result.mutate([...result.data])
    }

    const remove = async (employeeId: string) => {
        if (!result.data || !employeeId) {
            return
        }
        result.mutate(result.data.filter((employee) => employee.id !== employeeId))
    }

    return {
        ...result,
        isLoading: !result.data && !result.error,
        mutations: {add, update: updateMutation, remove},
    }
}

export type MutationsEmployee = ReturnType<typeof useEmployee>['mutations']

export function useEmployee(employeeId?: string | null) {
    const result = useSWR<Employee, Error>(
        employeeId ? `/rest/v1/employees/${employeeId}` : null,
        getDeimos,
    )
    async function updateMutation(payload: UpdateEmployeePayload) {
        if (!result.data || !employeeId) {
            return
        }
        const newInfo = await updateEmployee(result.data.companyId, employeeId, payload)
        result.mutate({...result.data, ...newInfo})
    }

    async function updateRole(payload: UpdateEmployeeRolePayload) {
        if (!result.data || !employeeId) {
            return
        }
        const newInfo = await updateEmployeeRole(employeeId, payload)
        result.mutate({...result.data, ...newInfo})
    }

    async function remove() {
        const employeeEmail = result.data?.email
        if (!employeeId || !employeeEmail) {
            return
        }
        await deleteEmployee(employeeId, employeeEmail)
        result.mutate()
    }

    async function addDepartmentToEmployee(teamId: string) {
        if (!result.data || !employeeId || !teamId) {
            return
        }
        const newInfo = await setTeam(employeeId, teamId)
        result.mutate({...result.data, ...newInfo})
    }

    async function removeDepartmentFromEmployee(departmentId: string) {
        if (!result.data || !employeeId || !departmentId) {
            return
        }
        const newInfo = await removeTeam(employeeId, departmentId)
        result.mutate({...result.data, ...newInfo})
    }

    return {
        ...result,
        mutations: {
            update: updateMutation,
            updateRole,
            remove,
            addDepartmentToEmployee,
            removeDepartmentFromEmployee,
        },
    }
}

export async function setTeam(employeeId: string, departmentId: string) {
    return request(`${baseUrl}/rest/v1/team/${departmentId}/employees`, {
        auth: 'user',
        method: 'POST',
        body: {
            employeeIds: [employeeId],
        },
    })
}

export async function removeTeam(employeeId: string, departmentId: string) {
    return request(`${baseUrl}/rest/v1/team/${departmentId}/employee/${employeeId}`, {
        auth: 'user',
        method: 'DELETE',
    })
}

export function useUnverifiedEmployee(verifyToken?: string) {
    return useSWR<OnboardingEmployeeData, Error>(
        verifyToken ? `/rest/v1/employee` : null,
        async () => getUnverifiedEmployee(verifyToken ?? ''),
        {shouldRetryOnError: false, revalidateOnFocus: false},
    )
}

export function useEmployeeSummary() {
    const user = useCompanyUser()
    return useSWR<EmployeeSummaryResponse, Error>(
        `/rest/v1/companies/${user.companyId}/employees/summary`,
        getDeimos,
    )
}

async function deleteBookkeeperByEmployeeId(
    employeeId: string,
    email: string,
): Promise<SuccessResponse> {
    return request(`${baseUrl}/rest/v1/bookkeepers/${employeeId}`, {
        auth: 'elevated',
        method: 'DELETE',
        scope: RequestScope.CARD,
        query: {email},
    })
}

export function useBookkeeperByEmployeeId() {
    async function destroy(employeeId: string | null | undefined, email: string | undefined) {
        return deleteBookkeeperByEmployeeId(employeeId as string, email as string)
    }

    return {destroy}
}

export async function deleteBookkeeperByUserId(
    userId: string,
    companyId: string,
): Promise<SuccessResponse> {
    const queryParams = qs.stringify({userId, companyId})
    const query = queryParams ? `?${queryParams}` : ''

    return request(`${baseUrl}/rest/v1/bookkeeper-by-userId${query}`, {
        auth: 'elevated',
        method: 'DELETE',
        scope: RequestScope.CARD,
    })
}

function useEmployeeStats(employeeId: string | null, includeToDoList?: boolean) {
    const query = qs.stringify({
        includeToDoList,
    })
    return useSWR<EmployeeStatsResponse, Error>(
        employeeId ? `/rest/v1/employees/${employeeId}/stats?${query}` : null,
        getDeimos,
    )
}

export function useCurrentEmployeeStats(includeToDoList?: boolean) {
    const user = useCompanyUser()
    return useEmployeeStats(user.employee.id, includeToDoList)
}

type PhysicalCards = {
    id: string
    employeeId?: string | null
}

export function useCompanyEmployeesWithoutPhysicalCards(cards: PhysicalCards[]): {
    data: EmployeeForm[]
    isLoading: boolean
} {
    const {data: employees, ...otherEmployeesProps} = useCompanyEmployees({
        hasCardAccess: true,
        includeLimits: false,
        type: EmployeeType.COMPANY,
        hasPhysicalCard: false,
    })

    const {data: previousCardOrders = []} = usePreviousCardOrders()
    const {data: activeCardOrders = []} = useActiveCardOrders()
    const hasMadeFirstOrder = previousCardOrders.length > 0 || activeCardOrders.length > 0

    const employeeIdsWithCards = React.useMemo(
        () => (cards ?? []).map(({employeeId}) => employeeId),
        [cards],
    )
    const employeesWithoutPhysicalCards = React.useMemo(
        () =>
            (employees ?? [])
                .filter(({id}) => !employeeIdsWithCards.includes(id))
                .sort((a, b) => sort.caseInsensitive(a.email, b.email)),
        [employeeIdsWithCards, employees],
    )

    const data = React.useMemo(
        () =>
            employeesWithoutPhysicalCards.map(({firstName, lastName, ...rest}) => {
                let currentName = [firstName || '', lastName || ''].join(' ')
                const validEmployeeName = Boolean(
                    currentName?.length <= EMBOSS_LINE_CHAR_LIMIT && currentName?.length !== 0,
                )

                if (isEmail(currentName.trim())) {
                    currentName = ''
                }

                return {
                    ...rest,
                    firstName,
                    lastName,
                    isChecked: hasMadeFirstOrder ? false : validEmployeeName,
                    currentName: validEmployeeName ? currentName : '',
                }
            }) as EmployeeForm[],
        [employeesWithoutPhysicalCards, hasMadeFirstOrder],
    )

    return {
        data,
        isLoading: (!employees && !otherEmployeesProps.error) || !cards,
    }
}

export interface PartnerEmployee {
    id: string
    userId: string
    firstName: string
    lastName?: string
    avatar?: {url: string} | null
    email: string
    partnerId: string
    role: 'owner' | 'member'
    clientCompanyIds: string[]
}

export function usePartnerEmployee(partnerEmployeeId?: string | null) {
    return useSWR<PartnerEmployee, Error>(
        partnerEmployeeId ? `/rest/v1/employees/${partnerEmployeeId}?includeLimits=false` : null,
        getDeimos,
    )
}

interface CreateAdminEmployeePayload {
    firstName?: string
    lastName?: string
}
/**
 * This hook is used to ensure that a company admin has an employee
 * attached to it. The call to create the Kerberos user and to create
 * the Deimos employee are separate, so in case the employee call fails
 * this hook will be used as a fallback in the onboarding flows, to retry
 * the employee creation. Admin can not proceed through the onboarding flow
 * without an employee (missing authorization rights).
 *
 * Task to address this on the backend instead so that the frontend does not
 * need to keep track of this and the hook can be removed: https://linear.app/pleo/issue/HEI-1451
 */
export const useEnsureAdminEmployeeIsCreated = (
    isInitialized: boolean,
    {firstName, lastName}: CreateAdminEmployeePayload,
) => {
    const user = useUser()

    useEffect(() => {
        if (user && !user.employee && !user.partnerEmployee && isInitialized) {
            if (!firstName || !lastName) {
                reportError('Missing name when creating employee')
            }

            request(`${config.endpoints.api}/rest/v1/employees`, {
                auth: RequestAuth.USER,
                headers: {
                    'X-Auth-Cache': 'ignore',
                },
                body: {
                    firstName: firstName ?? user.email,
                    lastName: lastName ?? 'N/A',
                },
                method: 'POST',
            })
                .then(forceSessionRefresh)
                .catch(reportError)
        }
    }, [user, isInitialized, firstName, lastName])
}

interface UpdateUnverifiedPayload {
    firstName: string
    lastName: string
    phone?: string
    jobTitle?: string
}

export interface CreateEmployeeParams {
    email: string
    firstName?: string
    lastName?: string
    cardAccess?: boolean
    role?: 'member' | 'owner'
}

export async function create(companyId: string, newUser: CreateEmployeeParams) {
    const permissions: string[] = newUser.cardAccess ? [EmployeePermission.CARD_ACCESS] : []
    const payload = {
        email: newUser.email,
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        permissions,
        role: newUser.role || 'member',
    }
    return request(`${baseUrl}/rest/v1/companies/${companyId}/employees`, {
        auth: 'elevated',
        scope: RequestScope.CARD,
        method: 'POST',
        body: payload,
    })
}

// useEmployee
export async function update(employeeId: string, payload: UpdateEmployeePayload) {
    let auth: RequestAuth = RequestAuth.USER
    let scope: undefined | RequestScope
    if (payload.role) {
        auth = RequestAuth.ELEVATED
        scope = RequestScope.PERMISSION
    }
    return request(`${baseUrl}/rest/v1/employees/${employeeId}`, {
        auth,
        scope,
        method: 'POST',
        body: payload || {},
    })
}

export async function updateUnverified(
    employeeId: string,
    verifyToken: string,
    payload: UpdateUnverifiedPayload,
) {
    return request(`${baseUrl}/rest/v1/employees/${employeeId}`, {
        method: 'PUT',
        headers: {verifyToken},
        body: payload,
    })
}

export async function getUnverifiedEmployee(verifyToken: string) {
    return request(`${baseUrl}/rest/v1/employee`, {
        method: 'GET',
        headers: {
            verifyToken,
        },
    })
}

export async function resendVerification(companyId: string, employeeId: string) {
    return request(`${baseUrl}/rest/v1/companies/${companyId}/employees/${employeeId}/resend`, {
        auth: 'user',
        method: 'POST',
    })
}

export async function setState(employeeId: string, key: string, value: any) {
    return request(`${baseUrl}/rest/v1/employees/${employeeId}/state`, {
        auth: 'user',
        method: 'PUT',
        body: {key, value},
    })
}
