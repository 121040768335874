import {t} from '@lingui/macro'

import type {ButtonProps} from '@pleo-io/telescope'
import {Button} from '@pleo-io/telescope'

import {useSupportChat} from '@product-web/shared--freshchat/use-support-chat'

export function ContactSupport({
    onClick,
    isDisabled,
    className,
    chatLabel,
    buttonVariant = 'tertiary',
    ...buttonProps
}: {
    isDisabled?: boolean
    onClick?: () => void
    className?: string
    chatLabel?: string | React.ReactNode
    buttonVariant?: ButtonProps['variant']
} & Omit<ButtonProps, 'variant'>) {
    const supportChat = useSupportChat()

    return (
        <Button
            {...buttonProps}
            disabled={isDisabled}
            className={className}
            variant={buttonVariant ?? 'tertiary'}
            onClick={() => {
                supportChat.show()
                onClick?.()
            }}
        >
            {chatLabel ?? t`Chat with us`}
        </Button>
    )
}
