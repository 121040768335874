import {useFlags} from '@product-web/shared--flags'
import type {CpqRatePlanType} from '@shared/bff--moons/generated/beyond'

import {bff} from '../bff-hooks'
import type {PageOrigin, PageSection} from '../index.bff'

type UsePreApprovedOverdraftInfoType = {
    pageOrigin?: PageOrigin
    pageSection?: PageSection
}

export type PreApprovedOverdraftInfo =
    | {
          isEligible: false
          isFetching: boolean
      }
    | {
          isEligible: true
          isFetching: boolean
          isRecommended: boolean
          recommendedPlan: CpqRatePlanType
          maxLimit: number
          limitsPerPlan: Partial<Record<CpqRatePlanType, number>>
          activationFeePercent: number
          currency: string
          interestRate: number
      }

export function usePreApprovedOverdraftInfo(
    params?: UsePreApprovedOverdraftInfoType,
): PreApprovedOverdraftInfo {
    const {preApprovedOverdraft} = useFlags()

    const {data, isLoading, isError} = bff.paywall.overdraft.getOverdraftInformation.useQuery(
        {
            pageOrigin: params?.pageOrigin,
            pageSection: params?.pageSection,
        },
        {
            enabled: preApprovedOverdraft,
        },
    )

    if (isError || !data?.isEligible || !preApprovedOverdraft) {
        return {
            isEligible: false,
            isFetching: isLoading,
        }
    }

    return {
        ...data,
        isEligible: true,
        isFetching: isLoading,
    }
}
