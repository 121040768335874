import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router'

import {useCashManagementAdoptionState} from '@product-web/shared--cash-management/adoption-switch/use-cash-management-adoption-state'
import {useFlags, useFlagsLoaded} from '@product-web/shared--flags'

import {bffHooks} from './bff-hooks'
import {useCreditPersistedState} from './credit-persisted-state'
import {usePleoReserveEligibility} from './pleo-reserve-api'

import {useHandleTopUp} from '../use-handle-top-up'
import {useWalletBalance} from '../use-wallet-balance'

export enum PleoReserveOverdueModalStep {
    INITIAL = 'INITIAL',
    TOP_UP_FINISHED = 'TOP_UP_FINISHED',
    DISMISSED = 'DISMISSED',
}

const useModalPersistedState = () => {
    return useCreditPersistedState<{
        step: PleoReserveOverdueModalStep
        updatedAt: Date | null
    }>('overdueModalState', {
        step: PleoReserveOverdueModalStep.INITIAL,
        updatedAt: null,
    })
}

const DISMISSED_MODAL_MAX_AGE = 7 * 24 * 60 * 60 * 1000 // 7 days

export const useResetReserveCollectionsModalPersistedState = () => {
    const pleoReserve = usePleoReserveEligibility()
    const [modalState, setModalState] = useModalPersistedState()

    // always reset dismissed state on init if user is in collections
    useEffect(() => {
        if (pleoReserve.data?.productState === 'COLLECTIONS') {
            setModalState({
                step: PleoReserveOverdueModalStep.INITIAL,
                updatedAt: null,
            })
        }
        // setModalState can't be in the dependencies because it's not memoized properly
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pleoReserve.data?.productState])

    useEffect(() => {
        if (!modalState.updatedAt) {
            return
        }
        const dismissedTimeMs = new Date().getTime() - new Date(modalState.updatedAt).getTime()
        if (dismissedTimeMs > DISMISSED_MODAL_MAX_AGE) {
            setModalState({
                step: PleoReserveOverdueModalStep.INITIAL,
                updatedAt: null,
            })
        }
        // setModalState can't be in the dependencies because it's not memoized properly
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalState])
}

export const usePleoReserveAmountOwed = () => {
    const {walletBalance} = useWalletBalance()

    const amountOwed = {
        value: Math.max((walletBalance?.available ?? 0) * -1, 0),
        currency: walletBalance?.currency,
    }

    return {amountOwed}
}

export const usePleoReserveCollections = () => {
    const [modalState, setModalState] = useModalPersistedState()

    const setModalStep = (step: PleoReserveOverdueModalStep) => {
        setModalState({
            step,
            updatedAt: new Date(),
        })
    }

    const {handleTopUp: startTopUp} = useHandleTopUp()

    const finishTopUp = () => {
        setModalStep(PleoReserveOverdueModalStep.TOP_UP_FINISHED)
    }

    const dismissModal = () => {
        setModalStep(PleoReserveOverdueModalStep.DISMISSED)
    }

    return {
        step: modalState.step,
        finishTopUp,
        startTopUp,
        dismissModal,
    }
}

/**
 * Redirect to wallet page when user is in collections flow to show them a non-dissmisible modal
 * asking them to repay what they owe.
 * It is defined as a component to be able to use it in all routes behind auth
 */
export const RedirectCollectionsToWalletPage = () => {
    const pleoReserve = usePleoReserveEligibility()
    const navigate = useNavigate()
    const location = useLocation()

    const featureFlagsLoaded = useFlagsLoaded()

    const {data, isInitialLoading} = bffHooks.credit.getRedirectCollectionsToWallet.useQuery()

    const {cashManagementFlow} = useFlags()

    const [isCashManagementEnabled] = useCashManagementAdoptionState()

    useEffect(() => {
        if (featureFlagsLoaded && !isInitialLoading) {
            const targetPath =
                !cashManagementFlow || !isCashManagementEnabled || !data?.hasCashManagementAdoption
                    ? '/wallet'
                    : '/cash-management'
            if (
                pleoReserve.data?.productState === 'COLLECTIONS' &&
                location.pathname !== targetPath
            ) {
                navigate(targetPath)
                return
            }
        }
    }, [
        featureFlagsLoaded,
        isCashManagementEnabled,
        cashManagementFlow,
        location.pathname,
        navigate,
        pleoReserve.data?.productState,
        isInitialLoading,
        data,
    ])

    return null
}
