/* eslint-disable string-to-lingui/missing-lingui-transformation */
import {t} from '@lingui/macro'
import styled from 'styled-components'

import {List, ListItem, Stack} from '@pleo-io/telescope'

import {EntityCardsGroup} from '../entity-cards-group'
import {GroupTitle} from '../group-title'
import type {EntitiesGroup} from '../non-bookkeeper-group/non-bookkeeper-group'

export const BookkeeperGroup = ({
    groupedEntities,
    selectedEntityId,
    onEntityCardClick,
    onVerifyClick,
    switchDisabled,
}: {
    groupedEntities: EntitiesGroup[]
    selectedEntityId: string | null
    onEntityCardClick: (companyId: string) => void
    onVerifyClick: (companyId: string) => void
    switchDisabled: boolean
}) => {
    const bookkeeperEntitiesGroup = groupedEntities.filter(
        (entitiesGroup) => entitiesGroup.entities[0].role === 'bookkeeper',
    )

    return (
        <Stack stretch>
            {bookkeeperEntitiesGroup.length > 0 && <GroupTitle title={t`Bookkeeping`} />}
            <StyledList>
                {bookkeeperEntitiesGroup.map((group, index, groups) => {
                    const shouldShowSeparator =
                        (group.type === 'company' && groups[index + 1]?.type === 'organization') ||
                        (group.type === 'organization' && groups[index + 1]?.type === 'company')

                    return (
                        <ListItem key={group.id}>
                            <EntityCardsGroup
                                showSeparatorAfterGroup={shouldShowSeparator}
                                showOrganizationName={group.type === 'organization'}
                                organizationName={group.name}
                                entities={group.entities}
                                activeCompanyId={selectedEntityId}
                                onClick={onEntityCardClick}
                                onVerifyClick={onVerifyClick}
                                switchDisabled={switchDisabled}
                            />
                        </ListItem>
                    )
                })}
            </StyledList>
        </Stack>
    )
}

const StyledList = styled(List)`
    > li {
        margin-bottom: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
`
