import {useCompanyFeatureEntitlement} from '@product-web/shared--entitlement/use-feature-entitlement'
import {useFlags} from '@product-web/shared--flags'

import {bff} from '../bff-hooks'

/**
 * `useReviewerConfig` returns whether the user is a `team`, `company`, `invoice` or `tag` reviewer.
 *
 * Each of these are individually returned for granular permissions checks, but are also aggregated in the
 * `isReviewer` (which will be `true` if any of the `team`, `company`, `invoice` or `tag` are `true`).
 *
 * @remarks the initial and placeholder data will be `false` for each corresponding permission.
 */

export const useReviewerConfig = () => {
    const {enablePurchaseOrdersFeature} = useFlags()
    const {isEntitled: isEntitledToPurchaseOrder} = useCompanyFeatureEntitlement('PURCHASE_ORDERS')
    const isCompanyEnabledForPurchaseOrders =
        enablePurchaseOrdersFeature && isEntitledToPurchaseOrder

    return bff.reviewerConfig.getReviewerConfig.useQuery(
        {
            isPurchaseOrdersEnabled: isCompanyEnabledForPurchaseOrders,
        },
        {
            placeholderData: {
                team: false,
                company: false,
                invoice: false,
                tag: false,
                manuallyRequested: false,
                hasPendingReviews: false,
                purchaseOrders: false,
                isReviewer: false,
            },
            staleTime: 10 /*s*/ * 1000,
            retry: (failureCount) => failureCount < 3,
            retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
        },
    )
}
