import type {CompanyAdoptionGuideResponse} from '@shared/bff--moons/generated/auxo'

/**
 * I found it easier to define the list of paths that are allowed, compared
 * to defining all paths that are not allowed. If we missed any, we can
 * always update it.
 */
export const ADOPTION_ESSENTIALS_PATHS = [
    '/onboarding',
    '/account',
    '/expenses',
    '/analytics',
    '/invoices',
    '/subscriptions',
    '/wallet',
    '/reimbursements',
    '/people',
    '/export',
    '/settings',
    '/company-verification',
]

export const getIsAdoptionCentreVisible = ({
    isDesktop,
    companyGuides,
    pathname,
}: {
    isDesktop: boolean
    companyGuides: CompanyAdoptionGuideResponse[]
    pathname: string
}): boolean => {
    const isVisibleOnCurrentPage = Boolean(
        ADOPTION_ESSENTIALS_PATHS.find((p) => pathname.startsWith(p)),
    )

    // For the prototype we only use the first guide
    const showPrompt = Boolean(
        companyGuides.length &&
            companyGuides.find((guide) => guide.adoptionGuide === 'EXPORT_ESSENTIALS')?.showPrompt,
    )

    return isDesktop && isVisibleOnCurrentPage && showPrompt
}
