import {t} from '@lingui/macro'

import type {ApplicationWithInstallationResponse} from '@shared/bff--moons/generated/endymion'

import {switchSamlIntegration} from './saml-integration-pattern-matcher'

type Provider = Pick<ApplicationWithInstallationResponse, 'name' | 'description'>

export const getSamlSsoLocalisedProviderInfo = switchSamlIntegration<Provider>({
    okta: () => ({
        description: t`Identity management platform for streamlined integration`,
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        name: 'Okta',
    }),
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    entra: () => ({description: t`Previously Azure Active Directory`, name: 'Microsoft Entra'}),
    google: () => ({
        description: t`Secure Online Productivity & Collaboration Tools`,
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        name: 'Google Workspace',
    }),
    custom: () => ({
        description: t`Set up custom connection with the Identity provider of your choice`,
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        name: 'Custom setup',
    }),
})
