import {t, Trans} from '@lingui/macro'
import type {FormikProps} from 'formik'
import {Formik} from 'formik'
import type React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import styled from 'styled-components'

import {
    Box,
    Button,
    ButtonGroup,
    FormikInput as Input,
    Link,
    List,
    Loading,
    ModalActions,
    ModalContent,
    ModalFooter,
    ModalSubtitle,
    ModalTitle,
    Popover,
    Stack,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {Info, Office} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/shared--analytics'
import type {APIPartnerInfo} from '@product-web/shared--api-deimos/partner'
import {useTermsDocuments} from '@product-web/shared--country-configuration/features/terms/terms'
import {getHelpCentreArticleLinkIntercom} from '@product-web/shared--locale/helpers'
import {breakpoints} from '@product-web/shared--styles/theme'
import HelpLink from '@product-web/shared--telescope-lab/helplink/helplink'
import {Step, Steps, useSteps} from '@product-web/shared--telescope-lab/modal-steps/modal-steps'

import BookkeeperAccessLevel, {
    BasicAccessFeatures,
    ExtendedAccessFeatures,
} from '../../common/bookkeeper-access-level'
import type {AccessLevel} from '../../common/types'
import * as ps from '../add-member-modal/add-member-modal.styles'

interface FormValues {
    firstName: string
    email: string
    accessLevel: AccessLevel | null
}

type InviteBookkeeperRequestBody = FormValues & {partner: APIPartnerInfo | null} & {
    parentResource?: string
    parentResourceId?: string
}

interface AddBookkeeperProps {
    validate: (values: FormValues) => any
    onEmailChange: (email: string) => Promise<void>
    onSubmit: (values: InviteBookkeeperRequestBody) => Promise<void>
    isFetchingPartner: boolean
    partnerInfo: APIPartnerInfo | null
    isAddBookkeeperPermitted?: boolean
    isLoading?: boolean
}

export const AddBookkeeperLegacy: React.FC<React.PropsWithChildren<AddBookkeeperProps>> = (
    props,
) => {
    const initialValues: FormValues = {
        firstName: '',
        email: '',
        accessLevel: null,
    }

    const steps = useSteps()

    return (
        <Formik
            initialValues={initialValues}
            validate={async (values: FormValues): Promise<any> => {
                await props.onEmailChange(values.email)
                return props.validate(values)
            }}
            validateOnBlur={false}
            onSubmit={async (values, helpers) => {
                await props.onSubmit({
                    ...values,
                    partner: props.partnerInfo,
                    parentResource: props.partnerInfo?.id ? 'partner' : undefined,
                    parentResourceId: props.partnerInfo?.id,
                })
                tracking.inviteBookkeeperActioned({action: 'completed'})
                helpers.setSubmitting(false)
            }}
        >
            {(formik) => (
                <Steps {...steps}>
                    <Step>
                        <StepAccessLevel
                            accessLevel={formik.values.accessLevel}
                            setLevel={(level) => {
                                formik.setFieldValue('accessLevel', level)
                                setTimeout(() => {
                                    steps.goToNextStep()
                                }, 300)
                            }}
                            isLoading={props.isLoading}
                        />
                    </Step>
                    <Step>
                        <StepInviteForm
                            {...props}
                            formik={formik}
                            onCancel={() => {
                                formik.setFieldValue('accessLevel', null)
                                steps.goToPreviousStep()
                            }}
                        />
                    </Step>
                </Steps>
            )}
        </Formik>
    )
}

export default AddBookkeeperLegacy

const StepAccessLevel = ({
    accessLevel,
    setLevel,
    isLoading,
}: {
    accessLevel: AccessLevel | null
    setLevel: (level: AccessLevel) => void
    isLoading?: boolean
}) => (
    <>
        <ModalTitle>
            <Trans>Invite bookkeeper</Trans>
        </ModalTitle>
        <ModalSubtitle>
            <Trans>Choose the level of access you'd like to give your bookkeeper</Trans>
        </ModalSubtitle>
        {isLoading ? (
            <ModalContent align="center">
                <Loading />
            </ModalContent>
        ) : (
            <ModalContent align="left">
                <BookkeeperAccessLevel accessLevel={accessLevel} setLevel={setLevel} />
            </ModalContent>
        )}
        <ModalFooter align="center">
            <Trans>External bookkeepers don't have access to a Pleo card</Trans>
        </ModalFooter>
    </>
)

const StepInviteForm = ({
    formik,
    onCancel,
    ...props
}: AddBookkeeperProps & {
    formik: FormikProps<FormValues>
    onCancel: () => void
}) => {
    const {privacyPolicy, masterServiceAgreement} = useTermsDocuments()
    return (
        <>
            <ModalTitle>
                <Trans>Invite bookkeeper</Trans>
            </ModalTitle>
            <ModalSubtitle>
                <Trans>
                    We'll send them an email containing all the details on how to get started with
                    Pleo as a bookkeeper
                </Trans>
            </ModalSubtitle>
            <ModalContent align="left">
                <Stack space={40}>
                    <ps.FieldRow>
                        <Input
                            autoFocus
                            name="firstName"
                            placeholder={t`Name`}
                            aria-label={t`Name`}
                            variant="bordered"
                            data-testid="invite-bookkeeper-first-name"
                        />
                        <Input
                            name="email"
                            onChange={(event) =>
                                formik.setFieldValue('email', event.target.value.trim())
                            }
                            placeholder={t`Email`}
                            aria-label={t`Email`}
                            variant="bordered"
                            data-testid="invite-bookkeeper-email"
                        />
                    </ps.FieldRow>
                    {props.partnerInfo ? (
                        <PartnerBox>
                            <PartnerIcon>
                                <Office />
                            </PartnerIcon>
                            <address>
                                <Text variant="large-accent" as="h4">
                                    {props.partnerInfo.name}
                                </Text>
                                {`${props.partnerInfo.bookkeeperFirstName} ${props.partnerInfo.bookkeeperLastName}`}
                            </address>
                            <Popover>
                                <Popover.Trigger>
                                    <QuestionIcon>
                                        <Info />
                                    </QuestionIcon>
                                </Popover.Trigger>
                                <Popover.Content side="right">
                                    <Box p={20} css={{maxWidth: 300}}>
                                        <Trans>
                                            Looks like you're about to add {props.partnerInfo.name}{' '}
                                            to your account.{' '}
                                            <Link
                                                href={getHelpCentreArticleLinkIntercom(
                                                    '3845294-adding-an-accounting-practice-to-pleo-account',
                                                )}
                                            >
                                                This
                                            </Link>{' '}
                                            is what it means for you.
                                        </Trans>
                                    </Box>
                                </Popover.Content>
                            </Popover>
                        </PartnerBox>
                    ) : null}
                    {!props.isAddBookkeeperPermitted && (
                        <Text variant="small-subtle" align="center">
                            <RedErrorMessage>
                                <Trans>
                                    You do not have access to this feature,{' '}
                                    <Link as={RouterLink} to="/settings/billing" inherit>
                                        upgrade your plan
                                    </Link>{' '}
                                    to continue
                                </Trans>
                            </RedErrorMessage>
                        </Text>
                    )}
                    <FeaturesSummary>
                        <Stack space={16}>
                            <Text
                                variant="large-accent"
                                weight={'regular'}
                                color="colorContentStatic"
                                align="center"
                            >
                                <Trans>Your Bookkeeper will able to...</Trans>
                            </Text>
                            <List>
                                <BasicAccessFeatures
                                    withMovingFunds={formik.values.accessLevel !== 'extended'}
                                    column={0}
                                />
                                {formik.values.accessLevel === 'extended' && (
                                    <ExtendedAccessFeatures
                                        levelSelectionStage={false}
                                        column={0}
                                    />
                                )}
                            </List>
                        </Stack>
                    </FeaturesSummary>
                    <ComplianceText color={'shade600'}>
                        <Trans>
                            By sending the invitation you are authorising the bookkeeper to access
                            the these administrative features and thus act as an admin for the
                            Company. Read the{' '}
                            <HelpLink url={masterServiceAgreement}>
                                Master Service Agreement
                            </HelpLink>
                            {' and the '}
                            <HelpLink url={privacyPolicy}> Privacy Policy.</HelpLink>
                        </Trans>
                    </ComplianceText>
                </Stack>
            </ModalContent>
            <ModalActions>
                <ButtonGroup>
                    <Button
                        type="button"
                        variant="secondary"
                        disabled={formik.isSubmitting}
                        onClick={onCancel}
                    >
                        <Trans>Back</Trans>
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        onClick={formik.submitForm}
                        loading={formik.isSubmitting || props.isFetchingPartner}
                        disabled={
                            (!formik.isValid && !formik.isSubmitting) ||
                            !props.isAddBookkeeperPermitted ||
                            props.isFetchingPartner
                        }
                    >
                        <Trans>Invite</Trans>
                    </Button>
                </ButtonGroup>
            </ModalActions>
            <ModalFooter align="center">
                <Trans>External bookkeepers don't have access to a Pleo card</Trans>
            </ModalFooter>
        </>
    )
}

const PartnerBox = styled.div`
    display: flex;
    flex: 1;
    margin: auto;
    justify-content: center;
    align-items: center;
    line-height: ${tokens.lineHeight2};
    font-size: 14px;

    address {
        text-align: left;
        color: ${tokens.colorContentStaticQuiet};
        font-size: 10px;
    }
`

const PartnerIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    height: 40px;
    margin-right: ${tokens.spacing16};
    font-size: 20px;
    color: ${tokens.colorContentStatic};
    background-color: ${tokens.colorBackgroundPresentationalPink};
    border-radius: ${tokens.circle};
`

const QuestionIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    height: 30px;
    margin-left: ${tokens.spacing16};
    font-size: 20px;
    box-shadow: ${tokens.shadowElevate};
    border: ${tokens.borderPrimary};
    padding: ${tokens.spacing2};
    color: ${tokens.colorContentInteractive};
    background-color: ${tokens.colorBackgroundInteractive};
    border-radius: ${tokens.circle};
    cursor: pointer;
`

const FeaturesSummary = styled.div`
    margin: auto;

    ${Text} {
        margin: auto;
    }

    ${List} {
        display: grid;
        grid-column-gap: 24px;
        grid-template-columns: 1fr 1fr;
        justify-items: flex-start;

        @media (max-width: ${breakpoints.smallTabletUp}) {
            display: block;
        }
    }
`

const ComplianceText = styled(Text)`
    max-width: 466px;
    justify-self: center;
    text-align: center;
`

const RedErrorMessage = styled.div`
    font-size: inherit;
    color: ${tokens.colorContentNegative};
`
