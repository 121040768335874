import {plural, t, Trans} from '@lingui/macro'
import type {ComponentProps} from 'react'
import {useEffect, useState} from 'react'
import styled from 'styled-components'

import {Box, Callout, Inline, Link, Stack, tokens} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import {useFlags} from '@product-web/shared--flags'
import {FeaturesComparisonPanel} from '@product-web/shared--plan-presentation/features-comparison-panel'
import {PlanColumn} from '@product-web/shared--plan-presentation/plan-column'
import {useUserPickedTrialPlanFlag} from '@product-web/shared--plan-presentation/use-user-picked-trial-plan-flag'
import {breakpoints} from '@product-web/shared--styles/theme'
import Wizard, {Step} from '@product-web/shared--telescope-lab/wizard/wizard'
import {useToaster} from '@product-web/shared--toaster'
import {useUser} from '@product-web/shared--user'
import {exhaustiveCheck} from '@product-web/shared--utils'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'
import type {CpqBillingPeriodType, CpqRatePlanType} from '@shared/bff--moons/generated/beyond'

import {CashbackCallout} from './cashback-callout'
import {PlanChangeSummary} from './plan-change-summary'
import {PreTrialPlanBreakdownWrapper} from './pre-trial-breakdown-wrapper'
import {DoneStep} from './pricing-modal-done-step'
import {WizardHeader} from './wizard-header'

import type {PlansData} from '../../index.bff'
import type {PageOrigin, PageSection} from '../../index.bff'
import {getIsAlmostOver, getIsLastDay} from '../../manage-plan/choose-plan-container.helper'
import {usePreApprovedOverdraftInfo} from '../../overdraft/use-pre-approved-overdraft-info'
import type {PlanUpgradeSource} from '../../types/plans'
import {PlanTypeName} from '../../types/plans'
import {getSortedPlans} from '../helpers/get-sorted-plans'
import {getWizardHeading} from '../helpers/get-wizard-heading'
import {PaymentConfirmationBreakdown} from '../payment-confirmation-breakdown'
import type {ModalVariant} from '../plan-change-clarification-modal'
import {PlanChangeClarificationModal} from '../plan-change-clarification-modal'
import type {FeatureType} from '../plan-features.bff'
import type {LatestTrackingPropsType} from '../pricing-plan-modal'
import {trackPricingPlansModalActioned} from '../tracking'

export const MAX_NUMBER_USERS_IN_STARTER = 3

const Plans = styled.div`
    margin-top: ${tokens.spacing32};
    gap: ${tokens.spacing24};
    align-items: stretch;
    flex-wrap: wrap;
    display: grid;
    padding-bottom: ${tokens.spacing32};

    @media (min-width: ${breakpoints.mediumTabletUp}) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: ${breakpoints.desktopMedUp}) {
        gap: ${tokens.spacing10};
        /* This precise minimum size prevents the columns overflowing the parent's right padding */
        grid-template-columns: repeat(4, minmax(267px, 1fr));
    }

    @media (min-width: ${breakpoints.desktopLrgUp}) {
        gap: ${tokens.spacing24};
    }
`

export type WizardStep = 'plans' | 'changes' | 'done'
export type WalletStatus = 'sufficient' | 'insufficient'
export type RedirectionFlow = 'default' | 'wallet-top-up' | 'wallet-overdraft'

export type PlanSelectedType = {
    planType: CpqRatePlanType
    isUpgrade: boolean
    freeUserLimit: number
}

type RegularFlowWizardProps = {
    onDismiss: () => void
    planUpgradeSource: PlanUpgradeSource
    pageOrigin: PageOrigin
    pageSection?: PageSection
    plansData?: PlansData
    setLatestTrackingProps?: (trackingData: LatestTrackingPropsType) => void
}

export const RegularFlowWizard = ({
    onDismiss,
    planUpgradeSource,
    pageOrigin,
    pageSection,
    plansData,
    setLatestTrackingProps,
}: RegularFlowWizardProps) => {
    const {pricing3GuidedMigration, planRecommendationsFromUpgradePoints} = useFlags()
    const {hasUserPickedTrialPlanFlag} = useUserPickedTrialPlanFlag()
    const user = useUser()
    const [step, setStep] = useState<WizardStep>('plans')
    const [isBillingAnnual, setIsBillingAnnual] = useState(true)
    const [selectedPlanType, setSelectedPlanType] = useState<PlanSelectedType | null>(null)
    const [redirectionFlow, setRedirectionFlow] = useState<RedirectionFlow>('default')
    const {showToast} = useToaster()

    const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints.desktopMedUp})`)
    const [clarificationModalVariant, setClarificationModalVariant] = useState<ModalVariant | null>(
        null,
    )

    const {
        isFdd: isCompanyVerified = false,
        currency = 'EUR',
        isOnTrial = false,
        isBeforeTrial = false,
        planSelection,
        currentPlan: currentPlanData,
        isLegacyPlan = false,
        hasCashback = false,
        planLimitsByPlanType = {} as PlansData['planLimitsByPlanType'],
    } = plansData || {}

    const targetBillingType = isBillingAnnual ? 'YEAR' : 'MONTH'
    const isBeforeOrDuringTrial = isBeforeTrial || isOnTrial
    const currentPlan =
        hasUserPickedTrialPlanFlag && isBeforeOrDuringTrial
            ? planSelection?.selectedPlan
            : currentPlanData
    const currentPlanName = currentPlan ? PlanTypeName[currentPlan] : null

    const sortedAvailablePlansPricing = getSortedPlans({
        isSmallScreen,
        currentPlan,
        availableSubscriptions: plansData?.plansPricing,
    })

    const preApprovedOverdraftInfo = usePreApprovedOverdraftInfo({pageOrigin, pageSection})
    const isPreApprovedOverdraftRecommended =
        preApprovedOverdraftInfo.isEligible && preApprovedOverdraftInfo.isRecommended

    const handleBackButtonClick = () => {
        setStep('plans')
        setSelectedPlanType(null)
        setLatestTrackingProps?.({
            step: getTrackingStep('plans'),
            plan: null,
        })
        trackPricingPlansModalActioned({
            action: 'back',
            step: 'payment_plan',
            origin: pageOrigin,
            section: pageSection,
            plan: selectedPlanType?.planType,
        })
    }

    const handleBillingTypeChange = (isAnnual: boolean) => {
        setIsBillingAnnual(isAnnual)

        trackPricingPlansModalActioned({
            action: 'annual_toggle_clicked',
            step: getTrackingStep(step),
            origin: pageOrigin,
            section: pageSection,
            plan: selectedPlanType?.planType,
            interval: targetBillingType,
        })
    }

    const handleSelectPlan = (targetPlan: PlansData['plansPricing'][number]) => {
        const isTargetPlanStarter = targetPlan.planName === 'STARTER'

        const isBlockedByNumberOfUsers =
            isTargetPlanStarter && (user?.company?.numberOfUsers ?? 0) > MAX_NUMBER_USERS_IN_STARTER

        if (targetPlan.isTermed) {
            setClarificationModalVariant('CHANGE_FROM_CUSTOM')
            return
        }

        if (targetPlan.isPlanChangeBlocked && !targetPlan.isUpgrade) {
            setClarificationModalVariant('DOWNGRADE_FROM_ANNUAL')
            return
        }

        if (isBlockedByNumberOfUsers) {
            setClarificationModalVariant('DOWNGRADE_TO_STARTER_USER_LIMIT')
            return
        }

        if (
            isTargetPlanStarter &&
            plansData?.availableAddons?.reimbursementOnlyUsers?.subscribedPackage
        ) {
            setClarificationModalVariant('ROU_ADDON_HAS_PURCHASED')
            return
        }

        if (isTargetPlanStarter) {
            setIsBillingAnnual(false)
        }

        setStep('changes')
        setSelectedPlanType({
            planType: targetPlan.planName,
            isUpgrade: targetPlan.isUpgrade,
            freeUserLimit: targetPlan.additionalUserBilling[targetBillingType].freeUserLimit ?? 0,
        })
        setLatestTrackingProps?.({
            step: getTrackingStep('changes'),
            plan: targetPlan.planName,
        })

        trackPricingPlansModalActioned({
            action: 'get_started',
            step: 'pricing_plan',
            origin: pageOrigin,
            section: pageSection,
            plan: targetPlan.planName,
        })
    }

    const handleContactUs = (planName: CpqRatePlanType) => {
        trackPricingPlansModalActioned({
            action: 'contact_us_clicked',
            step: getTrackingStep(step),
            origin: pageOrigin,
            section: pageSection,
            plan: planName,
        })
    }

    const handleOnDimissWizard = () => {
        onDismiss()

        // ask if we need to track this action here
        // because this is working in done step
        // but done step has its own tracking with done action
        trackPricingPlansModalActioned({
            action: 'abandoned',
            step: getTrackingStep(step),
            origin: pageOrigin,
            section: pageSection,
            plan: selectedPlanType?.planType!,
        })

        if (step === 'done') {
            setStep('plans')
            setSelectedPlanType(null)
            setIsBillingAnnual(false)
            setLatestTrackingProps?.({
                step: getTrackingStep('plans'),
                plan: null,
            })
        }
    }

    const handleOnPaymentError = () => {
        showToast(t`An error occurred. Please try again later or contact support.`, {
            level: 'error',
        })
    }

    const trialDaysRemaining = plansData?.trialDaysRemaining
    const trialEndDate = plansData?.trialEndDate ? new Date(plansData?.trialEndDate) : undefined
    const isTrialLastDay = getIsLastDay({trialEndDate})
    const isTrialAlmostOver = getIsAlmostOver({trialEndDate})
    const heading = getWizardHeading({
        step,
        selectedPlanType,
        isTrialLastDay,
        isTrialAlmostOver,
        isLegacyPlan,
        hasUserPickedTrialPlanFlag: hasUserPickedTrialPlanFlag ?? false,
        isBeforeTrial,
    })

    const handleOnConfirmation = (walletStatus: WalletStatus) => {
        if (isPreApprovedOverdraftRecommended && selectedPlanType?.isUpgrade) {
            setRedirectionFlow('wallet-overdraft')
        } else if (walletStatus === 'insufficient' && selectedPlanType?.isUpgrade) {
            setRedirectionFlow('wallet-top-up')
        } else {
            setRedirectionFlow('default')
        }
        setStep('done')
        setLatestTrackingProps?.({
            step: getTrackingStep('done'),
            plan: selectedPlanType?.planType!,
        })
    }

    // Helper function to highlight the recommended badge for legacy plan migration in the plan column
    const getIsMigrationRecommendedPlan = (planType: CpqRatePlanType) =>
        pricing3GuidedMigration && plansData?.migrationRecommendation?.recommendedPlan === planType

    // Helper function to highlight the border and the recommended badge in the plan column
    const getIsRecommendedPlanByFeature = (planType: CpqRatePlanType) =>
        planRecommendationsFromUpgradePoints && plansData?.recommendedPlan?.planType === planType

    // Helper function to highlight the overdraft copy in the plan column
    const getIsRecommendedPreApprovedOverdraft = (planType: CpqRatePlanType) => {
        return (
            isPreApprovedOverdraftRecommended &&
            preApprovedOverdraftInfo?.recommendedPlan === planType
        )
    }

    // Helper function to highlight the recommended plan based on usage selected in signup
    const getIsRecommendedPlanByUsage = (planType: CpqRatePlanType) =>
        hasUserPickedTrialPlanFlag &&
        isBeforeOrDuringTrial &&
        plansData?.planSelection.recommendedPlan === planType

    // Helper function to get and display the additional entitlements for the recommended plan column on the plans step
    const getAdditionalEtitlementsForPlanColumn = (planType: CpqRatePlanType) => {
        if (getIsRecommendedPlanByFeature(planType)) {
            return getAdditionalEntitlementsCopyByFeature(plansData?.recommendedPlan?.featureType)
        }
        return []
    }

    // Helper function to get the additional entitlements for the plan column on the summary step
    const getAdditionalEtitlementsForSummary = () =>
        getAdditionalEntitlementsCopyByFeature(plansData?.recommendedPlan?.featureType)

    const getPreApprovedOverdraftInfoForPlanColumn = (planType: CpqRatePlanType) => {
        return {
            isRecommended: getIsRecommendedPreApprovedOverdraft(planType),
            amount: preApprovedOverdraftInfo.isEligible
                ? preApprovedOverdraftInfo?.limitsPerPlan?.[planType]
                : 0,
        }
    }

    const getHasCashbackRecommendationWarning = (planType: CpqRatePlanType) =>
        pricing3GuidedMigration &&
        (planType === 'ADVANCED' || planType === 'BEYOND') &&
        plansData?.migrationRecommendation?.migrationFlowType === 'LEGACY_ESSENTIAL_CASHBACK_I' &&
        !isBillingAnnual

    const isBillingPeriodEqualToReccomended = isBillingAnnual
        ? plansData?.migrationRecommendation?.recommendedBillingPeriod === 'YEAR'
        : plansData?.migrationRecommendation?.recommendedBillingPeriod === 'MONTH'

    const selectedPlanData = sortedAvailablePlansPricing.find(
        (plan) => plan.planName === selectedPlanType?.planType,
    )

    const recommendedPlans = sortedAvailablePlansPricing.filter((availablePlanPricing) => {
        return (
            getIsRecommendedPlanByFeature(availablePlanPricing.planName) ||
            getIsRecommendedPreApprovedOverdraft(availablePlanPricing.planName) ||
            getIsMigrationRecommendedPlan(availablePlanPricing.planName)
        )
    })

    const isPlanRecommended = (planName: CpqRatePlanType) => {
        return (
            recommendedPlans.some((recommendedPlan) => recommendedPlan.planName === planName) ||
            (!recommendedPlans.length && getIsRecommendedPlanByUsage(planName))
        )
    }

    const getCurrentPlanTextOverride = () => {
        return hasUserPickedTrialPlanFlag && isOnTrial && trialDaysRemaining
            ? plural(trialDaysRemaining, {
                  one: `${trialDaysRemaining} day of trial left`,
                  other: `${trialDaysRemaining} days of trial left`,
              })
            : undefined
    }

    return (
        <>
            {!isCompanyVerified && !hasUserPickedTrialPlanFlag && (
                <Callout variant="warning">
                    <Callout.Text>
                        <Trans>
                            Company verification in progress. Please wait until you're fully
                            onboarded before changing plans.
                        </Trans>
                    </Callout.Text>
                </Callout>
            )}
            <Wizard step={step} isCurrentStepDone={step === 'done'}>
                <Step step={'plans'} label={t`Select plan`}>
                    <>
                        <WizardHeader
                            heading={heading}
                            isBillingAnnual={isBillingAnnual}
                            onBillingTypeChange={handleBillingTypeChange}
                        />
                        <Plans>
                            {sortedAvailablePlansPricing.map((availablePlanPricing) => (
                                <PlanColumn
                                    key={availablePlanPricing.planName}
                                    availablePlanPricing={availablePlanPricing}
                                    onSelectPlan={() => handleSelectPlan(availablePlanPricing)}
                                    onContactUs={handleContactUs}
                                    discountEnabled={isBillingAnnual}
                                    currency={currency}
                                    currentPlan={currentPlan ?? null}
                                    canSelectPlan={
                                        (isCompanyVerified || !!hasUserPickedTrialPlanFlag) &&
                                        !!currentPlan
                                    }
                                    selectButtonVariant={
                                        availablePlanPricing.planName !== 'STARTER'
                                            ? 'primary'
                                            : 'secondary'
                                    }
                                    isLegacyPlan={isLegacyPlan}
                                    showRecommendedBadge={isPlanRecommended(
                                        availablePlanPricing.planName,
                                    )}
                                    showContactUs
                                    additionalEntitlements={getAdditionalEtitlementsForPlanColumn(
                                        availablePlanPricing.planName,
                                    )}
                                    preApprovedOverdraftInfo={getPreApprovedOverdraftInfoForPlanColumn(
                                        availablePlanPricing.planName,
                                    )}
                                    currentPlanTextOverride={getCurrentPlanTextOverride()}
                                />
                            ))}
                        </Plans>
                        <FeaturesComparisonPanelContainer
                            availablePlansPricing={sortedAvailablePlansPricing}
                            companyCurrency={currency}
                            pageOrigin={pageOrigin}
                            pageSection={pageSection}
                            billingType={targetBillingType}
                            planLimitsByPlanType={planLimitsByPlanType}
                            onCompareAllFeaturesClick={() => {
                                trackPricingPlansModalActioned({
                                    action: 'view_all_link',
                                    step: 'pricing_plan',
                                    origin: pageOrigin,
                                    section: pageSection,
                                })
                            }}
                        />
                    </>
                </Step>
                <Step step={'changes'} label={t`Confirm changes`}>
                    <>
                        {hasUserPickedTrialPlanFlag &&
                            isOnTrial &&
                            trialDaysRemaining &&
                            currentPlanName && (
                                <Box mb={16} mx="auto" css={{maxWidth: 560}}>
                                    <Callout variant="warning">
                                        <Callout.Content>
                                            <Callout.ContentHeading>
                                                <Trans>Changing plan will end your trial</Trans>
                                            </Callout.ContentHeading>

                                            <Callout.Text>
                                                {plural(trialDaysRemaining, {
                                                    one: `You still have ${trialDaysRemaining} day remaining to use the ${currentPlanName} plan for free.`,
                                                    other: `You still have ${trialDaysRemaining} days remaining to use the ${currentPlanName} plan for free.`,
                                                })}
                                            </Callout.Text>
                                        </Callout.Content>
                                    </Callout>
                                </Box>
                            )}

                        <WizardHeader
                            heading={heading}
                            isBillingAnnual={isBillingAnnual}
                            onBillingTypeChange={handleBillingTypeChange}
                            isSwitchVisible={selectedPlanType?.planType !== 'STARTER'}
                            showRecommendedBadge={
                                (getIsMigrationRecommendedPlan(selectedPlanType?.planType!) &&
                                    isBillingPeriodEqualToReccomended) ||
                                getIsRecommendedPreApprovedOverdraft(selectedPlanType?.planType!) ||
                                getIsRecommendedPlanByFeature(selectedPlanType?.planType!)
                            }
                        />
                        <Inline justifyContent="flex-start" marginTop={24}>
                            <Link
                                role="link"
                                onClick={handleBackButtonClick}
                                aria-label={t`Select plan`}
                            >
                                <ArrowLeft size={16} marginRight={4} />
                                <Trans>Select plan</Trans>
                            </Link>
                        </Inline>
                        <Inline
                            paddingY={12}
                            alignItems="flex-start"
                            css={{
                                gap: tokens.spacing32,
                                flexDirection: isSmallScreen ? 'column' : 'row',
                            }}
                        >
                            <Stack space={16}>
                                {getHasCashbackRecommendationWarning(
                                    selectedPlanType?.planType!,
                                ) && <CashbackCallout />}
                                <PlanChangeSummary
                                    currentPlan={currentPlan!}
                                    targetPlan={selectedPlanType!}
                                    companyCurrency={currency}
                                    hasCashback={hasCashback}
                                    isBillingAnnual={isBillingAnnual}
                                    additionalEntitlements={getAdditionalEtitlementsForSummary()}
                                    isBeforeTrial={isBeforeTrial}
                                />
                            </Stack>

                            {hasUserPickedTrialPlanFlag && isBeforeTrial && selectedPlanData ? (
                                <PreTrialPlanBreakdownWrapper
                                    selectedPlanData={selectedPlanData}
                                    isBillingAnnual={isBillingAnnual}
                                    currency={currency}
                                    currentPlan={currentPlan!}
                                    isCompanyVerified={isCompanyVerified}
                                    onConfirm={() => setStep('done')}
                                    planLimitsByPlanType={planLimitsByPlanType}
                                />
                            ) : (
                                <PaymentConfirmationBreakdown
                                    targetBillingType={targetBillingType}
                                    targetPlan={selectedPlanType!}
                                    onError={handleOnPaymentError}
                                    onConfirmation={handleOnConfirmation}
                                    currentPlan={currentPlan!}
                                    currentBillingType={
                                        plansData?.currentBillingType as CpqBillingPeriodType
                                    }
                                    currentPlanEstimatedTotal={
                                        plansData?.currentPlanEstimatedTotal!
                                    }
                                    isLegacyPlan={isLegacyPlan}
                                    planUpgradeSource={planUpgradeSource}
                                    pageOrigin={pageOrigin}
                                    pageSection={pageSection}
                                    trackingStep="payment_plan"
                                    hasCashbackRecommendationWarning={getHasCashbackRecommendationWarning(
                                        selectedPlanType?.planType!,
                                    )}
                                />
                            )}
                        </Inline>
                    </>
                </Step>
                <Step step={'done'} label={t`Done`}>
                    <DoneStep
                        onClose={handleOnDimissWizard}
                        newPlan={selectedPlanType?.planType!}
                        isUpgrade={selectedPlanType?.isUpgrade!}
                        pageOrigin={pageOrigin}
                        pageSection={pageSection}
                        redirectionFlow={redirectionFlow}
                        isBeforeTrial={isBeforeTrial}
                        hasUserPickedTrialPlanFlag={hasUserPickedTrialPlanFlag}
                    />
                </Step>
            </Wizard>
            {clarificationModalVariant && (
                <PlanChangeClarificationModal
                    isOpen={!!clarificationModalVariant}
                    onDismiss={() => setClarificationModalVariant(null)}
                    variant={clarificationModalVariant}
                    pageOrigin={pageOrigin}
                    pageSection={pageSection}
                />
            )}
        </>
    )
}

type FeaturesComparisonPanelContainerProps = ComponentProps<typeof FeaturesComparisonPanel> & {
    pageOrigin: PageOrigin
    pageSection?: PageSection
}

const FeaturesComparisonPanelContainer = ({
    pageOrigin,
    pageSection,
    ...props
}: FeaturesComparisonPanelContainerProps) => {
    useEffect(() => {
        trackPricingPlansModalActioned({
            action: 'viewed',
            step: 'pricing_plan',
            origin: pageOrigin,
            section: pageSection,
        })
    }, [])

    return <FeaturesComparisonPanel {...props} />
}

const getTrackingStep = (step: WizardStep) => {
    switch (step) {
        case 'plans':
            return 'pricing_plan'
        case 'changes':
            return 'payment_plan'
        case 'done':
            return 'completed_plan_changes'
        default:
            return exhaustiveCheck(step)
    }
}

// Helper function to get the copy for the additional entitlements by feature
export const getAdditionalEntitlementsCopyByFeature = (featureType?: FeatureType) => {
    switch (featureType) {
        case 'ESSENTIAL_UNLIMITED_VENDOR_CARDS':
            return [t`Unlimited vendor cards`]
        case 'ESSENTIAL_SPEND_ANALYTICS':
            return [t`Spend analytics`]
        case 'ESSENTIAL_RECURRING_VENDORS':
            return [t`Recurring Vendors`]
        case 'ADVANCED_BUDGETS':
            return [t`Budgets`]
        case 'ESSENTIAL_TEAMS':
            return [t`Team Management`]
        case 'ESSENTIAL_REVIEW_POLICIES':
            return [t`Review Policies`]
        default:
            return []
    }
}
