import {SupportedLanguage} from '@product-web/shared--i18n'

import imageDe from './images/overdraft-info-modal-DE.jpg'
import imageDk from './images/overdraft-info-modal-DK.jpg'
import imageEnEur from './images/overdraft-info-modal-EN-EUR.jpg'
import imageEnGbp from './images/overdraft-info-modal-EN-GBP.jpg'
import imageEs from './images/overdraft-info-modal-ES.jpg'
import imageFr from './images/overdraft-info-modal-FR.jpg'
import imageNl from './images/overdraft-info-modal-NL.jpg'
import imagePt from './images/overdraft-info-modal-PT.jpg'
import imageSe from './images/overdraft-info-modal-SE.jpg'

const localeToImageMap: {[key in SupportedLanguage]?: string} = {
    [SupportedLanguage.DA]: imageDk,
    [SupportedLanguage.SV]: imageSe,
    [SupportedLanguage.EN]: imageEnEur,
    [SupportedLanguage.DE]: imageDe,
    [SupportedLanguage.DE_AT]: imageDe,
    [SupportedLanguage.ES]: imageEs,
    [SupportedLanguage.FR]: imageFr,
    [SupportedLanguage.FR_BE]: imageFr,
    [SupportedLanguage.FI]: imageSe,
    [SupportedLanguage.NL]: imageNl,
    [SupportedLanguage.NL_BE]: imageNl,
    [SupportedLanguage.PT]: imagePt,
    // No overdraft in Italy or Norway
}

export function getLocalisedImage(
    locale: SupportedLanguage = SupportedLanguage.EN,
    currency?: string,
) {
    if (locale === SupportedLanguage.EN && currency === 'GBP') {
        return imageEnGbp
    }

    return localeToImageMap[locale] || imageEnEur
}
