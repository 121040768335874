import * as React from 'react'
import {Route} from 'react-router-dom'

import {CardsContextProvider} from '@product-web/feature--cards/cards-context/cards-context'
import GeneralSettingsWrapper from '@product-web/feature--settings/general-settings-wrapper'
import {roleSets} from '@product-web/shared--user'

const CardsWrapper = React.lazy(async () => import('./screens/cards/cards-wrapper'))
const CardsPage = React.lazy(async () => import('./screens/cards/cards'))
const FrozenCardsPage = React.lazy(async () => import('./screens/frozen-cards/frozen-cards'))
const PersonalCardsDetails = React.lazy(
    async () => import('./components/card-details-personal/card-details-personal'),
)
const VendorCardsDetails = React.lazy(
    async () => import('./components/card-details-vendor/card-details-vendor'),
)
const CardDefaultsPage = React.lazy(async () => import('./screens/card-defaults/card-defaults'))
const SuspiciousTransactionPage = React.lazy(async () => import('./screens/suspicious/suspicious'))

export const routes = (
    <Route
        element={
            <CardsContextProvider>
                <CardsWrapper />
            </CardsContextProvider>
        }
        handle={{auth: true, allowedRoles: roleSets.companyOnly}}
    >
        <Route index element={<CardsPage />} />
        <Route path="personal-cards" element={<PersonalCardsDetails />} />
        <Route path=":cardId" element={<VendorCardsDetails />} />
    </Route>
)

export const settingsRoutes = (
    <Route
        element={<GeneralSettingsWrapper />}
        handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeperExtended}}
    >
        <Route index element={<CardDefaultsPage />} />
    </Route>
)

export const suspiciousTransactionRoutes = (
    <Route handle={{auth: true, allowedRoles: roleSets.company}}>
        <Route path=":transactionId/:token/:answer" element={<SuspiciousTransactionPage />} />
        <Route
            path=":transactionId/:token/:answer/:override"
            element={<SuspiciousTransactionPage />}
        />
    </Route>
)

export const frozenCardsRoutes = (
    <Route handle={{auth: false}} index element={<FrozenCardsPage />} />
)
