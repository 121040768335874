import styled from 'styled-components'

import {Box, ListItem, tokens} from '@pleo-io/telescope'

export const StyledListItem = styled(ListItem)`
    list-style-position: outside;
    margin-left: ${tokens.spacing20};
    margin-bottom: ${tokens.spacing2};
`

export const LearnMoreBox = styled(Box)`
    margin-top: ${tokens.spacing16};
    border-left: 2px solid ${tokens.colorContentInteractiveLink};
    background: ${tokens.colorBackgroundStaticLoud};
    padding: ${tokens.spacing8} ${tokens.spacing16};
`
