import {SupportedLanguage} from '@product-web/shared--i18n'

import srcDe from './images/overdraft-awareness-DE.jpg'
import srcDk from './images/overdraft-awareness-DK.jpg'
import srcEnEur from './images/overdraft-awareness-EN-EUR.jpg'
import srcEnGbp from './images/overdraft-awareness-EN-GBP.jpg'
import srcEs from './images/overdraft-awareness-ES.jpg'
import srcFr from './images/overdraft-awareness-FR.jpg'
import srcNl from './images/overdraft-awareness-NL.jpg'
import srcSe from './images/overdraft-awareness-SE.jpg'

const languageToImageMap: {[key in SupportedLanguage]?: string} = {
    [SupportedLanguage.DE]: srcDe,
    [SupportedLanguage.DE_AT]: srcDe,
    [SupportedLanguage.DA]: srcDk,
    [SupportedLanguage.EN]: srcEnEur,
    [SupportedLanguage.ES]: srcEs,
    [SupportedLanguage.FR]: srcFr,
    [SupportedLanguage.FR_BE]: srcFr,
    [SupportedLanguage.NL]: srcNl,
    [SupportedLanguage.NL_BE]: srcNl,
    [SupportedLanguage.SV]: srcSe,
}

export const getLocalisedImage = (language?: SupportedLanguage, currency?: string): string => {
    if (!language) {
        return srcEnEur
    }

    if (language === SupportedLanguage.EN && currency === 'GBP') {
        return srcEnGbp
    }

    const byMap = languageToImageMap[language]
    return byMap || srcEnEur
}
