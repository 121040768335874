import {Route} from 'react-router-dom'

import {ManageClientsAndTeams} from './manage-clients-and-teams'
import {routes as simplifyBookkeepingWorkflowRoutes} from './simplify-bookkeeping-workflow/routes'
import {SmarterSpendManagement} from './smarter-spend-management'
import {routes as transformClientFinancesRoutes} from './transform-client-finances/routes'
import {routes as unlockGrowthRoutes} from './unlock-growth/routes'

export const routes = (
    <>
        <Route index element={<SmarterSpendManagement />} />
        <Route path="simplify-bookkeeping-workflow">{simplifyBookkeepingWorkflowRoutes}</Route>
        <Route path="transform-client-finances">{transformClientFinancesRoutes}</Route>
        <Route path="unlock-growth">{unlockGrowthRoutes}</Route>
        <Route path="manage-clients-and-team" element={<ManageClientsAndTeams />} />
    </>
)
