import {t, Trans} from '@lingui/macro'
import type {ReactElement} from 'react'

import {Inline, ModalSplit, Stack, Text} from '@pleo-io/telescope'
import {Calculator, Sun, Trust} from '@pleo-io/telescope-pictograms'

import {useWalletCurrency} from '@product-web/feature--funds-management/use-wallet-currency'
import {formatCurrency} from '@product-web/shared--locale/currency'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useUser} from '@product-web/shared--user'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {getLocalisedImage} from './get-localised-image'
import {useOverdraftAwarenessFlow} from './use-overdraft-awareness-flow'

export const RECURRING_SPEND_CARD_KEY = '@pleo/adoption/recurring-expenses-card-dismissed'
export const RECURRING_SPEND_ANNOUNCEMENT_MODAL_KEY = '@pleo/spend-guide-announcement-modal'

interface OverdraftAwarenessFlowModalProps {
    displayModal: boolean
    handleCloseModal: () => void
    onClick: () => void
}

export const OverdraftAwarenessFlowModal = ({
    displayModal,
    handleCloseModal,
    onClick,
}: OverdraftAwarenessFlowModalProps) => {
    const {terms} = useOverdraftAwarenessFlow()

    const isSmallTabletUp = useMediaQuery(`(max-width: ${breakpoints.smallTabletUp})`)

    const user = useUser()
    const {walletCurrency} = useWalletCurrency()

    const currency = terms?.currency
    const interestRate = terms?.interestRate
    const limit = terms?.limit

    const formatedLimitValue = formatCurrency(limit, currency)

    interface BenefitListProps {
        image: ReactElement
        title: string
        textContent: string
    }
    const benefitsList: BenefitListProps[] = [
        {
            image: <Trust size="64" css={{height: '100%'}} />,
            title: t`Borrow up to ${formatedLimitValue}`,
            textContent: t`Activate your limit for an upfront fee of ${interestRate}%`,
        },
        {
            image: <Sun size="64" css={{height: '100%'}} />,
            title: t`No interest for 30 days`,
            textContent: t`Repay your balance within 30 days to enjoy interest-free spending`,
        },
        {
            image: <Calculator size="64" css={{height: '100%'}} />,
            title: t`Your business safety net`,
            textContent: t`Prevent declined transactions and ensure bills are paid on time, no matter what comes your way`,
        },
    ]

    const imgSrc = getLocalisedImage(user?.language ?? undefined, walletCurrency)

    return (
        <ModalSplit
            isOpen={displayModal}
            onDismiss={handleCloseModal}
            data-testid="overdraft-awareness-flow-modal"
            css={isSmallTabletUp ? {display: 'none'} : {}}
        >
            <ModalSplit.Content>
                <ModalSplit.Body>
                    <ModalSplit.Title>
                        <Trans>Great news! You're eligible for Pleo Overdraft</Trans>
                    </ModalSplit.Title>
                    <ModalSplit.Text>
                        <Inline space={24} flexDirection="column" css={{rowGap: '24px'}}>
                            <Trans>
                                Get instant access to funds when you need more flexibility.
                            </Trans>

                            {benefitsList.map((benefit, key) => (
                                <Inline space={16} alignItems="center" key={key}>
                                    {benefit.image}
                                    <Stack space={4}>
                                        <Text>{benefit.title}</Text>
                                        <Text variant="small-subtle">{benefit.textContent}</Text>
                                    </Stack>
                                </Inline>
                            ))}
                        </Inline>
                    </ModalSplit.Text>
                </ModalSplit.Body>
                <ModalSplit.Actions>
                    <ModalSplit.NextButton
                        onClick={onClick}
                        data-testid="overdraft-awareness-flow-modal-cta"
                    >
                        <Trans>Set up now</Trans>
                    </ModalSplit.NextButton>
                </ModalSplit.Actions>
            </ModalSplit.Content>

            <ModalSplit.IllustrationContainer>
                <ModalSplit.Illustration>
                    <img src={imgSrc} alt="" />
                </ModalSplit.Illustration>
            </ModalSplit.IllustrationContainer>
        </ModalSplit>
    )
}
