import type {PropsWithChildren} from 'react'
import {createContext, useCallback, useContext, useRef, useState} from 'react'
import {createPortal} from 'react-dom'

import {reportError} from '@product-web/shared--error/report'

type ActivePrompt = {
    name: string
}

type AdoptionPromptContextValue = {
    activePrompt: ActivePrompt | undefined
    addActivePrompt: (addedPrompt: ActivePrompt) => void
    removeActivePrompt: (removedPrompt: ActivePrompt) => void
    portal: HTMLDivElement | null
}

/**
 * The context controls which of the active adoption prompts is visible to the user
 */
export const AdoptionPromptContext = createContext<AdoptionPromptContextValue>({
    activePrompt: undefined,
    addActivePrompt: () => {},
    removeActivePrompt: () => {},
    portal: null,
})

const PORTAL_ELEMENT_ID = 'adoption-prompt-portal'

export const AdoptionPromptProvider = ({children}: PropsWithChildren) => {
    const [activePrompts, setActivePrompts] = useState<ActivePrompt[]>([])
    const ref = useRef<HTMLDivElement>(null)

    const addActivePrompt = useCallback((addedPrompt: ActivePrompt) => {
        setActivePrompts((prompts) => {
            if (prompts.some((prompt) => prompt.name === addedPrompt.name)) {
                reportError(
                    `Encountered two prompts with the same name "${prompt.name}". Make sure to give unique names to each prompt.`,
                )
                return prompts
            }
            return [...prompts, addedPrompt]
        })
    }, [])

    const removeActivePrompt = useCallback((removedPrompt: ActivePrompt) => {
        setActivePrompts((prompts) =>
            prompts.filter((prompt) => prompt.name !== removedPrompt.name),
        )
    }, [])

    const contextValue: AdoptionPromptContextValue = {
        activePrompt: activePrompts[0],
        addActivePrompt,
        removeActivePrompt,
        portal: ref.current,
    }

    return (
        <AdoptionPromptContext.Provider value={contextValue}>
            {children}
            {createPortal(<div ref={ref} id={PORTAL_ELEMENT_ID}></div>, document.body)}
        </AdoptionPromptContext.Provider>
    )
}

export const useAdoptionPrompt = () => {
    const context = useContext(AdoptionPromptContext)
    if (!context) {
        throw new Error('useAdoptionPrompt must be used within an AdoptionPromptProvider')
    }
    return context
}
