import type {Dispatch, ReactNode, SetStateAction} from 'react'
import {createContext, useContext, useState} from 'react'

import {invariant} from '@product-web/shared--utils'

type ReferralDetails = {
    email: string
    firstName: string
    lastName: string
    companySize: string
    phoneNumber?: string
}

type CompanyDetails = {
    registryId?: string
    legalName?: string
    registrationNumber?: string
    address?: {
        addressLine1?: string
        addressLine2?: string
        country?: string
        locality?: string
        postalCode?: string
        region?: string
    }
}

type InviteClientContext = {
    referralDetails?: ReferralDetails
    setReferralDetails: Dispatch<SetStateAction<ReferralDetails | undefined>>
    companyDetails?: CompanyDetails
    setCompanyDetails: Dispatch<SetStateAction<CompanyDetails | undefined>>
}

const InviteClientContext = createContext<InviteClientContext | undefined>(undefined)

export const useInviteClientContext = () => {
    const inviteClientContext = useContext(InviteClientContext)
    invariant(
        inviteClientContext,
        'useInviteClientContext must be used inside InviteClientContextProvider',
    )
    return inviteClientContext
}

export const InviteClientContextProvider = ({
    children,
    initialReferralDetails,
    initialCompanyDetails,
}: {
    children: ReactNode
    initialReferralDetails?: ReferralDetails
    initialCompanyDetails?: CompanyDetails
}) => {
    const [referralDetails, setReferralDetails] = useState<ReferralDetails | undefined>(
        initialReferralDetails,
    )
    const [companyDetails, setCompanyDetails] = useState<CompanyDetails | undefined>(
        initialCompanyDetails,
    )

    return (
        <InviteClientContext.Provider
            value={{referralDetails, setReferralDetails, companyDetails, setCompanyDetails}}
        >
            {children}
        </InviteClientContext.Provider>
    )
}
