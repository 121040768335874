import styled from 'styled-components'

import {Drawer} from '@pleo-io/telescope'

import {useHasAllowedRole} from '@product-web/shared--user'

import AdoptionAccordion from './accordion'
import {useAdoptionCentre} from './adoption-centre-context'
import AdoptionCentreFooter from './adoption-centre-footer'

import {bff} from '../bff-hooks'

export const AdoptionDrawer = () => {
    const isOwnerOrBookkeeper = useHasAllowedRole(['owner', 'bookkeeper-extended'])
    const {setDrawerOpen, isDrawerOpen, DRAWER_ID} = useAdoptionCentre()

    const {data: guides = []} = bff.adoptionCentre.getCompanyGuides.useQuery(undefined, {
        enabled: isOwnerOrBookkeeper,
    })

    const onDismiss = () => {
        setDrawerOpen(false)
    }

    return (
        <Drawer isOpen={isDrawerOpen} onDismiss={onDismiss} drawerId={DRAWER_ID}>
            <DrawerWrapper>
                <Drawer.Body>
                    <AdoptionAccordion guides={guides} closeAdoptionCentre={onDismiss} />
                </Drawer.Body>
                <Drawer.Footer css={{justifyContent: 'center'}}>
                    <AdoptionCentreFooter closeAdoptionCentre={onDismiss} />
                </Drawer.Footer>
            </DrawerWrapper>
        </Drawer>
    )
}
const DrawerWrapper = styled(Drawer.Panel)`
    max-width: 416px;
    min-width: 416px;
`
