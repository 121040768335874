import {overdraftAwarenessFlow} from '@product-web/shared--analytics'

type OverdraftAwarenessFlowAction = 'viewed' | 'clicked' | 'dismissed'
type OverdraftAwarenessFlowComponent = 'modal' | 'promotional-banner'

interface OverdraftAwarenessFlowProperties {
    action: OverdraftAwarenessFlowAction
    component: OverdraftAwarenessFlowComponent
    cta?: string
}

export const trackOverdraftAwarenessFlow = (properties: OverdraftAwarenessFlowProperties) => {
    overdraftAwarenessFlow(properties)
}
