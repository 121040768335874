import type {MetaUser} from '@grafana/faro-react'
import {faro} from '@grafana/faro-react'
import {useEffect} from 'react'

import {useTokenData} from '@product-web/shared--auth--session/context'
import {useCurrentSubApp} from '@product-web/shared--routes/use-current-sub-app'

const DEFAULT_SERVICE = 'browser-product-web'

export function useEnhancedGrafanaFaro() {
    const subApp = useCurrentSubApp()
    const tokenData = useTokenData()
    const userId = tokenData?.user.id
    const companyId = tokenData?.user.cmp ?? undefined

    useEffect(() => {
        const {setUser, resetUser} = faro.api

        if (userId) {
            const userMeta: MetaUser = {id: userId, attributes: {}}

            if (companyId !== undefined) {
                userMeta.attributes = {'company-id': companyId}
            }

            setUser({id: userId})
        } else {
            resetUser()
        }
    }, [userId, companyId])

    useEffect(() => {
        const {getSession, setSession} = faro.api
        const serviceName = subApp ? `${DEFAULT_SERVICE}-${subApp}` : DEFAULT_SERVICE

        setSession(getSession(), {
            overrides: {serviceName},
        })
    }, [subApp])
}
