import {t} from '@lingui/macro'
import {Dialog} from '@reach/dialog'
import {motion} from 'framer-motion'
import type {PropsWithChildren} from 'react'
import styled from 'styled-components'

import {IconButton, Inline, Text, tokens} from '@pleo-io/telescope'
import {Close} from '@pleo-io/telescope-icons'

const StyledDialog = styled(Dialog)<{$dangerouslySetZIndexValue: number}>`
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: ${tokens.colorBackgroundStatic};
    z-index: ${({$dangerouslySetZIndexValue}) => $dangerouslySetZIndexValue};
    overflow: auto;
`

export const Heading = styled(Text).attrs({
    variant: '3xlarge-accent',
    align: 'center',
    as: 'h2',
})`
    flex: 1;
`

export const Subtitle = styled(Text).attrs({
    variant: 'large-accent',
    align: 'center',
    as: 'p',
    color: 'colorContentStaticQuiet',
})`
    padding: ${tokens.spacing8} 0 ${tokens.spacing32} 0;
`

const AnimatedModal = motion(StyledDialog)

export const FullScreenDialog = ({
    isOpen,
    onClose,
    children,
    dangerouslySetZIndexValue = tokens.zIndexWindow,
    hideCloseButton = false,
    headerLink,
}: PropsWithChildren<{
    isOpen: boolean
    onClose: () => void
    dangerouslySetZIndexValue?: number
    hideCloseButton?: boolean
    headerLink?: React.ReactNode
}>) => {
    if (!isOpen) {
        return null
    }

    return (
        <AnimatedModal
            isOpen={isOpen}
            onDismiss={onClose}
            aria-label="pricing-selection-modal"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.25, ease: 'circOut'}}
            $dangerouslySetZIndexValue={dangerouslySetZIndexValue}
        >
            <Inline
                justifyContent="space-between"
                alignItems="center"
                paddingX={32}
                paddingTop={12}
            >
                <div>{headerLink && <>{headerLink}</>}</div>
                {!hideCloseButton && (
                    <IconButton
                        onClick={onClose}
                        variant="quiet"
                        Icon={Close}
                        aria-label={t`Close`}
                        tooltipProps={{content: t`Close`}}
                        data-testid="close-button-pricing-modal"
                    />
                )}
            </Inline>
            {children}
        </AnimatedModal>
    )
}
