import {reportError} from '@product-web/shared--error/report'
import type {SupportedLanguage} from '@product-web/shared--i18n'
import {formatMinorNumberToCurrency} from '@product-web/shared--plan-presentation/currency-formatter'
import {paymentFormatOptions} from '@product-web/shared--plan-presentation/pricing-plans.helper'
import type {Amount, AmountCurrency} from '@shared/bff--moons/generated/beyond'

import type {PlanSelectedType} from '../components/regular-flow-wizard'

export function calculatePriceBreakdown(
    isTargetYearly: boolean,
    targetPaymentValue: number,
    targetPayment:
        | {
              totalIncludingTax: {value: number; currency: AmountCurrency}
              platformIncludingTax?: {value: number; currency: AmountCurrency} | undefined
              usersIncludingTax?: {value: number; currency: AmountCurrency} | undefined
          }
        | undefined,
    locale: SupportedLanguage,
    currentBillingType: string,
    currentPlanEstimatedTotal: Amount | null,
    numberOfUsers: number,
    targetPlan: PlanSelectedType,
) {
    try {
        const targetPaymentPerMonth = isTargetYearly ? targetPaymentValue / 12 : targetPaymentValue
        const targetPaymentCurrency = targetPayment?.totalIncludingTax.currency

        const formattedTargetSubtotal = formatMinorNumberToCurrency({
            value: targetPaymentPerMonth,
            locale,
            options: {...paymentFormatOptions, currency: targetPaymentCurrency},
        })

        // Current billing is calculated as monthly
        const currentBilling =
            currentBillingType === 'YEAR'
                ? (currentPlanEstimatedTotal?.value ?? 0) / 12
                : currentPlanEstimatedTotal?.value ?? 0

        // Price difference is calculated as monthly
        const priceDifference = formatMinorNumberToCurrency({
            value: targetPaymentPerMonth - currentBilling,
            locale,
            options: {
                ...paymentFormatOptions,
                currency: targetPaymentCurrency,
                signDisplay: 'always',
            },
        })

        // Due today is calculated as monthly or yearly depending on the target billing type
        const dueToday = formatMinorNumberToCurrency({
            value: targetPaymentValue,
            locale,
            options: {...paymentFormatOptions, currency: targetPaymentCurrency},
        })

        const additionalUsersValue = targetPayment?.usersIncludingTax?.value ?? 0
        const hasAdditionalUsers = !!additionalUsersValue

        const additionalUsersEstimationCost = isTargetYearly
            ? additionalUsersValue / 12
            : additionalUsersValue

        const additionalUsersCount = numberOfUsers - (targetPlan.freeUserLimit ?? 0)

        const formattedAdditionalUserEstimationCost = formatMinorNumberToCurrency({
            value: additionalUsersEstimationCost,
            locale,
            options: {
                ...paymentFormatOptions,
                currency: targetPayment?.platformIncludingTax?.currency,
            },
        })

        const additionalCostPerUser = formatMinorNumberToCurrency({
            value: additionalUsersEstimationCost / additionalUsersCount,
            locale,
            options: {
                ...paymentFormatOptions,
                currency: targetPayment?.platformIncludingTax?.currency,
            },
        })

        return {
            hasAdditionalUsers,
            formattedAdditionalUserEstimationCost,
            additionalUsersCount,
            additionalCostPerUser,
            formattedTargetSubtotal,
            priceDifference,
            dueToday,
            error: false,
        }
    } catch (error) {
        reportError(error)
        return {
            error: true,
        }
    }
}
