import {t} from '@lingui/macro'
import useSWR from 'swr'

import type * as API from '@pleo-io/deimos'

import {request} from '@product-web/shared--api'
import config from '@product-web/shared--config'
import {useToaster} from '@product-web/shared--toaster'

import {getDeimos} from './helpers'

const baseUrl = config.endpoints.api

export type CreateEmployeeContactPayload = {
    firstName: string
    lastName: string
    companyName: string
    avatar?: {url: string} | null
}

export type UpdateEmployeeContactPayload = Partial<CreateEmployeeContactPayload>

export type Mutations = ReturnType<typeof useCompanyEmployeeAttendables>['mutations']

async function addEmployeeContact(employeeId: string, payload: CreateEmployeeContactPayload) {
    return request(`${baseUrl}/rest/v1/employees/${employeeId}/contacts`, {
        auth: 'user',
        method: 'POST',
        body: payload,
    })
}

export async function updateEmployeeContact(id: string, payload: UpdateEmployeeContactPayload) {
    return request(`${baseUrl}/rest/v1/contacts/${id}`, {
        auth: 'user',
        method: 'PUT',
        body: payload,
    })
}

async function deleteEmployeeContact(id: string) {
    return request(`${baseUrl}/rest/v1/contacts/${id}`, {
        auth: 'user',
        method: 'DELETE',
    })
}

export function useCompanyEmployeeAttendables(id: string | null, searchQuery?: string) {
    const {showToast} = useToaster()
    const query = searchQuery ? `?query=${searchQuery}` : ''
    const result = useSWR<API.GetAllAttendableEmployeeResponse, Error>(
        id ? `/rest/v1/employees/${id}/attendables${query}` : null,
        getDeimos,
    )

    async function create(payload: CreateEmployeeContactPayload) {
        if (!id) {
            return null
        }
        try {
            const response = await addEmployeeContact(id, payload)
            result.mutate()
            return response
        } catch (error) {
            showToast(t`Please try again. If the issue persists, contact customer support`, {
                title: t`Unable to create a new contact`,
                level: 'error',
            })
            throw error
        }
    }

    async function update(contactId: string, payload: CreateEmployeeContactPayload) {
        try {
            const response = await updateEmployeeContact(contactId, payload)
            result.mutate()
            return response
        } catch (error) {
            showToast(t`Please try again. If the issue persists, contact customer support`, {
                title: t`Unable to update contact`,
                level: 'error',
            })
            throw error
        }
    }

    async function remove(contactId: string) {
        await deleteEmployeeContact(contactId)
        result.mutate()
    }

    return {...result, mutations: {create, remove, update}}
}
