import {t, Trans} from '@lingui/macro'
import {useCallback, useEffect} from 'react'
import {useNavigate} from 'react-router'
import styled from 'styled-components'

import {Button, Inline, Stack, Text} from '@pleo-io/telescope'

import type {CpqRatePlanType} from '@shared/bff--moons/generated/beyond'

import DowngradeConfirmationSVG from './done-step-confirmation-downgrade.svg'
import UpgradeConfirmationSVG from './done-step-confirmation-upgrade.svg'
import type {RedirectionFlow} from './regular-flow-wizard'

import type {PageOrigin, PageSection} from '../../index.bff'
import {PlanTypeName} from '../../types/plans'
import {trackPricingPlansModalActioned} from '../tracking'

type DoneStepProps = {
    onClose: () => void
    isUpgrade: boolean
    newPlan: CpqRatePlanType | null
    pageOrigin: PageOrigin
    pageSection?: PageSection
    redirectionFlow: RedirectionFlow
    isBeforeTrial: boolean
    hasUserPickedTrialPlanFlag?: boolean
}

const StepWrapper = styled(Stack)`
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    height: 100%;
`

export const DoneStep = ({
    onClose,
    isUpgrade,
    newPlan,
    pageOrigin,
    pageSection,
    redirectionFlow,
    isBeforeTrial,
    hasUserPickedTrialPlanFlag = false,
}: DoneStepProps) => {
    const planName = PlanTypeName[newPlan!]
    const navigate = useNavigate()

    const trackEvent = useCallback(
        (action: 'done_wallet' | 'done' | 'viewed' | 'done-overdraft') => {
            trackPricingPlansModalActioned({
                action,
                step: 'completed_plan_changes',
                origin: pageOrigin,
                section: pageSection,
                plan: newPlan!,
            })
        },
        [newPlan, pageOrigin, pageSection],
    )

    const handleTopUp = () => {
        trackEvent('done_wallet')
        navigate('/wallet')
    }

    const handleNavigateToOverdraft = () => {
        trackEvent('done-overdraft')
        navigate('/wallet/direct-debit/overdraft')
    }

    const handleOnClose = () => {
        trackEvent('done')
        onClose()
    }

    const renderActions = () => {
        switch (redirectionFlow) {
            case 'wallet-top-up':
                return (
                    <>
                        <Text color="colorContentStatic" weight="medium">
                            {t`Remember to top up your wallet within 7 days so your plan fees are covered. `}
                        </Text>
                        <Inline space={16}>
                            <Button variant="primary" onClick={() => handleTopUp()}>
                                <Trans>Top up now</Trans>
                            </Button>
                            <Button variant="secondary" onClick={handleOnClose}>
                                <Trans>Later</Trans>
                            </Button>
                        </Inline>
                    </>
                )
            case 'wallet-overdraft':
                return (
                    <Inline space={16}>
                        <Button variant="primary" onClick={handleNavigateToOverdraft}>
                            <Trans>Activate overdraft</Trans>
                        </Button>
                        <Button variant="secondary" onClick={handleOnClose}>
                            <Trans>Not now</Trans>
                        </Button>
                    </Inline>
                )
            default:
                return (
                    <Button variant="primary" onClick={handleOnClose}>
                        <Trans>Continue</Trans>
                    </Button>
                )
        }
    }

    useEffect(() => {
        if (newPlan) {
            trackEvent('viewed')
        }
    }, [newPlan, trackEvent])

    return (
        <StepWrapper align="center" space={24}>
            <img
                src={isUpgrade ? UpgradeConfirmationSVG : DowngradeConfirmationSVG}
                alt=""
                width={320}
                height={240}
                css={{margin: '0 auto'}}
            />
            <Text variant="2xlarge-accent" weight="medium">
                <Trans>Welcome to {planName} plan</Trans>
            </Text>
            <Text color="colorContentStaticQuiet">
                {getPlanChangeText({isUpgrade, isBeforeTrial, hasUserPickedTrialPlanFlag})}
            </Text>
            {renderActions()}
        </StepWrapper>
    )
}

const getPlanChangeText = ({
    isUpgrade,
    isBeforeTrial,
    hasUserPickedTrialPlanFlag,
}: {
    isUpgrade: boolean
    isBeforeTrial: boolean
    hasUserPickedTrialPlanFlag: boolean
}) => {
    if (hasUserPickedTrialPlanFlag && isBeforeTrial) {
        if (isUpgrade) {
            return t`Your account has been updated and all new features have been unlocked.`
        }

        return t`Your account has been updated together with any feature changes.`
    }

    if (isUpgrade) {
        return t`Your account has been updated and all new features have been unlocked. We've sent an email confirming the details of your new plan.`
    }

    return t`Your account has been updated together with any feature changes. We've also sent you an email confirming the details of your new plan.`
}
