import {usePromptsPersistedState} from '@product-web/feature--funds-management/feature-addoption/auto-top-up-adoption-prompts/auto-top-up-adoption-prompts-state'
import {useFlags} from '@product-web/shared--flags'
import {useUser} from '@product-web/shared--user'

import {bffHooks} from '../bff-hooks'

/**
 *
 * @returns {boolean} - Whether the Overdraft Awareness Flow should be visible to the Admin
 *
 * @return {true} if all of the following conditions are met:
 * - if user is an Admin
 * - if company doesn't have an active overdraft
 * - if company is eligible for overdraft
 * - if company balance is not below threshold OR if is user has dismissed the Overdraft AdoptionPrompt
 * - if feature-gate is enabled
 */

export const useOverdraftAwarenessFlow = () => {
    const {overdraftAwarenessFlow: flagEnabled} = useFlags()
    const user = useUser()

    const canCheckForOverdraftActive = flagEnabled && user?.role === 'owner'

    const {data: isOverdraftActivated} =
        bffHooks.overdraft.overdraftAwarenessFlowRouter.isCompanyOverdraftActive.useQuery(
            undefined,
            {
                enabled: canCheckForOverdraftActive,
            },
        )

    const {dismissedPrompts} = usePromptsPersistedState()

    const {data: isCompanyBalanceBelowThreshold = false} =
        bffHooks.overdraft.overdraftAwarenessFlowRouter.isCompanyBalanceBelowThreshold.useQuery(
            undefined,
            {
                enabled: !dismissedPrompts?.dismissedDate,
            },
        )

    const canCheckForOverdraftAwarenessFlow =
        flagEnabled &&
        user?.role === 'owner' &&
        !isOverdraftActivated &&
        !isCompanyBalanceBelowThreshold // only check for overdraft awareness flow if company balance is not below threshold

    const {data} =
        bffHooks.overdraft.overdraftAwarenessFlowRouter.isCompanyEligibleForOverdraft.useQuery(
            undefined,
            {
                enabled: canCheckForOverdraftAwarenessFlow,
            },
        )

    return {
        isEligible: data?.isEligible ?? false,
        terms: data?.terms,
    }
}
