/* eslint-disable string-to-lingui/text-restrictions */

import {t, Trans} from '@lingui/macro'
import * as React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import {Button, Inline, ModalSplit, Stack, tokens} from '@pleo-io/telescope'
import {Text} from '@pleo-io/telescope'
import {CheckCircleFilled, CircleEmpty} from '@pleo-io/telescope-icons'

import {dayjs} from '@product-web/shared--dates/dayjs'
import {formatCurrency} from '@product-web/shared--locale/currency'
import type {ActivePlanType} from '@product-web/shared--paywall/types/plans'
import {PlanTypeName} from '@product-web/shared--paywall/types/plans'
import {useUserPickedTrialPlanFlag} from '@product-web/shared--plan-presentation/use-user-picked-trial-plan-flag'
import {useUser} from '@product-web/shared--user'
import {exhaustiveCheck} from '@product-web/shared--utils'

import trialEndIllustration from './images/person-in-clock.svg'
import trialStartIllustration from './images/waiter.svg'

import {bff} from '../bff-hooks'

//#region Components

export type TrialModalProps = {
    hasDismissedTrialStartedModal: boolean
    hasDismissedTrialEndingModal: boolean
    hasDismissedTrialEndedModal: boolean
}

export const TrialModal = ({
    hasDismissedTrialStartedModal,
    hasDismissedTrialEndingModal,
    hasDismissedTrialEndedModal,
}: TrialModalProps) => {
    const user = useUser()
    const navigate = useNavigate()
    const location = useLocation()
    const [hasBeenDismissed, setHasBeenDismissed] = React.useState(false)
    const {data} = bff.featureOnboarding.trialModal.getData.useQuery({
        numUsers: user?.company?.numberOfUsers,
    })
    const {mutateAsync: markModalAsDismissed} =
        bff.featureOnboarding.trialModal.markModalAsDismissed.useMutation()

    if (!data) {
        return null
    }

    // We don't want to show the trial started modal during accounting setup
    // so it doesn't interrupt users on their way back from integrating with
    // their accounting software and continuing that setup
    const isAccountingRoute = ['/onboarding/setup-accounting', '/settings/accounting/setup'].some(
        (path) => location.pathname.startsWith(path),
    )

    const shouldShowModalByType =
        (data.trialStatus === 'STARTED' && !hasDismissedTrialStartedModal && !isAccountingRoute) ||
        (data.trialStatus === 'ALMOST_ENDED' && !hasDismissedTrialEndingModal) ||
        (data.trialStatus === 'ENDED' && !hasDismissedTrialEndedModal)

    const shouldShowModal = !hasBeenDismissed && shouldShowModalByType

    const formattedFirstWalletLoadDate = data.firstWalletLoadDate
        ? dayjs(data.firstWalletLoadDate).format('Do MMMM')
        : null
    const formattedLastBillingDate = dayjs(data.lastBillingDate).format('Do MMMM')
    const formattedNextBillingDate = dayjs(data.nextBillingDate).format('Do MMMM')
    const formattedBillingAmount = customFormatCurrency(data.estimatedBillingAmount)

    const dismiss = async () => {
        setHasBeenDismissed(true)

        await markModalAsDismissed(data.trialStatus)
    }

    const timelineItems = [
        {
            // This one should always be complete since we should only show this modal if the trial has started
            isComplete: true,
            title: t`Start of trial`,
            description: formattedFirstWalletLoadDate
                ? t`On ${formattedFirstWalletLoadDate}, your funds arrived in your Pleo wallet.`
                : t`Your funds arrived in your Pleo wallet.`,
        },
        {
            isComplete: data.isAfterTrialEndReminder,
            title: t`Day 14: Reminder`,
            description: data.isAfterTrialEndReminder
                ? t`We sent an email to remind you that your trial ends in 7 days.`
                : t`You get an email reminding you that your trial ends in 7 days.`,
        },
        {
            isComplete: data.trialStatus === 'ENDED',
            title: t`Day 21: End of trial`,
            description:
                data.trialStatus === 'ENDED'
                    ? t`On ${formattedLastBillingDate}, you were billed ${formattedBillingAmount} for your annual subscription.`
                    : t`On ${formattedNextBillingDate}, you'll be billed ${formattedBillingAmount} for your annual subscription. Change your plan anytime before.`,
        },
    ]

    const title = getModalTitle(data.trialStatus)

    return (
        <ModalSplit
            isOpen={shouldShowModal}
            onDismiss={dismiss}
            data-testid="trial-modal"
            aria-label={title}
        >
            <ModalSplit.Content>
                <ModalSplit.Body>
                    <ModalSplit.Title>{title}</ModalSplit.Title>

                    <Stack space={40}>
                        <Text>{getModalDescription(data.trialStatus, data.currentPlan)}</Text>

                        <ol>
                            {timelineItems.map((item, index, arr) => {
                                const lastCompleteItem = arr.findLast((i) => i.isComplete)
                                const lastCompleteItemIndex = lastCompleteItem
                                    ? arr.indexOf(lastCompleteItem)
                                    : 0

                                const Icon =
                                    index <= lastCompleteItemIndex ? CheckCircleFilled : CircleEmpty

                                return (
                                    <TimelineItem
                                        as="li"
                                        key={item.title}
                                        aria-current={
                                            index === lastCompleteItemIndex ? 'step' : undefined
                                        }
                                    >
                                        {index < arr.length - 1 && (
                                            <TimelineItemTail
                                                $highlight={index <= lastCompleteItemIndex}
                                            />
                                        )}

                                        <Inline as="span" space={14}>
                                            <TimelineIconWrapper>
                                                <Icon
                                                    size={16}
                                                    color={
                                                        index <= lastCompleteItemIndex
                                                            ? tokens.colorContentStatic
                                                            : tokens.colorBorderStaticLoud
                                                    }
                                                />
                                            </TimelineIconWrapper>

                                            <Stack as="span" space={2}>
                                                <TimelineItemTitle>{item.title}</TimelineItemTitle>

                                                <Text
                                                    variant="small-subtle"
                                                    color="colorContentStatic"
                                                >
                                                    {item.description}
                                                </Text>
                                            </Stack>
                                        </Inline>
                                    </TimelineItem>
                                )
                            })}
                        </ol>
                    </Stack>
                </ModalSplit.Body>

                {data.trialStatus === 'STARTED' && (
                    <Actions>
                        <RightAlignedButton
                            variant="primary"
                            onClick={async () => {
                                dismiss()
                                navigate('/onboarding/get-started')
                            }}
                        >
                            <Trans>Explore Pleo</Trans>
                        </RightAlignedButton>
                    </Actions>
                )}

                {(data.trialStatus === 'ALMOST_ENDED' || data.trialStatus === 'ENDED') && (
                    <Actions>
                        <Button
                            variant="secondary"
                            onClick={async () => {
                                dismiss()
                                navigate('/settings/general/billing?choosePlan=true')
                            }}
                        >
                            <Trans>Explore plans</Trans>
                        </Button>

                        <Button variant="primary" onClick={dismiss}>
                            <Trans>Continue</Trans>
                        </Button>
                    </Actions>
                )}
            </ModalSplit.Content>

            <ModalSplit.IllustrationContainer>
                <ModalSplit.Illustration>
                    <img src={getModalIllustration(data.trialStatus)} alt="" />
                </ModalSplit.Illustration>
            </ModalSplit.IllustrationContainer>
        </ModalSplit>
    )
}

export const TrialModalContainer = () => {
    const {data} = bff.featureOnboarding.trialModal.getContainerData.useQuery()
    const {hasUserPickedTrialPlanFlag, isLoadingUserPickedTrialPlanFlag} =
        useUserPickedTrialPlanFlag()

    if (!data || isLoadingUserPickedTrialPlanFlag || !hasUserPickedTrialPlanFlag) {
        return null
    }

    // If the user has already dismissed all modal variants, we don't need to show anything
    if (
        data.hasDismissedTrialStartedModal &&
        data.hasDismissedTrialEndingModal &&
        data.hasDismissedTrialEndedModal
    ) {
        return null
    }

    return (
        <TrialModal
            hasDismissedTrialStartedModal={data.hasDismissedTrialStartedModal}
            hasDismissedTrialEndingModal={data.hasDismissedTrialEndingModal}
            hasDismissedTrialEndedModal={data.hasDismissedTrialEndedModal}
        />
    )
}

//#endregion Components

//#region Helpers

type TrialStatus = 'STARTED' | 'ALMOST_ENDED' | 'ENDED'
type TrialPlan = Exclude<`${ActivePlanType}`, 'STARTER'>

const getModalTitle = (trialStatus: TrialStatus) => {
    switch (trialStatus) {
        case 'STARTED':
            return t`Your trial has started!`
        case 'ALMOST_ENDED':
            return t`Your free trial ends tomorrow`
        case 'ENDED':
            return t`Your free trial has ended`
        default:
            return exhaustiveCheck(trialStatus)
    }
}

const getModalDescription = (trialStatus: TrialStatus, currentPlan: TrialPlan) => {
    const planName = PlanTypeName[currentPlan]

    switch (trialStatus) {
        case 'STARTED':
            return t`Get a head start on expenses with free access to Pleo ${planName}.`
        case 'ALMOST_ENDED':
        case 'ENDED':
            switch (currentPlan) {
                case 'ESSENTIAL':
                    return t`Stay on Essential plan to keep using limits, purchase reviews, and other cost-control tools.`
                case 'ADVANCED':
                    return t`Stay on Advanced plan to keep using limits, purchase reviews, and other cost-control tools at scale.`
                case 'BEYOND':
                    return t`Stay on Beyond plan to keep using cost-control tools with a specialised support.`
                default:
                    return exhaustiveCheck(currentPlan)
            }
        default:
            return exhaustiveCheck(trialStatus)
    }
}

const getModalIllustration = (trialStatus: TrialStatus) => {
    switch (trialStatus) {
        case 'STARTED':
            return trialStartIllustration
        case 'ALMOST_ENDED':
        case 'ENDED':
            return trialEndIllustration
        default:
            return exhaustiveCheck(trialStatus)
    }
}

type CustomFormatCurrencyArgs = {
    value: number
    currency: string
}

const customFormatCurrency = ({value, currency}: CustomFormatCurrencyArgs) => {
    const majorCurrencyValue = value / 100
    const decimalPlaces = Number.isInteger(majorCurrencyValue) ? 0 : 2

    return formatCurrency(majorCurrencyValue, currency, {
        format: {
            currencyDisplay: 'symbol',
            style: 'currency',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
            currency,
        },
    })
}

//#endregion Helpers

//#region Styles

// The 16px icons have a 2px of whitespace on each side. We need to adjust positioning of the timeline tail to ensure
// the tail touches the actual icon
const offset = '2px'
const iconSize = '16px' // ← Make sure to match with the icon size in the markup
const timelineItemTailWidth = '2px'

const TimelineItemTitle = styled(Text).attrs({
    weight: 'medium',
})``

const TimelineItem = styled(TimelineItemTitle)`
    position: relative;

    &:not(:last-child) {
        padding-bottom: ${tokens.spacing24};
    }
`

const TimelineIconWrapper = styled.span`
    display: flex;
    /* Pushes the icon down slightly to center-align with the title text */
    margin-top: calc((1lh - ${iconSize}) / 2);
`

type TimelineItemTailProps = {
    $highlight: boolean
}

const TimelineItemTail = styled.span<TimelineItemTailProps>`
    position: absolute;
    inset-block-start: calc(${iconSize} - ${offset} + ((1lh - ${iconSize}) / 2));
    inset-inline-start: calc((${iconSize} - ${timelineItemTailWidth}) / 2);
    height: calc(100% - ${iconSize} + (${offset} * 2));
    width: ${timelineItemTailWidth};
    background: ${($props) =>
        $props.$highlight ? tokens.colorBorderInteractiveLoud : tokens.colorBorderStaticLoud};
`

const RightAlignedButton = styled(Button)`
    margin-left: auto;
`

const Actions = styled(ModalSplit.Actions)`
    flex-wrap: wrap;
    gap: ${tokens.spacing16};
`

//#endregion Styles
