import type {PlansData} from '../../index.bff'

type GetSortedPlansArgs = {
    isSmallScreen: boolean
    currentPlan?: PlansData['currentPlan']
    availableSubscriptions?: PlansData['plansPricing']
}

export const getSortedPlans = ({
    isSmallScreen,
    currentPlan,
    availableSubscriptions,
}: GetSortedPlansArgs) => {
    if (!availableSubscriptions?.length) {
        return []
    }

    if (!isSmallScreen || !currentPlan) {
        return availableSubscriptions
    }

    const currentPlanSubscription = availableSubscriptions.find(
        (subs) => subs.planName === currentPlan,
    )

    if (currentPlanSubscription) {
        return [
            ...availableSubscriptions.filter((subs) => subs.planName !== currentPlan),
            currentPlanSubscription,
        ]
    }

    return availableSubscriptions
}
