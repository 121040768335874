import type {FC} from 'react'

import {ExpenseViewType} from '@pleo-io/deimos'
import type {Props as IconProps} from '@pleo-io/telescope-icons'
import {ArrowCircleLeft, CashSingle, Reimburse, SendMoney} from '@pleo-io/telescope-icons'

import {customColorSchemeTokens} from '@product-web/shared--styles/custom-tokens'

interface Props extends IconProps {
    type?: ExpenseViewType | string
}

export const CashIcon: FC<Props> = ({type, color, size}) => {
    switch (type) {
        case ExpenseViewType.EXPENSE:
        case ExpenseViewType.REIMBURSEMENT:
        case 'REIMBURSEMENT':
        case 'EXTERNAL_REIMBURSEMENT':
            return (
                <Reimburse
                    size={size}
                    color={color ?? customColorSchemeTokens.colorContentCashIcon}
                />
            )
        case ExpenseViewType.TRANSFER_REFUND:
        case 'TRANSFER_REFUND':
        case 'MANUAL_REFUND':
            return (
                <SendMoney
                    size={size}
                    color={color ?? customColorSchemeTokens.colorContentCashIcon}
                />
            )
        case ExpenseViewType.CASH_RETURNED:
        case 'CASH_RETURNED':
            return (
                <ArrowCircleLeft
                    size={size}
                    color={color ?? customColorSchemeTokens.colorContentCashIcon}
                />
            )
        default:
            return (
                <CashSingle
                    size={size}
                    color={color ?? customColorSchemeTokens.colorContentCashIcon}
                />
            )
    }
}
