import {useCallback} from 'react'

import type {AutoTopUpStatus, AutoTopUpType} from '@product-web/shared--api-types/wallet'
import {useLoggedInUser} from '@product-web/shared--user'
import {createPersistedState} from '@product-web/shared--web-platform/persisted-state'
import {localStorage} from '@product-web/shared--web-platform/storage/local'

import type {
    SelectedBankInstitution,
    VrpBankAccountDetails,
} from './types/vrp-auto-top-up-institution.types'

const [usePersistedState] = createPersistedState('autoTopUp', localStorage)

// we need to use the local storage here (instead of React context, for example),
// because we need to store provided settings while user redirects to and from their bank website,
// where they receive the consent token to approve the Open Banking transfer
// todo: create a hook to navigate to the VRP activation flow with all the necessary params - https://linear.app/pleo/issue/WALLE-6069
export function useVrpAutoTopUpStorage() {
    const user = useLoggedInUser()
    const [savedVrpAutoTopUpSettings, setSavedVrpAutoTopUpSettings] = usePersistedState<{
        currency: string
        autoTopupStatus?: AutoTopUpStatus
        autoTopupType: AutoTopUpType
        autoTopUpAmount: number
        autoTopUpThreshold: number
        pleoReserveEnabled?: boolean
        isSwitchingToFastOptionFlow?: boolean
        isOnlyVrpPaymentMethodSelected?: boolean
        hasTransferRulesEligibility?: boolean
        preFilledTransferRuleConfiguration?: {
            sourceOfFunds?: {accountId: string; mandateId: string}
            skipTopUpSettings?: {
                isSkipTopUpConditionEnabled: boolean
                sourceAccountBalanceThreshold: number
            }
        }
        selectedBankInstitution?: SelectedBankInstitution
        selectedBankAccountDetails?: VrpBankAccountDetails
    } | null>(`vrpAutoTopUpSettings_${user.companyId}`, null)

    const cleanSavedVrpAutoTopUpSettings = useCallback(() => {
        setSavedVrpAutoTopUpSettings(null)
    }, [setSavedVrpAutoTopUpSettings])

    return {
        savedVrpAutoTopUpSettings,
        setSavedVrpAutoTopUpSettings,
        cleanSavedVrpAutoTopUpSettings,
    }
}
