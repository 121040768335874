import {t, Trans} from '@lingui/macro'
import {useState} from 'react'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Box, Button, Inline, Link, PromotionalBanner, Stack, Text, tokens} from '@pleo-io/telescope'
import {
    ArrowLeft,
    ArrowRight,
    CircleCheck,
    Clock,
    Heart,
    Team,
    UserSecurity,
} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'
import * as tracking from '@product-web/shared--analytics'
import {InlineWistiaVideo} from '@product-web/shared--telescope-lab/wistia-video/inline-wistia-video'

import {LessonNavigation} from './lesson-navigation'

import {SplitContent} from '../../../../components/split-content'
import inviteMyTeamImg from '../../../../images/academy/invite-my-team-demo.webp'
import manageMyClientsImg from '../../../../images/academy/manage-my-clients-demo.webp'
import onboardMyClientsImg from '../../../../images/academy/onboard-my-clients-demo.webp'
import {CourseCompleteModal} from '../../components/course-complete-modal'
import {OverviewRight} from '../../components/overview-right/overview-right'
import {CardGroup, DemoCard} from '../../components/snapshot'

export const ManageClientsAndTeams = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleModal = () => setIsOpen(!isOpen)

    return (
        <>
            <Helmet>
                <title>{t`Get started with the Partner Portal`}</title>
            </Helmet>
            <Page.Header css={{backgroundColor: tokens.colorBackgroundInteractive}}>
                <Page.Backlink>
                    <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                        <Trans>Back to overview</Trans>
                    </Link>
                </Page.Backlink>
                <Page.Title>
                    <Trans>Get started with the Partner Portal</Trans>
                </Page.Title>
                <Inline alignItems="center" space={6} mb={24}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>8 mins</Trans>
                    </Text>
                </Inline>
                <Text as="h3" variant="xlarge-accent">
                    <Trans>Module overview</Trans>
                </Text>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main flex={4}>
                    <Text>
                        <Trans>
                            Explore how Pleo's Partner Portal can help you onboard and manage your
                            clients, and streamline your team's bookkeeping workflows.
                        </Trans>
                    </Text>
                    <Box mt={24}>
                        <InlineWistiaVideo
                            ariaLabel={t`Play video: Simplify bookkeeping workflow`}
                            thumbnail={{
                                src: 'https://embed-ssl.wistia.com/deliveries/082db6fb92c9ab528b3acd97bd391bb501ac45f3.jpg?video_still_time=1.6',
                            }}
                            videoId="sx3n4lijfg"
                            autoPlay={false}
                            trackingCallback={(action) =>
                                tracking.partnerAcademy({action, video: 'manage_clients_and_team'})
                            }
                        />
                    </Box>
                    <Stack my={48} space={24}>
                        <PromotionalBanner variant="bordered">
                            <PromotionalBanner.Content>
                                <PromotionalBanner.ContentHeading>
                                    <Inline space={12}>
                                        <UserSecurity size={24} />
                                        <Trans>Improve efficiency with centralised access</Trans>
                                    </Inline>
                                </PromotionalBanner.ContentHeading>
                                <PromotionalBanner.ContentText>
                                    <Trans>
                                        A centralised view of all your Pleo clients gives you peace
                                        of mind that your team has the books under control.
                                    </Trans>
                                </PromotionalBanner.ContentText>
                            </PromotionalBanner.Content>
                        </PromotionalBanner>
                        <PromotionalBanner variant="bordered">
                            <PromotionalBanner.Content>
                                <PromotionalBanner.ContentHeading>
                                    <Inline space={12}>
                                        <Heart size={24} />
                                        <Trans>
                                            Access exclusive training, support and resources
                                        </Trans>
                                    </Inline>
                                </PromotionalBanner.ContentHeading>
                                <PromotionalBanner.ContentText>
                                    <Trans>
                                        Your dedicated hub for training, content to support you and
                                        your clients, and materials to improve your collaboration
                                        with us.
                                    </Trans>
                                </PromotionalBanner.ContentText>
                            </PromotionalBanner.Content>
                        </PromotionalBanner>
                        <PromotionalBanner variant="bordered">
                            <PromotionalBanner.Content>
                                <PromotionalBanner.ContentHeading>
                                    <Inline space={12}>
                                        <Team size={24} />
                                        <Trans>Enhance collaboration with our team</Trans>
                                    </Inline>
                                </PromotionalBanner.ContentHeading>
                                <PromotionalBanner.ContentText>
                                    <Trans>
                                        Find everything you need in the Partner Portal to make your
                                        partnership with us a true success.
                                    </Trans>
                                </PromotionalBanner.ContentText>
                            </PromotionalBanner.Content>
                        </PromotionalBanner>
                        <PromotionalBanner variant="bordered">
                            <PromotionalBanner.Content>
                                <PromotionalBanner.ContentHeading>
                                    <Inline space={12}>
                                        <CircleCheck size={24} />
                                        <Trans>Manage your partnership with ease</Trans>
                                    </Inline>
                                </PromotionalBanner.ContentHeading>
                                <PromotionalBanner.ContentText>
                                    <Trans>
                                        Easily control everything from client access to compliance
                                        in one place.
                                    </Trans>
                                </PromotionalBanner.ContentText>
                            </PromotionalBanner.Content>
                        </PromotionalBanner>
                    </Stack>
                    <Box my={48}>
                        <Text as="h4" space={32} variant="xlarge-accent" weight="semibold">
                            <Trans>Try for yourself</Trans>
                        </Text>
                        <CardGroup minChildWidth={350}>
                            <DemoCard
                                demoUrl="https://app.navattic.com/preview/flow/cm691tp7w000103jx6yg26kjm?s=0"
                                image={inviteMyTeamImg}
                                title={t`How to invite my team`}
                            />
                            <DemoCard
                                demoUrl="https://app.navattic.com/preview/flow/cm6kjn9fb000003kz363calkw?s=0"
                                image={manageMyClientsImg}
                                title={t`How to manage my clients`}
                            />
                            <DemoCard
                                demoUrl="https://app.navattic.com/preview/flow/cm6kjnlsm000003jzfz0r9npg?s=0"
                                image={onboardMyClientsImg}
                                title={t`How to onboard my clients`}
                            />
                        </CardGroup>
                    </Box>
                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link as={RouterLink} to="../unlock-growth" IconLeft={ArrowLeft}>
                            <Trans>Back</Trans>
                        </Link>
                        <Button variant="primary" IconRight={ArrowRight} onClick={toggleModal}>
                            <Trans>Complete course</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <OverviewRight>
                        <LessonNavigation />
                    </OverviewRight>
                </SplitContent.Right>
            </SplitContent>
            <CourseCompleteModal completedCourse={t`Smarter spend management`} isOpen={isOpen} />
        </>
    )
}
