import {Outlet, useLocation, useNavigate} from 'react-router-dom'

import {LoadingPage} from '@pleo-io/telescope'

import {useFlags, useFlagsLoaded} from '@product-web/shared--flags'

import {useCashManagementAdoptionState} from '../adoption-switch/use-cash-management-adoption-state'
import {sharedCashManagementBff} from '../bff-hooks'

const redirects: Record<string, string> = {
    '/wallet': '/cash-management',
    '/wallet/manage/overdraft/statements': '/cash-management/overdraft/statements',
    '/wallet/manage/overdraft/change-limit': '/cash-management/overdraft/manage/change-limit',
    '/wallet/manage/overdraft': '/cash-management/overdraft',
    '/wallet/manage/auto-top-up': '/cash-management/auto-top-up',
    '/wallet/manage/direct-debit': '/cash-management/direct-debit',
    '/wallet/manage/funding-source': '/cash-management',
    '/wallet/create-sub-wallet': '/cash-management/create-sub-wallet',
    '/wallet/withdrawal': '/cash-management/withdrawal',
    '/wallet/history': '/cash-management/balance-history',
    '/wallet/unload': '/cash-management/unload',
    '/wallet/overdraft/activate': '/cash-management/overdraft/activate',
    '/wallet/overdraft/renew': '/cash-management/overdraft/renew',
    '/wallet/overdraft/eligibility-application':
        '/cash-management/overdraft/eligibility-application',
    '/wallet/overdraft/eligibility-application/info-required':
        '/cash-management/overdraft/eligibility-application/info-required',
    '/wallet/overdraft/higher-limit-application':
        '/cash-management/overdraft/higher-limit-application',
    '/wallet/overdraft/higher-limit-application/info-required':
        '/cash-management/overdraft/higher-limit-application/info-required',
    '/wallet/direct-debit': '/cash-management/direct-debit/create',
    '/wallet/direct-debit/overdraft': '/cash-management/direct-debit/create/overdraft',
    '/wallet/direct-debit/auto-top-up': '/cash-management/direct-debit/create/auto-top-up',
    '/wallet/auto-top-up-activation': '/cash-management/auto-top-up-activation',
}

export function WalletCashManagementRedirects() {
    const navigate = useNavigate()
    const {cashManagementFlow} = useFlags()
    const flagLoaded = useFlagsLoaded()
    const location = useLocation()
    const [isCashManagementEnabled] = useCashManagementAdoptionState()
    const {data, isInitialLoading} =
        sharedCashManagementBff.walletCashManagementRedirects.getWalletCashManagementRedirects.useQuery()

    const isLoading = isInitialLoading || !data || !flagLoaded
    if (isLoading) {
        return <LoadingPage />
    }

    const isWalletPage = location.pathname.includes('/wallet')

    const shouldRedirect =
        isWalletPage &&
        isCashManagementEnabled &&
        cashManagementFlow &&
        data.hasCashManagementAdoption

    if (shouldRedirect) {
        const redirectPath = redirects[location.pathname]
        navigate(redirectPath ?? '/cash-management')
    }

    return <Outlet />
}
