import {t, Trans} from '@lingui/macro'
import {useState} from 'react'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Box, Button, Inline, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft, Clock, Rocket, Team, User} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'
import * as tracking from '@product-web/shared--analytics'
import {InlineWistiaVideo} from '@product-web/shared--telescope-lab/wistia-video/inline-wistia-video'

import {LessonNavigation} from './lesson-navigation'

import {SplitContent} from '../../../../components/split-content'
import {useAcademyContext} from '../../components/academy-context/academy-context'
import {CourseCompleteModal} from '../../components/course-complete-modal'
import {OverviewRight, UsefulMaterials} from '../../components/overview-right/overview-right'
import {Card, CardGroup} from '../../components/snapshot'

export const MultiEntities = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleModal = () => setIsOpen(!isOpen)
    const {country, showSpendManagement} = useAcademyContext()

    const getVideo = () => {
        switch (country) {
            case 'AT':
            case 'DE':
                return {
                    id: 'vohrc7r06t',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/8fb624866b8556819705a81a87688c5d.webp',
                }
            case 'DK':
            case 'GL':
                return {
                    id: 'cmp6epuylj',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/6bfb7893c8893378013fe2d476d66a93.webp',
                }
            case 'FR':
                return {
                    id: 'd4900ldx5d',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/3c0c6bc78c72b67568d8b9e611b5dcd2.webp',
                }
            case 'SE':
                return {
                    id: 'l54dmesvq5',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/325f2f251ca338cce07142360830bbe9.webp',
                }
            case 'ES':
                return {
                    id: '4esjjd7mty',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/3706cc05a76df50f5d93b12ecf8be9eb.webp',
                }
            case 'NL':
                return {
                    id: 'je6uuumi8w',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/e0fbb54e74562e232c249af0dfa212a0.webp',
                }
            default:
                // default is GB
                return {
                    id: 'ay3tybefub',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/ab12821c0c17c08f48cb3dae8090f8c8.webp',
                }
        }
    }

    const getMultiEntitiesUrl = () => {
        switch (country) {
            case 'DE':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/pT4-eOzWoFyLUCvU#/content/cf66a505-25b0-4ee8-8832-b96bd3c56513'
            case 'DK':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/x4stBu6d_7KE-2Uq#/content/2683c095-aae5-4612-abf7-1420f922da06'
            case 'SE':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/zfIDiA-z2hCp8Nyr#/content/cba48fe8-df92-4fc1-a6cb-f02a3e8152bb'
            default:
                // Default is EN
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/VrNiy3RHUlDWoLsF#/content/9475e3b6-74d1-482f-87fa-27b171d6299b'
        }
    }

    return (
        <>
            <Helmet>
                <title>{t`The Pleo Product`}</title>
            </Helmet>
            <Page.Header>
                <Page.Backlink>
                    <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                        <Trans>Back to overview</Trans>
                    </Link>
                </Page.Backlink>
                <Page.Title>
                    <Trans>The Pleo Product</Trans>
                </Page.Title>
                <Inline alignItems="center" space={6} mb={8}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>2 mins</Trans>
                    </Text>
                </Inline>
                <SplitContent>
                    <SplitContent.Main>
                        <Text>
                            <Trans>
                                Manage your client's multiple entities across Europe easily.
                            </Trans>
                        </Text>
                        <Box mb={24} />
                    </SplitContent.Main>
                    <SplitContent.Right></SplitContent.Right>
                </SplitContent>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main flex={4}>
                    <Stack space={16}>
                        <Text as="h2" variant="2xlarge-accent" space={4}>
                            <Trans>Multi-entities</Trans>
                        </Text>
                        <Text>
                            <Trans>
                                Complete visibility and control over business spending with one
                                connected tool that works across all of Europe, and soon the US.
                            </Trans>
                        </Text>
                        <InlineWistiaVideo
                            ariaLabel={t`Play video - Multi-entities`}
                            thumbnail={{src: getVideo().thumbnail}}
                            videoId={getVideo().id}
                            trackingCallback={(action) =>
                                tracking.partnerAcademy({action, video: 'multi_entities'})
                            }
                            autoPlay={false}
                            imgMinHeight={348}
                        />
                        <Box mb={24} />
                        <Text as="h2" variant="2xlarge-accent">
                            <Trans>Multi-entities in a snapshot</Trans>
                        </Text>
                        <CardGroup>
                            <Card
                                icon={<Team />}
                                title={t`Multi-entity teams`}
                                description={t`Create global teams with members and reviewers from different entities. Approve spend across entities easily.`}
                            />
                            <Card
                                icon={<User />}
                                title={t`No exchange rate surcharge`}
                                description={t`Enjoy 0% exchange rate surcharges instead of 2% fee per expense.`}
                            />
                            <Card
                                icon={<Rocket />}
                                title={t`End of immediate accounting work`}
                                description={t`Export and post directly to your or your clients' local accounts.`}
                            />
                        </CardGroup>
                    </Stack>
                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link as={RouterLink} to="../vendor-cards" IconLeft={ArrowLeft}>
                            <Trans>Back</Trans>
                        </Link>
                        <Button variant="primary" onClick={toggleModal}>
                            <Trans>Complete course</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <OverviewRight>
                        <LessonNavigation />
                        <UsefulMaterials>
                            <UsefulMaterials.Document url={getMultiEntitiesUrl()}>
                                <Trans>Multi-entities</Trans>
                            </UsefulMaterials.Document>
                            <UsefulMaterials.HelpCenterArticle articleId={103000254598}>
                                <Trans>Help centre: Multi-entity management</Trans>
                            </UsefulMaterials.HelpCenterArticle>
                        </UsefulMaterials>
                    </OverviewRight>
                </SplitContent.Right>
            </SplitContent>
            <CourseCompleteModal
                completedCourse={t`The Pleo Product`}
                nextCourse={
                    showSpendManagement
                        ? t`Transform your practice with smarter spend management`
                        : undefined
                }
                nextCoursePath={showSpendManagement ? 'smarter-spend-management' : undefined}
                isOpen={isOpen}
            />
        </>
    )
}
