import {Formik} from 'formik'
import {useEffect, useState} from 'react'

import {Accordion} from '@pleo-io/telescope'

import tracking from '@product-web/shared--analytics'
import type {CompanyAdoptionGuideResponse} from '@shared/bff--moons/generated/auxo'

import {ExportGuide, PRO_TIPS_ACCORDION_ID} from './guides/export-guide'
import {AdoptionGuides, getGuideToShow} from './guides/helper'
import {MORE_ITEM_ACCORDION_ID, MoreItem} from './more-section/more-item'

import {FeedbackState} from '../feedback/feedback'
import {Unhelpful, UnhelpfulReason} from '../feedback/unhelpful'

interface AdoptionAccordionProps {
    guides: CompanyAdoptionGuideResponse[]
    closeAdoptionCentre: () => void
}

export enum AdoptionAccordionView {
    INITIAL = 'INITIAL',
    UNHELPFUL_FEEDBACK = 'UNHELPFUL_FEEDBACK',
    GUIDE_DETAILS = 'GUIDE_DETAILS',
}

export const AdoptionAccordion = ({guides, closeAdoptionCentre}: AdoptionAccordionProps) => {
    const guide = getGuideToShow(guides)

    const [feedbackState, setFeedbackState] = useState(FeedbackState.None)
    const [view, setView] = useState<AdoptionAccordionView>(AdoptionAccordionView.INITIAL)

    const defaultOpenAccordionId = guide ? PRO_TIPS_ACCORDION_ID : MORE_ITEM_ACCORDION_ID

    const [viewTracked, setViewTracked] = useState(false)
    const [accordionValue, setAccordionValue] = useState<string[]>([defaultOpenAccordionId])

    useEffect(() => {
        if (accordionValue.includes(PRO_TIPS_ACCORDION_ID) && !viewTracked && guide) {
            tracking.adoptionEssentialsCentreActioned({
                action: 'viewed_guide',
                guide: guide.adoptionGuide,
            })
            setViewTracked(true)
        }
    }, [viewTracked, setViewTracked, guide, accordionValue])

    const values = {
        unhelpful: {
            reason: String(UnhelpfulReason.None),
            otherReason: '',
        },
        helpful: {
            feedback: '',
        },
    }

    return (
        <Accordion
            type="multiple"
            value={accordionValue}
            data-testid="adoption-accordion"
            onValueChange={(newValue) => setAccordionValue(newValue)}
        >
            <Formik initialValues={values} onSubmit={() => {}}>
                <>
                    {guide && guide.adoptionGuide === AdoptionGuides.ExportEssentials ? (
                        <ExportGuide
                            view={view}
                            setView={setView}
                            guide={guide}
                            feedbackState={feedbackState}
                            setFeedbackState={setFeedbackState}
                        />
                    ) : (
                        <MoreItem />
                    )}

                    {guide && view === AdoptionAccordionView.UNHELPFUL_FEEDBACK && (
                        <Unhelpful
                            setFeedbackState={setFeedbackState}
                            guideName={guide.adoptionGuide}
                            closeAdoptionCentre={closeAdoptionCentre}
                            setView={setView}
                        />
                    )}
                </>
            </Formik>
        </Accordion>
    )
}
