import {Trans} from '@lingui/macro'

import {Inline, Text, tokens} from '@pleo-io/telescope'
import {Bank, Close} from '@pleo-io/telescope-icons'

import {SupportedLanguage} from '@product-web/shared--i18n'
import {useUser} from '@product-web/shared--user'
import type {BillingInfoCurrency} from '@shared/bff--moons/generated/beyond'

import {formatMinorNumberToCurrency} from './currency-formatter'
import type {PlanConfiguration} from './pricing-plans.helper'
import {renderUpToCreditLine} from './pricing-plans.helper'

type OverdraftLineProps = {
    currency: BillingInfoCurrency
    hasDefaultOverdraft?: boolean
    preApprovedOverdraftInfo?: {
        amount?: number
        isRecommended?: boolean
    }
    planConfiguration?: PlanConfiguration
}

export const OverdraftLine = ({
    preApprovedOverdraftInfo,
    hasDefaultOverdraft,
    currency,
    planConfiguration,
}: OverdraftLineProps) => {
    const user = useUser()
    const locale = user?.language ?? SupportedLanguage.EN

    const iconColor = preApprovedOverdraftInfo?.isRecommended
        ? tokens.colorContentDiscover
        : tokens.colorContentStatic
    const textColor = preApprovedOverdraftInfo?.isRecommended
        ? 'colorContentDiscover'
        : 'colorContentStatic'
    const iconStyle = {marginTop: '0.2em'}

    if (preApprovedOverdraftInfo?.amount) {
        return (
            <Inline space={8} alignItems="flex-start">
                <Bank color={iconColor} size={16} css={iconStyle} />
                <Text color={textColor}>
                    <Trans>
                        {formatMinorNumberToCurrency({
                            value: preApprovedOverdraftInfo.amount,
                            options: {currency, maximumFractionDigits: 1, notation: 'compact'},
                            locale,
                        })}{' '}
                        pre-approved overdraft
                    </Trans>
                </Text>
            </Inline>
        )
    }

    if (hasDefaultOverdraft) {
        if (planConfiguration?.overdraft) {
            return (
                <Inline space={8} alignItems="flex-start">
                    <Bank color={iconColor} size={16} css={iconStyle} />
                    <Text color={textColor}>
                        {renderUpToCreditLine({planConfiguration, currency, locale})}
                    </Text>
                </Inline>
            )
        }

        return (
            <Inline space={8} alignItems="flex-start">
                <Close size={16} color={tokens.colorContentStaticQuiet} css={iconStyle} />
                <Text color="colorContentStaticQuiet">
                    <Trans>No overdraft</Trans>
                </Text>
            </Inline>
        )
    }

    return null
}
