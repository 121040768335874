import {t} from '@lingui/macro'
import {useEffect, useState} from 'react'

import {AdoptionPrompt} from '@product-web/feature--help-centre/adoption-prompt'
import {useHasAllowedRole} from '@product-web/shared--user'
import {localStorage} from '@product-web/shared--web-platform/storage/local'
import type {CompanyAdoptionGuideResponseAdoptionGuide} from '@shared/bff--moons/generated/auxo'

import paperplaneImage from './accordion/heading-image/paperplane-accountant.webp'
import {useAdoptionCentre} from './adoption-centre-context'
import {trackAdoptionPrompt} from './tracking'

import {bff} from '../bff-hooks'

type PromptAction = 'completed' | 'dismissed'

export const getStorageKey = (guideName: CompanyAdoptionGuideResponseAdoptionGuide) =>
    `GUIDE_PROMPT_${guideName}`

export const AdoptionCentrePrompt = () => {
    const isOwnerOrBookkeeper = useHasAllowedRole(['owner', 'bookkeeper-extended'])
    const {setDrawerOpen} = useAdoptionCentre()

    const {data: guides = []} = bff.adoptionCentre.getCompanyGuides.useQuery(undefined, {
        enabled: isOwnerOrBookkeeper,
    })

    const [isVisible, setVisible] = useState(false)

    useEffect(() => {
        if (guides.length > 0) {
            const guide = guides[0]
            const guideName = guide.adoptionGuide
            const promptLocalStorageValue = localStorage.getItem(getStorageKey(guideName))
            const promptOpen = Boolean(guide.showPrompt && !promptLocalStorageValue)
            if (promptOpen) {
                trackAdoptionPrompt('viewed', guideName)
                setVisible(true)
            }
        }
    }, [guides])

    if (!isVisible || !guides?.length) {
        return null
    }

    const guide = guides[0]
    const guideName = guide.adoptionGuide

    const hidePrompt = (action: PromptAction) => {
        setVisible(false)
        localStorage.setItem(getStorageKey(guideName), action)
    }
    const onDismiss = () => {
        hidePrompt('dismissed')
        trackAdoptionPrompt('abandoned', guideName)
    }
    const onComplete = () => {
        hidePrompt('completed')
        trackAdoptionPrompt('completed', guideName)
        setDrawerOpen(true)
    }

    return (
        <AdoptionPrompt
            name={guideName}
            size={'medium'}
            completeText={t`Let's do it`}
            dismissText={t`Not now`}
            imageSrc={paperplaneImage}
            onComplete={onComplete}
            onDismiss={onDismiss}
            title={t`It's time to Export`}
            text={t`Out of Pleo and into your accounting systems, get your expenses ready for reconciliation`}
        />
    )
}
