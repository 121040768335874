import {useEffect, useMemo, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useGate} from 'statsig-react'

import {generateSystems} from '@product-web/shared--accounting-systems'
import {useCompanySettings} from '@product-web/shared--api-deimos/company-settings'
import {ExportFeature} from '@product-web/shared--api-types/accounting'
import {useTokenData} from '@product-web/shared--auth--session/context'
import {useCashManagementAdoptionState} from '@product-web/shared--cash-management/adoption-switch/use-cash-management-adoption-state'
import {useFlags, useFlagsLoaded} from '@product-web/shared--flags'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {bff} from './bff-hooks'
import {useInternalNavigationContext} from './navigation-internal-provider'
import {
    BILL_INVOICES_COUNTRIES_WHITELIST,
    VENDOR_CARDS_COUNTRIES_BLACKLIST,
} from './navigation-items-allowed-countries'
import type {ShowNavigationItemsContext} from './navigation-items-metadata'
import {getNavigationItemsMetadata} from './navigation-items-metadata'

function getSystemsWithoutPocket() {
    return (
        Object.entries(generateSystems())
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, system]) => !system.supportedFeatures?.[ExportFeature.POCKET])
            .map(([name]) => name)
            .sort((a, b) => a.localeCompare(b))
    )
}

export function useNavigationItems() {
    const location = useLocation()
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)
    const featureFlags = useFlags()
    const isFlagsLoaded = useFlagsLoaded()

    const [isCashManagementEnabled] = useCashManagementAdoptionState()

    const {selectedEntityId} = useInternalNavigationContext()
    const {email: tokenEmail} = useTokenData()?.user || {}
    // Linear ticket: https://linear.app/pleo/issue/ACTIN-3339/optimize-deimos-endpoint-calls-for-accounting-system-settings
    // Using SWR hook to fetch company settings in order to reduce the number of requests sent to Deimos via BFFs
    // This is a temporary solution until we can start fetching accounting settings from Endymion
    const {data: companySettings} = useCompanySettings()
    const gate = useGate('company_onboarding_-_has_get_started_screen')

    const pathname = location.pathname
    const isWorkingAsPartner = pathname.startsWith('/partner')

    const systemsWithoutPocket = useMemo(() => getSystemsWithoutPocket(), [])

    const {data, isLoading, isFetching, isStale} = bff.navigation.getNavigationData.useQuery(
        {
            isPartnerPortal: isWorkingAsPartner,
            enablePurchaseOrdersFeature: Boolean(featureFlags.enablePurchaseOrdersFeature),
            hasCashManagementFlowAccess:
                Boolean(featureFlags.cashManagementFlow) && isCashManagementEnabled,
            systemsWithoutPocket,
            billInvoicesCountries: BILL_INVOICES_COUNTRIES_WHITELIST,
            vendorCardBlacklistCountries: VENDOR_CARDS_COUNTRIES_BLACKLIST,
            isB4BNavChangeLive: Boolean(featureFlags.b4BNav),
            reviewPage: Boolean(featureFlags.reviewPage),
            companyOnboardingVersion: gate.value ? 'get-started' : 'pleo-guide',
            newVismaEconomicsMigration: featureFlags.newVismaEconomicsMigration?.split(',') || [],
            requestsPage: featureFlags.requestsPage,
            accountingSystem: companySettings?.accounting.system,
        },
        {
            enabled: isFlagsLoaded,
        },
    )

    const {items: navigationItems = [], email, companyId} = data || {}

    const showNavigationItemContext: ShowNavigationItemsContext = {
        isTablet,
    }

    const navItemsMetadata = getNavigationItemsMetadata()

    const filteredNavItems = navigationItems
        .filter((item) => {
            if (!navItemsMetadata[item.id]) {
                return false
            }

            if (navItemsMetadata[item.id].showIf) {
                return navItemsMetadata[item.id].showIf?.(showNavigationItemContext)
            }

            return true
        })
        .map((item) => {
            return {
                label: navItemsMetadata[item.id].label,
                match: item.matchPath,
                newFeature: item.newFeature,
                flag: navItemsMetadata[item.id]?.flag,
                to: item.to,
                extraNode: navItemsMetadata[item.id].extraNode,
            }
        })

    // We're using cache for immediate navigation items rendering on entity switch
    const cachedItemsByCompany = useRef<Map<string, typeof filteredNavItems>>(new Map())

    // We use this state to avoid unnecessary navigation items flickering
    // since it's only updated from cache
    const [items, setItems] = useState(filteredNavItems)

    // We need to take into account isFetching and isStale
    const isItemsLoading = isLoading || isFetching || isStale || !isFlagsLoaded

    useEffect(() => {
        if (!selectedEntityId) {
            // if selectedCompanyId is not defined we can not use cache
            // instead we use filteredNavItems directly
            return
        }

        if (!isItemsLoading && companyId === selectedEntityId && email === tokenEmail) {
            // When the final data with refreshed LD flags is loaded
            // we're updating the cache
            cachedItemsByCompany.current.set(selectedEntityId, filteredNavItems)
        }

        // if selectedCompanyId is defined we're always update items from cache for
        // immediate visual change
        setItems(cachedItemsByCompany.current.get(selectedEntityId) ?? [])
    }, [selectedEntityId, isItemsLoading, companyId, tokenEmail, email, isWorkingAsPartner])

    return {
        items: selectedEntityId ? items : filteredNavItems,
        isLoading: selectedEntityId ? items.length === 0 : filteredNavItems.length === 0,
    }
}
