import type {ComponentPropsWithoutRef} from 'react'
import styled, {css} from 'styled-components'

import {px, tokens} from '@pleo-io/telescope'

interface TableProps {
    // For accessibility reasons, should have description of the table content
    caption: string
}

const StyledTable = styled.table`
    width: 100%;

    caption {
        display: none;
    }
`

export const Table = ({
    caption,
    children,
    ...props
}: TableProps & ComponentPropsWithoutRef<'table'>) => {
    return (
        <StyledTable {...props}>
            <caption>{caption}</caption>
            {children}
        </StyledTable>
    )
}

export const Head = styled.thead``

export const HeaderRow = styled.tr`
    height: ${px(40)};
    border-top: ${tokens.borderStatic};
    border-bottom: ${tokens.borderStatic};
`

interface TableHeaderCellProps {
    // For accessibility reasons, should define the scope of a header
    scope: 'col' | 'row' | 'colgroup' | 'rowgroup'
    textAlign?: 'left' | 'center' | 'right'
}

export const HeaderCell = styled.th<TableHeaderCellProps>`
    padding: 0 ${tokens.spacing8};
    vertical-align: middle;
    text-align: ${({textAlign}) => textAlign ?? 'left'};
    font-size: ${tokens.fontSmall};
    font-weight: ${tokens.fontWeightMedium};
    color: ${tokens.colorContentStaticQuiet};
`

export const Body = styled.tbody``

const borderedRow = css`
    td {
        box-shadow:
            0 ${px(3)} 0 ${tokens.colorBorderInteractiveSelected} inset,
            0 ${px(-3)} 0 ${tokens.colorBorderInteractiveSelected} inset;

        &:first-child {
            box-shadow:
                0 ${px(3)} 0 ${tokens.colorBorderInteractiveSelected} inset,
                0 ${px(-3)} 0 ${tokens.colorBorderInteractiveSelected} inset,
                ${px(3)} 0 0 0 ${tokens.colorBorderInteractiveSelected} inset;
            border-radius: ${tokens.arc12} 0 0 ${tokens.arc12};
        }

        &:last-child {
            box-shadow:
                0 ${px(3)} 0 ${tokens.colorBorderInteractiveSelected} inset,
                0 ${px(-3)} 0 ${tokens.colorBorderInteractiveSelected} inset,
                ${px(-3)} 0 0 0 ${tokens.colorBorderInteractiveSelected} inset;
            border-radius: 0 ${tokens.arc12} ${tokens.arc12} 0;
        }
    }
`

const baseRow = css`
    height: ${px(56)};
    border-top: ${tokens.borderStatic};
    border-bottom: ${tokens.borderStatic};
`

export type RowProps = {$isActive?: boolean; $isClickable?: boolean}
export const Row = styled.tr<RowProps>`
    ${baseRow}

    &:focus,
    &:focus-visible,
    &:focus-within {
        outline: none;
        ${borderedRow}
    }
    ${({$isActive}) => $isActive && borderedRow}
    ${({$isClickable}) =>
        $isClickable &&
        css`
            &:hover {
                background-color: ${tokens.colorBackgroundInteractiveQuietHover};
                cursor: pointer;
            }
        `}
`

export const GroupSummaryRow = styled.tr<{$isHighlighted?: boolean}>`
    ${baseRow}

    ${({$isHighlighted}) =>
        $isHighlighted &&
        css`
            && {
                background-color: ${tokens.colorBackgroundStaticLoud};
            }

            & > td {
                font-weight: ${tokens.fontWeightMedium};
            }
        `}
`

export const Cell = styled.td`
    vertical-align: middle;
    padding: 0 ${tokens.spacing8};
    font-size: ${tokens.fontMedium};
`

export const ScrollableWrapper = styled.div<{$stickyHeader?: boolean}>`
    position: relative;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    ${(props) =>
        props.$stickyHeader &&
        css`
            thead {
                position: sticky;
                top: 0;
                z-index: ${tokens.zIndexSurface};
                background: ${tokens.colorBackgroundStatic};

                tr {
                    border: none;
                }

                th {
                    box-shadow:
                        inset 0 ${px(1)} 0 ${tokens.colorBorderStatic},
                        inset 0 ${px(-1)} 0 ${tokens.colorBorderStatic};
                }
            }

            tbody tr:first-child {
                border-top: none;
            }
        `}
`
