import {featureFundsManagementBff} from './bff-hooks'

// Temp hook to help all the teams to make moving to BFF smooth
// Once we all moved to BFF rails to call the API, we can remove this temp "useWalletCurrency" hook
export const useWalletCurrency = () => {
    const {data, isInitialLoading} = featureFundsManagementBff.utils.getWalletCurrency.useQuery()
    return {
        walletCurrency: data,
        isLoading: isInitialLoading,
    }
}
