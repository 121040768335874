import {t, Trans} from '@lingui/macro'

import {useAcademyContext} from '../../components/academy-context/academy-context'
import {LessonNav} from '../../components/overview-right/overview-right'

export const LessonNavigation = () => {
    const {hideInvoices} = useAcademyContext()

    return (
        <LessonNav>
            <LessonNav.Link
                ariaLabel={t`Reimbursements and Mileage`}
                path="/partner/academy/the-pleo-product/reimbursements-and-mileage"
            >
                <Trans>Reimbursements & Mileage (2 mins)</Trans>
            </LessonNav.Link>
            {!hideInvoices && (
                <LessonNav.Link
                    ariaLabel={t`Invoices`}
                    path="/partner/academy/the-pleo-product/invoices"
                >
                    <Trans>Invoices (2 mins)</Trans>
                </LessonNav.Link>
            )}
            <LessonNav.Link
                ariaLabel={t`Vendor cards`}
                path="/partner/academy/the-pleo-product/vendor-cards"
            >
                <Trans>Vendor cards (2 mins)</Trans>
            </LessonNav.Link>
            <LessonNav.Link
                ariaLabel={t`Multi-entities`}
                path="/partner/academy/the-pleo-product/multi-entities"
            >
                <Trans>Multi-entities (2 mins)</Trans>
            </LessonNav.Link>
        </LessonNav>
    )
}
