import {useRect} from '@reach/rect'
import type {CSSProperties} from 'react'
import {forwardRef, useEffect, useRef} from 'react'
import styled from 'styled-components'

import type {TextProps} from '@pleo-io/telescope'
import {px, Text} from '@pleo-io/telescope'

type MeasuredTextProps = TextProps & {
    children: React.ReactNode
    onWidthChange?: (width: number) => void
    calcTextStyles?: CSSProperties
}

/*
 * This component is used to measure width of the passed text.
 * It renders an invisible copy of the text component with the same text style
 * but with no word wrap.
 *
 * Since passed props might override the style of the invisible text
 * there is a property calcTextStyles that allows to
 * force necessary styles for the invisible text copy to make proper measurements.
 */
export const MeasuredText = forwardRef<HTMLDivElement, MeasuredTextProps>((props, ref) => {
    const {children, calcTextStyles, onWidthChange, ...rest} = props
    const textWidthRef = useRef<HTMLDivElement>(null)
    const textRect = useRect(textWidthRef)

    useEffect(() => {
        if (typeof textRect?.width !== 'undefined') {
            onWidthChange?.(textRect.width)
        }
    }, [onWidthChange, textRect?.width])

    return (
        <>
            <TextWidthCalculation {...rest} ref={textWidthRef} style={calcTextStyles}>
                {children}
            </TextWidthCalculation>
            <Text {...rest} ref={ref}>
                {children}
            </Text>
        </>
    )
})

// eslint-disable-next-line string-to-lingui/missing-lingui-transformation
MeasuredText.displayName = 'MeasuredText'

const TextWidthCalculation = styled(Text)`
    white-space: nowrap;
    position: fixed;
    left: ${px(-9999)};
    opacity: 0;
`
