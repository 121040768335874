import {t, Trans} from '@lingui/macro'
import {useState} from 'react'

import {Button, Inline, Link, ModalSplit, Stack, Text} from '@pleo-io/telescope'
import {NewTab} from '@pleo-io/telescope-icons'
import {AddFunds, Collab, NoFunds} from '@pleo-io/telescope-pictograms'

import {useTermsDocuments} from '@product-web/shared--country-configuration/features/terms/terms'
import {SupportedLanguage} from '@product-web/shared--i18n'
import type {MinorCurrency} from '@product-web/shared--locale/currency'
import {formatMinorNumberToCurrency} from '@product-web/shared--plan-presentation/currency-formatter'
import {useUser} from '@product-web/shared--user'

import {getLocalisedImage} from './get-localised-image'

import type {PageOrigin, PageSection} from '../../../index.bff'
import {PricingPlanModalWrapper} from '../../../pricing-plan-modal/pricing-plan-modal-wrapper'
import {PlanUpgradeSource} from '../../../types/plans'

export type PreApprovedOverdraftInfoModalProps = {
    overdraftAmount: number
    currency: string
    activationFeePercent: number
    isOpen: boolean
    onDismiss: () => void
    onUpgrade?: () => void
    pageOrigin: PageOrigin
    pageSection?: PageSection
}
export function PreApprovedOverdraftInfoModal({
    pageOrigin,
    pageSection,
    overdraftAmount,
    currency,
    activationFeePercent,
    isOpen,
    onDismiss,
    onUpgrade,
}: PreApprovedOverdraftInfoModalProps) {
    const user = useUser()
    const locale = user?.language ?? SupportedLanguage.EN
    const [isPricingPlanModalOpen, setIsPricingPlanModalOpen] = useState(false)
    const {overdraftTerms} = useTermsDocuments()

    const overdraftAmountLimitAmount = formatMinorNumberToCurrency({
        locale,
        value: overdraftAmount as MinorCurrency,
        options: {currency},
    })

    const modalTitle = t`You're pre-approved for a ${overdraftAmountLimitAmount} overdraft`
    const annualFeeText = t`Set a limit that works for your business and activate for a ${activationFeePercent}% annual fee.`
    const onUpgradeToEssential = () => {
        onUpgrade && onUpgrade()
        setIsPricingPlanModalOpen(true)
    }

    return (
        <>
            <ModalSplit aria-label={t`Overdraft info modal`} isOpen={isOpen} onDismiss={onDismiss}>
                <ModalSplit.Content>
                    <ModalSplit.Body>
                        <ModalSplit.Title>{modalTitle}</ModalSplit.Title>
                        <Stack space={24}>
                            <ModalSplit.Text>
                                <Trans>
                                    Upgrade and unlock instant access to funds when you need more
                                    flexibility.
                                </Trans>
                            </ModalSplit.Text>
                            <Inline space={16} alignY="center">
                                <AddFunds size="48" />
                                <Stack>
                                    <ModalSplit.Text>
                                        <Trans>Keep your cash flow steady</Trans>
                                    </ModalSplit.Text>

                                    <Text as="p" variant="small-subtle" align="left">
                                        <Trans>
                                            Prevent declined transactions and ensure bills are paid
                                            on time to save money on late fees.
                                        </Trans>
                                    </Text>
                                </Stack>
                            </Inline>
                            <Inline space={16} alignY="center">
                                <Collab size="48" />
                                <Stack>
                                    <ModalSplit.Text>
                                        <Trans>Fair and transparent pricing</Trans>
                                    </ModalSplit.Text>
                                    <Text as="p" variant="small-subtle" align="left">
                                        {annualFeeText}
                                    </Text>
                                </Stack>
                            </Inline>
                            <Inline space={16} alignY="center">
                                <NoFunds size="48" />
                                <Stack>
                                    <ModalSplit.Text>
                                        <Trans>0% interest when you repay monthly</Trans>
                                    </ModalSplit.Text>
                                    <Text as="p" variant="small-subtle" align="left">
                                        <Trans>
                                            Repay within the same month, and you won't pay any
                                            interest or additional fees.
                                        </Trans>
                                    </Text>
                                </Stack>
                            </Inline>
                        </Stack>
                    </ModalSplit.Body>

                    <ModalSplit.Actions>
                        <Inline
                            alignY="center"
                            justifyContent="space-between"
                            style={{width: '100%'}}
                        >
                            <Link
                                IconRight={NewTab}
                                href={overdraftTerms}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Trans>Terms & conditions</Trans>
                            </Link>
                            <Button variant="primary" type="button" onClick={onUpgradeToEssential}>
                                <Trans>Upgrade</Trans>
                            </Button>
                        </Inline>
                    </ModalSplit.Actions>
                </ModalSplit.Content>
                <ModalSplit.Photograph>
                    <img src={getLocalisedImage(locale, currency)} alt="" />
                </ModalSplit.Photograph>
            </ModalSplit>
            <PricingPlanModalWrapper
                isOpen={isPricingPlanModalOpen}
                onDismiss={() => setIsPricingPlanModalOpen(false)}
                pageOrigin={pageOrigin}
                pageSection={pageSection}
                planUpgradeSource={PlanUpgradeSource.OVERDRAFT_FLOW}
            />
        </>
    )
}
