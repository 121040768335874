import {ReactQueryDevtools} from '@tanstack/react-query-devtools'

declare global {
    interface Window {
        toggleTanstackQueryDevtools: () => void
    }
}

export const ReactQueryDevtoolsWrapper = () => {
    const isDevMode = process.env.NODE_ENV !== 'production'

    if (!isDevMode) {
        return null
    }

    window.toggleTanstackQueryDevtools = () => {
        const currentSetting = localStorage.getItem('tanstack-query-devtools') === 'true'
        localStorage.setItem('tanstack-query-devtools', (!currentSetting).toString())
        window.location.reload()
    }

    const isDevtoolsEnabled = localStorage.getItem('tanstack-query-devtools') === 'true'

    if (!isDevtoolsEnabled) {
        return null
    }

    return (
        <div data-testid="react-query-devtools">
            <ReactQueryDevtools
                initialIsOpen={true}
                toggleButtonProps={{style: {transform: 'scale(0.7)'}}}
            />
        </div>
    )
}
