import type {PromoCardType} from './hooks/use-promo-cards'
import PreApprovedOverdraftPromoCard from './pre-approved-overdraft-promo-card/pre-approved-overdraft-promo-card'
import UpgradePromoCard from './upgrade-promo-card/upgrade-promo-card'

type PromoCardContainerProps = {
    promoCardType: PromoCardType
}

const PromoCardContainer = ({promoCardType}: PromoCardContainerProps) => {
    switch (promoCardType) {
        case 'upgrade':
            return <UpgradePromoCard />
        case 'pre-approved-overdraft':
            return <PreApprovedOverdraftPromoCard />
        default:
            return null
    }
}

export default PromoCardContainer
