import type {MouseEventHandler} from 'react'
import type React from 'react'
import styled from 'styled-components'

import {Link, Text, tokens} from '@pleo-io/telescope'

const Card = styled.div`
    background-color: ${tokens.colorBackgroundStaticLoud};
    border-radius: ${tokens.arc8};
    padding: ${tokens.spacing16} ${tokens.spacing20};
    cursor: pointer;
`

type PromoCardProps = {
    message: React.ReactNode
    icon: React.ReactNode
    ctaText: string
    onCardClicked: MouseEventHandler<HTMLAnchorElement | HTMLDivElement>
}

// Presentation layer of the promo card
const PromoCard = ({onCardClicked, message, icon, ctaText}: PromoCardProps) => {
    return (
        <Card onClick={onCardClicked} css={{backgroundColor: tokens.colorBackgroundStatic}}>
            {icon}
            <Text as="p" variant="small-subtle" color="colorContentStatic" space={4}>
                {message}
            </Text>
            <Link>{ctaText}</Link>
        </Card>
    )
}

export default PromoCard
