import {createContext, useCallback, useContext, useState} from 'react'

import config from '@product-web/shared--config'
import {reportError} from '@product-web/shared--error/report'
import {omit} from '@product-web/shared--utils'
import {asyncLoadScript} from '@product-web/shared--web-platform/async-load-script'

import {hubspotAPI} from './api'
import type {HubspotConsentPreferences} from './api.types'
import {defaultConsentState} from './defaults'
import {useInitialisedAction} from './hooks'
import {updateMappedGtagConsent} from './side-effects'

import {isEqual} from '../utils'

/**
 * Context to hold Hubspot consent state and commands.
 */
export const HubspotConsentContext = createContext<{
    isInitialised: boolean
    consentPreferences: HubspotConsentPreferences
    showBanner: () => void
} | null>(null)

/**
 * Internal hook to manage Hubspot script & banner initialisation.
 */
const useHubspotLoader = () => {
    const [isInitialised, setInitialisedState] = useState(false)

    const setHubspotInitialized = useCallback(() => {
        setInitialisedState(true)
    }, [setInitialisedState])

    const loadHubspotScript = useCallback(async () => {
        try {
            await asyncLoadScript({
                id: 'hs-script-loader',
                src: `https://js.hs-banner.com/v2/${config.hubspot.portalId}/banner.js`,
                asynchronous: true,
            })
            setHubspotInitialized()
        } catch (error) {
            reportError(error)
            setHubspotInitialized()
        }
    }, [setHubspotInitialized])

    useInitialisedAction({
        onInitialised: loadHubspotScript,
    })

    return {isInitialised}
}

/**
 * Internal hook to manage Hubspot consent preference updates.
 */
const useHubspotConsentPreferences = () => {
    const [consentPreferences, setConsentPreferences] =
        useState<HubspotConsentPreferences>(defaultConsentState)

    const updateConsentPreferences = useCallback(
        (newConsentPreferences: HubspotConsentPreferences) => {
            // Only update if the new preferences are different
            if (isEqual(consentPreferences, newConsentPreferences)) {
                return
            }

            // Update state with the new preferences
            setConsentPreferences(newConsentPreferences)

            // Side-effect: Update Google Tag Manager consent preferences
            updateMappedGtagConsent(newConsentPreferences)
        },
        [consentPreferences],
    )

    const registerConsentHandler = useCallback(() => {
        // Register the Hubspot consent listener
        hubspotAPI.addPrivacyConsentListener((preferences) => {
            updateConsentPreferences(omit(preferences, 'previousCategories'))
        }) // Note: The hubspot API does not support removing this listener
    }, [updateConsentPreferences])

    useInitialisedAction({
        onInitialised: registerConsentHandler,
    })

    return {consentPreferences}
}

// Internal hook: Provides the Hubspot API commands
const useHubspotActions = () => ({
    actions: {
        showBanner: useCallback(() => hubspotAPI.showBanner(), []),
    },
})

/**
 * Provider for Hubspot consent state.
 */
export const HubspotConsentProvider = ({children}: {children: React.ReactNode}) => {
    // Initialise Hubspot consent banner
    const {isInitialised} = useHubspotLoader()

    // Subscribe to Hubspot consent preference updates
    const {consentPreferences} = useHubspotConsentPreferences()

    // Provide Hubspot API commands
    const {actions} = useHubspotActions()

    return (
        <HubspotConsentContext.Provider value={{isInitialised, consentPreferences, ...actions}}>
            {children}
        </HubspotConsentContext.Provider>
    )
}

/**
 * Hook to access Hubspot consent state.
 */
export const useHubspotConsent = () => {
    const context = useContext(HubspotConsentContext)
    if (!context) {
        throw new Error('useHubspotConsent must be used within a HubspotConsentProvider')
    }
    return context
}
