import * as React from 'react'
import {Navigate, Outlet, Route} from 'react-router-dom'

import {LoadingPage} from '@pleo-io/telescope'

import {useReviewerConfig} from '@product-web/feature--review/reviewer-config/use-reviewer-config'
import {roleSets} from '@product-web/shared--user'

const ReviewOverviewPage = React.lazy(
    async () => import('./screens/review-overview/review-overview'),
)

const ReviewPage = React.lazy(async () => import('./screens/review-list/review'))
const CompanyReviewPage = React.lazy(async () => import('./screens/company-review/company-review'))
const TagReviewPage = React.lazy(async () => import('./screens/tag-review/tag-review'))

export const routes = (
    <Route handle={{auth: true, allowedRoles: roleSets.companyAndReviewer}}>
        <Route element={<ReviewPageWrapper />}>
            <Route index element={<ReviewPage />} />
            <Route path="review/*" element={<ReviewPage />} />
        </Route>
    </Route>
)

export const reviewsSettingsRoutes = (
    <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}>
        <Route index element={<ReviewOverviewPage />} />
        <Route path="company" element={<CompanyReviewPage />} />
        <Route path="tags" element={<TagReviewPage />} />
    </Route>
)

function ReviewPageWrapper() {
    const {data: reviewerPermissions, isFetched: isFetchedReviewerConfig} = useReviewerConfig()

    if (!isFetchedReviewerConfig) {
        return <LoadingPage />
    }

    if (reviewerPermissions?.isReviewer) {
        return <Outlet />
    }

    return <Navigate to={'/expenses'} replace />
}
