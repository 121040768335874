import {useQueryClient} from '@tanstack/react-query'
import qs from 'qs'
import type {SWRConfiguration} from 'swr'
import useSWR from 'swr'

import type {
    AccountCategoryTypeKey,
    CreateAccount,
    CreateAccountCategory,
    CreateAccountCategoryResponse,
    CreateAccountResponse,
    GetAllAccountCategoryResponse,
    SuccessResponse,
    UpdateAccount,
    UpdateAccountCategory,
    UpdateAccountCategoryResponse,
    UpdateAccountResponse,
} from '@pleo-io/deimos'

import {request} from '@product-web/shared--api'
import config from '@product-web/shared--config'
import {useCompanyUser} from '@product-web/shared--user'

import {getDeimos} from '../helpers'

const baseUrl = config.endpoints.api
async function createAccountCategory(
    companyId: string,
    payload: Partial<CreateAccountCategory>,
): Promise<CreateAccountCategoryResponse> {
    return request(`${baseUrl}/rest/v1/companies/${companyId}/account-categories`, {
        auth: 'user',
        method: 'POST',
        body: payload,
    })
}

async function updateAccountCategory(
    id: string,
    payload: Partial<UpdateAccountCategory>,
): Promise<UpdateAccountCategoryResponse> {
    return request(`${baseUrl}/rest/v1/account-categories/${id}`, {
        auth: 'user',
        method: 'PUT',
        body: payload,
    })
}
async function deleteAccountCategory(id: string): Promise<SuccessResponse> {
    return request(`${baseUrl}/rest/v1/account-categories/${id}`, {
        auth: 'user',
        method: 'DELETE',
    })
}

async function createAccountCategoryAccount(
    companyId: string,
    payload: Partial<CreateAccount>,
): Promise<CreateAccountResponse> {
    return request(`${baseUrl}/rest/v1/companies/${companyId}/accounts`, {
        auth: 'user',
        method: 'POST',
        body: payload,
    })
}

async function updateAccountCategoryAccount(
    id: string,
    payload: Partial<UpdateAccount>,
): Promise<UpdateAccountResponse> {
    return request(`${baseUrl}/rest/v1/accounts/${id}`, {
        auth: 'user',
        method: 'PUT',
        body: payload,
    })
}
async function deleteAccountCategoryAccount(id: string): Promise<SuccessResponse> {
    return request(`${baseUrl}/rest/v1/accounts/${id}`, {
        auth: 'user',
        method: 'DELETE',
    })
}

export type ImportAccountCategoriesPayload = {
    file: File
    onProgress?: (number: number) => void
}

interface ImportAccountCategoriesResultItem {
    categoryName: string
    createNew: boolean
    addCount: number
    categoryType: AccountCategoryTypeKey
}

export type ImportAccountCategoriesResult = {
    items: ImportAccountCategoriesResultItem[]
}

export type ExportAccountCategoriesResult = {
    url: string
    filename: string
}

async function importCategories(
    id: string,
    payload: ImportAccountCategoriesPayload,
    dryRun = false,
): Promise<ImportAccountCategoriesResult> {
    return request(`${baseUrl}/rest/v1/company-settings/${id}/account-categories/import`, {
        auth: 'user',
        method: 'POST',
        query: {dryRun},
        ...payload,
    })
}

async function exportCategories(
    id: string,
    categoryIds?: string[],
): Promise<ExportAccountCategoriesResult> {
    const queryParams = qs.stringify({categoryIds}, {indices: false})
    const normalizedQueryParams = queryParams ? '?' + queryParams : ''

    return request(
        `${baseUrl}/rest/v1/company-settings/${id}/account-categories/export${normalizedQueryParams}`,
        {
            auth: 'user',
            method: 'GET',
        },
    )
}

export function useAccountCategories({
    bypassHook = false,
    swrConfig = {revalidateOnFocus: false},
    includeDepartments = false,
    expenseCompanyId,
}: {
    bypassHook?: boolean
    swrConfig?: SWRConfiguration
    includeDepartments?: boolean
    expenseCompanyId?: string
} = {}) {
    const {companyId} = useCompanyUser()
    const departmentsAppendix = includeDepartments ? '&includeDepartments=true' : ''
    const url =
        companyId && !bypassHook
            ? `/rest/v1/companies/${
                  expenseCompanyId ?? companyId
              }/account-categories?eager=true&sortBy=createdAt&order=DESC${departmentsAppendix}`
            : null

    const result = useSWR<GetAllAccountCategoryResponse, Error>(url, getDeimos, swrConfig)

    async function importAccountCategories(
        payload: ImportAccountCategoriesPayload,
        dryRun = false,
    ): Promise<ImportAccountCategoriesResult> {
        return await importCategories(companyId, payload, dryRun)
    }

    async function exportAccountCategories(
        categoryIds?: string[],
    ): Promise<ExportAccountCategoriesResult> {
        return await exportCategories(companyId, categoryIds)
    }

    async function createCategory(payload: Partial<CreateAccountCategory>) {
        if (!companyId) {
            return
        }
        await createAccountCategory(companyId, payload)
    }

    async function updateCategory(id: string, payload: Partial<UpdateAccountCategory>) {
        await updateAccountCategory(id, payload)
    }

    async function archiveCategory(id: string) {
        await updateCategory(id, {hidden: true})
    }

    async function unarchiveCategory(id: string) {
        await updateCategory(id, {hidden: false})
    }

    async function deleteCategory(id: string) {
        await deleteAccountCategory(id)
    }

    async function createAccount(payload: Partial<CreateAccount>) {
        if (!companyId) {
            return
        }

        await createAccountCategoryAccount(companyId, payload)
    }
    async function updateAccount(id: string, payload: Partial<UpdateAccount>) {
        await updateAccountCategoryAccount(id, payload)
    }

    async function archiveAccount(id: string) {
        await updateAccountCategoryAccount(id, {hidden: true})
    }

    async function unarchiveAccount(id: string) {
        await updateAccountCategoryAccount(id, {hidden: false})
    }

    async function deleteAccount(id: string) {
        await deleteAccountCategoryAccount(id)
    }

    return {
        ...result,
        mutations: {
            createCategory,
            updateCategory,
            archiveCategory,
            unarchiveCategory,
            deleteCategory,
            createAccount,
            updateAccount,
            archiveAccount,
            unarchiveAccount,
            deleteAccount,
            importAccountCategories,
            exportAccountCategories,
        },
    }
}

export const useImportAccountCategories = (): {
    importAccountCategories: (
        payload: ImportAccountCategoriesPayload,
        dryRun?: boolean,
    ) => Promise<ImportAccountCategoriesResult>
} => {
    const {companyId} = useCompanyUser()
    const queryClient = useQueryClient()

    const importAccountCategories = async (
        payload: ImportAccountCategoriesPayload,
        dryRun = false,
    ): Promise<ImportAccountCategoriesResult> => {
        const response = await importCategories(companyId, payload, dryRun)
        await queryClient.invalidateQueries()

        return response
    }

    return {
        importAccountCategories,
    }
}
