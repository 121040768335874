import {t, Trans} from '@lingui/macro'
import {useState} from 'react'
import {useNavigate} from 'react-router'
import styled from 'styled-components'

import {
    Box,
    Button,
    Inline,
    Loading,
    Modal,
    RadioButton,
    RadioGroup,
    Stack,
    Text,
    Textarea,
    tokens,
} from '@pleo-io/telescope'
import {ArrowLeft, SpeechBubble} from '@pleo-io/telescope-icons'

import {useAutoTopUpPermissions} from '@product-web/feature--funds-management/use-auto-top-up-permissions'
import {useAutoTopUpSettings} from '@product-web/feature--funds-management/use-company-top-up-settings'
import {ContactSupport} from '@product-web/feature--ui-contact-support'
import {paywallActioned, paywallFeedbackSubmitted} from '@product-web/shared--analytics'
import {breakpoints} from '@product-web/shared--styles/theme'

import AutoTopUpIcon from './images/auto-top-up-icon.svg'
import transferModalBackground from './images/illustration-transfer-modal.svg'
import {getPaywallTrackingView} from './paywall'
import {
    IllustrationImage,
    IllustrationPanel,
    LeftPanelContainer,
    PaywallCallToAction,
    SpanParagraph,
    TwoPanelContainer,
} from './paywall.styles'
import {PaywallView, usePaywallContext} from './paywall-context'

export interface Props {
    isDismissible: boolean
    onClose: () => void
}

const FlexContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    @media (max-width: ${breakpoints.desktopMedUp}) {
        height: 300px;
    }
`

const TextBack = styled(Text)`
    padding: ${tokens.spacing40} 0 0 ${tokens.spacing40};
    cursor: pointer;
`

const Subheading = styled(Text).attrs({
    variant: 'large-accent',
    weight: 'medium',
    align: 'left',
})`
    margin-bottom: ${tokens.spacing12};
`

export enum FeedbackOption {
    LowBalance = 'LowBalance',
    TechnicalIssues = 'TechnicalIssues',
    StillDeciding = 'StillDeciding',
    NotPriority = 'NotPriority',
    Other = 'Other',
}

const AutoTopupCTA = ({onClose, isDismissible}: {onClose: () => void; isDismissible: boolean}) => {
    const {view} = usePaywallContext()
    const onCloseProxy = () => {
        const trackingView = getPaywallTrackingView(view, !isDismissible)
        if (trackingView) {
            paywallActioned({
                action: 'link_clicked',
                is_dismissible: isDismissible,
                target_url: '/wallet',
                link_text: 'auto_top-up',
                view: trackingView,
            })
        }
        onClose()
    }
    return (
        <>
            <Subheading>
                <Trans>Don't want to top up manually?</Trans>
            </Subheading>
            <Box mt={12}>
                <PaywallCallToAction
                    image={AutoTopUpIcon}
                    to="/wallet"
                    onClick={onCloseProxy}
                    data-testid="auto-topup-button"
                >
                    <Inline alignY="center" space={8}>
                        <Text variant="large-accent">{t`Auto top-up`}</Text>
                    </Inline>
                    <Text as="div" color="colorContentInteractiveQuiet">
                        <Trans>
                            Automatically top up your wallet if the balance falls below a set amount
                        </Trans>
                    </Text>
                </PaywallCallToAction>
            </Box>
        </>
    )
}

const FeedbackCTA = ({
    showOtherFeedbackInput,
    setShowOtherFeedbackInput,
}: {
    showOtherFeedbackInput: boolean
    setShowOtherFeedbackInput: (showOtherFeedbackInput: boolean) => void
}) => {
    const [otherFeedbackText, setOtherFeedbackText] = useState('')
    const {didSubmitFeedback, setDidSubmitFeedback} = usePaywallContext()
    const onSubmitFeedback = (option: FeedbackOption, otherText?: string) => {
        paywallFeedbackSubmitted({
            option,
            other_text: otherText,
        })
        setDidSubmitFeedback(true)
    }
    const submitFeeddbackText = () => {
        onSubmitFeedback(FeedbackOption.Other, otherFeedbackText)
        setShowOtherFeedbackInput(false)
    }
    const onOptionSelected = (option: FeedbackOption) => {
        if (option === FeedbackOption.Other) {
            setShowOtherFeedbackInput(true)
        } else {
            onSubmitFeedback(option)
        }
    }
    if (didSubmitFeedback) {
        return (
            <>
                <Subheading>
                    <Trans>We have received your feedback, thank you</Trans>
                </Subheading>
            </>
        )
    }

    return (
        <>
            <Box hidden={showOtherFeedbackInput} data-testid="paywall-feedback-options">
                <Subheading id="improveLabel">
                    <Trans>How could we improve?</Trans>
                </Subheading>
                <Box mb={16}>
                    <Text align="left" id="improveDescription">
                        <Trans>
                            We recognise that this isn't an ideal situation. Is there anything we at
                            Pleo could have done to prevent it?
                        </Trans>
                    </Text>
                </Box>
            </Box>
            {(!showOtherFeedbackInput && (
                <RadioGroup
                    aria-labelledby="improveLabel"
                    aria-describedby="improveDescription"
                    onValueChange={onOptionSelected}
                    defaultValue={undefined}
                >
                    <Stack space={16}>
                        <RadioButton
                            value={FeedbackOption.LowBalance}
                            label={t`I wasn't made aware that my balance was low`}
                        />
                        <RadioButton
                            value={FeedbackOption.TechnicalIssues}
                            label={t`I ran into technical issues with the Pleo app`}
                        />
                        <RadioButton
                            value={FeedbackOption.StillDeciding}
                            label={t`I'm still deciding if Pleo is right for us`}
                        />
                        <RadioButton
                            value={FeedbackOption.NotPriority}
                            label={t`I just had other stuff going on`}
                        />
                        <RadioButton value={FeedbackOption.Other} label={t`Other`} />
                    </Stack>
                </RadioGroup>
            )) || (
                <>
                    <Stack space={40} data-testid="paywall-feedback-input">
                        <Text variant="3xlarge-accent">
                            <Trans>What could we have done better?</Trans>
                        </Text>
                        <Stack space={12}>
                            <Textarea
                                aria-label="followup-question-text-area"
                                data-testid="paywall-feedback-input-textarea"
                                name="feedback"
                                showCounter
                                fixedHeight="100px"
                                maxLength={1000}
                                value={otherFeedbackText}
                                onChange={(event) => setOtherFeedbackText(event.target.value)}
                            />

                            <Text
                                variant="small-subtle"
                                color="colorContentInteractive"
                                weight="medium"
                            >
                                <Inline alignY="center" space={10}>
                                    <SpeechBubble size={16} />
                                    <span>{t`We value your feedback`}</span>
                                </Inline>
                            </Text>
                        </Stack>
                        <Inline justifySelf="flex-end">
                            <Button
                                variant="primary"
                                onClick={submitFeeddbackText}
                                data-testid="paywall-feedback-input-submit-button"
                            >
                                <Trans>Send</Trans>
                            </Button>
                        </Inline>
                    </Stack>
                </>
            )}
        </>
    )
}

export const ModalPendingTransfer = ({isDismissible, onClose}: Props) => {
    const [showOtherFeedbackInput, setShowOtherFeedbackInput] = useState(false)
    const {setView} = usePaywallContext()
    const navigate = useNavigate()
    const {autoTopUp} = useAutoTopUpSettings()
    const {isVisible: isAutoTopUpVisible, isLoading: isAutoTopUpPermissionsLoading} =
        useAutoTopUpPermissions()
    const suggestAutoTopup =
        isDismissible && isAutoTopUpVisible && autoTopUp.autoTopupStatus !== 'ENABLED'

    return (
        <TwoPanelContainer>
            <LeftPanelContainer>
                <TextBack
                    color="colorContentInteractiveLink"
                    data-testid="back-button"
                    onClick={() =>
                        showOtherFeedbackInput
                            ? setShowOtherFeedbackInput(false)
                            : setView(PaywallView.VIEW_TRANSFER)
                    }
                >
                    <Inline alignItems="center">
                        <ArrowLeft size={16} />
                        <Trans>Back</Trans>
                    </Inline>
                </TextBack>
                <FlexContainer>
                    <Modal.Title hidden={showOtherFeedbackInput}>
                        <Text
                            data-testid="pending-transfer-title"
                            align="left"
                            variant="3xlarge-accent"
                        >
                            <Trans>Thanks! This shouldn't take long</Trans>
                        </Text>
                    </Modal.Title>
                    <Modal.Content>
                        <Box mb={40} hidden={showOtherFeedbackInput}>
                            <Text
                                align="left"
                                as="div"
                                variant="medium-default"
                                data-testid="pending-transfer-subtitle"
                            >
                                <Trans>
                                    We'll send a notification when your account is ready to use
                                    again. Typically, it takes{' '}
                                    <SpanParagraph weight="bold">1-2 hours</SpanParagraph> for your
                                    funds to clear.
                                </Trans>
                            </Text>
                        </Box>
                        {(isAutoTopUpPermissionsLoading && <Loading />) ||
                            (suggestAutoTopup ? (
                                <AutoTopupCTA onClose={onClose} isDismissible={isDismissible} />
                            ) : (
                                <FeedbackCTA
                                    showOtherFeedbackInput={showOtherFeedbackInput}
                                    setShowOtherFeedbackInput={setShowOtherFeedbackInput}
                                />
                            ))}
                    </Modal.Content>
                </FlexContainer>
                <Modal.Footer data-testid="suspended-modal-footer">
                    <Stack align="center">
                        <Text
                            as="div"
                            color="colorContentInteractiveQuiet"
                            data-testid="chat-to-us-link"
                        >
                            <Trans>Need help?</Trans>{' '}
                            <ContactSupport
                                chatLabel={t`Chat to us`}
                                onClick={() => {
                                    const trackingView = getPaywallTrackingView(
                                        PaywallView.PENDING_TRANSFER,
                                        isDismissible,
                                    )
                                    if (trackingView) {
                                        paywallActioned({
                                            action: 'support_chat',
                                            is_dismissible: isDismissible,
                                            view: trackingView,
                                        })
                                    }
                                    if (!isDismissible) {
                                        navigate('/export')
                                    } else {
                                        onClose()
                                    }
                                }}
                            />
                        </Text>
                    </Stack>
                </Modal.Footer>
            </LeftPanelContainer>

            <IllustrationPanel>
                <IllustrationImage src={transferModalBackground} />
            </IllustrationPanel>
        </TwoPanelContainer>
    )
}
