import {t, Trans} from '@lingui/macro'
import styled from 'styled-components'

import {Box, Button, Inline, Modal, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowRight} from '@pleo-io/telescope-icons'

import {AppStoreBadge, PlayStoreBadge} from '@product-web/feature--ui-store-badges'
import * as tracking from '@product-web/shared--analytics'
import {breakpoints} from '@product-web/shared--styles/theme'
import {ActionBanner} from '@product-web/shared--telescope-lab/action-banner/action-banner'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import downloadMobileApp from './download-mobile-app.svg'

import qrAndroidDownload from '../../images/qr-android-download.svg'
import qrAppleDownload from '../../images/qr-apple-download.svg'
import type {DownloadAppModalActionedExtended} from '../../types'

const ModalText = styled(Text)`
    margin: auto;
    width: 85%;

    @media (min-width: ${breakpoints.tabletUp}) {
        width: 100%;
    }
`

const StoreSectionDesktop = styled(Inline)`
    justify-content: center;
    margin-bottom: ${tokens.spacing36};
    margin-top: ${tokens.spacing24};
`

const StoreSectionMobile = styled(Stack)`
    margin-top: ${tokens.spacing16};
    margin-bottom: ${tokens.spacing20};
`

const StyledModal = styled(Modal)`
    max-width: 500px;

    @media (min-width: ${breakpoints.tabletUp}) {
        width: 522px;
        max-width: none;
    }
`

export const appStoreClickedModalTrackingProps: DownloadAppModalActionedExtended = {
    action: 'app_store_clicked',
}

export const playStoreClickedModalTrackingProps: DownloadAppModalActionedExtended = {
    action: 'play_store_clicked',
}

export const closedModalTrackingProps: DownloadAppModalActionedExtended = {
    action: 'closed',
}

interface QrCodeSectionProps {
    qrImage: string
    device: 'Android' | 'Apple iOS'
}

const QrCodeSection = ({qrImage, device}: QrCodeSectionProps) => {
    return (
        <ActionBanner css={{width: '166px'}}>
            <Stack space={10} align="center">
                <Box>
                    <Text align="left">{device}:</Text>
                </Box>
                <img src={qrImage} alt="" height="60" width="60" />
                {device === 'Apple iOS' ? (
                    <AppStoreBadge
                        height={35}
                        width={106}
                        onClick={() =>
                            tracking.downloadAppModalActioned(appStoreClickedModalTrackingProps)
                        }
                    />
                ) : null}
                {device === 'Android' ? (
                    <PlayStoreBadge
                        height={35}
                        width={116}
                        onClick={() =>
                            tracking.downloadAppModalActioned(playStoreClickedModalTrackingProps)
                        }
                    />
                ) : null}
            </Stack>
        </ActionBanner>
    )
}

interface DownloadButtonMobileProps {
    device: 'Android' | 'Apple'
}

const DownloadButtonMobile = ({device}: DownloadButtonMobileProps) => {
    let downloadLink: string
    if (device === 'Apple') {
        downloadLink = 'https://apps.apple.com/app/pleo/id1110743278'
    } else if (device === 'Android') {
        downloadLink = 'https://play.google.com/store/apps/details?id=io.pleo.android'
    }

    return (
        <ActionBanner
            onClick={() => {
                tracking.downloadAppModalActioned(
                    device === 'Apple'
                        ? appStoreClickedModalTrackingProps
                        : playStoreClickedModalTrackingProps,
                )
                window.location.href = downloadLink
            }}
            css={{padding: `${tokens.spacing28}`}}
        >
            <Inline justifyContent={'space-between'} flexGrow={1}>
                <Text>
                    <Trans>Download for {device} device</Trans>
                </Text>
                <ArrowRight color={tokens.colorContentInteractiveQuiet} />
            </Inline>
        </ActionBanner>
    )
}

interface DownloadAppModalProps {
    isOpen: boolean
    closeModal: () => void
}

export const DownloadAppModal = ({isOpen, closeModal}: DownloadAppModalProps) => {
    const isLargeScreen = useMediaQuery(`(min-width: ${breakpoints.tabletUp})`)

    const onClose = () => {
        tracking.downloadAppModalActioned(closedModalTrackingProps)
        closeModal()
    }

    return (
        <StyledModal isOpen={isOpen} aria-label={t`Download Pleo mobile app`}>
            <Modal.Close data-testid="close-download-modal" onClick={onClose} />
            <Modal.Title>
                <Trans>You are now ready to start spending</Trans>
            </Modal.Title>
            <Modal.Content>
                <Modal.Illustration>
                    <img
                        src={downloadMobileApp}
                        alt={t`Download Pleo mobile app`}
                        height="118"
                        width="194"
                    />
                </Modal.Illustration>

                <ModalText>
                    <Trans>
                        Download the app to activate virtual card and test out our spending and
                        receipt capturing experience.
                    </Trans>{' '}
                    {isLargeScreen ? (
                        <Trans>Open camera and scan to download the Pleo mobile apps.</Trans>
                    ) : null}
                </ModalText>

                {isLargeScreen ? (
                    <StoreSectionDesktop space={20}>
                        {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                        <QrCodeSection qrImage={qrAppleDownload} device="Apple iOS" />
                        {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                        <QrCodeSection qrImage={qrAndroidDownload} device="Android" />
                    </StoreSectionDesktop>
                ) : (
                    <StoreSectionMobile space={20}>
                        {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                        <DownloadButtonMobile device="Apple" />
                        {/* eslint-disable-next-line string-to-lingui/missing-lingui-transformation */}
                        <DownloadButtonMobile device="Android" />
                    </StoreSectionMobile>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose} variant="secondary">
                    <Trans>Close</Trans>
                </Button>
            </Modal.Actions>
        </StyledModal>
    )
}
