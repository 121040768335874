import styled, {css} from 'styled-components'

import {focusRing, Inline, NakedButton, px, Stack, Text, tokens} from '@pleo-io/telescope'

const ActiveDragging = css`
    border: ${tokens.sizeBorderDefault} dashed ${tokens.colorBorderInteractiveLoud};
`

export const ExpenseCard = styled(NakedButton)<{
    $isActive?: boolean
    $isDragging?: boolean
    $isNarrow?: boolean
}>`
    width: 100%;
    padding-top: ${tokens.spacing8};
    padding-bottom: ${tokens.spacing8};
    padding-left: ${tokens.spacing8};
    padding-right: ${(props) => (props.$isNarrow ? tokens.spacing8 : tokens.spacing24)};
    margin-bottom: ${tokens.spacing12};
    border: ${tokens.borderLightest};
    border-radius: ${tokens.arc12};
    transition:
        background-color ${tokens.smoothInOut},
        border ${tokens.smoothInOut},
        transform ${tokens.smoothInOut};
    ${focusRing('regular')};

    &:hover {
        box-sizing: border-box;
        background-color: ${tokens.colorBackgroundInteractiveQuietHover};
    }
    ${(props) =>
        props.$isDragging &&
        css`
            background: ${tokens.colorBackgroundStaticLoud};
            border: ${tokens.sizeBorderDefault} dashed ${tokens.colorBorderStatic};
        `}

    &:active {
        transform: scale(0.95);
        transition: ${tokens.smoothInOut};
    }

    ${(props) =>
        props.$isActive &&
        css`
            background-color: ${tokens.colorBackgroundStaticPage};
            border: ${tokens.borderInteractiveQuiet};
            box-shadow: none;
        `}

    ${(props) => props.$isDragging && props.$isActive && ActiveDragging}
`

export const ExpenseCardInline = styled(Inline).attrs({
    marginY: 8,
    space: 8,
})<{$isNarrow?: boolean}>`
    max-width: ${(props) => (props.$isNarrow ? px(666) : px(700))};
    width: 100%;
`

export const ExpenseCardWrapper = styled(Inline).attrs({
    justifyContent: 'space-between',
})`
    width: 100%;
`

export const ExpenseCardStack = styled(Stack).attrs({
    space: 4,
    align: 'left',
})`
    width: fit-content;
    text-align: left;
`

export const ExpenseCardInformationStack = styled(Stack).attrs({
    space: 2,
    alignItems: 'end',
    flexDirection: 'column',
})`
    width: fit-content;
    display: flex;
`

export const ExpenseCardAmountText = styled(Text)<{$isRefund?: boolean}>`
    text-align: right;

    ${(props) =>
        props.$isRefund &&
        css`
            color: ${tokens.colorContentPositive};
        `}
`

export const ExpenseCardDetails = styled(Stack)`
    display: flex;
    flex-direction: column;
    width: auto;
    padding-left: ${tokens.spacing14};
    border-left: ${tokens.sizeBorderThick} solid ${tokens.colorBorderInteractiveQuiet};
    overflow-wrap: break-word;
    overflow: hidden;
    max-width: 100%;
`
