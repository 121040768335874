import {Trans} from '@lingui/macro'

import {Button, Text, tokens} from '@pleo-io/telescope'

import type {DispatchActions, Step} from '@product-web/feature--ui-guides/guide'

import PaperAirplaneIllustration from '../../../images/paper-airplane.svg'

export const useSuccessStep = (): Step => {
    const color = tokens.colorBackgroundPresentationalPink

    return {
        key: 'success',
        color,
        illustrationBgColor: color,
        title: (
            <Text variant="4xlarge-accent" as="h3" align="left">
                <Trans>Your request was sent</Trans>
            </Text>
        ),
        sections: [
            {
                key: 'description',
                content: (
                    <Text as="p" align="left">
                        <Trans>
                            Your account manager will contact you by phone or email to get you set
                            up soon.
                        </Trans>
                    </Text>
                ),
            },
        ],
        illustration: <img src={PaperAirplaneIllustration} alt="" />,
        actions: [
            {
                key: 'done',
                action: ({onCompleted}: DispatchActions) => (
                    <Button variant="primary" onClick={onCompleted}>
                        <Trans>Done</Trans>
                    </Button>
                ),
            },
        ],
    }
}
