import {Trans} from '@lingui/macro'
import type React from 'react'
import {useEffect, useState} from 'react'
import {useGate} from 'statsig-react'
import styled from 'styled-components'

import {IconButton, Inline, Popover, Stack, Text, tokens} from '@pleo-io/telescope'
import {Close} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/shared--analytics'
import {useFlags} from '@product-web/shared--flags'

import {PLEO_GUIDE_AWARENESS_COMPLETED_VALUE} from './storage'
import virtualCardReady from './virtual-card-ready.svg'

import {bff} from '../bff-hooks'
import type {CardReadyPopoverActionedExtended} from '../tracking/types'

export const closedPopoverTrackingProps: CardReadyPopoverActionedExtended = {
    action: 'closed',
}

export const useCardReadyPopover = () => {
    const gate = useGate('company_onboarding_-_has_get_started_screen')
    const featureFlags = useFlags()

    const {data: shouldShowCardPopover} =
        bff.featureOnboarding.cardReadyPopover.getCardReadyPopover.useQuery(
            {
                companyOnboardingVersion: gate.value ? 'get-started' : 'pleo-guide',
            },
            {
                enabled: !gate.isLoading,
            },
        )
    const {mutate: setCardReadyPopover} =
        bff.featureOnboarding.cardReadyPopover.setCardReadyPopover.useMutation()

    const isVisible = featureFlags.bifrostNudgeToSpendExperiment && shouldShowCardPopover

    const close = async () => {
        if (isVisible) {
            setCardReadyPopover(PLEO_GUIDE_AWARENESS_COMPLETED_VALUE)
        }
    }

    return {isVisible, close}
}

export const CardReadyPopover: React.FC<React.PropsWithChildren<{enabled: boolean}>> = ({
    children,
    enabled,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const {isVisible, close} = useCardReadyPopover()

    useEffect(() => {
        if (isVisible && enabled) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }

        return () => setIsOpen(false)
    }, [isVisible, enabled])

    const onClose = async () => {
        setIsOpen(false)
        await close()
    }

    return (
        <CardReadyPopoverPresentation isOpen={isOpen} onClose={onClose}>
            {children}
        </CardReadyPopoverPresentation>
    )
}

interface CardReadyPopoverPresentationProps {
    isOpen: boolean
    onClose: () => void
}

export const CardReadyPopoverPresentation: React.FC<
    React.PropsWithChildren<CardReadyPopoverPresentationProps>
> = ({isOpen, onClose, children}) => (
    <Popover open={isOpen}>
        <Popover.Trigger>{children}</Popover.Trigger>
        <Popover.Content side="right">
            <StyledIconButton
                tooltipProps={{content: ''}}
                Icon={Close}
                variant="quiet"
                data-testid="close-card-popover"
                onClick={() => {
                    tracking.cardReadyPopoverActioned(closedPopoverTrackingProps)
                    onClose()
                }}
            />
            <Inline pl={40} pr={60} py={28} alignItems="center">
                <img src={virtualCardReady} alt="" height="81" width="45" />
                <StyledStack ml={32}>
                    <Text weight="medium" variant="xlarge-accent">
                        <Trans>Virtual card is ready to use</Trans>
                    </Text>
                    <Text>
                        <Trans>
                            You can now buy things on your virtual Pleo card — find it here
                        </Trans>
                    </Text>
                </StyledStack>
                <Popover.Arrow />
            </Inline>
        </Popover.Content>
    </Popover>
)

const StyledIconButton = styled(IconButton)`
    position: absolute;
    top: ${tokens.spacing20};
    right: ${tokens.spacing20};
    cursor: pointer;
`

const StyledStack = styled(Stack)`
    width: 300px;
`
