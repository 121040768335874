import {t} from '@lingui/macro'
import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {Trust} from '@pleo-io/telescope-pictograms'

import {OverdraftAwarenessFlowModal} from '@product-web/sub-app--funds-management/components/overdraft/awareness-flow/overdraft-awareness-flow-modal'
import {useOverdraftAwarenessFlow} from '@product-web/sub-app--funds-management/components/overdraft/awareness-flow/use-overdraft-awareness-flow'

import {trackOverdraftAwarenessFlow} from './overdraft-awareness-flow.tracking.helpers'

import {NavigationPromotionalBanner} from '../navigation-promotional-banner'

export const OVERDRAFT_MODAL = '@pleo/overdraft-modal'
export const OVERDRAFT_PROMOTIONAL_BANNER = '@pleo/overdraft-promotional-banner'

/**
 * MODAL DISPLAY REQUIREMENTS:
 * - user is owner of the company
 * - company has to be eligible for overdraft AND direct debit,
 * - user shouldn't be on the overdraft activation page
 * - modal has not been dismissed yet
 *
 * PROMOTIONAL BANNER DISPLAY REQUIREMENTS:
 * - user is owner of the company
 * - modal has been dismissed already
 * - promotional banner has not been dismissed yet
 * - user hasn't set up overdraft yet
 */
export const OverdraftAwarenessFlow = () => {
    const [showModal, setShowModal] = useState(false)
    const overdraftActivationUrls = [
        '/wallet/overdraft/activate',
        '/wallet/direct-debit/overdraft',
        '/cash-management/overdraft/activate',
        '/cash-management/direct-debit/create/overdraft',
    ]

    const {isEligible} = useOverdraftAwarenessFlow()
    const navigate = useNavigate()
    const location = useLocation()
    const path = location.pathname

    const hasAlreadyBeenDismissed = (key: string): boolean => {
        return localStorage.getItem(key) === 'dismissed'
    }

    const dismissElement = (key: string): void => {
        return localStorage.setItem(key, 'dismissed')
    }

    const isOverdraftActivationPage = overdraftActivationUrls.some((url) => path.includes(url))

    useEffect(() => {
        if (isEligible && !hasAlreadyBeenDismissed(OVERDRAFT_MODAL)) {
            trackOverdraftAwarenessFlow({
                component: 'modal',
                action: 'viewed',
            })

            setShowModal(true)
        }

        isOverdraftActivationPage && setShowModal(false)
    }, [isEligible, isOverdraftActivationPage])

    const handleCloseModal = () => {
        trackOverdraftAwarenessFlow({
            component: 'modal',
            action: 'dismissed',
        })

        dismissElement(OVERDRAFT_MODAL)
        setShowModal(false)
        return null
    }

    const onModalCtaClick = () => {
        trackOverdraftAwarenessFlow({
            component: 'modal',
            action: 'clicked',
            cta: 'set_up_now',
        })

        handleCloseModal()
        navigate(overdraftActivationUrls[0], {state: {source: 'overdraft-awareness-flow'}})
        dismissElement(OVERDRAFT_PROMOTIONAL_BANNER)
    }

    if (showModal) {
        return (
            <OverdraftAwarenessFlowModal
                displayModal={showModal}
                handleCloseModal={handleCloseModal}
                onClick={onModalCtaClick}
            />
        )
    }

    const showPromotionalBanner =
        hasAlreadyBeenDismissed(OVERDRAFT_MODAL) &&
        !hasAlreadyBeenDismissed(OVERDRAFT_PROMOTIONAL_BANNER) &&
        !isOverdraftActivationPage &&
        isEligible

    const handleClickPromotionalBanner = () => {
        trackOverdraftAwarenessFlow({
            component: 'promotional-banner',
            action: 'clicked',
            cta: 'view_rates',
        })
        setShowModal(true)
    }
    const handleClosePromotionalBanner = () => {
        dismissElement(OVERDRAFT_PROMOTIONAL_BANNER)

        trackOverdraftAwarenessFlow({
            component: 'promotional-banner',
            action: 'dismissed',
        })
    }

    if (showPromotionalBanner) {
        return (
            <NavigationPromotionalBanner
                pictogram={<Trust size="48" />}
                onClick={handleClickPromotionalBanner}
                onClose={handleClosePromotionalBanner}
                description={t`Keep the business up when the funds are low with overdraft`}
                linkText={t`View rates`}
                tooltipText={t`Close overdraft banner`}
                testId="overdraft-awareness-flow-promotional-banner"
            />
        )
    }

    return null
}
