import {t, Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import styled from 'styled-components'

import {Button, List, ListIcon, ListItem, Text, tokens} from '@pleo-io/telescope'
import {Check} from '@pleo-io/telescope-icons'

import type {SetupAccountingPersonaActionedExtended} from '@product-web/feature--onboarding/tracking/types'
import tracking from '@product-web/shared--analytics'
import {customColorSchemeTokens} from '@product-web/shared--styles/custom-tokens'
import {breakpoints} from '@product-web/shared--styles/theme'

import type {AccessLevel} from './types'

import {useAddMemberModalContext} from '../lib/add-member-modal-context'

export enum DialogVariant {
    OneButton = 'OneButton',
    TwoButtons = 'TwoButtons',
}

interface BookkeeperAccessLevelProps {
    accessLevel: AccessLevel | null
    setLevel: (level: AccessLevel) => void
    variant?: DialogVariant
}

const BookkeeperAccessLevel = ({
    accessLevel,
    setLevel,
    variant = DialogVariant.TwoButtons,
}: BookkeeperAccessLevelProps) => {
    const {modalFlowOrigin} = useAddMemberModalContext()

    return (
        <Grid>
            <Box>
                <Text variant="xlarge-accent" as="h3" color="colorContentStatic" space={10}>
                    {t`Limited Access`}
                </Text>
                <Text as="p" space={20} color={'colorContentStaticQuiet'}>
                    {t`Just the bookkeeping essentials`}
                </Text>
                <FeaturesList>
                    <BasicAccessFeatures />
                </FeaturesList>
                <SelectButton
                    accessLevel={accessLevel}
                    newAccessLevel={'basic'}
                    setLevel={setLevel}
                    variant={variant}
                    onClick={() => {
                        if (modalFlowOrigin === 'onboarding') {
                            tracking.setupAccountingPersonaActioned({
                                action: 'access_selected',
                                access: 'limited',
                            } as SetupAccountingPersonaActionedExtended)
                        }
                    }}
                />
            </Box>
            <Box>
                <Text variant="xlarge-accent" as="h3" color="colorContentStatic" space={10}>
                    {t`Extended Access`}
                </Text>
                <Text as="p" space={20} color={'colorContentStaticQuiet'}>
                    {t`Essential plus certain administrator rights`}
                </Text>
                <FeaturesList>
                    <ExtendedAccessFeatures />
                </FeaturesList>
                <SelectButton
                    accessLevel={accessLevel}
                    newAccessLevel={'extended'}
                    setLevel={setLevel}
                    variant={variant}
                    onClick={() => {
                        if (modalFlowOrigin === 'onboarding') {
                            tracking.setupAccountingPersonaActioned({
                                action: 'access_selected',
                                access: 'extended',
                            } as SetupAccountingPersonaActionedExtended)
                        }
                    }}
                />
            </Box>
        </Grid>
    )
}

export const BasicAccessFeatures = ({
    column = 1,
    withMovingFunds = true,
}: {
    column?: number
    withMovingFunds?: boolean
}) => (
    <>
        <FeatureRow column={column} message={t`See and edit expenses`} />
        <FeatureRow column={column} message={t`Edit accounting settings`} />
        <FeatureRow column={column} message={t`Export transactions`} />
        <FeatureRow column={column} message={t`Settle out-of-pocket expenses (optional)`} />
        {withMovingFunds && <MovingFundsFeature column={column} />}
    </>
)

export const ExtendedAccessFeatures = ({
    column = 2,
    levelSelectionStage = true,
}: {
    column?: number
    levelSelectionStage?: boolean
}) => (
    <>
        {levelSelectionStage ? (
            <>
                <FeatureRow column={column} message={t`Add and edit users`} />
                <FeatureRow column={column} message={t`Set and control limits`} />
            </>
        ) : (
            <FeatureRow column={column} message={t`Add, edit users and limits`} />
        )}
        <FeatureRow column={column} message={t`Access company wallet`} />
        <FeatureRow column={column} message={t`Settle out-of-pocket expenses (optional)`} />
        <MovingFundsFeature column={column} />
    </>
)

const FeatureRow = ({
    message,
    column = 1,
    iconColor = customColorSchemeTokens.colorContentPresentationalPink,
}: {
    message: ReactNode
    column?: number
    iconColor?: string
}) => (
    <FeaturesListItem column={column}>
        <ListIcon size={24} as={Check} color={iconColor} />
        <Text color={'shade600'}>{message}</Text>
    </FeaturesListItem>
)

const MovingFundsFeature = ({column = 1}: {column?: number}) => (
    <FeatureRow message={t`Moving funds (optional)`} column={column} />
)

interface SelectButtonProps {
    accessLevel: AccessLevel | null
    newAccessLevel: AccessLevel
    setLevel: (level: AccessLevel) => void
    variant: DialogVariant
    onClick: () => void
}

const SelectButton = ({
    accessLevel,
    newAccessLevel,
    setLevel,
    variant,
    onClick,
}: SelectButtonProps) => {
    const dataTestId = `access-level-${newAccessLevel ?? 'unknown'}`
    const isText = variant === 'OneButton' && accessLevel === newAccessLevel

    if (isText) {
        return (
            <SelectText data-testid={dataTestId} align={'center'} variant="medium-default">
                {accessLevel === newAccessLevel && <Check mr={8} />}
                <Trans>Select</Trans>
            </SelectText>
        )
    }

    return (
        <BottomButton
            data-testid={dataTestId}
            variant={accessLevel === newAccessLevel ? 'primary' : 'secondary'}
            onClick={() => {
                onClick()
                setLevel(newAccessLevel)
            }}
        >
            {accessLevel === newAccessLevel && <Check mr={8} />}
            <Trans>Select</Trans>
        </BottomButton>
    )
}

export default BookkeeperAccessLevel

const Grid = styled.div`
    display: grid;
    grid-gap: ${tokens.spacing12};
    grid-template-columns: 1fr 1fr;

    @media (max-width: ${breakpoints.tabletUp}) {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
    }
`

const Box = styled.div`
    border: ${tokens.borderPrimary};
    padding: ${tokens.spacing24};
    border-radius: ${tokens.arc8};
    display: flex;
    flex-direction: column;
`

const FeaturesList = styled(List)`
    margin-bottom: ${tokens.spacing40};

    @media (max-width: ${breakpoints.tabletUp}) {
        margin-bottom: ${tokens.spacing20};
    }
`

const FeaturesListItem = styled(ListItem)<{column: number}>`
    grid-column: ${({column}) => column};
    display: flex;
    align-items: center;

    &:last-child {
        margin-bottom: ${tokens.spacing12};
    }
`

const BottomButton = styled(Button)`
    margin: auto auto 0;
`

const SelectText = styled(Text)`
    margin: ${tokens.spacing8};
`
