import {plural, t, Trans} from '@lingui/macro'
import styled, {css} from 'styled-components'

import {Button, Card, Inline, Stack, Switch, Text, tokens} from '@pleo-io/telescope'

import {SupportedLanguage} from '@product-web/shared--i18n'
import {
    Container,
    containerQuery,
} from '@product-web/shared--telescope-lab/container-queries/container'
import {useLoggedInUser} from '@product-web/shared--user'

import {formatMinorNumberToCurrency} from './currency-formatter'
import {PlanTypeName} from './pricing-plans.helper'
import {TrialPopover} from './trial-popover'

const MOBILE_BREAKPOINT = 500
const CONTAINER_QUERY_NAME = 'PreTrialPlanBreakdown'

//#region Components

type PreTrialPlanBreakdownProps = {
    isAnnualBilling: boolean
    onChangeIsAnnualBilling?: (isAnnualBilling: boolean) => void
    planName: string
    planHasFreeTrial: boolean
    planPrice: number
    currency: string
    onSelectPlanClick: () => void
    onCompareAllPlansClick?: () => void
    numIncludedUsers?: number
    numMaxAdditionalUsers?: number
    additionalUsersPrice?: number
    isDowngrade?: boolean
    trialPopoverProps: React.ComponentProps<typeof TrialPopover>
    isLoading?: boolean
}

export const PreTrialPlanBreakdown = ({
    isAnnualBilling,
    onChangeIsAnnualBilling,
    planName,
    planHasFreeTrial,
    planPrice,
    currency,
    onSelectPlanClick,
    onCompareAllPlansClick,
    numIncludedUsers,
    numMaxAdditionalUsers,
    additionalUsersPrice,
    isDowngrade,
    trialPopoverProps,
    isLoading,
}: PreTrialPlanBreakdownProps) => {
    const formatAmount = useFormatMinorNumberToCurrency(currency)

    const isPaidPlan = planPrice > 0
    const planCost = getCostPerMonthAndYear(planPrice, isAnnualBilling)

    return (
        <Container name={CONTAINER_QUERY_NAME}>
            <StyledCard>
                {planHasFreeTrial && (
                    <>
                        <RowGroup as="dl" data-testid="trial-section">
                            <Row>
                                <MainText as="dt">
                                    <Trans>21-day free trial</Trans>
                                </MainText>

                                <MainText as="dd">{formatAmount(0)}</MainText>
                            </Row>

                            <SubText as="dd">
                                <Inline as="span" space={8} alignItems="start">
                                    <Trans>Starts after adding funds to Pleo</Trans>

                                    <PopoverWrapper>
                                        <TrialPopover {...trialPopoverProps} />
                                    </PopoverWrapper>
                                </Inline>
                            </SubText>
                        </RowGroup>

                        <Card.Divider />
                    </>
                )}

                <Stack space={16}>
                    <RowGroup as="dl" data-testid="breakdown-section">
                        <Row>
                            <MainText as="dt">
                                {planHasFreeTrial ? (
                                    <Trans>After trial ends</Trans>
                                ) : (
                                    <Trans>{planName} plan</Trans>
                                )}
                            </MainText>

                            <MainText as="dd">
                                <Trans>{formatAmount(planCost.perMonth)}/month</Trans>
                            </MainText>
                        </Row>

                        <Row>
                            {numIncludedUsers && additionalUsersPrice && (
                                <SubText as="dd">
                                    {getAdditionalUsersText({
                                        numIncludedUsers,
                                        numMaxAdditionalUsers,
                                        formattedCostPerMonth: formatAmount(
                                            getCostPerMonthAndYear(
                                                additionalUsersPrice,
                                                isAnnualBilling,
                                            ).perMonth,
                                        ),
                                    })}
                                </SubText>
                            )}

                            {isPaidPlan && (
                                <ToggleableSubText as="dd" $isVisibile={isAnnualBilling}>
                                    <Trans>{formatAmount(planCost.perYear)}/year</Trans>
                                </ToggleableSubText>
                            )}
                        </Row>
                    </RowGroup>

                    {isPaidPlan && onChangeIsAnnualBilling && (
                        <Switch checked={isAnnualBilling} onCheckedChange={onChangeIsAnnualBilling}>
                            <Trans>Annual billing (Up to 10% discount)</Trans>
                        </Switch>
                    )}
                </Stack>

                <Card.Divider />

                <Row as="dl" data-testid="summary-section">
                    <MainText as="dt">
                        <Trans>To pay now</Trans>
                    </MainText>

                    <MainText as="dd">{formatAmount(planHasFreeTrial ? 0 : planPrice)}</MainText>
                </Row>

                <ButtonGroup>
                    {isDowngrade ? (
                        <Button
                            variant="primary"
                            onClick={onSelectPlanClick}
                            destructive
                            loading={isLoading}
                        >
                            <Trans>Downgrade to {planName}</Trans>
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={onSelectPlanClick} loading={isLoading}>
                            {planHasFreeTrial ? (
                                <Trans>Try {planName} for free</Trans>
                            ) : (
                                <Trans>Continue with {planName}</Trans>
                            )}
                        </Button>
                    )}

                    {onCompareAllPlansClick && (
                        <Button variant="secondary" onClick={onCompareAllPlansClick}>
                            <Trans>Compare all plans</Trans>
                        </Button>
                    )}
                </ButtonGroup>
            </StyledCard>
        </Container>
    )
}

type PreTrialPlanBreakdownContainerProps = {
    plan: keyof typeof PlanTypeName
} & Omit<PreTrialPlanBreakdownProps, 'planName'>

export const PreTrialPlanBreakdownContainer = ({
    plan,
    ...props
}: PreTrialPlanBreakdownContainerProps) => {
    return <PreTrialPlanBreakdown {...props} planName={PlanTypeName[plan]} />
}

//#endregion Components

//#region Helpers

type GetAdditionalUsersTextParams = {
    numIncludedUsers: number
    numMaxAdditionalUsers: number | undefined
    formattedCostPerMonth: string
}

const getAdditionalUsersText = ({
    formattedCostPerMonth,
    numIncludedUsers,
    numMaxAdditionalUsers,
}: GetAdditionalUsersTextParams) => {
    const pluralizedIncludedUsers = plural(numIncludedUsers, {
        one: 'user',
        other: 'users',
    })

    if (numMaxAdditionalUsers !== undefined) {
        const pluralizedMaxAdditionalUsers = plural(numMaxAdditionalUsers, {
            one: 'user',
            other: 'users',
        })

        return t`Includes ${numIncludedUsers} ${pluralizedIncludedUsers}, ${numMaxAdditionalUsers} additional ${pluralizedMaxAdditionalUsers} ${formattedCostPerMonth}/user/month`
    }

    return t`Includes ${numIncludedUsers} ${pluralizedIncludedUsers}, then ${formattedCostPerMonth}/user/month`
}

const getCostPerMonthAndYear = (planPrice: number, isAnnualBilling: boolean) => {
    // If the plan is billed annually, we divide the price by 12 to get the monthly cost
    const perMonth = isAnnualBilling ? planPrice / 12 : planPrice
    // If the plan is billed monthly, we multiply the price by 12 to get the yearly cost
    const perYear = isAnnualBilling ? planPrice : planPrice * 12

    return {perMonth, perYear}
}

const useFormatMinorNumberToCurrency = (currency: string) => {
    const user = useLoggedInUser()
    const locale = user.language

    return (value: number) => {
        const decimalPlaces = Number.isInteger(value / 100) ? 0 : 2

        return formatMinorNumberToCurrency({
            value,
            locale: locale ?? SupportedLanguage.EN,
            options: {
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces,
                currency,
            },
        })
    }
}

//#endregion Helpers

//#region Styles

const StyledCard = styled(Card).attrs({space: 32})`
    padding: ${tokens.spacing24};

    ${containerQuery(
        {
            name: CONTAINER_QUERY_NAME,
            minWidth: MOBILE_BREAKPOINT,
        },
        css`
            padding: ${tokens.spacing32};
        `,
    )}
`

const RowGroup = styled(Stack).attrs({stretch: true, space: 2})``

const Row = styled(Inline).attrs({justifyContent: 'space-between', wrap: false})`
    column-gap: ${tokens.spacing24};

    ${containerQuery(
        {
            name: CONTAINER_QUERY_NAME,
            minWidth: MOBILE_BREAKPOINT,
        },
        css`
            column-gap: ${tokens.spacing40};
        `,
    )}
`

const lastRowChildStyles = css`
    ${Row} > &:last-child:not(:only-child) {
        text-align: right;
        white-space: nowrap;
    }
`

const MainText = styled(Text).attrs({variant: 'large-accent', weight: 'medium'})`
    ${lastRowChildStyles}
`

const SubText = styled(Text).attrs({variant: 'small-subtle', color: 'colorContentStaticQuiet'})`
    ${lastRowChildStyles}
`

const ButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: ${tokens.spacing16};
    row-gap: ${tokens.spacing16};

    ${containerQuery(
        {
            name: CONTAINER_QUERY_NAME,
            minWidth: MOBILE_BREAKPOINT,
        },
        css`
            flex-direction: row;
            justify-content: start;
        `,
    )}
`

type ToggleableSubTextProps = {
    $isVisibile: boolean
}

const ToggleableSubText = styled(SubText)<ToggleableSubTextProps>`
    visibility: ${({$isVisibile}) => ($isVisibile ? 'unset' : 'hidden')};
`

// Extends the styling of the text element so we can set the height to 1lh to keep the contents vertically centered to
// the height of 1 line of text
const PopoverWrapper = styled.span`
    height: 1lh;
    display: flex;
    align-items: center;
`

//#endregion Styles
