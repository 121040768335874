import React from 'react'
import {uuid} from 'short-uuid'

import {useIsLoggedIn} from '@product-web/shared--auth--session/context'

import {bff} from './bff-hooks'
import {OutageBanner} from './status-page.view'
import type {OutageComponentName} from './status-page-client.bff'

type StatusPageContextAPI = {
    registerReportedComponents: (id: string, components: OutageComponentName[]) => void
    unregisterReportedComponents: (id: string) => void
}

const StatusPageContext = React.createContext<StatusPageContextAPI>({
    registerReportedComponents: () => {},
    unregisterReportedComponents: () => {},
})

/**
 * This provider wraps the pages container. It's responsible for displaying a notification banner
 * that informs the user of any currently ongoing issues in the Pleo systems. The information comes
 * from a 3rd party service called incident.io, which is used to track outages and incidents.
 * See: https://status.pleo.io, https://incident.io/status-pages.
 */
export const StatusPageProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
    const [state, dispatch] = React.useReducer(reducer, {default: DEFAULT_REPORTED_COMPONENTS})

    const isLoggedIn = useIsLoggedIn()

    const {data: componentsWithOutage = []} = bff.getComponentsWithOutage.useQuery(undefined, {
        refetchOnWindowFocus: false,
    })

    const contextValue: StatusPageContextAPI = React.useMemo(
        () => ({
            registerReportedComponents: (id, components) => {
                dispatch({type: 'register', components, id})
            },
            unregisterReportedComponents: (id) => {
                dispatch({type: 'unregister', id})
            },
        }),
        [],
    )

    /**
     * In order to register and deregister additional reported components without
     * any interference, we save each call to useStatusPage under a random string key, e.g.
     * {'68c7bad0c4': 'wallet', '3591bf5a0b': 'wallet', default: ['transactions', 'mobileApp', 'webApp']}
     * We then flatten and dedupe the components before passing to the notification banner component.
     */
    const reportedComponents = [...new Set(Object.values(state).flat())]

    const components = componentsWithOutage.map((component) => ({
        ...component,
        isReported: reportedComponents.includes(component.name),
    }))

    return (
        <StatusPageContext.Provider value={contextValue}>
            {isLoggedIn && <OutageBanner components={components} />}
            {children}
        </StatusPageContext.Provider>
    )
}

function reducer(
    reportedComponents: Record<string, OutageComponentName[]>,
    action:
        | {type: 'register'; id: string; components: OutageComponentName[]}
        | {type: 'unregister'; id: string},
) {
    switch (action.type) {
        case 'register':
            return {...reportedComponents, [action.id]: action.components}
        case 'unregister': {
            const {[action.id]: ignored, ...nextReportedComponents} = reportedComponents
            return nextReportedComponents
        }
        default:
            throw new Error('Unknown action type')
    }
}

/**
 * The notification banner displays either a generic message about an outage in some functionality,
 * or more details, if the component that has an outage is specifically registered reported for a given
 * page. We always report details on outage in the following components:
 */
const DEFAULT_REPORTED_COMPONENTS: OutageComponentName[] = [
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    'Transactions',
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    'Mobile application',
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    'Web application',
]

/**
 * Custom React hook for registering reported Status Page components. Any React component
 * can call this hook with one more component names, and as long as it remains mounted
 * the outage of these components will be explicitly listed in the outage notification banner.
 *
 * Note: Pages in the app can register other Status Page components using the useStatusPage custom hook.
 *
 * @param components A single component name or a list of component names (from config.statuspage)
 */
export function useStatusPage(components: OutageComponentName | OutageComponentName[]) {
    // Consume the StatusPageContext to get access to the register and unregister functions
    const statusPage = React.useContext(StatusPageContext)

    // Setup a predictable array of components
    const componentsArray = Array.isArray(components) ? components : [components]

    // Get a "hash" of components to only call useEffect if components change
    const componentsHash = componentsArray.sort().join(':')

    React.useEffect(() => {
        const id = uuid()
        statusPage.registerReportedComponents(id, componentsArray)
        return () => statusPage.unregisterReportedComponents(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentsHash, statusPage])
}
