import Country from '@product-web/shared--locale/country'
import {useUser} from '@product-web/shared--user'

import {useCompanySettings} from './company-settings'

export enum RepresentationLevels {
    Full = 'FULL',
    Limited = 'LIMITED',
    None = 'NONE',
}

export function useRepresentationLevel(bypassSystemCheck?: boolean) {
    const user = useUser()
    const preferences = useCompanySettings()

    if (
        user?.company?.address?.country?.includes(Country.SE) &&
        (bypassSystemCheck ||
            [
                'fortnox',
                'businesscentral',
                'sie',
                'sie_marathon',
                'visma',
                'generic',
                'custom',
            ].includes(preferences?.data?.accounting?.system ?? 'none'))
    ) {
        return RepresentationLevels.Full
    }
    return RepresentationLevels.None
}
