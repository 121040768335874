import {t, Trans} from '@lingui/macro'
import {ErrorBoundary} from '@sentry/react'
import {useEffect} from 'react'
import {Link as RouterLink, Outlet, useLocation} from 'react-router-dom'
import styled from 'styled-components'

import {Inline, Link, Stack, Text} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import {ContactSupport} from '@product-web/feature--ui-contact-support'
import tracking from '@product-web/shared--analytics'

import blackCatImage from '../images/black-cat.webp'

export function CashManagementTabErrorBoundary() {
    return (
        <ErrorBoundary
            beforeCapture={(scope) => scope.setLevel('fatal')}
            fallback={({resetError}) => <CashManagementError resetError={resetError} />}
        >
            <Outlet />
        </ErrorBoundary>
    )
}

export function CashManagementPageErrorBoundary() {
    return (
        <ErrorBoundary
            beforeCapture={(scope) => scope.setLevel('fatal')}
            fallback={({resetError}) => <CashManagementError isFullPage resetError={resetError} />}
        >
            <Outlet />
        </ErrorBoundary>
    )
}

function CashManagementError({
    isFullPage = false,
    resetError,
}: {
    isFullPage?: boolean
    resetError: () => void
}) {
    const location = useLocation()

    useEffect(() => {
        tracking.cashManagementErrorViewed({
            path: location.pathname,
        })
        return resetError
    }, [location.pathname, resetError])

    if (isFullPage) {
        return (
            <Inline
                flexWrap="wrap"
                space={32}
                align="center"
                alignItems="center"
                css={{width: '100%', minHeight: '100%'}}
            >
                <ErrorBoundaryContent hasBackLink={isFullPage} />
            </Inline>
        )
    }
    return (
        <Inline>
            <ErrorBoundaryContent hasBackLink={isFullPage} />
        </Inline>
    )
}

function ErrorBoundaryContent({hasBackLink}: {hasBackLink: boolean}) {
    return (
        <>
            <Stack space={32} css={{maxWidth: 368}}>
                <Stack space={16}>
                    <Text variant="2xlarge-accent">
                        <Trans>Something is not quite right...</Trans>
                    </Text>
                    <Text>
                        <Trans>Contact our support team for help or try again later.</Trans>
                    </Text>
                </Stack>
                <ContactSupport buttonVariant="secondary" />
                {hasBackLink && (
                    <Link as={RouterLink} to="/cash-management" IconLeft={ArrowLeft}>
                        <Trans>Back to Cash Management</Trans>
                    </Link>
                )}
            </Stack>
            <StyledImage src={blackCatImage} alt={t`Black cat`} />
        </>
    )
}

const StyledImage = styled.img`
    width: 300px;
`
