import {t, Trans} from '@lingui/macro'
import type React from 'react'
import {useNavigate} from 'react-router'
import styled from 'styled-components'

import type {Person} from '@pleo-io/deimos'
import {Button, Drawer, IconButton, Inline, px, Stack, Text, tokens} from '@pleo-io/telescope'
import {Close} from '@pleo-io/telescope-icons'

import {useAccountSwitchModal} from '@product-web/feature--multi-entity/add-account/add-account'
import {AvatarEmployee} from '@product-web/feature--ui-avatar-employee/avatar-employee'
import type {Company} from '@product-web/shared--api-types/company'
import type {LoggedInAccount, SwitchAccountPayload} from '@product-web/shared--auth--accounts'

import {NavigationAccounts, NavigationItemMobile} from './navigation-accounts'
import {NoWrapParagraph} from './navigation-avatar'

export const MOBILE_NAVIGATION_DRAWER_ID = 'mobile-navigation-drawer'

interface NavigationAvatarMobileProps {
    isOpen: boolean
    onDismiss: () => void
    employee?: Partial<Person>
    company?: Company
    accounts?: LoggedInAccount[]
    hasAccounts?: boolean
    switchAccount: (payload: SwitchAccountPayload) => void
    onLogout: () => void
}

export const NavigationAvatarMobile: React.FC<NavigationAvatarMobileProps> = ({
    isOpen,
    onDismiss,
    employee,
    company,
    accounts,
    hasAccounts,
    switchAccount,
    onLogout,
}) => {
    const navigate = useNavigate()
    const {open: openSwitchAccountModal} = useAccountSwitchModal()

    return (
        <Drawer isOpen={isOpen} onDismiss={onDismiss} drawerId={MOBILE_NAVIGATION_DRAWER_ID}>
            <DrawerWrapper>
                <ContentWrapper>
                    <CloseModalWrapper>
                        <IconButton
                            onClick={onDismiss}
                            Icon={Close}
                            variant="secondary"
                            tooltipProps={{dangerouslyOmitTooltip: true, 'aria-label': t`Close`}}
                        />
                    </CloseModalWrapper>
                    <Stack space={0} align="center" stretch>
                        <AvatarWrapper>
                            <Inline space={8}>
                                <AvatarEmployee employee={employee} size={40} />
                                <Stack align="left">
                                    <NoWrapParagraph align="left">
                                        {employee?.firstName} {employee?.lastName}
                                    </NoWrapParagraph>
                                    <Text align="left" variant="small-subtle">
                                        {company?.name}
                                    </Text>
                                </Stack>
                            </Inline>

                            <Button
                                variant="secondary"
                                onClick={() => {
                                    navigate('/account/profile')
                                    onDismiss()
                                }}
                            >
                                <Trans>My account</Trans>
                            </Button>
                        </AvatarWrapper>
                        {hasAccounts && (
                            <NavigationAccounts
                                items={accounts}
                                switchAccount={switchAccount}
                                navigationItem={NavigationItemMobile}
                            />
                        )}

                        <MenuItem onClick={() => openSwitchAccountModal()}>
                            <Trans>Add account</Trans>
                        </MenuItem>
                        {hasAccounts ? (
                            <MenuItem onClick={onLogout}>
                                <Trans>Logout of all</Trans>
                            </MenuItem>
                        ) : (
                            <MenuItem onClick={onLogout}>
                                <Trans>Logout</Trans>
                            </MenuItem>
                        )}
                    </Stack>
                </ContentWrapper>
            </DrawerWrapper>
        </Drawer>
    )
}
const DrawerWrapper = styled(Drawer.Panel)`
    max-width: ${px(275)};
`
const ContentWrapper = styled.div`
    background-color: ${tokens.colorBackgroundStatic};
    height: 100%;
`

const AvatarWrapper = styled.div`
    padding: 0 ${tokens.spacing20} ${tokens.spacing20};
    display: flex;
    flex-direction: column;
    border-bottom: ${tokens.borderPrimary};

    button {
        margin-top: ${tokens.spacing16};
    }
`
const CloseModalWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: ${tokens.spacing24} ${tokens.spacing20} 0 0;
    background-color: ${tokens.colorBackgroundStatic};
`

const MenuItem = styled.div`
    font-size: ${px(14)};
    line-height: ${tokens.lineHeight3};
    padding: ${tokens.spacing8} ${tokens.spacing20};
    text-align: left;
    transition: ${tokens.smoothInOut};
    transition-property: background-color;

    &:hover,
    &:focus {
        background: ${tokens.colorBackgroundInteractiveQuietHover};
        color: ${tokens.colorContentInteractiveHover};
    }

    cursor: pointer;
    outline: none;
`
