/**
 * The maximum number of users allowed on the Starter plan.
 */
//
export const maxAllowedUsersOnStarter = 3

/**
 * The number of free users on the Starter plan.
 */
//
export const freeUsersOnStarter = 2
