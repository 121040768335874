import {t, Trans} from '@lingui/macro'
import {useState} from 'react'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Button, Inline, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft, ArrowRight, Clock} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'

import {ModuleOverview} from './module-overview'

import {SplitContent} from '../../../../../components/split-content'
import {QuizForm, type QuizQuestion} from '../../../components/quiz-form/quiz-form'

export const Quiz = () => {
    const [canContinue, setCanContinue] = useState(false)

    const questions: QuizQuestion[] = [
        {
            answers: [
                {
                    label: t`They provide multiple data sources, improving decision-making`,
                },
                {
                    isCorrect: true,
                    label: t`They create bottlenecks, increase errors, and limit real-time cash flow monitoring`,
                },
                {
                    label: t`They simplify cash flow management by consolidating data across platforms`,
                },
                {
                    label: t`They eliminate manual reconciliation tasks altogether`,
                },
            ],
            explanation: t`Disconnected tools delay reporting, increase the risk of errors, and limit visibility into cash flow, making it harder for clients to make timely financial decisions.`,
            question: t`What challenges do disconnected tools create for managing cash flow?`,
            scenario: t`A client relies on several disconnected tools for managing invoices, reimbursements, and expenses. They're constantly missing cash flow insights and struggling with errors.`,
        },
        {
            answers: [
                {
                    isCorrect: true,
                    label: t`Use automated receipt capture and streamlined reimbursements to save time and improve accuracy`,
                },
                {
                    label: t`Focus on manual receipt uploads and month-end reconciliations to maintain control`,
                },
                {
                    label: t`Delay reimbursements until all receipts are submitted`,
                },
                {
                    label: t`Use a dashboard to track expenses but avoid automating approvals`,
                },
            ],
            explanation: t`Automated receipt capture eliminates follow-ups, saving time and reducing errors. Streamlined reimbursements improve timelines, keeping employees satisfied while freeing up the finance team to focus on strategic work.`,
            question: t`What approach would you recommend to address their challenges?`,
            scenario: t`A client shares that their finance team spends hours every week chasing receipts and reconciling expenses, delaying financial reports. They're frustrated and want to reduce admin work while improving reimbursement timelines.`,
        },
        {
            answers: [
                {
                    isCorrect: true,
                    label: t`It allows teams to identify issues early, manage budgets effectively, and reduce risks.`,
                },
                {
                    label: t`It eliminates the need for expense tracking altogether.`,
                },
                {
                    label: t`It ensures reports are manually reviewed for more accuracy.`,
                },
                {
                    label: t`It focuses only on compliance, not proactive decision-making.`,
                },
            ],
            explanation: t` Real-time visibility helps teams spot irregularities, monitor budgets closely, and make faster decisions, enabling greater financial control and reducing risks.`,
            question: t`Why is real-time visibility critical for finance teams?`,
            scenario: t`Vistair's finance team struggled to manage budgets and spot spending issues in time, leading to delays in decision-making. After implementing real-time visibility into spending, they improved financial control and reduced risks.`,
        },
        {
            answers: [
                {
                    isCorrect: true,
                    label: t`Transactions are logged and categorised as they happen, reducing last-minute bottlenecks and improving reporting accuracy.`,
                },
                {
                    label: t`It simplifies reporting by removing the need for approvals.`,
                },
                {
                    label: t`It works best when reconciliations are delayed until month-end.`,
                },
                {
                    label: t`It eliminates detailed tracking, streamlining reporting tasks.`,
                },
            ],
            explanation: t`Real-time visibility resolves bottlenecks by keeping data updated throughout the month, reducing errors and allowing teams to meet deadlines without stress.`,
            question: t`How would you explain the role of real-time visibility in addressing these challenges?`,
            scenario: t`A finance team struggles with month-end chaos caused by missing receipts and fragmented workflows. They rely on disconnected tools, often missing reporting deadlines.`,
        },
    ]

    return (
        <>
            <Helmet>
                <title>{t`Quiz: Streamline clients' finances`}</title>
            </Helmet>
            <Page.Header css={{backgroundColor: tokens.colorBackgroundInteractive}}>
                <Page.Backlink>
                    <Link as={RouterLink} to="../../" IconLeft={ArrowLeft}>
                        <Trans>Back to overview</Trans>
                    </Link>
                </Page.Backlink>
                <Page.Title>
                    <Trans>Streamline clients' finances</Trans>
                </Page.Title>
                <Inline alignItems="center" space={6} mb={24}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>6 mins</Trans>
                    </Text>
                </Inline>
            </Page.Header>
            <Stack my={24} space={8}>
                <Text as="h2" variant="2xlarge-accent">
                    <Trans>Quiz</Trans>
                </Text>
                <Text variant="large-accent">
                    <Trans>
                        Complete questions to test your new knowledge. Try as many times as you
                        need.
                    </Trans>
                </Text>
            </Stack>
            <SplitContent>
                <SplitContent.Main flex={4}>
                    <QuizForm
                        quizKey="transform_client_finances"
                        setCanContinue={setCanContinue}
                        quizQuestions={questions}
                    />
                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                            <Trans>Back</Trans>
                        </Link>
                        <Button
                            variant="primary"
                            as={RouterLink}
                            to="../../unlock-growth"
                            disabled={!canContinue}
                            IconRight={ArrowRight}
                        >
                            <Trans>Continue</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <ModuleOverview />
                </SplitContent.Right>
            </SplitContent>
        </>
    )
}
