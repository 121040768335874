import {Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import styled from 'styled-components'

import {Box, Card, Link, Stack, tokens} from '@pleo-io/telescope'
import {Download, Link as LinkIcon} from '@pleo-io/telescope-icons'

import {getHelpCentreArticleLink} from '@product-web/shared--locale/helpers'

export const UsefulMaterials = ({children}: {children: ReactNode}) => (
    <UsefulMaterialsContainer>
        <Card.Title headingLevel="h5">
            <Trans>Useful materials</Trans>
        </Card.Title>
        <Stack mt={12} space={12}>
            {children}
        </Stack>
    </UsefulMaterialsContainer>
)

const Document = ({url, children}: {url: string; children: ReactNode}) => (
    <Link IconLeft={Download} href={url} target="_blank" rel="noopener noreferrer">
        {children}
    </Link>
)

const HelpCenterArticle = ({articleId, children}: {articleId: number; children: ReactNode}) => (
    <Link
        IconLeft={LinkIcon}
        href={getHelpCentreArticleLink(articleId)}
        target="_blank"
        rel="noopener noreferrer"
    >
        {children}
    </Link>
)

const UsefulMaterialsContainer = styled(Box).attrs({p: 24})`
    border-top: 1px solid ${tokens.colorBorderStatic};
`

UsefulMaterials.Document = Document
UsefulMaterials.HelpCenterArticle = HelpCenterArticle
