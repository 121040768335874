import {Trans} from '@lingui/macro'

import {Badge} from '@pleo-io/telescope'
import {Check} from '@pleo-io/telescope-icons'
import {Lock} from '@pleo-io/telescope-icons'

import type {PageOrigin} from './plan-badges.helper'
import {getRequiredPlanPerFeature} from './plan-badges.helper'

import {bff} from '../bff-hooks'
import {PlanTypeName} from '../pricing-plans.helper'

type IncludedInPlanBadgeProps = {
    /**
     * Add a border to the badge to make it stand out more from the background, especially on gray backgrounds.
     * @default false
     */
    loud?: boolean
}

export const IncludedInPlanBadge = ({loud}: IncludedInPlanBadgeProps) => {
    const {data, isLoading, error} =
        bff.planPresentation.planBadges.getIncludedInPlanBadgeData.useQuery()

    if (error) {
        return null
    }

    const planName = data?.currentPlan ? PlanTypeName[data.currentPlan] : null

    if (!isLoading && !planName) {
        return null
    }

    return (
        <Badge variant="positive" Icon={Check} skeleton={isLoading} loud={loud}>
            <Trans>Included in {planName}</Trans>
        </Badge>
    )
}

type RequiredPlanBadgeProps = {
    feature: PageOrigin
    /**
     * Add a border to the badge to make it stand out more from the background, especially on gray backgrounds.
     * @default false
     */
    loud?: boolean
}

export const RequiredPlanBadge = ({feature, loud}: RequiredPlanBadgeProps) => {
    const requiredPlan = getRequiredPlanPerFeature(feature)
    const lowestPlanWithFeature = PlanTypeName[requiredPlan]

    return (
        <Badge variant="discover" Icon={Lock} loud={loud}>
            <Trans>{lowestPlanWithFeature} plan</Trans>
        </Badge>
    )
}
