import {Route} from 'react-router-dom'

import {Quiz} from './quiz'
import {UnlockGrowth} from './unlock-growth'

export const routes = (
    <>
        <Route index element={<UnlockGrowth />} />
        <Route path="quiz" element={<Quiz />} />
    </>
)
