import {t, Trans} from '@lingui/macro'
import type React from 'react'
import {useState} from 'react'
import styled from 'styled-components'

import type {Person} from '@pleo-io/deimos'
import {
    Avatar,
    Drawer,
    IconButton,
    Inline,
    NakedButton,
    px,
    Stack,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {
    ChevronDown,
    CirclePlus,
    Close,
    CreditCard,
    EmotionSmiley,
    Logout,
    Swap,
} from '@pleo-io/telescope-icons'

import {useAccountSwitchModal} from '@product-web/feature--multi-entity/add-account/add-account'
import tracking from '@product-web/shared--analytics'
import type {Company} from '@product-web/shared--api-types/company'
import type {LoggedInAccount, SwitchAccountPayload} from '@product-web/shared--auth--accounts'
import {useAccessToken} from '@product-web/shared--auth--session/context'
import {getUniqueConnectedAccountEmails} from '@product-web/shared--navigation/helpers'

import NavigationAccountItemMobile from './navigation-account-item-mobile'

export const MOBILE_NAVIGATION_DRAWER_ID = 'mobile-navigation-drawer'

interface NavigationAvatarMobileProps {
    isOpen: boolean
    onDismiss: () => void
    isOwner: boolean
    activeAccount?: LoggedInAccount
    employee?: Partial<Person>
    company?: Company
    accounts?: LoggedInAccount[]
    hasConnectedEmails?: boolean
    switchAccount: (payload: SwitchAccountPayload) => void
    onLogout?: () => void
}

export const NavigationAvatarMobile: React.FC<NavigationAvatarMobileProps> = ({
    isOpen,
    onDismiss,
    employee,
    company,
    accounts,
    activeAccount,
    hasConnectedEmails,
    isOwner,
    switchAccount,
    onLogout,
}) => {
    const fullName = `${employee?.firstName} ${employee?.lastName}`
    const currentAccessToken = useAccessToken()
    const [accountSwitchOpen, setAccountSwitchOpen] = useState(false)
    const {open: openSwitchAccountModal} = useAccountSwitchModal()

    const onLogoutClick = () => {
        tracking.logOutClicked({
            numberOfActiveAccounts: accounts?.length ?? 0,
        })
        onLogout?.()
    }

    const toggleSwitchAccountMenu = () => {
        if (!accountSwitchOpen) {
            // check so we aren't tracking when the user closes the menu
            tracking.switchAccountClicked({
                numberOfActiveAccounts: accounts?.length ?? 0,
            })
        }
        setAccountSwitchOpen(!accountSwitchOpen)
    }

    const accountsList = accounts?.map((account) => {
        const isCurrentLoggedInAccount = account.accessToken === currentAccessToken
        return (
            <NavigationAccountItemMobile
                key={account.email}
                email={account.email}
                accessToken={account.accessToken}
                isCurrentLoggedInAccount={isCurrentLoggedInAccount}
                activeAccount={activeAccount}
                switchAccount={switchAccount}
                numberOfActiveAccounts={accounts?.length}
            />
        )
    })

    const uniqueConnectedAccountEmails = getUniqueConnectedAccountEmails(accounts)
    const loggedOutAccountList = uniqueConnectedAccountEmails.map((account) => {
        return (
            <>
                <NavigationAccountItemMobile
                    key={account.email}
                    email={account.email}
                    loggedOutAccount={account}
                    activeAccount={activeAccount}
                    switchAccount={switchAccount}
                />
            </>
        )
    })

    return (
        <Drawer isOpen={isOpen} onDismiss={onDismiss} drawerId={MOBILE_NAVIGATION_DRAWER_ID}>
            <DrawerWrapper>
                <CloseModalWrapper>
                    <IconButton
                        onClick={onDismiss}
                        Icon={Close}
                        variant="secondary"
                        tooltipProps={{dangerouslyOmitTooltip: true, 'aria-label': t`Close`}}
                    />
                </CloseModalWrapper>
                <ContentWrapper>
                    <Inline alignY={'center'} pb={16}>
                        <NavAvatar name={fullName} size={40} src={employee?.avatar?.url} />
                        <Stack align="left">
                            <NoWrapFontWeightMedium align="left">
                                {employee?.firstName} {employee?.lastName}
                            </NoWrapFontWeightMedium>
                            {hasConnectedEmails && (
                                <NoWrap variant="small-subtle">{company?.name}</NoWrap>
                            )}
                        </Stack>
                    </Inline>
                    <Stack align="left" space={24}>
                        <Item
                            href="/account/profile"
                            onClick={() => {
                                tracking.myAccountClicked({variant: 'mobile'})
                            }}
                        >
                            <Inline alignY="center" space={12}>
                                <EmotionSmiley
                                    size={20}
                                    color={tokens.colorContentInteractiveQuiet}
                                />
                                <Text>
                                    <Trans>My account</Trans>
                                </Text>
                            </Inline>
                        </Item>
                        {isOwner && (
                            <Item
                                href="/account/cards"
                                onClick={() => {
                                    tracking.myCardsClicked({variant: 'mobile'})
                                }}
                            >
                                <Inline alignY="center" space={12}>
                                    <CreditCard
                                        size={20}
                                        color={tokens.colorContentInteractiveQuiet}
                                    />
                                    <Text>
                                        <Trans>My cards</Trans>
                                    </Text>
                                </Inline>
                            </Item>
                        )}
                        {hasConnectedEmails ? (
                            <>
                                <NakedButtonWithToggle onClick={() => toggleSwitchAccountMenu()}>
                                    <InlineWithToggle alignX="space-between" alignY="center">
                                        <Inline alignY="center" space={12}>
                                            <Swap
                                                size={20}
                                                color={tokens.colorContentInteractiveQuiet}
                                            />
                                            <Text>
                                                <Trans>Switch Account</Trans>
                                            </Text>
                                        </Inline>

                                        <ChevronIcon
                                            $accountSwitchOpen={accountSwitchOpen}
                                            size={16}
                                            color={tokens.colorContentInteractive}
                                        />
                                    </InlineWithToggle>
                                </NakedButtonWithToggle>
                                {accountSwitchOpen && (
                                    <Stack space={6}>
                                        <NakedButton
                                            onClick={() => {
                                                tracking.addAccountClicked({
                                                    context: 'switch-account',
                                                    numberOfActiveAccounts: accounts?.length ?? 0,
                                                })
                                                openSwitchAccountModal()
                                            }}
                                        >
                                            <Inline alignY={'center'} space={12} pb={8} pl={12}>
                                                <CirclePlus
                                                    size={20}
                                                    color={tokens.colorContentInteractiveQuiet}
                                                />
                                                <Text>
                                                    <Trans>Add account</Trans>
                                                </Text>
                                            </Inline>
                                        </NakedButton>
                                        {accountsList}
                                        {loggedOutAccountList}
                                    </Stack>
                                )}
                                <NakedButton onClick={() => onLogoutClick()}>
                                    <Inline alignY="center" space={12}>
                                        <Logout
                                            size={20}
                                            color={tokens.colorContentInteractiveQuiet}
                                        />
                                        <Text>
                                            <Trans>Logout of all accounts</Trans>
                                        </Text>
                                    </Inline>
                                </NakedButton>
                            </>
                        ) : (
                            <>
                                <NakedButton
                                    onClick={() => {
                                        tracking.addAccountClicked({
                                            context: 'account-menu',
                                            numberOfActiveAccounts: accounts?.length ?? 0,
                                        })
                                        openSwitchAccountModal()
                                    }}
                                >
                                    <Inline alignY="center" space={12}>
                                        <CirclePlus
                                            size={20}
                                            color={tokens.colorContentInteractive}
                                        />
                                        <Text>
                                            <Trans>Add account</Trans>
                                        </Text>
                                    </Inline>
                                </NakedButton>
                                <NakedButton onClick={() => onLogoutClick()}>
                                    <Inline alignY="center" space={12}>
                                        <Logout size={20} color={tokens.colorContentInteractive} />
                                        <Text>
                                            <Trans>Logout</Trans>
                                        </Text>
                                    </Inline>
                                </NakedButton>
                            </>
                        )}
                    </Stack>
                </ContentWrapper>
            </DrawerWrapper>
        </Drawer>
    )
}
const DrawerWrapper = styled(Drawer.Panel)`
    max-width: ${px(275)};
`

const NakedButtonWithToggle = styled(NakedButton)`
    width: 100%;
`

const Item = styled.a`
    width: 100%;
`
const ContentWrapper = styled.div`
    background-color: ${tokens.colorBackgroundStatic};
    height: 100%;
    padding-left: ${tokens.spacing20};
    padding-right: ${tokens.spacing28};

    ${NakedButton}, ${Item}, ${NakedButtonWithToggle} {
        ${Text} {
            color: ${tokens.colorContentInteractive};
        }

        &:hover {
            ${Text} {
                color: ${tokens.colorContentInteractiveHover};
            }

            svg {
                fill: ${tokens.colorContentInteractiveHover};
            }
        }
    }
`

const NavAvatar = styled(Avatar)`
    margin: ${tokens.spacing12} ${tokens.spacing12} ${tokens.spacing16} 0;
`

export const NoWrapFontWeightMedium = styled(Text).attrs({
    variant: 'medium-default',
    as: 'p',
    truncate: true,
})`
    font-weight: ${tokens.fontWeightMedium};
    width: inherit;
`

const NoWrap = styled(Text).attrs({
    truncate: true,
})`
    width: inherit;
    text-align: left;
`

const CloseModalWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-left: ${tokens.spacing20};
    padding-right: ${tokens.spacing20};
    padding-top: ${tokens.spacing12};
    padding-bottom: ${tokens.spacing4};
`

const InlineWithToggle = styled(Inline)`
    width: 100%;
`

const ChevronIcon = styled(ChevronDown)<{$accountSwitchOpen: boolean}>`
    transform: ${({$accountSwitchOpen}) => ($accountSwitchOpen ? 'rotate(180deg)' : 'none')};
    transition: ${tokens.smoothInOut};
`
