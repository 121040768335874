import type {FC} from 'react'
import styled from 'styled-components'

import {List, ListItem, Stack, Text, tokens} from '@pleo-io/telescope'

import {EntityCard} from './entity-card/entity-card'
import type {Entity} from './non-bookkeeper-group/non-bookkeeper-group'

interface EntityCardsGroupProps {
    organizationName?: string
    entities: Entity[]
    showSeparatorAfterGroup?: boolean
    showOrganizationName?: boolean
    activeCompanyId: string | null
    onClick: (companyId: string) => void
    onVerifyClick: (companyId: string) => void
    switchDisabled: boolean
}

export const EntityCardsGroup: FC<EntityCardsGroupProps> = ({
    organizationName,
    entities,
    showSeparatorAfterGroup,
    showOrganizationName,
    activeCompanyId,
    onClick,
    onVerifyClick,
    switchDisabled,
}) => (
    <Stack stretch marginTop={16} marginBottom={4}>
        {showOrganizationName && (
            <OrganizationName weight="medium" variant="small-subtle" truncate>
                {organizationName}
            </OrganizationName>
        )}
        <StyledList>
            {entities.map((entity) => (
                <ListItem key={entity.companyId}>
                    <EntityCard
                        testId={`entity-card-item-${entity.companyId}`}
                        isActive={activeCompanyId === entity.companyId}
                        verificationStatus={entity.verificationStatus}
                        isHomeEntity={entity.isSpendingEntity}
                        companyName={entity.companyName}
                        role={entity.role}
                        missingReceiptsCount={entity.exportData?.missingReceiptsCount}
                        exportQueuedCount={entity.exportData?.exportQueuedCount}
                        walletBalance={entity.walletBalance}
                        onClick={() => onClick(entity.companyId)}
                        onVerifyClick={() => onVerifyClick(entity.companyId)}
                        disabled={switchDisabled}
                    />
                </ListItem>
            ))}
        </StyledList>
        {showSeparatorAfterGroup && <GroupSeparator />}
    </Stack>
)

const OrganizationName = styled(Text)`
    margin-bottom: ${tokens.spacing12};
`

const StyledList = styled(List)`
    display: grid;
    gap: ${tokens.spacing12};

    > li {
        margin-bottom: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
`

const GroupSeparator = styled.div<{$marginBottom?: string}>`
    margin: ${tokens.spacing24} ${tokens.spacing20} ${tokens.spacing8} ${tokens.spacing20};
    border: 0;
    border-top: ${tokens.borderStaticLoud};
`
