import {Card, tokens} from '@pleo-io/telescope'

import type {BillingInfoCurrency, CpqRatePlanType} from '@shared/bff--moons/generated/beyond'

import {BaseEntitlementsSection} from './base-entitlements-section'
import {EligibleEntitlementsSection} from './eligible-entitlements-section'

type PlanChangeSummaryProps = {
    targetPlan: {planType: CpqRatePlanType; isUpgrade: boolean}
    currentPlan: CpqRatePlanType
    companyCurrency: BillingInfoCurrency
    isBillingAnnual: boolean
    hasCashback?: boolean
    additionalEntitlements?: string[]
    isBeforeTrial?: boolean
}

export const PlanChangeSummary = ({
    targetPlan,
    currentPlan,
    companyCurrency,
    isBillingAnnual,
    hasCashback,
    additionalEntitlements,
    isBeforeTrial,
}: PlanChangeSummaryProps) => {
    return (
        <Card padding={32}>
            <BaseEntitlementsSection
                companyCurrency={companyCurrency}
                targetPlan={targetPlan}
                currentPlan={currentPlan}
                additionalEntitlements={additionalEntitlements}
                hasCurrentSubscriptionCashback={hasCashback}
                isBeforeTrial={isBeforeTrial}
            />
            {targetPlan.isUpgrade && (
                <EligibleEntitlementsSection
                    currency={companyCurrency}
                    targetPlanType={targetPlan.planType}
                    isBillingAnnual={isBillingAnnual}
                    hasCurrentSubscriptionCashback={!!hasCashback}
                >
                    <Card.Divider
                        css={{marginTop: tokens.spacing24, marginBottom: tokens.spacing24}}
                    />
                </EligibleEntitlementsSection>
            )}
        </Card>
    )
}
