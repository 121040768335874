import {Trans} from '@lingui/macro'
import {useState} from 'react'

import {Badge, Box, Button, Flex, Text} from '@pleo-io/telescope'

import type {PageOrigin} from '@product-web/shared--paywall/index.bff'
import {pxBreakpoints} from '@product-web/shared--styles/theme'
import {useContainerQuery} from '@product-web/shared--telescope-lab/container-queries/container'

import {LandingPagesConfig} from '../landing-pages.helper'

export interface LandingPageTopAddonsProps {
    pageOrigin: PageOrigin
    title: string
    subtitle?: string
}

export const LandingPageTopAddons = ({pageOrigin, title, subtitle}: LandingPageTopAddonsProps) => {
    const [showRouAddOnModal, setShowRouAddOnModal] = useState(false)

    const landingPageConfig = LandingPagesConfig[pageOrigin]
    const ctaCopy = landingPageConfig?.getCtaCopy()

    const isTabletMedUp = useContainerQuery({
        name: 'main',
        maxWidth: pxBreakpoints.tabletMedUp,
    })

    return (
        <>
            <Flex
                gap={24}
                justifyContent={'space-between'}
                alignItems={'start'}
                flexDirection={isTabletMedUp ? 'column' : 'row'}
            >
                <div>
                    <Box paddingBottom={24}>
                        <Badge variant="positive">
                            <Trans>Available add-on</Trans>
                        </Badge>
                    </Box>
                    <Text variant="4xlarge-accent" weight="medium">
                        {title}
                    </Text>
                    {subtitle ? (
                        <Box paddingTop={8}>
                            <Text variant={isTabletMedUp ? 'medium-default' : 'large-accent'}>
                                {subtitle}
                            </Text>
                        </Box>
                    ) : null}
                </div>
                {ctaCopy && (
                    <Button variant="primary" onClick={() => setShowRouAddOnModal(true)}>
                        {ctaCopy}
                    </Button>
                )}
            </Flex>
            {showRouAddOnModal && (
                // TODO - Show rou add-on modal
                <></>
            )}
        </>
    )
}
