import {Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import {useState} from 'react'

import {Badge, Box, Button, Inline, Text, tokens} from '@pleo-io/telescope'

import type {PageOrigin} from '@product-web/shared--paywall/index.bff'
import {PricingPlanModalWrapper} from '@product-web/shared--paywall/pricing-plan-modal/pricing-plan-modal-wrapper'
import {PlanTypeName, type PlanUpgradeSource} from '@product-web/shared--paywall/types/plans'
import {
    IncludedInPlanBadge,
    RequiredPlanBadge,
} from '@product-web/shared--plan-presentation/plan-badges/plan-badges'
import {getRequiredPlanPerFeature} from '@product-web/shared--plan-presentation/plan-badges/plan-badges.helper'
import {UserPickedTrialPlanWrapper} from '@product-web/shared--plan-presentation/user-picked-trial-plan-wrapper'
import {pxBreakpoints} from '@product-web/shared--styles/theme'
import {useContainerQuery} from '@product-web/shared--telescope-lab/container-queries/container'

import {LandingPagesConfig} from '../landing-pages.helper'

export interface LandingPageTopProps {
    pageOrigin: PageOrigin
    planUpgradeSource: PlanUpgradeSource
    entitledCTA?: ReactNode
    title: string
    subtitle?: string
    isOnTrial?: boolean
    topAction?: ReactNode
}

export const LandingPageTop = ({
    pageOrigin,
    planUpgradeSource,
    entitledCTA,
    title,
    subtitle,
    isOnTrial,
    topAction,
}: LandingPageTopProps) => {
    const [showPlanUpdateModal, setShowPlanUpdateModal] = useState(false)

    // We call this function also here to maintain the existing behaviour but will remove
    // at the end of the experiment
    const requiredPlan = getRequiredPlanPerFeature(pageOrigin)
    const requiredPlanCopy = PlanTypeName[requiredPlan]

    const landingPageConfig = LandingPagesConfig[pageOrigin]
    const ctaCopy = landingPageConfig?.getCtaCopy()

    const isDesktop = useContainerQuery({
        name: 'main',
        minWidth: pxBreakpoints.tabletMedUp,
    })

    return (
        <Inline
            flexDirection={isDesktop ? 'row' : 'column'}
            justifyContent="space-between"
            css={{gap: `${tokens.spacing24}`}}
        >
            <div>
                <Box paddingBottom={24}>
                    {entitledCTA ? (
                        <UserPickedTrialPlanWrapper
                            Control={
                                <Badge variant="positive">
                                    {isOnTrial ? (
                                        <Trans>Included in your trial</Trans>
                                    ) : (
                                        <Trans>Included in your plan</Trans>
                                    )}
                                </Badge>
                            }
                            Variation={<IncludedInPlanBadge />}
                        />
                    ) : (
                        <UserPickedTrialPlanWrapper
                            Control={
                                <Badge variant="discover">
                                    <Trans>{requiredPlanCopy} plan</Trans>
                                </Badge>
                            }
                            Variation={<RequiredPlanBadge feature={pageOrigin} />}
                        />
                    )}
                </Box>
                <Text variant="4xlarge-accent" weight="medium">
                    {title}
                </Text>
                {subtitle ? (
                    <Box paddingTop={8}>
                        <Text variant={isDesktop ? 'large-accent' : 'medium-default'}>
                            {subtitle}
                        </Text>
                    </Box>
                ) : null}
            </div>
            <Inline flexDirection="column" alignItems={isDesktop ? 'end' : 'start'}>
                <Inline space={12}>
                    {topAction ? topAction : null}
                    {entitledCTA
                        ? entitledCTA
                        : ctaCopy && (
                              <Button
                                  variant="primary"
                                  onClick={() => setShowPlanUpdateModal(true)}
                              >
                                  {ctaCopy}
                              </Button>
                          )}
                </Inline>
            </Inline>
            {showPlanUpdateModal && (
                <PricingPlanModalWrapper
                    isOpen
                    onDismiss={() => setShowPlanUpdateModal(false)}
                    planUpgradeSource={planUpgradeSource}
                    pageOrigin={pageOrigin}
                    pageSection="landing-page"
                />
            )}
        </Inline>
    )
}
