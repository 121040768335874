export enum EmployeeStatus {
    trialBannerHideDate = 'trialBannerHideDate',
    getStarted = 'getstarted',
    askedToSwitchLanguage = 'askedToSwitchLanguage',
    skipBillInvoiceExportWarning = 'skipBillInvoiceExportWarning',
    onboardingHasSeenAppDownloadPage = 'onboarding.hasSeenAppDownloadPage',
    storeboxHasDismissedModal = 'storebox.hasDismissedModal',
    pocketHasMutedReimbursementModal = 'pocket.hasMutedReimbursementModal',
    pocketHasMutedPurchaseDateModal = 'pocket.hasMutedPurchaseDateModal',
    subscriptionsOnboardingDismissed = 'subscriptions.onboarding.dismissed',
    expensePanelHasMutedDateConfirmationModal = 'expensePanelHasMutedDateConfirmationModal',
    hasSeenEndTrialModal = 'hasSeenEndTrialModal',
    hasSeenAddExpenseGuide = 'hasSeenAddExpenseGuide',
    hasSeenMultiEntityInfoModal = 'hasSeenMultiEntityInfoModal',
    hasSeenCompanyReviewGuide = 'hasSeenCompanyReviewGuide',
    hasSeenMileageWelcomeModal = 'hasSeenMileageWelcomeModal',
    hasSeenExportReimbursementExpenseListPopover = 'hasSeenExportReimbursementExpenseListPopover',
    hasSeenExportReimbursementDetailsPopover = 'hasSeenExportReimbursementDetailsPopover',
    hasSeenAIDROnboardingModal = 'hasSeenAIDROnboardingModal',
    hasSeenTemporaryLimitPopover = 'hasSeenTemporaryLimitPopover',
    hasSeenTemporaryCardIntro = 'hasSeenTemporaryCardIntro',
    hasMutedDirectReimbursementIntroModal = 'hasSeenDirectReimbursementIntroModal',
    hasSeenCrossCurrencyWarningModal = 'hasSeenCrossCurrencyWarningModal',
    hasSeenCardOnboardingView = 'hasSeenCardOnboardingView',
    hasSeenCardComparisonModal = 'hasSeenCardComparisonModal',
    hasSeenPersonalCardsPopover = 'hasSeenPersonalCardsPopover',
    hasDismissedScheduledReimbursementBanner = 'hasDismissedScheduledReimbursementBanner',
}
