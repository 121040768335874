/* eslint-disable string-to-lingui/missing-lingui-transformation */
import type {Operation} from '@trpc/client'

import {getTokenPayload} from '@product-web/shared--auth--jwt/payload'
import {getJwtExpiryMs} from '@product-web/shared--auth--jwt/utils'
import {getScopedToken} from '@product-web/shared--auth--scoped-tokens'
import {
    __getAccessToken,
    memoForceSessionRefresh,
} from '@product-web/shared--auth--session/operations'
import type {ClientContext} from '@product-web/shared--bff-context'
import {BffErrorMessages} from '@shared/bff--client'

export async function getHeaders(operation: Operation) {
    const token = await getAccessToken(operation)
    const ajsHeaders = getAjsHeaders()
    return token ? {Authorization: `Bearer ${token}`, ...ajsHeaders} : ajsHeaders
}

/**
 * Prepare the operational context for the final request.
 * Based on the operational context and environment, we may need to:
 * - get a new elevated scoped token
 */
async function getAccessToken(op: Operation) {
    const {auth} = op.context as ClientContext

    if (!auth) {
        // No specific auth context specified, we use the current access token.
        // First we make sure it's fresh, if not we refresh it first.
        await refreshTokenIfNeeded()
        return __getAccessToken()
    }

    if (auth.type === 'elevated') {
        // If this is an elevated auth request, we need to get a new scoped token.
        // Note that this will likely show a PIN modal.
        const {elevatedResourceIds, forcePIN, scope} = auth
        const accessToken =
            (await getScopedToken(scope, {
                force: forcePIN,
                elevatedResourceIds,
            })) || null

        if (accessToken === null) {
            throw new Error(BffErrorMessages.PIN_MODAL_CANCELLED)
        }

        return accessToken
    }

    if (auth.type === 'token') {
        // If this is a request with a specific token, we use that token.
        return auth.accessToken
    }

    throw new Error(`Unknown auth type, please use the defineClientContext helper`)
}

/**
 * Get the anonymousId from Analytics JS, if available.
 * This will be added to the headers so that we can identify and track anonymous users in the BFF.
 * More info on Segment Identify: https://segment.com/docs/connections/spec/best-practices-identify/
 */
function getAjsHeaders() {
    const anonymousId = window.analytics?.user?.().anonymousId()
    if (anonymousId) {
        return {['ajs-anonymous-id']: anonymousId}
    }
    return {}
}

/**
 * Check if the token is expired or about to expire. If so, force refresh the
 * session to acquire a new token.
 */
async function refreshTokenIfNeeded() {
    // 20s is the timeout on our BFF lambda, which means that with 25s here,
    // even in the worst case scenario the token won't expire while the
    // BFF call is in progress, and we should never see 401s coming back.
    const MIN_TOKEN_TTL_MS = 25000

    const accessToken = __getAccessToken()
    if (!accessToken) {
        return
    }
    const tokenPayload = getTokenPayload(accessToken)
    if (!tokenPayload || getJwtExpiryMs(tokenPayload) < MIN_TOKEN_TTL_MS) {
        // We only want to trigger the session refresh once, even if we're dealing with
        // multiple requests triggered at the same time
        await memoForceSessionRefresh()
    }
}
