import {t} from '@lingui/macro'
import styled from 'styled-components'

import {InvoiceStatus} from '@pleo-io/deimos'
import {Text, tokens} from '@pleo-io/telescope'

import {getImgKey} from '@product-web/feature--expense-details/helpers'
import {AvatarExpense} from '@product-web/feature--expenses/avatar-expense/avatar-expense'
import Push from '@product-web/feature--expenses/components/push'
import * as s from '@product-web/feature--expenses/expense.styles'
import {formatEmployee} from '@product-web/feature--expenses/helpers'
import {useAccountCategories} from '@product-web/shared--api-deimos/account-categories'
import {dayjs} from '@product-web/shared--dates/dayjs'
import {formatCurrency} from '@product-web/shared--locale/currency'
import {useLoggedInUser} from '@product-web/shared--user'

import type {BudgetExpenseData} from '../lib/types'

interface Props {
    budgetExpense: BudgetExpenseData
}

export const BudgetExpense = ({budgetExpense}: Props) => {
    const user = useLoggedInUser()
    const {data: accountCategories = []} = useAccountCategories({
        expenseCompanyId: user.companyId,
    })
    if (!budgetExpense) {
        return null
    }

    const accountCategoryTypeKey = getImgKey(budgetExpense.accountId ?? null, accountCategories)

    const formattedDate = budgetExpense.performedAt
        ? dayjs(budgetExpense.performedAt).format('DD MMM YYYY')
        : ''

    const getExpensePayDatesInfo = (budgetExpenseStatus: string) => {
        switch (budgetExpenseStatus) {
            case InvoiceStatus.PENDING_APPROVAL:
            case InvoiceStatus.READY: {
                return t`Due on ${formattedDate}`
            }
            case InvoiceStatus.SCHEDULED:
            case InvoiceStatus.PREPARING:
            case InvoiceStatus.PREPARED:
            case InvoiceStatus.PAYMENT_IN_PROGRESS: {
                return t`Scheduled for ${formattedDate}`
            }
            case InvoiceStatus.PAID:
            case InvoiceStatus.MARKED_AS_PAID: {
                return t`Paid on ${formattedDate}`
            }
            default:
                return ''
        }
    }

    return (
        <StyledBudgetExpense>
            <s.ExpenseCardInline $isNarrow={true}>
                <AvatarExpense
                    merchantName={budgetExpense?.supplierName}
                    isPersonalExpense={false}
                    label=""
                    accountCategoryTypeKey={
                        accountCategoryTypeKey === 'empty' ? null : accountCategoryTypeKey
                    }
                />
                <s.ExpenseCardStack>
                    <Text variant="large-accent">{budgetExpense.supplierName}</Text>
                    <Text variant="small-subtle">
                        {formatEmployee({
                            firstName: budgetExpense.expenseOwner?.name ?? '',
                            lastName: '',
                        })}
                    </Text>
                    {/** Uncomment this when moon EP provides the tagGroup Id */}
                    {/* <Details expense={budgetExpense} /> */}
                </s.ExpenseCardStack>
                <s.ExpenseCardAmountText
                    variant="large-accent"
                    // Uncomment with this https://linear.app/pleo/issue/PEG-5304/enrich-and-enhance-budget-expense-list-with-more-data
                    //$isRefund={isRefund(budgetExpense)}
                    data-testid={'budget-expense-formatted-amount'}
                >
                    {formatCurrency(
                        Math.abs(budgetExpense.amount?.value ?? 0),
                        budgetExpense.amount?.currency,
                        {
                            // Uncomment with this https://linear.app/pleo/issue/PEG-5304/enrich-and-enhance-budget-expense-list-with-more-data
                            //showPlus: {isRefund(budgetExpense)},
                        },
                    )}
                    {budgetExpense.expenseStatus !== undefined && (
                        <Text variant="small-subtle">
                            {getExpensePayDatesInfo(budgetExpense.expenseStatus)}
                        </Text>
                    )}
                </s.ExpenseCardAmountText>
            </s.ExpenseCardInline>
            <Push id={budgetExpense.id} />
        </StyledBudgetExpense>
    )
}

export const StyledBudgetExpense = styled.div`
    width: 100%;
    padding: ${tokens.spacing8};
    margin-bottom: ${tokens.spacing12};

    &:hover {
        cursor: auto;
    }
`

/** Uncomment this when data needed is provided
 * https://linear.app/pleo/issue/PEG-5304/enrich-and-enhance-budget-expense-list-with-more-data
 */
// const Details = ({budgetExpense}: {budgetExpense: BudgetExpenseData}) => {
//     return (
//         <s.ExpenseCardDetails>
//             <BudgetExpenseTags budgetExpense={budgetExpense} />
//         </s.ExpenseCardDetails>
//     )
// }

// const BudgetExpenseTags = ({budgetExpense}: {budgetExpense: BudgetExpenseData}) => {
//     return (
//         <Text italic align="left" variant="small-subtle">
//             {budgetExpense.tagGroup.map((tagGroup, index) => (
//                 <React.Fragment key={tagGroup.groupId}>
//                     {tagGroup && index ? ', ' : ''}
//                     <ExpenseTagGroup tagGroup={tagGroup} />
//                 </React.Fragment>
//             ))}
//         </Text>
//     )
// }

export default BudgetExpense
