import type {SelectTypes} from '@pleo-io/telescope'

import {dayjs} from '@product-web/shared--dates/dayjs'
import {convertToMajorCurrency, convertToMinorCurrency} from '@product-web/shared--locale/currency'
import type {CompanyUserData} from '@product-web/shared--user-types'
import type {MoneyModelCurrency} from '@shared/bff--moons/generated/budgets'

import {CLOSED_BUDGET_DATE_FORMAT, DATEPICKER_DATE_FORMAT} from './constants'

import type {
    TagBudgetCreateSchemaType,
    TagBudgetEditResponse,
    TagBudgetEditSchemaType,
} from '../bff'

export type Option = SelectTypes.Option

export type EntityWithTags = {
    entity: Option
    tags: Option[]
}

type TagRule = {
    tagGroupRowId: string
    tagGroupId: string
    companyId: string
}

type Tag = {
    label: string
    value: string
}

export interface TagBudgetFormData {
    name: string
    tags: Tag[]
    entitiesWithTags: EntityWithTags[]
    amount: number
    currency: MoneyModelCurrency
    startDate: string
    endDate: string
    reviewers: SelectTypes.Option[]
}

export type ExtendedTag = {
    label: string
    value: string
    isArchived: boolean | undefined
    tagRule: {
        tagGroupRowId: string
        tagGroupId: string
        companyId: string
    }
}

export type EntityGroupedTags = {
    companyName: string
    companyId: string
    tags: ExtendedTag[]
}

export interface TagBudgetEditFormData {
    name: string
    amount: number
    currency: MoneyModelCurrency
    startDate: string
    endDate: string
    reviewers: SelectTypes.Option[]
    tagsGroupedByEntity: EntityGroupedTags[]
}

export const formatClosedBudgetDate = (inputDate: string) => {
    return dayjs(inputDate).format(CLOSED_BUDGET_DATE_FORMAT)
}

const getStartDate = (date: string | undefined) => {
    return dayjs(date ?? new Date())
        .utc()
        .format(DATEPICKER_DATE_FORMAT)
}

const getEndDate = (date: string | undefined) => {
    return date ? dayjs(date).utc().format(DATEPICKER_DATE_FORMAT) : null
}

export function getInitialTagBudgetFormData({
    tagBudget,
    currency,
}: {
    tagBudget?: TagBudgetEditResponse | undefined
    currency: MoneyModelCurrency
}) {
    const name = tagBudget?.name ?? ''
    const startDate = getStartDate(tagBudget?.startDate)
    const endDate = getEndDate(tagBudget?.endDate)
    const reviewers = [...(tagBudget?.reviewers ?? [])]

    return {
        name,
        amount: 0,
        currency,
        tags: [],
        entitiesWithTags: [],
        startDate: startDate ?? '',
        endDate: endDate ?? '',
        reviewers,
    } satisfies TagBudgetFormData
}

export function getInitialTagBudgetEditFormData({
    tagBudget,
    currency,
}: {
    tagBudget?: TagBudgetEditResponse | undefined
    currency: MoneyModelCurrency
}) {
    const name = tagBudget?.name ?? ''
    const startDate = getStartDate(tagBudget?.startDate)
    const endDate = getEndDate(tagBudget?.endDate)
    const reviewers = tagBudget?.reviewers ?? []
    const tagsGroupedByEntity = tagBudget?.tagsGroupedByEntity ?? []

    const getTagBudgetAmount = () => {
        if (!tagBudget?.amount) {
            return null
        }

        return convertToMajorCurrency(tagBudget.amount, tagBudget.currency, true)
    }

    return {
        name,
        amount: getTagBudgetAmount() ?? 0,
        currency,
        startDate: startDate ?? '',
        endDate: endDate ?? '',
        reviewers,
        tagsGroupedByEntity,
    } satisfies TagBudgetEditFormData
}

export const getStartDateUTC = (date: string) => {
    return dayjs.utc(date, DATEPICKER_DATE_FORMAT).toISOString()
}

export const getEndDateUTC = (date: string | null) => {
    if (!date) {
        return ''
    }

    // The automatic end time is 23:59:59 on the date that was chosen as an end date.
    return dayjs.utc(date, DATEPICKER_DATE_FORMAT).endOf('day').toISOString()
}

export const getEarlierEndDateUTC = () => {
    return dayjs.utc().toISOString()
}

export const mapTagsFromOtherEntities = (entities: EntityWithTags[]) => {
    return entities.map((entity) => entity.tags).flat()
}

export function getTagTrackingData(state: TagBudgetFormData) {
    const tagsFromOtherEntities = mapTagsFromOtherEntities(state.entitiesWithTags)
    const combinedTags = [...state.tags, ...tagsFromOtherEntities]

    const tagIds = combinedTags.map(mapOptionToTagRule).map((option) => option.tagGroupRowId!)
    const tagNames = combinedTags.map((tag) => tag.label)

    return {
        tag_ids: tagIds,
        tag_names: tagNames,
    }
}

export function mapOptionToTagRule(option: SelectTypes.Option): TagRule {
    const {tagGroupId, tagGroupRowId, companyId} = JSON.parse(`${option.value}`)
    return {
        tagGroupId: `${tagGroupId!}`,
        tagGroupRowId: `${tagGroupRowId!}`,
        companyId: `${companyId!}`,
    }
}

export function mapTagRuleBudgetRule(currentTagRule: TagRule) {
    return {
        tagRule: {
            ...currentTagRule,
        },
    }
}

export function prepareTagBudgetFormPayload({
    formValue,
    user,
}: {
    formValue: TagBudgetFormData
    user: CompanyUserData
}) {
    const tagsFromOtherEntities = mapTagsFromOtherEntities(formValue.entitiesWithTags)
    const combinedTags = [...formValue.tags, ...tagsFromOtherEntities]
    const budgetRules = combinedTags.map(mapOptionToTagRule).map(mapTagRuleBudgetRule)

    const amountInMinorCurrency = convertToMinorCurrency({
        value: formValue.amount,
        currency: formValue.currency as MoneyModelCurrency,
    })

    const shouldUseOrganizationId =
        tagsFromOtherEntities.length > 0 && Boolean(user.organization?.id)

    const companyOrOrganizationIdPayload = {
        ...(shouldUseOrganizationId
            ? {organizationId: user.organization?.id}
            : {companyId: user.companyId}),
    }

    return {
        name: formValue.name,
        startDate: getStartDateUTC(formValue.startDate),
        endDate: getEndDateUTC(formValue.endDate),
        reviewers: formValue.reviewers.map((option) => option.value),
        amount: {
            value: amountInMinorCurrency,
            currency: formValue.currency,
        },
        budgetRules,
        ...companyOrOrganizationIdPayload,
    } satisfies TagBudgetCreateSchemaType
}

export const prepareEditTagBudgetFormPayload = ({
    formValue,
    currency,
    user,
    isMultiEntity,
}: {
    formValue: TagBudgetEditFormData
    currency: MoneyModelCurrency
    user: CompanyUserData
    isMultiEntity: boolean
}) => {
    const {name, tagsGroupedByEntity, amount, reviewers, startDate, endDate} = formValue

    // Check if the company ID for any of the tags is different from the current entity's company ID
    const hasTagsFromOtherEntities = tagsGroupedByEntity.some((entity) => {
        return entity.companyId !== user.companyId
    })

    // Send either organizationId or companyId based on hasTagsFromOtherEntities
    const shouldUseOrganizationId = hasTagsFromOtherEntities && isMultiEntity
    const companyOrOrganizationIdPayload = {
        ...(shouldUseOrganizationId
            ? {organizationId: user.organization?.id}
            : {companyId: user.companyId}),
    }

    const amountInMinorCurrency = convertToMinorCurrency({
        value: amount,
        currency,
    })

    // Map tags to budget rules
    const budgetRules = tagsGroupedByEntity
        .flatMap((entity) => entity.tags)
        .map(({tagRule}) => ({tagRule}))

    return {
        name,
        amount: {
            value: amountInMinorCurrency,
            currency,
        },
        startDate: getStartDateUTC(startDate),
        endDate: getEndDateUTC(endDate),
        reviewers: reviewers?.map((option) => option.value),
        budgetRules,
        ...companyOrOrganizationIdPayload,
    } satisfies TagBudgetEditSchemaType['payload']
}
