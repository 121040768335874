import {Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import styled from 'styled-components'

import {Box, Card, Inline, NakedButton, tokens} from '@pleo-io/telescope'

interface LessonLinkProps {
    ariaLabel: string
    path: string
    children: ReactNode
}

interface LessonNavProps {
    children: ReactNode
}

export const LessonNav = ({children}: LessonNavProps) => (
    <Box pt={24} px={24}>
        <Card.Title headingLevel="h5">
            <Trans>Overview</Trans>
        </Card.Title>
        {children}
    </Box>
)

const LessonLink = ({ariaLabel, path, children}: LessonLinkProps) => {
    const {pathname} = useLocation()
    const isActive = pathname.includes(`${path}`)
    return (
        <LessonButton aria-label={ariaLabel} as={RouterLink} to={path} $isActive={isActive}>
            <Inline my={24} space={12} css={{fontSize: tokens.fontMedium}}>
                <NumberedItem $isActive={isActive} />
                {children}
            </Inline>
        </LessonButton>
    )
}

const LessonButton = styled(NakedButton).attrs({as: RouterLink})<{$isActive: boolean}>`
    display: block;
    counter-increment: lesson-counter;
    ${({$isActive}) => `
        color: ${$isActive ? tokens.colorContentStatic : tokens.colorContentStaticQuiet};
        font-weight: ${$isActive ? tokens.fontWeightSemibold : tokens.fontWeightRegular};
    `}
    transition:
        color ${tokens.smoothInOut},
        font-weight ${tokens.smoothInOut};

    &:hover {
        border-color: ${tokens.colorBorderInteractiveSelected};
        color: ${tokens.colorContentStatic};
        font-weight: ${tokens.fontWeightSemibold};
        cursor: pointer;
    }

    &:hover :is(span) {
        border: ${tokens.borderInteractiveHover};
    }
`
const NumberedItem = styled.span<{$isActive: boolean}>`
    display: inline-grid;
    justify-items: center;
    align-items: center;
    min-width: ${tokens.spacing24};
    min-height: ${tokens.spacing24};
    border: ${tokens.sizeBorderDefault} solid
        ${({$isActive}) =>
            $isActive ? tokens.colorBorderInteractiveSelected : tokens.colorBorderInteractive};
    border-radius: ${tokens.circle};

    &::before {
        content: counter(lesson-counter);
    }
`

LessonNav.Link = LessonLink
