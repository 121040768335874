import {t} from '@lingui/macro'
import {useMemo, useState} from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import diamondSvg from '@product-web/shared--images/icons/diamond.svg'
import {PricingPlanModalWrapper} from '@product-web/shared--paywall/pricing-plan-modal/pricing-plan-modal-wrapper'
import {PlanUpgradeSource} from '@product-web/shared--paywall/types/plans'

import {getMessage} from './upgrade-promo-card.helpers'

import PromoCard from '../components/promo-card'
import {trackPromoCardActioned} from '../lib/tracking'

const Icon = styled.img`
    margin-bottom: ${tokens.spacing4};
    width: 24px;
`

const UpgradePromoCard = () => {
    const [isPricingPlanModalOpen, setIsPricingPlanModalOpen] = useState(false)

    const upgradePromoCardMessage = useMemo(() => getMessage(), [])

    const handleOpenPricingPlanModal = () => {
        trackPromoCardActioned({
            action: 'viewed',
            message: upgradePromoCardMessage.copy,
            variation: upgradePromoCardMessage.variation,
        })
        setIsPricingPlanModalOpen(true)
    }

    const handleDismissPricingPlanModal = (didCompleteAction?: boolean) => {
        trackPromoCardActioned({
            action: didCompleteAction ? 'completed' : 'abandoned',
            message: upgradePromoCardMessage.copy,
            variation: upgradePromoCardMessage.variation,
        })

        setIsPricingPlanModalOpen(false)
    }

    return (
        <>
            <PromoCard
                message={upgradePromoCardMessage.copy}
                ctaText={t`Upgrade`}
                icon={<Icon src={diamondSvg} alt={t`Diamond icon`} />}
                onCardClicked={handleOpenPricingPlanModal}
            />
            <PricingPlanModalWrapper
                isOpen={isPricingPlanModalOpen}
                onDismiss={handleDismissPricingPlanModal}
                planUpgradeSource={PlanUpgradeSource.PROMO_CARD_FLOW}
                pageOrigin="promo"
                pageSection="promo-card"
            />
        </>
    )
}

export default UpgradePromoCard
