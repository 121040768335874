import {t} from '@lingui/macro'
import {AnimatePresence, motion} from 'framer-motion'
import {type FC, useRef} from 'react'
import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import styled, {css} from 'styled-components'

import {IconButton, Inline, px, Stack, tokens} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import tracking from '@product-web/shared--analytics'
import {useLoggedInAccounts} from '@product-web/shared--auth--accounts'
import {useTokenData} from '@product-web/shared--auth--session/context'
import {useMobileNavigationVisibility} from '@product-web/shared--navigation/navigation-context'
import {customColorSchemeTokens} from '@product-web/shared--styles/custom-tokens'
import {breakpoints} from '@product-web/shared--styles/theme'
import {Keys} from '@product-web/shared--web-platform/keyboard'
import {useKeyPress} from '@product-web/shared--web-platform/use-keypress'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {BookkeeperGroup} from './bookkeeper-group/bookkeeper-group'
import {NonBookkeeperGroup} from './non-bookkeeper-group/non-bookkeeper-group'

import {bff} from '../../bff-hooks'
import {getSwitchAccountRedirectLocation} from '../../get-switch-account-redirect-location'
import {useInternalNavigationContext} from '../../navigation-internal-provider'

const SWITCH_ENTITY_DELAY_IN_MS = 500
const ENABLE_SWITCH_ENTITY_DELAY_IN_MS = 3000

interface EntitySwitcherPanelProps {
    isPanelVisible: boolean
    setPanelVisible: (visible: boolean) => void
}

export const EntitySwitcherPanel: FC<EntitySwitcherPanelProps> = ({
    isPanelVisible,
    setPanelVisible,
}) => {
    const panelRef = useRef<HTMLDivElement>(null)
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)

    const {
        navItemsWidth: shift,
        entitySwitcherWidth,
        selectedEntityId,
        setSelectedEntityId,
        canChangeEntity,
        setCanChangeEntity,
    } = useInternalNavigationContext()

    const {switchAccount, accounts} = useLoggedInAccounts()
    const location = useLocation()
    const navigate = useNavigate()
    const {setIsMobileNavigationVisible} = useMobileNavigationVisibility()
    const tokenPayload = useTokenData()
    const email = tokenPayload?.user.email

    useKeyPress(Keys.ESCAPE, () => {
        setPanelVisible(false)
    })

    useEffect(() => {
        if (isPanelVisible) {
            panelRef.current?.focus()
        }
    }, [isPanelVisible])

    const {data: groupedEntities = []} = bff.navigation.getEntitySwitcherPanelData.useQuery(
        undefined,
        {
            keepPreviousData: true,
            meta: {dangerouslyPreventDefaultQueryInvalidationOnMutation: true}, // Mitigating traffic for inc-938
        },
    )

    const entities = groupedEntities.flatMap((entityGroup) => entityGroup.entities)
    const selectedEntity = entities.find((entity) => entity.companyId === selectedEntityId)

    const containsOrganizationAdmin = entities.some(
        (entity) => entity.role === 'owner' && entity.organizationId,
    )
    const containsNonOrgAdmin = entities.some(
        (entity) => entity.role === 'owner' && !entity.organizationId,
    )

    const handleBackButtonClick = () => {
        setPanelVisible(false)
    }

    useEffect(() => {
        // We're using API response to determine when we can enable company switching
        // So if there will be a big latency during API call we still
        // want allow user to switch the entity
        let timeout: ReturnType<typeof setTimeout>
        if (!canChangeEntity) {
            timeout = setTimeout(() => {
                setCanChangeEntity(true)
            }, ENABLE_SWITCH_ENTITY_DELAY_IN_MS)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [canChangeEntity])

    const isEntitySwitchInProgress = !email || !selectedEntity || !canChangeEntity

    const handleEntityCardClick = async (companyId: string, redirectLocation?: string) => {
        const isSwitchingDisabled =
            isEntitySwitchInProgress || companyId === selectedEntity.companyId

        const targetEntity = entities.find((entity) => entity.companyId === companyId)
        const isTargetSpendingEntity = !!targetEntity?.isSpendingEntity
        const numberOfExpensesInQueue = targetEntity?.exportData?.exportQueuedCount
        const numberOfMissingReceipts = targetEntity?.exportData?.missingReceiptsCount

        tracking.switchEntity({
            currentViewingEntityId: selectedEntityId ?? undefined,
            entityId: companyId,
            isSpendingEntity: isTargetSpendingEntity,
            isSwitchingEnabled: !isSwitchingDisabled,
            numberOfActiveAccounts: accounts.length ?? 0,
            numberOfEntities: entities.length,
            numberOfExpensesInQueue,
            numberOfMissingReceipts,
            source: 'entity-switcher',
            type: targetEntity!.role,
        })

        if (isSwitchingDisabled) {
            // we do not let user switching to the same account
            // also we do not let to switch account if switching is already in progress
            return
        }

        setCanChangeEntity(false)
        setSelectedEntityId(companyId)

        const isOrganizationAdmin =
            selectedEntity?.role === 'owner' && !!selectedEntity?.organizationId

        const redirectTo =
            redirectLocation ??
            getSwitchAccountRedirectLocation(location, {
                isSpendingEntity: selectedEntity.isSpendingEntity,
                isOrganizationAdmin,
            })

        if (isTablet) {
            // hiding both entity switch and navigation panels on mobile devices
            // before switching entity
            setPanelVisible(false)
            setIsMobileNavigationVisible(false)
            setTimeout(async () => {
                // For mobile devices we want user to see the main app
                // content before starting the switch account process
                await switchAccount({email, companyId, location: redirectTo})
            }, SWITCH_ENTITY_DELAY_IN_MS)
        } else {
            await switchAccount({email, companyId, location: redirectTo})
        }
    }

    const handleVerifyClick = async (companyId: string) => {
        if (companyId !== selectedEntityId && email) {
            await handleEntityCardClick(companyId, '/company-verification')
        } else if (companyId === selectedEntityId) {
            if (isTablet) {
                // hiding both entity switch and navigation panels on mobile devices
                // before switching entity
                setPanelVisible(false)
                setIsMobileNavigationVisible(false)
            }

            navigate('/company-verification')
        }
    }

    return (
        <AnimatePresence>
            {isPanelVisible ? (
                <Panel
                    ref={panelRef}
                    role="region"
                    aria-label={t`Entity switcher panel`}
                    tabIndex={-1}
                    initial={{x: -shift}}
                    animate={{x: 0}}
                    exit={{x: -shift}}
                    transition={{ease: 'easeInOut', duration: 0.23}}
                    $isVisible={isPanelVisible}
                    $width={entitySwitcherWidth}
                    $shift={shift}
                >
                    {isTablet && (
                        <BackButtonWrapper alignY="center">
                            <BackButton
                                size={'small'}
                                onClick={handleBackButtonClick}
                                Icon={ArrowLeft}
                                variant="secondary"
                                aria-label={t`Back`}
                                tooltipProps={{
                                    content: t`Back`,
                                    side: 'right',
                                }}
                            />
                        </BackButtonWrapper>
                    )}
                    <ScrollableStack>
                        <NonBookkeeperGroup
                            groupedEntities={groupedEntities}
                            selectedEntityId={selectedEntityId}
                            onEntityCardClick={handleEntityCardClick}
                            onVerifyClick={handleVerifyClick}
                            containsOrganizationAdmin={containsOrganizationAdmin}
                            containsNonOrgAdmin={containsNonOrgAdmin}
                            switchDisabled={isEntitySwitchInProgress}
                        />
                        <BookkeeperGroup
                            groupedEntities={groupedEntities}
                            selectedEntityId={selectedEntityId}
                            onEntityCardClick={handleEntityCardClick}
                            onVerifyClick={handleVerifyClick}
                            switchDisabled={isEntitySwitchInProgress}
                        />
                    </ScrollableStack>
                </Panel>
            ) : null}
        </AnimatePresence>
    )
}

const Panel = styled(motion.div)<{$isVisible: boolean; $width: number; $shift: number}>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: ${({$shift}) => px($shift)};
    display: flex;
    flex-direction: column;
    width: ${({$width}) => px($width)};
    height: 100vh;
    box-sizing: border-box;
    font-size: ${tokens.fontSmall};
    background-color: ${customColorSchemeTokens.colorBackgroundEntitySwitcherPanel};
    z-index: ${tokens.zIndexSurface};

    @media (max-width: ${breakpoints.tabletMedUp}) {
        z-index: ${tokens.zIndexWindow};
        left: 100vw;
        border: none;
        width: 100vw;

        ${({$isVisible}) =>
            $isVisible &&
            css`
                left: 0;
            `}
    }
`

const ScrollableStack = styled(Stack)`
    height: 100%;
    overflow: auto;
    grid-gap: 0;
    justify-items: stretch;
    padding-left: ${tokens.spacing20};
    padding-right: ${tokens.spacing20};
    margin-bottom: ${tokens.spacing48};

    @media (max-width: ${breakpoints.tabletMedUp}) {
        padding-left: ${tokens.spacing56};
        padding-right: ${tokens.spacing56};
        margin-bottom: ${tokens.spacing32};
    }

    @media (max-width: ${breakpoints.mobileLrgUp}) {
        padding-left: ${tokens.spacing24};
        padding-right: ${tokens.spacing24};
    }
`

const BackButton = styled(IconButton)`
    height: ${tokens.spacing32};
    width: ${tokens.spacing32};
    margin-left: ${tokens.spacing12};
`

const BackButtonWrapper = styled(Inline)`
    height: ${px(64)};
    flex-shrink: 0;
`
