import type {FC} from 'react'
import {useEffect, useState} from 'react'

import {useUserPickedTrialPlanFlag} from '@product-web/shared--plan-presentation/use-user-picked-trial-plan-flag'
import {EmployeeStatus} from '@product-web/shared--shell/employee-status'
import {getEmployeeStatus, useUser, useUserMutations} from '@product-web/shared--user'

import {bff} from './bff-hooks'
import {useCompanySubscription} from './lib/company-subscription'
import {PricingPlanModalWrapper} from './pricing-plan-modal/pricing-plan-modal-wrapper'
import {PlanType, PlanUpgradeSource} from './types/plans'

const DAYS_BEFORE_END_OF_TRIAL = 5

/*
    Prevents calling subscription API unnecessarily if conditions are not met
*/
function useEndTrialModalRequirements() {
    const user = useUser()
    const {hasUserPickedTrialPlanFlag, isLoadingUserPickedTrialPlanFlag} =
        useUserPickedTrialPlanFlag()
    const hasSeenEndTrial = Boolean(getEmployeeStatus(user)[EmployeeStatus.hasSeenEndTrialModal])
    const userExists = Boolean(user)

    const {data: preSelectedPlatformPlan, isLoading} =
        bff.paywall.endTrial.getPreSelectedPricingPlanName.useQuery(undefined, {
            enabled: !hasSeenEndTrial && userExists && Boolean(user?.companyId),
        })

    const isFreemiumUser =
        !preSelectedPlatformPlan || preSelectedPlatformPlan.toUpperCase() === PlanType.STARTER

    const shouldShowEndTrialModal =
        !isLoading &&
        isFreemiumUser &&
        userExists &&
        !hasSeenEndTrial &&
        !isLoadingUserPickedTrialPlanFlag &&
        // We show a different modal if the user has picked a trial plan
        !hasUserPickedTrialPlanFlag

    return {
        shouldShowEndTrialModal,
        isLoading,
    }
}

function useEndTrial() {
    const user = useUser()

    const {data: subscription} = useCompanySubscription()

    const [isEndTrialModalOpen, setIsEndTrialModalOpen] = useState(false)

    const {setEmployeeStateKey} = useUserMutations()

    const dismissEndTrialModal = () => {
        setEmployeeStateKey(EmployeeStatus.hasSeenEndTrialModal, true)
        setIsEndTrialModalOpen(false)
    }

    const hasSubscription = subscription !== undefined
    const userExists = Boolean(user)

    useEffect(() => {
        const getIsEligibleForDowngrade = () => (user?.company?.numberOfUsers || 0) <= 3

        const getIsTrialClose = () => {
            if (subscription?.isInTrialPeriod && subscription.trialPeriod?.exclusiveEndDate) {
                // In this case future date is DAYS_BEFORE_END_OF_TRIAL days in the future
                const futureDate =
                    Number(new Date()) + 1000 * 60 * 60 * 24 * DAYS_BEFORE_END_OF_TRIAL
                // Is the trial end date closer in the future than DAYS_BEFORE_END_OF_TRIAL days?
                const enoughTimePassedOnTrial =
                    Number(new Date(subscription.trialPeriod?.exclusiveEndDate)) < futureDate
                return enoughTimePassedOnTrial
            }
            // no sub, not on trial, or null trial object
            return false
        }

        if (getIsTrialClose() && userExists && hasSubscription && getIsEligibleForDowngrade()) {
            setIsEndTrialModalOpen(true)
        }
    }, [
        hasSubscription,
        userExists,
        subscription?.isInTrialPeriod,
        subscription?.trialPeriod?.exclusiveEndDate,
        user?.company?.numberOfUsers,
    ])

    return {
        isEndTrialModalOpen,
        dismissEndTrialModal,
    }
}

const ChoosePlan = () => {
    const {isEndTrialModalOpen, dismissEndTrialModal} = useEndTrial()

    return (
        <PricingPlanModalWrapper
            isOpen={isEndTrialModalOpen}
            onDismiss={dismissEndTrialModal}
            pageOrigin="end-trial"
            pageSection="end-trial-modal"
            planUpgradeSource={PlanUpgradeSource.END_TRIAL_MODAL}
        />
    )
}

const EndTrialModalRequirements: FC = () => {
    // Prevents subscription from being called unnecessarily
    const {shouldShowEndTrialModal} = useEndTrialModalRequirements()

    if (!shouldShowEndTrialModal) {
        return null
    }

    return <ChoosePlan />
}

export {EndTrialModalRequirements as EndTrialModal, useEndTrialModalRequirements}
