import {
    createReactRouterV6DataOptions,
    getWebInstrumentations,
    initializeFaro,
    ReactIntegration,
} from '@grafana/faro-react'
import {TracingInstrumentation} from '@grafana/faro-web-tracing'
import {matchRoutes} from 'react-router-dom'

type Environment = 'local' | 'test' | 'feature' | 'product-dev' | 'staging' | 'production'

const API_URL_REGEX_STRING = 'https://api(?!.insights)[^/?#]*.pleo.io/'
const AUTH_URL_REGEX_STRING = 'https://auth[^/?#]*.pleo.io/'
const LAMBDA_URL_REGEX_STRING = 'https://[^/?#]*.lambda-url.[^/?#]*.on.aws/'
const TRACKED_URLS_REGEX_STRING = `(${API_URL_REGEX_STRING}|${AUTH_URL_REGEX_STRING}|${LAMBDA_URL_REGEX_STRING})`

const deploymentEnvironmentMap: Record<Environment, string> = {
    local: 'local',
    test: 'test',
    feature: 'product-dev',
    'product-dev': 'product-dev',
    staging: 'product-staging',
    production: 'product-production',
}

export function initGrafanaFaro({
    name,
    url,
    release,
    environment,
}: {
    name: string
    url: string
    release?: string
    environment: Environment
}) {
    initializeFaro({
        url,

        app: {
            name,
            namespace: 'browser',
            release,
            environment: deploymentEnvironmentMap[environment],
        },

        ignoreUrls: [new RegExp(`^(?!.*${TRACKED_URLS_REGEX_STRING}).*`)],

        sessionTracking: {
            samplingRate: window.Cypress ? 0 : 1,
        },

        instrumentations: [
            // Mandatory, omits default instrumentations otherwise.
            ...getWebInstrumentations(),
            new ReactIntegration({
                router: createReactRouterV6DataOptions({
                    matchRoutes,
                }),
            }),

            // Tracing package to get end-to-end visibility for HTTP requests.
            new TracingInstrumentation({
                instrumentationOptions: {
                    propagateTraceHeaderCorsUrls: [new RegExp(`^${TRACKED_URLS_REGEX_STRING}`)],
                },
            }),
        ],
    })
}
