import {t} from '@lingui/macro'
import styled from 'styled-components'

import {Inline, NakedButton, Popover, Text, tokens} from '@pleo-io/telescope'
import {CheckCircleFilled, CircleEmpty, Info} from '@pleo-io/telescope-icons'

export const supportedStepNames = [
    'sign-up' as const,
    'pick-plan' as const,
    'verify-company' as const,
    'add-funds' as const,
    'trial-starts' as const,
]

type StepName = (typeof supportedStepNames)[number]

type Step = {
    name: StepName
    label: string
}

type TrialPopoverProps = {
    stepsToShowNames: StepName[]
    currentStep: StepName
}

export const TrialPopover = ({
    stepsToShowNames,
    currentStep: currentStepName,
}: TrialPopoverProps) => {
    const allSteps: Step[] = [
        {name: 'sign-up', label: t`Sign up`},
        {name: 'pick-plan', label: t`Pick the plan`},
        {name: 'verify-company', label: t`Verify your company`},
        {name: 'add-funds', label: t`Add funds to Pleo`},
        {name: 'trial-starts', label: t`Your 21-day trial starts`},
    ]

    return (
        <Popover>
            <Popover.Trigger aria-label={t`Information`}>
                <Trigger>
                    <Info size={16} aria-hidden />
                </Trigger>
            </Popover.Trigger>

            <Popover.Content>
                <Popover.Arrow />

                <Timeline>
                    {allSteps
                        .filter((step) => stepsToShowNames.includes(step.name))
                        .map((step, index, arr) => {
                            const currentStep =
                                arr.find((item) => item.name === currentStepName) ?? null
                            const currentStepIndex = currentStep ? arr.indexOf(currentStep) : 0

                            const Icon = index < currentStepIndex ? CheckCircleFilled : CircleEmpty

                            return (
                                <TimelineItem
                                    key={step.name}
                                    aria-current={index === currentStepIndex ? 'step' : undefined}
                                >
                                    {index < arr.length - 1 && (
                                        <TimelineItemTail $highlight={index < currentStepIndex} />
                                    )}

                                    <Inline as="span" space={16}>
                                        <Icon
                                            size={24}
                                            color={
                                                index <= currentStepIndex
                                                    ? tokens.colorContentStatic
                                                    : tokens.colorBorderStaticLoud
                                            }
                                        />

                                        <TimelineItemLabel
                                            as="span"
                                            variant="small-subtle"
                                            weight={
                                                index <= currentStepIndex ? 'medium' : 'regular'
                                            }
                                            color={
                                                index <= currentStepIndex
                                                    ? 'colorContentStatic'
                                                    : 'colorContentStaticQuiet'
                                            }
                                        >
                                            {step.label}
                                        </TimelineItemLabel>
                                    </Inline>
                                </TimelineItem>
                            )
                        })}
                </Timeline>
            </Popover.Content>
        </Popover>
    )
}

// The 24px icons have a 2px of whitespace on each side. We need to adjust positioning of the timeline tail to ensure
// the tail touches the actual icon
const offset = '2px'
const iconSize = '24px' // ← Make sure to match with the icon size in the markup
const timelineItemTailWidth = '2px'

const Timeline = styled.ol`
    width: 280px;
    padding: ${tokens.spacing16};
`

const TimelineItem = styled.li`
    position: relative;

    &:not(:last-child) {
        padding-bottom: ${tokens.spacing16};
    }
`

type TimelineItemTailProps = {
    $highlight: boolean
}

const TimelineItemTail = styled.span<TimelineItemTailProps>`
    position: absolute;
    inset-block-start: calc(${iconSize} - ${offset});
    inset-inline-start: calc((${iconSize} - ${timelineItemTailWidth}) / 2);
    height: calc(100% - ${iconSize} + (${offset} * 2));
    width: ${timelineItemTailWidth};
    background: ${($props) =>
        $props.$highlight ? tokens.colorBorderInteractiveLoud : tokens.colorBorderStaticLoud};
`

const TimelineItemLabel = styled(Text)`
    align-self: center;
`

const Trigger = styled(NakedButton)`
    color: ${tokens.colorContentInteractiveQuiet};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }
`
