import {t, Trans} from '@lingui/macro'
import {plural} from '@lingui/macro'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import {Badge, Button, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowRight, Export} from '@pleo-io/telescope-icons'

import AddMemberModalWrapper, {
    AddMemberModalScreen,
} from '@product-web/feature--people/add-modals/add-member-modal/add-member-modal'
import * as tracking from '@product-web/shared--analytics'
import {reportError} from '@product-web/shared--error/report'
import {IncludedInPlanBadge} from '@product-web/shared--plan-presentation/plan-badges/plan-badges'
import {UserPickedTrialPlanWrapper} from '@product-web/shared--plan-presentation/user-picked-trial-plan-wrapper'
import {
    ActionBanner,
    ActionBannerActionIcon,
    ActionBannerContent,
} from '@product-web/shared--telescope-lab/action-banner/action-banner'
import {getCompanyName, useLoggedInUser} from '@product-web/shared--user'
import {isNonNullable} from '@product-web/shared--utils'

import {MilestoneAccordion} from './milestone-accordion'

import {bff} from '../bff-hooks'
import {getMilestoneProgress} from '../lib/get-milestone-progress'
import type {MilestoneName, MilestoneStatus, Task} from '../universal/types'

type View = 'initial' | 'tasks' | 'invite-success'

type BookkeepingMilestoneAccordionItemProps = {
    status: MilestoneStatus
    tasks: Task[]
    milestoneName: MilestoneName
    bookkeeperEmails: string[]
    refreshData: () => Promise<void>
    isOnTrial: boolean
}

export const BookkeepingMilestoneAccordionItem = ({
    status,
    tasks,
    milestoneName,
    bookkeeperEmails,
    refreshData,
    isOnTrial,
}: BookkeepingMilestoneAccordionItemProps) => {
    const navigate = useNavigate()
    const user = useLoggedInUser()
    const {mutate: startTask} =
        bff.companyOnboarding.getStarted.startCompanyOnboardingTask.useMutation()

    const companyName = getCompanyName(user)
    const allTasksComplete = tasks.every((task) => task.status === 'COMPLETE')
    const someTasksComplete = tasks.some((task) => task.status === 'COMPLETE')
    const isBookkeeperInvited = Boolean(bookkeeperEmails.length)

    const getInitialView = React.useCallback((): View => {
        if (allTasksComplete) {
            return 'tasks'
        }

        if (isBookkeeperInvited) {
            return 'invite-success'
        }

        if (someTasksComplete) {
            return 'tasks'
        }

        return 'initial'
    }, [allTasksComplete, someTasksComplete, isBookkeeperInvited])

    const [view, setView] = React.useState<View>(getInitialView)

    React.useEffect(() => {
        setView(getInitialView())
    }, [getInitialView])

    const bookkeepingTasks: React.ComponentProps<typeof MilestoneAccordion.Tasks>['tasks'] = tasks
        .map((task) => {
            switch (task.name) {
                case 'LINK_ACCOUNTING_SYSTEM':
                    return {
                        heading: t`Select your accounting system`,
                        status: task.status,
                        cta: (
                            <Button
                                variant="primary"
                                IconRight={ArrowRight}
                                onClick={() => {
                                    startTask(task.name)
                                    tracking.companyOnboardingMilestoneActioned({
                                        action: 'started',
                                        milestone: milestoneName,
                                        task: task.name,
                                    })
                                    navigate('/onboarding/setup-accounting')
                                }}
                            >
                                <Trans>Continue</Trans>
                            </Button>
                        ),
                    }

                case 'SET_UP_TAGS':
                    return {
                        heading: t`Add a tag`,
                        status: task.status,
                        cta: (
                            <Button
                                variant="primary"
                                IconRight={ArrowRight}
                                onClick={() => {
                                    startTask(task.name)
                                    tracking.companyOnboardingMilestoneActioned({
                                        action: 'started',
                                        milestone: milestoneName,
                                        task: task.name,
                                    })
                                    navigate('/settings/accounting/tags')
                                }}
                            >
                                <Trans>Continue</Trans>
                            </Button>
                        ),
                    }

                default: {
                    reportError('Invalid milestone task name', null, {
                        milestoneName,
                        taskName: task.name,
                    })

                    return null
                }
            }
        })
        .filter(isNonNullable)

    const startBookkeeperInviteFlow = async () => {
        await AddMemberModalWrapper.show({
            initialScreen: AddMemberModalScreen.EXTERNAL_BOOKKEEPER,
            data: [],
            onAddMember: async () => {
                // We need to refresh the data manually here because the invite mutation is not via BFF yet and so
                // doesn't refresh query data automatically
                await refreshData()

                setView('invite-success')
            },
        })
    }

    const bookkeeperInvitedMessage = plural(bookkeeperEmails.length, {
        one: `You invited ${bookkeeperEmails[0]} to help with this.`,
        other: `You invited ${bookkeeperEmails[0]} and ${plural(bookkeeperEmails.length - 1, {
            one: '# other',
            other: '# others',
        })} to help with this.`,
    })

    return (
        <MilestoneAccordion.Item value={milestoneName} status={status}>
            <MilestoneAccordion.Header
                headingLevel="h2"
                heading={t`Connect accounting`}
                Icon={Export}
                progress={getMilestoneProgress(bookkeepingTasks)}
            />

            <MilestoneAccordion.Content>
                {view === 'initial' && (
                    <MilestoneAccordion.ContentItem>
                        <Stack space={16} stretch>
                            <Text variant="large-accent" weight="medium">
                                <Trans>Who does the bookkeeping for {companyName}?</Trans>
                            </Text>

                            <StyledActionBanner
                                onClick={() => {
                                    tracking.companyOnboardingBookkeepingChoiceActioned({
                                        choice: 'do-it-yourself',
                                    })
                                    setView('tasks')
                                }}
                            >
                                <ActionBannerContent title={t`I do the bookkeeping`} />

                                <ActionBannerActionIcon />
                            </StyledActionBanner>

                            <StyledActionBanner
                                onClick={() => {
                                    tracking.companyOnboardingBookkeepingChoiceActioned({
                                        choice: 'invite-bookkeeper',
                                    })
                                    startBookkeeperInviteFlow()
                                }}
                            >
                                <ActionBannerContentWrapper>
                                    <ActionBannerContent
                                        title={t`Someone else does the bookkeeping`}
                                    />

                                    {isOnTrial && (
                                        <UserPickedTrialPlanWrapper
                                            Control={
                                                <Badge variant="discover">
                                                    <Trans>Trial</Trans>
                                                </Badge>
                                            }
                                            Variation={<IncludedInPlanBadge />}
                                        />
                                    )}
                                </ActionBannerContentWrapper>

                                <ActionBannerActionIcon />
                            </StyledActionBanner>
                        </Stack>
                    </MilestoneAccordion.ContentItem>
                )}

                {view === 'invite-success' && (
                    <MilestoneAccordion.ContentItem>
                        <Stack space={16} align="center">
                            <Text variant="large-accent">{bookkeeperInvitedMessage}</Text>

                            <Button
                                variant="secondary"
                                onClick={() => setView('tasks')}
                                IconRight={ArrowRight}
                            >
                                <Trans>I'll try it myself</Trans>
                            </Button>
                        </Stack>
                    </MilestoneAccordion.ContentItem>
                )}

                {view === 'tasks' && <MilestoneAccordion.Tasks tasks={bookkeepingTasks} />}

                {view === 'tasks' && !allTasksComplete && (
                    <>
                        {isBookkeeperInvited ? (
                            <MilestoneAccordion.Footer description={bookkeeperInvitedMessage} />
                        ) : (
                            <MilestoneAccordion.Footer
                                description={t`Need to invite your bookkeeper?`}
                                badgeText={isOnTrial ? t`Trial` : undefined}
                                cta={
                                    <Button
                                        variant="secondary"
                                        onClick={startBookkeeperInviteFlow}
                                        IconRight={ArrowRight}
                                    >
                                        <Trans>Invite someone to Pleo</Trans>
                                    </Button>
                                }
                            />
                        )}
                    </>
                )}
            </MilestoneAccordion.Content>
        </MilestoneAccordion.Item>
    )
}

const StyledActionBanner = styled(ActionBanner)`
    column-gap: ${tokens.spacing8};
`

const ActionBannerContentWrapper = styled.div`
    display: flex;
    column-gap: ${tokens.spacing8};
    align-items: center;
`
