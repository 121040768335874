import {t} from '@lingui/macro'

import {Link, Tabs, tokens} from '@pleo-io/telescope'

export const AcademyTabs = () => (
    <Tabs.TriggerList
        css={{
            marginBottom: tokens.spacing36,
            justifyContent: 'center',
            borderBottom: tokens.borderInteractiveQuiet,
        }}
    >
        <Tabs.Trigger key="takeaways" aria-label={t`Takeaways Tab`} value="takeaways" asChild>
            <Link href="#takeaways">
                <Tabs.TriggerText text={t`Takeaways`} />
            </Link>
        </Tabs.Trigger>
        <Tabs.Trigger key="article" aria-label={t`Article Tab`} value="article" asChild>
            <Link href="#article">
                <Tabs.TriggerText text={t`Article`} />
            </Link>
        </Tabs.Trigger>
    </Tabs.TriggerList>
)
