import {t, Trans} from '@lingui/macro'
import type {RefObject} from 'react'
import {useEffect, useRef, useState} from 'react'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'
import styled from 'styled-components'

import {
    Box,
    Button,
    IconButton,
    Inline,
    Link,
    List,
    ListItem,
    Stack,
    Tabs,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {
    ArrowLeft,
    ArrowRight,
    ChevronUp,
    Clock,
    HighFive,
    Shield,
    ThumbUp,
} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'
import * as tracking from '@product-web/shared--analytics'
import {InlineWistiaVideo} from '@product-web/shared--telescope-lab/wistia-video/inline-wistia-video'

import {ModuleOverview} from './module-overview'

import {SplitContent} from '../../../../../components/split-content'
import {AcademyTabs} from '../../../components/academy-tabs'
import {Card, CardGroup} from '../../../components/snapshot'
import {Testimonial} from '../../../components/testimonial'

export const TransformClientFinances = () => {
    const [currentTab, setCurrentTab] = useState('takeaways')
    const [hasOverlap, setHasOverlap] = useState(false)
    const [showBackToTop, setShowBackToTop] = useState(false)

    const stickyContainerRef = useRef<HTMLDivElement>(null)
    const takeawaysRef = useRef<HTMLDivElement>(null)
    const introductionRef = useRef<HTMLDivElement>(null)
    const splitMainRef = useRef<HTMLDivElement>(null)

    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    const what3Words = 'What 3 Words'
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    const vistair = 'Vistair'
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    const koto = 'Koto'
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    const ninjasInPyjamas = 'Ninjas in Pyjamas'

    useEffect(() => {
        const handleScroll = () => {
            if (stickyContainerRef.current && takeawaysRef.current) {
                const stickyContainerRect = stickyContainerRef.current.getBoundingClientRect()
                const takeawaysRect = takeawaysRef.current.getBoundingClientRect()
                setHasOverlap(stickyContainerRect.bottom > takeawaysRect.top)
            }

            if (stickyContainerRef.current && introductionRef.current) {
                const stickyContainerRect = stickyContainerRef.current.getBoundingClientRect()
                const section1Rect = introductionRef.current.getBoundingClientRect()
                setHasOverlap(stickyContainerRect.bottom > section1Rect.top)
            }

            const scrollY = Math.round(window.scrollY)
            setShowBackToTop(scrollY > 40)
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    })

    const scrollToSection = (sectionRef: RefObject<HTMLDivElement>) => {
        if (sectionRef.current && stickyContainerRef.current) {
            const stickyRect = stickyContainerRef.current.getBoundingClientRect()
            const targetPosition =
                stickyRect.top + sectionRef.current.offsetTop - stickyRect.bottom - 20

            window.scrollTo({top: targetPosition, behavior: 'smooth'})
        }
    }

    return (
        <>
            <Helmet>
                <title>{t`Streamline clients' finances`}</title>
            </Helmet>
            <Page.Header css={{backgroundColor: tokens.colorBackgroundInteractive}}>
                <Page.Backlink>
                    <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                        <Trans>Back to overview</Trans>
                    </Link>
                </Page.Backlink>
                <Page.Title>
                    <Trans>Streamline clients' finances</Trans>
                </Page.Title>
                <Inline alignItems="center" space={6} mb={24}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>12 mins</Trans>
                    </Text>
                </Inline>
                <Text as="h3" variant="xlarge-accent">
                    <Trans>Module overview</Trans>
                </Text>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main flex={4} ref={splitMainRef}>
                    <Tabs
                        value={currentTab}
                        onValueChange={(newValue) => {
                            setCurrentTab(newValue)
                            if (newValue === 'takeaways') {
                                scrollToSection(takeawaysRef)
                                return
                            }
                            scrollToSection(introductionRef)
                        }}
                    >
                        <Text>
                            <Trans>
                                Discover how inefficient spend management can impact your clients'
                                business efficiency. We'll also explore how tools like Pleo can help
                                them reduce costs, streamline processes and improve their financial
                                visibility.
                            </Trans>
                        </Text>
                        <Box mt={24}>
                            <InlineWistiaVideo
                                ariaLabel={t`Play video: Streamlined spend management`}
                                thumbnail={{
                                    src: 'https://embed-ssl.wistia.com/deliveries/2c7cb83cd1d9785dd5242c4c075d826d03a44b5e.jpg?video_still_time=1.6',
                                }}
                                videoId="bkc4l63e5d"
                                autoPlay={false}
                                trackingCallback={(action) =>
                                    tracking.partnerAcademy({
                                        action,
                                        video: 'transform_client_finances',
                                    })
                                }
                            />
                        </Box>
                        <StickyContainer ref={stickyContainerRef} $hasOverlap={hasOverlap}>
                            <AcademyTabs />
                        </StickyContainer>
                        <Tabs.Content value="takeaways">
                            <div ref={takeawaysRef}>
                                <CardGroup>
                                    <Card
                                        icon={<Clock />}
                                        title={t`Give your clients their time back`}
                                        description={t`Automate tasks like receipt capture, reimbursements, and reconciliations to free up hours of admin work.`}
                                    />
                                    <Card
                                        icon={<Shield />}
                                        title={t`Help clients take back control`}
                                        description={t`Real-time insights and customisable controls reassure clients that they're in control of their finances.`}
                                    />
                                    <Card
                                        icon={<HighFive />}
                                        title={t`Deliver month-end reports in good time`}
                                        description={t`Organise data in real time to cut down on last-minute scrambling and bottlenecks.`}
                                    />
                                    <Card
                                        icon={<ThumbUp />}
                                        title={t`Avoid costly errors and duplications`}
                                        description={t`Eliminating disconnected tools helps protect your clients from financial blunders.`}
                                    />
                                </CardGroup>
                            </div>
                        </Tabs.Content>
                        <Tabs.Content value="article">
                            <Stack mt={24} space={16} ref={introductionRef}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>Introduction</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        In this module, we'll explore how inefficient spend
                                        management impacts your clients daily and how tools like
                                        Pleo can help them save money, simplify processes, and gain
                                        better financial control and visibility.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>In this session you will learn:</Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            Why tackling inefficient spend management is key to
                                            business success for clients.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            How tools like Pleo solve common client challenges like
                                            manual processes, limited spend visibility, and
                                            disconnected systems.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            Practical strategies to help clients save time, reduce
                                            costs, and simplify bookkeeping.
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>Let's dive in.</Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>
                                        Why streamlined spend management matters for clients
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Many businesses juggle a chaotic mix of disconnected tools,
                                        making the life of the finance team almost impossible. It's
                                        likely they have one tool for handling invoices, another for
                                        tracking employee spending, and yet another for managing
                                        reimbursements.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        In fact, only 52% of companies have a 30-day view of their
                                        cash obligations, and many rely on data that is two weeks
                                        old. Imagine what your clients could achieve in their
                                        business if they had visibility into their spend management?
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Here's why it matters: Efficient spend management frees up
                                        time for smarter decisions, helps avoid costly errors, and
                                        gives your clients the financial control they need to grow.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Let's explore how clients can solve these challenges
                                        effectively.
                                    </Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>What makes Pleo stand out for clients</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        By centralising previously disconnected business spend
                                        processes, clients can work smarter, reduce inefficiencies,
                                        and gain control of their finances. Pleo brings everything
                                        together in one platform, eliminating manual admin and
                                        streamlining processes.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Here's what clients love about Pleo and why making the
                                        switch was a no-brainer for them:
                                    </Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>Automated processes:</strong> Tasks like receipt
                                            capture and reconciliation are handled automatically
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Real-time visibility:</strong> No more waiting
                                            until month-end for spend insights
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Simplified expense management:</strong>{' '}
                                            Everything from invoices to reimbursements is all under
                                            one roof
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>The truth is in the numbers:</Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>96% receipt capture rate</strong> with automated
                                            reminders
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>5x faster reconciliations</strong>, giving
                                            finance teams valuable time back
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Up to £1,200 saved per user annually</strong>{' '}
                                            with better visibility and smarter spending controls
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        With the right tools in place, clients can shift from
                                        reacting to financial challenges to making proactive
                                        decisions that drive growth.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>Let's look at some examples of how it works.</Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>
                                        How automated spend management helps clients save time
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Manual tasks like chasing receipts, filling out expense
                                        forms, and reconciling transactions drain time and energy
                                        from finance teams.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        82% of finance leaders admit that excessive manual finance
                                        processes are hindering their organisation's growth plans
                                        for the year ahead, with an individual supplier invoice now
                                        taking an average of 41 minutes to process.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Here's how clients solve this challenge with Pleo:
                                    </Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>Automated receipt capture:</strong> Employees
                                            snap a photo of their receipt, and Pleo attaches it to
                                            the transaction instantly — no more chasing receipts
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Fast reimbursements:</strong> Approved
                                            reimbursements are processed within 20 minutes, reducing
                                            delays
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Simplified approvals:</strong> Automated
                                            workflows replace manual email chains, speeding up
                                            expense approvals
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        With this process, <strong>{what3Words}</strong> reduced
                                        their time spent on reconciling credit cards each month from
                                        10% to 2%.
                                    </Trans>
                                </Text>
                                <Stack>
                                    <Text>
                                        <strong>
                                            <Trans>The Problem:</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            {what3Words} struggled with time-consuming, error-prone
                                            credit card reconciliations. Employees frequently
                                            misplaced receipts, leading to messy accounting and
                                            unnecessary admin work for the finance team.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Text>
                                        <strong>
                                            <Trans>The Solution:</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            With Pleo, What3Words automated receipt uploads for
                                            employees and cut out manual follow-ups for the finance
                                            team with automated reminders.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Text>
                                        <strong>
                                            <Trans>The Impact:</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            Credit card reconciliation time dropped from 10% to just
                                            2%, and teams gained more control over travel expenses
                                            while management kept full visibility over spending.
                                        </Trans>
                                    </Text>
                                </Stack>

                                <Testimonial
                                    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                                    author="Charlie Maynard"
                                    companyPosition={t`Finance Manager of ${what3Words}`}
                                    quote={t`We used to spend around 10% of our time on credit card reconciliations each month. With Pleo, that's been reduced to just 2%. This gives us the bandwidth to focus on more business-critical projects.`}
                                    url="https://blog.pleo.io/en/hero-what3words"
                                />
                                <Text>
                                    <Trans>
                                        Pleo helps businesses save over 138 hours of admin work each
                                        year - how much time could your clients save by automating
                                        their expense management?
                                    </Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>
                                        How real-time spend visibility helps clients save money and
                                        gain control
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Saving money starts with visibility and control, yet many
                                        businesses struggle to monitor spending effectively, leading
                                        to missed savings opportunities and inefficiencies.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        In 2024, organisations lost 5% of their revenue to
                                        occupational fraud, with expense reimbursement fraud being a
                                        significant contributor. These losses strain budgets and
                                        hinder investments in growth.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        For businesses looking to protect their revenues and grow
                                        their bottom line, it's extremely important to remain in
                                        control of cash going out. By introducing real-time
                                        visibility through overhauling old-school processes,
                                        business owners can be confident that spend is being
                                        effectively monitored.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Here's how clients can gain better financial control through
                                        automating their spend processes:
                                    </Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>Real-Time Spending Insights:</strong> Managers
                                            track spending as it happens.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Centralised Dashboard:</strong> Spending data is
                                            grouped in one place
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Smart Approval Workflows:</strong> Automate
                                            expense approvals to keep spending transparent and
                                            accountable.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Card Controls:</strong> clients can easily
                                            control employees' access to funds through weekend
                                            blocks and temporary cards
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        For <strong>{vistair}</strong>, a leader in aviation
                                        technology, gaining visibility into issues as they happened
                                        and getting them under control was crucial to avoiding
                                        costly mistakes.
                                    </Trans>
                                </Text>
                                <Stack>
                                    <Text>
                                        <strong>
                                            <Trans>The Problem:</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            {vistair} relied on outdated processes like manual
                                            expense tracking and shared Excel spreadsheets. These
                                            inefficient systems left the finance team without clear
                                            visibility into day-to-day spending.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Text>
                                        <strong>
                                            <Trans>The Solution:</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            Automated expense categorisation and data dashboards in
                                            Pleo helped the finance team make faster, more informed
                                            decisions.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Text>
                                        <strong>
                                            <Trans>The Impact:</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            The finance team now identifies and addresses spending
                                            issues faster, improving budget management and reducing
                                            risk.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Testimonial
                                    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                                    author="Johnny Bray"
                                    companyPosition={t`CFO of ${vistair}`}
                                    quote={t`Pleo provides the monitoring controls we need to spot issues as soon as they happen, so we can act fast.`}
                                    url="https://blog.pleo.io/en/beyond-vistair"
                                />
                                <Text>
                                    <Trans>
                                        How much more could your clients save with real-time
                                        visibility into their spending?
                                    </Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>
                                        How streamlined spend management simplifies month-end for
                                        you and your clients
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Month-end closing often turns into a scramble — chasing
                                        receipts, reconciling transactions, and fixing errors under
                                        tight deadlines. Disconnected tools and unclear policies
                                        make it even harder for finance teams to stay on top of
                                        reporting.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        This can impact your relationship with clients: bottlenecks
                                        due to missing reconciliation data create frustrations for
                                        your bookkeeping team, whilst clients can become irritated
                                        with constant chasing.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Accounting firms who implement automated spend solutions
                                        report happier clients and employees.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Here's how clients break free from month-end chaos with
                                        Pleo:
                                    </Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>Smart company cards:</strong> Employees use Pleo
                                            cards for purchases, and receipts are captured
                                            instantly—no more chasing paperwork
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Automated receipt capture:</strong> Employees
                                            snap a photo of their receipt at the point of purchase,
                                            keeping records up to date
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Organised, real-time data:</strong> Finance
                                            teams get instant access to organised, categorised
                                            expense data, preventing last-minute bottlenecks
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        Let's take a look at how <strong>{koto}</strong> ended
                                        month-end chaos for their finance team=:
                                    </Trans>
                                </Text>
                                <Stack>
                                    <Text>
                                        <strong>
                                            <Trans>The Problem:</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            The month-end process was overwhelming at {koto}. The
                                            finance team spent hours manually collecting and
                                            reconciling receipts, with up to six people involved in
                                            a single transaction. Delayed submissions and unclear
                                            policies added unnecessary stress and inefficiencies to
                                            their reporting.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Text>
                                        <strong>
                                            <Trans>The Solution:</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            {koto} introduced Pleo's smart company cards and
                                            automated workflows. Receipts were submitted instantly,
                                            and the number of people involved dropped from six to
                                            two.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Text>
                                        <strong>
                                            <Trans>The Impact:</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            {koto} now spends 50% less time on month-end expense
                                            management, with no more last-minute scrambling for
                                            receipts and full visibility for the finance team
                                            throughout the month.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Text>
                                    <Trans>
                                        How much easier could month-end be for you and your clients
                                        with a more streamlined process?
                                    </Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>
                                        How consolidated accounts payable can eliminate costly
                                        errors and duplications
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Managing accounts payable often means juggling multiple
                                        tools for invoice processing, approvals, and
                                        payments—leading to delays, errors, and extra admin work.
                                        Fragmented systems make it harder to track expenses and slow
                                        down operations.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        94% of CFOs say that they have received the same invoice
                                        twice, and 30% of CFOs report that paying invoices has
                                        caused them stress.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Poorly managed accounts payable can be a huge source of
                                        stress, as we can see from these statistics. Many of your
                                        clients' largest transactions will be paid via invoice, so
                                        it's crucial that this process is managed effectively to
                                        avoid costly errors and unnecessary stress.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>Here's how Pleo simplifies the process:</Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>Automated invoice capture:</strong> Invoices are
                                            automatically captured through OCR technology and sent
                                            to the right approvers.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Faster approvals:</strong> Automated workflows
                                            ensure invoices reviewed without manual follow-ups.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Direct payments:</strong> Once approved,
                                            payments can be processed directly from the Pleo wallet,
                                            reducing errors and delays.
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        {ninjasInPyjamas} was able to remove bottlenecks from their
                                        supply chain and enable faster supplier payments with
                                        simplified accounts payable.
                                    </Trans>
                                </Text>
                                <Stack>
                                    <Text>
                                        <strong>
                                            <Trans>The Problem:</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            {ninjasInPyjamas} (NIP), a global esports organisation,
                                            faced frequent supplier payment delays and missing
                                            receipts, which slowed down operations and created
                                            reporting headaches for their finance team.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Text>
                                        <strong>
                                            <Trans>The Solution:</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            NIP automated their accounts payable process with faster
                                            invoice capture, automatic receipt collection, and
                                            direct supplier payments from the Pleo wallet,
                                            streamlining their entire workflow.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Text>
                                        <strong>
                                            <Trans>The Impact:</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            NIP removed bottlenecks from their supply chain,
                                            enabling faster supplier payments while their accounting
                                            system reconciled transactions automatically.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Text>
                                    <Trans>
                                        What unnecessary costs could your clients eliminate with a
                                        more efficient accounts payable process?
                                    </Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>Closing Thoughts</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Spend management doesn't have to be a struggle for your
                                        clients. With Pleo, you can help them simplify their
                                        process, reduce errors, and gain full visibility over their
                                        spending—all from one platform.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Here's how <strong>Pleo</strong> makes a difference for your
                                        clients:
                                    </Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>Save time</strong> by automating tasks like
                                            receipt capture and approvals.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Cut costs</strong> with real-time expense
                                            tracking.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Keeps spending in check</strong> with smarter
                                            controls and spending limits.
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        Ready to see what's next? Let's dive into Module 3 and
                                        explore how you can become a Pleo Partner and help your
                                        clients make the shift to better spend management.
                                    </Trans>
                                </Text>
                            </Stack>
                        </Tabs.Content>
                    </Tabs>

                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link
                            as={RouterLink}
                            to="../simplify-bookkeeping-workflow"
                            IconLeft={ArrowLeft}
                        >
                            <Trans>Back</Trans>
                        </Link>
                        <Button
                            variant="primary"
                            as={RouterLink}
                            to="./quiz"
                            IconRight={ArrowRight}
                        >
                            <Trans>Take the quiz</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <SplitContent.Sticky>
                        <ModuleOverview />
                    </SplitContent.Sticky>
                </SplitContent.Right>
            </SplitContent>
            {showBackToTop && (
                <IconButton
                    aria-label={t`Back to top`}
                    tooltipProps={{content: t`Back to top`}}
                    variant="secondary"
                    Icon={ChevronUp}
                    onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                    css={{
                        position: 'fixed',
                        bottom: 40,
                        left: (splitMainRef.current?.getBoundingClientRect()?.right ?? 0) + 80,
                        padding: tokens.spacing24,
                        border: tokens.borderInteractive,
                    }}
                />
            )}
        </>
    )
}

const StickyContainer = styled(Box).attrs({mb: 24})<{$hasOverlap: boolean}>`
    position: sticky;
    top: 0;
    padding-top: ${tokens.spacing20};
    background-color: ${tokens.colorBackgroundInteractive};
    transition: box-shadow ${tokens.smoothInOut};

    ${({$hasOverlap}) => $hasOverlap && `box-shadow: ${tokens.shadowElevate};`}
`
