import type {CompanyAdoptionGuideResponse} from '@shared/bff--moons/generated/auxo'

export enum AdoptionGuides {
    ExportEssentials = 'EXPORT_ESSENTIALS',
    Automation = 'AUTOMATION_GUIDE',
}

export const getGuideToShow = (
    guides: CompanyAdoptionGuideResponse[],
): CompanyAdoptionGuideResponse | undefined => {
    return guides.find(({adoptionGuide}) => adoptionGuide === AdoptionGuides.ExportEssentials)
}
