import type {ReactNode} from 'react'

import {useUserPickedTrialPlanFlag} from './use-user-picked-trial-plan-flag'

type UserPickedTrialPlanWrapperProps = {
    Control: ReactNode
    Variation: ReactNode
}

export const UserPickedTrialPlanWrapper = ({
    Control,
    Variation,
}: UserPickedTrialPlanWrapperProps) => {
    const {isLoadingUserPickedTrialPlanFlag, hasUserPickedTrialPlanFlag} =
        useUserPickedTrialPlanFlag()

    if (isLoadingUserPickedTrialPlanFlag) {
        return null
    }

    if (hasUserPickedTrialPlanFlag) {
        return <>{Variation}</>
    }

    return <>{Control}</>
}
