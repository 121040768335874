import {Route} from 'react-router-dom'

import {Quiz} from './quiz'
import {TransformClientFinances} from './transform-client-finances'

export const routes = (
    <>
        <Route index element={<TransformClientFinances />} />
        <Route path="quiz" element={<Quiz />} />
    </>
)
