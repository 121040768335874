import {t, Trans} from '@lingui/macro'
import {useLocation, useNavigate} from 'react-router'

import {Badge, Button, Inline, List, ListItem, ModalSplit, Stack} from '@pleo-io/telescope'
import {NewTab} from '@pleo-io/telescope-icons'

import {getHelpCentreArticleLink} from '@product-web/shared--locale/helpers'
import {IncludedInPlanBadge} from '@product-web/shared--plan-presentation/plan-badges/plan-badges'
import {UserPickedTrialPlanWrapper} from '@product-web/shared--plan-presentation/user-picked-trial-plan-wrapper'
import {useUser} from '@product-web/shared--user'

import {useVismaMigrationAdoption} from './use-visma-migration-adoption'
import VismaMigrationAdoptionPromptIllustration from './visma-migration-adoption-prompt.svg'

import {
    trackVismaMigrationAdoptionPromptActioned,
    trackVismaMigrationInstructionsActioned,
} from '../lib/tracking'

export const VismaMigrationAdoptionPrompt = () => {
    const {
        shouldShowVismaMigrationAdoptionPrompt,
        setVismaMigrationAdoptionPromptActioned,
        setVismaMigrationAdoptionPromptDismissed,
        setVismaMigrationAdoptionPromptRemindLater,
    } = useVismaMigrationAdoption()

    if (!shouldShowVismaMigrationAdoptionPrompt) {
        return null
    }

    return (
        <VismaMigrationAdoptionPromptPresentation
            setVismaMigrationAdoptionPromptActioned={setVismaMigrationAdoptionPromptActioned}
            setVismaMigrationAdoptionPromptDismissed={setVismaMigrationAdoptionPromptDismissed}
            setVismaMigrationAdoptionPromptRemindLater={setVismaMigrationAdoptionPromptRemindLater}
        />
    )
}

interface VismaMigrationAdoptionPromptPresentationProps {
    setVismaMigrationAdoptionPromptActioned: () => void
    setVismaMigrationAdoptionPromptDismissed: () => void
    setVismaMigrationAdoptionPromptRemindLater: () => void
}

export const VismaMigrationAdoptionPromptPresentation = ({
    setVismaMigrationAdoptionPromptActioned,
    setVismaMigrationAdoptionPromptDismissed,
    setVismaMigrationAdoptionPromptRemindLater,
}: VismaMigrationAdoptionPromptPresentationProps) => {
    const navigate = useNavigate()
    const location = useLocation()
    const user = useUser()

    const handleAccessInstructions = () => {
        trackVismaMigrationInstructionsActioned({
            companyId: user?.company?.id,
            userId: user?.id,
        })
        const languageCode = user?.language || 'en'
        const url = getHelpCentreArticleLink(
            '103000254694-setting-up-the-visma-e-conomic-integration',
            languageCode,
        )
        const instructionsWindow = window.open(url, '_blank', 'noopener,noreferrer')

        if (instructionsWindow) {
            instructionsWindow.opener = null
        }
    }

    return (
        <ModalSplit
            onDismiss={() => {
                trackVismaMigrationAdoptionPromptActioned({
                    type: 'dismissed',
                    pagePath: location.pathname,
                    userId: user?.id,
                    companyId: user?.companyId,
                })
                setVismaMigrationAdoptionPromptDismissed()
            }}
        >
            <ModalSplit.Content>
                <ModalSplit.Body>
                    <Inline mb={16}>
                        <UserPickedTrialPlanWrapper
                            Control={
                                <Badge variant="discover">
                                    <Trans>Included in your plan</Trans>
                                </Badge>
                            }
                            Variation={<IncludedInPlanBadge />}
                        />
                    </Inline>
                    <ModalSplit.Title>
                        <Trans>Pleo Invoices is now available for Visma e-conomic</Trans>
                    </ModalSplit.Title>
                    <Stack space={16}>
                        <ModalSplit.Text>
                            <Trans>Good news, you can now get access to Invoices.</Trans>
                        </ModalSplit.Text>
                        <ModalSplit.Text>
                            <List listStyle="bullet">
                                <ListItem>
                                    <Trans>Get an overview of unpaid invoices</Trans>
                                </ListItem>
                                <ListItem>
                                    <Trans>Request approvals from the right people</Trans>
                                </ListItem>
                                <ListItem>
                                    <Trans>Schedule payments from your Pleo wallet</Trans>
                                </ListItem>
                            </List>
                        </ModalSplit.Text>
                        <ModalSplit.Text>
                            <Trans>
                                To try it out, simply update your integration with Visma e-conomic.
                            </Trans>
                        </ModalSplit.Text>
                        <ModalSplit.Text>
                            <Button
                                variant="tertiary"
                                type="button"
                                onClick={() => handleAccessInstructions()}
                            >
                                <Inline alignItems={'center'} alignContent={'center'} space={4}>
                                    <Trans>Enabling Invoices for Visma e-conomic</Trans>
                                    <NewTab size={16} />
                                </Inline>
                            </Button>
                        </ModalSplit.Text>
                    </Stack>
                </ModalSplit.Body>
                <ModalSplit.Actions>
                    <Button
                        onClick={() => {
                            trackVismaMigrationAdoptionPromptActioned({
                                type: 'dismissed',
                                pagePath: location.pathname,
                                userId: user?.id,
                                companyId: user?.company?.id,
                            })
                            setVismaMigrationAdoptionPromptRemindLater()
                        }}
                        variant="tertiary"
                        type="button"
                    >
                        <Trans>Remind me later</Trans>
                    </Button>
                    <ModalSplit.NextButton
                        onClick={() => {
                            trackVismaMigrationAdoptionPromptActioned({
                                type: 'accessed',
                                pagePath: location.pathname,
                                userId: user?.id,
                                companyId: user?.company?.id,
                            })
                            setVismaMigrationAdoptionPromptActioned()
                            navigate('/invoices')
                        }}
                    >
                        <Trans>Go to Invoices</Trans>
                    </ModalSplit.NextButton>
                </ModalSplit.Actions>
            </ModalSplit.Content>
            <ModalSplit.IllustrationContainer
                css={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <ModalSplit.Illustration>
                    <img
                        src={VismaMigrationAdoptionPromptIllustration}
                        alt={t`Pleo Invoices is now available for Visma e-conomic`}
                    />
                </ModalSplit.Illustration>
            </ModalSplit.IllustrationContainer>
        </ModalSplit>
    )
}
