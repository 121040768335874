import {useCompanySubscription} from '@product-web/shared--paywall/lib/company-subscription'
import {usePreApprovedOverdraftInfo} from '@product-web/shared--paywall/overdraft/use-pre-approved-overdraft-info'
import {getIsCompanyOwner, useUser} from '@product-web/shared--user'

export type PromoCardType = 'upgrade' | 'pre-approved-overdraft' | null

type usePromoCardsReturnType = {
    isPromoCardVisible: boolean
    promoCardType: PromoCardType
}

export default function usePromoCards(): usePromoCardsReturnType {
    let promoCardType: PromoCardType = null

    const user = useUser()
    const isCompanyOwner = getIsCompanyOwner(user)
    const {data: subscription} = useCompanySubscription()
    const preApprovedOverdraftInfo = usePreApprovedOverdraftInfo()

    const planType = subscription?.platformRatePlan.type
    const isStarterPlan = planType === 'STARTER' || planType === 'FREE'

    // Add isFetching flag to avoid flickering between promo cards
    if (!preApprovedOverdraftInfo.isFetching) {
        if (isStarterPlan && preApprovedOverdraftInfo.isEligible) {
            promoCardType = 'pre-approved-overdraft'
        } else if (isCompanyOwner && isStarterPlan && !subscription?.isInTrialPeriod) {
            promoCardType = 'upgrade'
        }
    }

    return {
        isPromoCardVisible: !!promoCardType,
        promoCardType,
    }
}
