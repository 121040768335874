import debounce from 'lodash.debounce'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import {
    getFormCompanyCountry,
    getFormCompanySize,
} from '@product-web/feature--register/company-storage'
import {useCompanySettings} from '@product-web/shared--api-deimos/company-settings'
import {group, identify, page} from '@product-web/shared--consent/analytics'
import {useFlags} from '@product-web/shared--flags'
import {getLanguageByLocale} from '@product-web/shared--locale/helpers'
import Locale from '@product-web/shared--locale/locale'
import {usePrevious} from '@product-web/shared--react-utils'
import {redactUrlParams} from '@product-web/shared--redact-url-params'
import {getFirstName, getLastName, getLocale, useUser} from '@product-web/shared--user'
import {checkGenericEmail} from '@product-web/shared--utils'

import {useLoadAnalyticsWithConsent} from './hooks'

// debounced to mitigate multiple tracking multiple page events during redirects
const debouncedPage = debounce(
    (path: string, properties?: Record<string, any>, options?: Record<string, any>) => {
        page(path, properties, options)
    },
    1000,
)

// Identify user for analytics tracking
export function useEnhanceAnalytics() {
    const user = useUser()
    const {
        isProductLedQ42022,
        bifrostNudgeToSpendExperiment,
        invoicesOnboarding,
        popularVendorCardsLandingPage,
        isCompanySearchButtonLive,
        planRecommendationsFromUpgradePoints,
        vendorCardRecommendations,
    } = useFlags()
    const {pathname} = useLocation()
    const {data: companySettings} = useCompanySettings()

    useLoadAnalyticsWithConsent()

    useEffect(() => {
        const bigformCompanyCountry = getFormCompanyCountry()
        const bigformCompanySize = getFormCompanySize()
        if (!user) {
            return
        }

        identify(user.id, {
            firstName: getFirstName(user),
            lastName: getLastName(user),
            email: user.email,
            employeeId: user.employee?.id,
            companyId: user.company?.id,
            role: user.role,
            partnerId: user?.partner?.id,
            pleoLanguage: getLanguageByLocale(getLocale(user, Locale.en_GB)),
            partnerRole: user?.partnerEmployee?.role,
            bigformCompanyCountry,
            bigformCompanySize,
            isReviewer: !!user.reviewer?.length,
            companyCountry: user?.company?.address?.country ?? '',
            companyPlan: user?.company?.plan ?? '',
            isGenericEmail: checkGenericEmail(user.email),
            splitTest: isProductLedQ42022,
            splitTestVersion: isProductLedQ42022 ? 'testQ42022' : undefined,
            bifrostNudgeToSpendExperiment,
            isMultiEntity: !!user?.organization,
            isSpendEntity: !!user?.isSpendingEntity,
            companyOnboardedVia: user.company?.onboardedVia,
            invoicesOnboardingExperiment: invoicesOnboarding,
            popularVendorCardsLandingPage,
            isCompanySearchButtonLiveExperiment: isCompanySearchButtonLive,
            planRecommendationsFromUpgradePoints,
            vendorCardRecommendations,
        })
    }, [
        user?.id,
        user?.company?.address?.country,
        isProductLedQ42022,
        bifrostNudgeToSpendExperiment,
        invoicesOnboarding,
        isCompanySearchButtonLive,
        popularVendorCardsLandingPage,
        planRecommendationsFromUpgradePoints,
        vendorCardRecommendations,
    ])

    useEffect(() => {
        if (!user) {
            return
        }

        if (user.company?.status) {
            const bigformCompanyCountry = getFormCompanyCountry()
            const bigformCompanySize = getFormCompanySize()
            const accountingSystem = companySettings?.accounting?.system

            const companyAddress = {
                street: user.company?.address?.addressLine1,
                city: user.company?.address?.locality,
                state: user.company?.address?.region,
                postalCode: user.company?.address?.postalCode,
                country: user.company?.address?.country,
            }

            group(user.company.id, {
                name: user.company.name,
                address: companyAddress,
                companyCountry: user.company.address.country,
                companyPlan: user.company.plan,
                createdAt: user.company.createdAt,
                description: user.company.registrationNumber,
                bigformCompanyCountry: bigformCompanyCountry || '',
                bigformCompanySize: bigformCompanySize ?? '',
                id: user.company.id,
                employees: bigformCompanySize ?? 0,
                numberOfUsers: user.company.numberOfUsers,
                role: user.role,
                isFDD: !!user.company.status.fdd,
                isSDD: !!user.company.status.sdd,
                isEDD: !!user.company.status.edd,
                isHEDD: !!user.company.status.hedd,
                isHEDD1: !!user.company.status.hedd1,
                isMultiEntity: !!user?.organization,
                isSpendEntity: !!user?.isSpendingEntity,
                isSelfOnboarding: !!user.company.status.selfOnboarding,
                ...(accountingSystem && {
                    accountingSystem,
                    accountingIntegration: accountingSystem,
                }),
            })

            // We can now group a user by organisations also for multi-entity companies
            if (user.organization?.id) {
                group(user.organization.id, {
                    traits: {
                        id: user.organization.id,
                        name: user.organization.name,
                    },
                })
            }
        }
    }, [user, companySettings])

    // Track client-side routing page changes
    const previousPathname = usePrevious(pathname)
    useEffect(() => {
        if (pathname) {
            // Reporting search params can result into security issues sometimes. For example we have oAuth redirect back to Pleo with auth code in search params.
            // Please use navigation state if possible or add sensitive params to ~/redact-url-params
            const {search, href} = new URL(redactUrlParams(window.location.toString()))

            debouncedPage(pathname, {
                search,
                url: href,
                previous_path: previousPathname ?? null,
                screen: {
                    width: window.screen.width,
                    height: window.screen.height,
                },
            })
        }
    }, [pathname])
}
