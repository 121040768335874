import type {FC} from 'react'
import {useEffect} from 'react'
import styled, {css} from 'styled-components'

import {px, tokens} from '@pleo-io/telescope'

import {useFlags} from '@product-web/shared--flags'
import {navigationOffset} from '@product-web/shared--navigation/constants'
import {useMobileNavigationVisibility} from '@product-web/shared--navigation/navigation-context'
import {breakpoints} from '@product-web/shared--styles/theme'
import {Keys} from '@product-web/shared--web-platform/keyboard'
import {useKeyPress} from '@product-web/shared--web-platform/use-keypress'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {EntitySwitcherButtonWrapper} from './entity-switcher-button/entity-switcher-button'
import {EntitySwitcherPanel} from './entity-switcher-panel/entity-switcher-panel'

import {useInternalNavigationContext} from '../navigation-internal-provider'
import {NavigationItems} from '../navigation-items'
import {ActivePromotionalBanner} from '../navigation-promotional-banner/navigation-promotional-banner.helper'
import {NavigationAvatar} from '../navigation-v2/navigation-avatar'

export const MainSlot: FC = () => {
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)
    const {isMobileNavigationVisible, setIsMobileNavigationVisible} =
        useMobileNavigationVisibility()

    const {navigationPromotionalBanner} = useFlags()

    const {navItemsWidth, isEntitySwitcherVisible, setIsEntitySwitcherVisible} =
        useInternalNavigationContext()

    useEffect(() => {
        if (isTablet) {
            // Hiding the entity switcher panel and the navigation items panel
            // when the screen size changes to mobile
            // because users wants to see app content, not the navigation when app resized to mobile
            setIsMobileNavigationVisible(false)
            setIsEntitySwitcherVisible(false)
        }
    }, [isTablet, setIsEntitySwitcherVisible, setIsMobileNavigationVisible])

    useKeyPress(Keys.ESCAPE, () => {
        if (isTablet && isMobileNavigationVisible && !isEntitySwitcherVisible) {
            setIsMobileNavigationVisible(false)
        }
    })

    const handleEntitySwitcherButtonClick = () => {
        setIsEntitySwitcherVisible(!isEntitySwitcherVisible)
    }

    const handleNavigationItemClick = () => {
        setIsMobileNavigationVisible(false)
    }

    return (
        <>
            <NavigationItemsWrapper
                $isVisibleForMobile={isMobileNavigationVisible}
                $width={navItemsWidth}
            >
                <EntitySwitcherButtonWrapper
                    isActive={isEntitySwitcherVisible}
                    onClick={handleEntitySwitcherButtonClick}
                />

                {navigationPromotionalBanner && <ActivePromotionalBanner />}

                <NavigationItems onClickItem={handleNavigationItemClick} showTopSpacing={false} />
                {!isTablet && (
                    <NavigationAvatarWrapper>
                        <NavigationAvatar />
                    </NavigationAvatarWrapper>
                )}
            </NavigationItemsWrapper>
            <EntitySwitcherPanel
                isPanelVisible={isEntitySwitcherVisible}
                setPanelVisible={setIsEntitySwitcherVisible}
            />
        </>
    )
}

const NavigationItemsWrapper = styled.div<{$isVisibleForMobile?: boolean; $width: number}>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: ${({$width}) => px($width)};
    height: 100vh;
    box-sizing: border-box;
    font-size: ${tokens.fontSmall};
    flex-direction: column;
    background-color: ${tokens.colorBackgroundStaticLoudest};
    transition: ${`all ${tokens.smoothInOut}`};
    z-index: ${tokens.zIndexOverlay};

    @media (max-width: ${breakpoints.tabletMedUp}) {
        top: ${navigationOffset};
        left: calc(-1 * ${breakpoints.tabletMedUp});
        height: calc(100vh - ${navigationOffset});
        background-color: ${tokens.colorBackgroundStatic};
        border: none;
        width: 100vw;

        ${(props) =>
            props.$isVisibleForMobile &&
            css`
                left: 0;
            `}
    }
`

const NavigationAvatarWrapper = styled.div`
    margin-top: auto;
    margin-bottom: ${tokens.spacing12};
`
