import type {ReactElement} from 'react'

import {useFlags} from '@product-web/shared--flags'

import {
    OVERDRAFT_MODAL,
    OVERDRAFT_PROMOTIONAL_BANNER,
    OverdraftAwarenessFlow,
} from './promotions/overdraft-awareness-flow'
import {
    SPEND_ACTIVATION_GUIDE_BANNER_KEY,
    SPEND_ACTIVATION_GUIDE_BANNER_MODAL_KEY,
    SpendActivationGuide,
} from './promotions/spend-activation-guide'

export enum NavigationPromotionalBanners {
    OVERDRAFT = 'overdraft-awareness',
    SPEND_ACTIVATION_GUIDE = 'spend-activation-guide',
    UPGRADE = 'upgrade-from-starter',
}

const promotionalBannerMap: {[key in NavigationPromotionalBanners]?: ReactElement} = {
    [NavigationPromotionalBanners.OVERDRAFT]: <OverdraftAwarenessFlow />,
    [NavigationPromotionalBanners.SPEND_ACTIVATION_GUIDE]: <SpendActivationGuide />,
}

const useShowOverdraft = (): boolean => {
    const {overdraftAwarenessFlow: flagEnabled} = useFlags()

    if (!flagEnabled) {
        return false
    }

    const isBannerDismissed = localStorage.getItem(OVERDRAFT_PROMOTIONAL_BANNER) === 'dismissed'
    const isModalDismissed = localStorage.getItem(OVERDRAFT_MODAL) === 'dismissed'

    return !(isBannerDismissed && isModalDismissed)
}

/**
 * The Spend Activation Guide has a lot of BFF calls inside to determine whether to show the guide or not
 * To avoid constantly make those calls, we will check if the user has already dismissed the banner and modal
 */
const useShowSpendActivationGuide = (): boolean => {
    const isBannerDismissed =
        localStorage.getItem(SPEND_ACTIVATION_GUIDE_BANNER_KEY) === 'dismissed'
    const isModalDismissed =
        localStorage.getItem(SPEND_ACTIVATION_GUIDE_BANNER_MODAL_KEY) === 'dismissed'

    return !(isBannerDismissed && isModalDismissed)
}

/**
 * Prioritize showing Overdraft banner over Spend Activation Guide
 */
export const ActivePromotionalBanner = (): ReactElement | null => {
    const showOverdraft = useShowOverdraft()
    const showSpendActivationGuide = useShowSpendActivationGuide()

    if (showOverdraft) {
        return promotionalBannerMap[NavigationPromotionalBanners.OVERDRAFT] || null
    }

    if (showSpendActivationGuide) {
        return promotionalBannerMap[NavigationPromotionalBanners.SPEND_ACTIVATION_GUIDE] || null
    }

    return null
}
