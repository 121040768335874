import {t} from '@lingui/macro'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {Switch} from '@pleo-io/telescope-pictograms'

import {reportError} from '@product-web/shared--error/report'
import {useMobileNavigationVisibility} from '@product-web/shared--navigation/navigation-context'
import {ProgressCard} from '@product-web/sub-app--recurring-vendors/components/spend-activation-guide/progress-card'
import {RecurringSpendAnnouncement} from '@product-web/sub-app--recurring-vendors/components/spend-activation-guide/progress-card/announcement-modal'
import {useSpendActivationGuide} from '@product-web/sub-app--recurring-vendors/components/spend-activation-guide/use-spend-activation-guide'
import useSpendActivationGuideProgress from '@product-web/sub-app--recurring-vendors/components/spend-activation-guide/use-spend-activation-guide-progress'

import {NavigationPromotionalBanner} from '../navigation-promotional-banner'

export const SPEND_ACTIVATION_GUIDE_BANNER_KEY = 'recurring-spend-card'
export const SPEND_ACTIVATION_GUIDE_BANNER_MODAL_KEY = 'recurring-spend-announcement-modal'

/**
 * The Spend Activation Guide Banner promotes recurring spend features.
 *
 * It manages the display of:
 *
 * 1. Announcement Modal (shown first when an Admin loads the app)
 *    - If dismissed, the promotional-banner appears.
 *    - If clicked, the modal reopens.
 * 2. Promotional Banner (displays if the modal was dismissed)
 *    - If dismissed, it will never show again.
 * 3. Progress Card (shown after the Admin completes the modal flow and starts the setup guide)
 *    - Remains visible until the guide is either completed or skipped.
 */
export const SpendActivationGuide = () => {
    const isAnnouncementModalFirstView =
        localStorage.getItem(SPEND_ACTIVATION_GUIDE_BANNER_MODAL_KEY) === null
    const [shouldShowModal, setShouldShowModal] = useState(isAnnouncementModalFirstView)
    const [guideStarting, setGuideStarting] = useState(false)

    const navigate = useNavigate()
    const {setIsMobileNavigationVisible} = useMobileNavigationVisibility()

    const isBannerDismissed =
        localStorage.getItem(SPEND_ACTIVATION_GUIDE_BANNER_KEY) === 'dismissed'

    const {isEnabled = false} = useSpendActivationGuide()

    const {
        isLoading: isProgressLoading,
        progress: {percent, allCompleted},
        isStarted,
        isSkipped,
    } = useSpendActivationGuideProgress({enabled: isEnabled})

    const showNothing =
        !isEnabled ||
        isBannerDismissed ||
        allCompleted ||
        isSkipped ||
        isProgressLoading ||
        guideStarting

    useEffect(() => {
        if (isStarted && !isProgressLoading) {
            setGuideStarting(false)
        }
    }, [isStarted, isProgressLoading])

    if (showNothing) {
        return null
    }

    const showModal = shouldShowModal && !isStarted
    const showBanner = isEnabled && !isStarted && !isSkipped
    const showGuideProgress = isStarted && !isProgressLoading

    const goToGuide = () => {
        if (!isStarted) {
            setGuideStarting(true)
        }
        setIsMobileNavigationVisible(false)
        navigate('/recurring-vendors/setup-guide')
    }

    const handleCloseBanner = () => {
        localStorage.setItem(SPEND_ACTIVATION_GUIDE_BANNER_KEY, 'dismissed')
    }

    const handleCloseModal = (show: boolean) => {
        if (!show && isAnnouncementModalFirstView) {
            try {
                localStorage.setItem(SPEND_ACTIVATION_GUIDE_BANNER_MODAL_KEY, 'dismissed')
            } catch (error) {
                reportError(
                    error,
                    `Could not set local storage key ${SPEND_ACTIVATION_GUIDE_BANNER_MODAL_KEY}`,
                )
            }
        }
        setShouldShowModal(show)
    }

    if (showModal) {
        return (
            <RecurringSpendAnnouncement
                isOpen={showModal}
                setShowModal={handleCloseModal}
                goToGuide={goToGuide}
            />
        )
    }

    if (showGuideProgress) {
        return <ProgressCard percent={percent} goToSetupGuide={goToGuide} />
    }

    if (showBanner) {
        return (
            <NavigationPromotionalBanner
                pictogram={<Switch size="48" />}
                onClick={() => setShouldShowModal(true)}
                onClose={handleCloseBanner}
                description={t`Activate smart features for your recurring expenses`}
                tooltipText={t`Close spend activation guide banner`}
                linkText={t`See how it works`}
                testId="spend-activation-promotional-banner"
            />
        )
    }

    return null
}
