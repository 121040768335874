import {Plural} from '@lingui/macro'
import type {ReactElement, ReactNode} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import styled from 'styled-components'

import type {StackProps} from '@pleo-io/telescope'
import {Inline, NakedButton, Stack, Text, tokens} from '@pleo-io/telescope'

interface CourseSummaryProps {
    children: Array<ReactElement<CourseItemProps> | false>
}

export const CourseSummary = ({children}: CourseSummaryProps) => <Container>{children}</Container>

interface CourseItemProps {
    ariaLabel: string
    path: string
    children: ReactNode
}

const CourseItem = ({ariaLabel, path, children}: CourseItemProps) => (
    <StyledCourseItem key={ariaLabel} aria-label={ariaLabel} as={RouterLink} to={path}>
        <Inline alignItems="center" m={24} space={16}>
            <StepCount />
            {children}
        </Inline>
    </StyledCourseItem>
)

const Minutes = ({minutes}: {minutes: number}) => (
    <Text color="colorContentStaticQuiet">
        <Plural value={minutes} one="# min" other="# mins" />
    </Text>
)
const Container = styled(Stack).attrs<StackProps>({justifyItems: 'stretch', my: 40})`
    counter-reset: step-counter;
    width: 100%;
    background: ${tokens.colorBackgroundStatic};
`

const StyledCourseItem = styled(NakedButton)`
    counter-increment: step-counter;
    border-top: ${tokens.borderStatic};
    border-left: ${tokens.borderStatic};
    border-right: ${tokens.borderStatic};
    border-radius: 0;

    &:first-child {
        border-top-left-radius: ${tokens.arc12};
        border-top-right-radius: ${tokens.arc12};
    }

    &:last-child {
        border-bottom: ${tokens.borderStatic};
        border-bottom-left-radius: ${tokens.arc12};
        border-bottom-right-radius: ${tokens.arc12};
    }

    transition: background-color ${tokens.smoothInOut};

    &:hover {
        background-color: ${tokens.colorBackgroundInteractiveHover};
        color: ${tokens.colorContentInteractiveHover};
    }
`
const StepCount = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${tokens.spacing20};
    height: ${tokens.spacing20};
    padding: 0 ${tokens.spacing8};
    font-size: ${tokens.fontSmall};
    border-radius: ${tokens.arc99999};
    border: ${tokens.borderInteractiveSelected};
    white-space: nowrap;
    user-select: none;

    &::before {
        content: counter(step-counter);
    }
`

CourseSummary.Item = CourseItem
CourseSummary.Minutes = Minutes
CourseSummary.Title = styled(Text).attrs({as: 'h4'})``
