import {Trans} from '@lingui/macro'
import {Field, Form, Formik} from 'formik'
import {type Dispatch, type MouseEvent, type SetStateAction, useEffect, useState} from 'react'

import {Box, Button, Callout, FormControl, RadioButton, Stack, Text} from '@pleo-io/telescope'
import {Attention, HighFive} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/shared--analytics'
import yup from '@product-web/shared--validation/yup'

export const LOCAL_STORAGE_QUIZ_PREFIX = '@pleo/partner/academy/quiz/'

const quizOptions = ['A', 'B', 'C', 'D', 'E', 'F']

/**
 * We ensure that each question has at least 2 and at most 6 answers
 * so we can assign a value to each answer in the form
 */
type MinTwoAndMaxSixItems<T> = [T, T, T?, T?, T?, T?]
type QuizOption = (typeof quizOptions)[number]
interface FormValues {
    [key: `question${number}`]: string
}
interface QuizAnswer {
    label: string
    isCorrect?: true
}
export interface QuizQuestion {
    scenario?: string
    question: string
    answers: MinTwoAndMaxSixItems<QuizAnswer>
    explanation?: string
}
interface Answer extends QuizAnswer {
    value: QuizOption
}
interface Question extends QuizQuestion {
    key: keyof FormValues
    answers: MinTwoAndMaxSixItems<Answer>
}
interface Props {
    quizKey: string
    quizQuestions: QuizQuestion[]
    setCanContinue?: Dispatch<SetStateAction<boolean>>
}

/**
 * @param quizKey Used to identify quiz completion in tracking events and local storage
 * @param quizQuestions List of questions to be displayed in the quiz
 * @param setCanContinue Set state action for the parent to allow the user to continue when quiz is completed successfully
 */
export const QuizForm = ({quizKey, quizQuestions, setCanContinue}: Props) => {
    const LOCAL_STORAGE_KEY = LOCAL_STORAGE_QUIZ_PREFIX + quizKey
    const [hasCompletedQuiz, setHasCompletedQuiz] = useState(
        localStorage.getItem(LOCAL_STORAGE_KEY) === 'true',
    )

    useEffect(() => {
        if (hasCompletedQuiz) {
            setCanContinue?.(true)
        }
    }, [hasCompletedQuiz, setCanContinue])

    const questions: Question[] = quizQuestions.map(({answers, ...question}, index) => ({
        ...question,
        key: `question${index + 1}`,
        answers: answers.map((answer, i) => ({
            ...answer,
            value: quizOptions[i],
        })) as Question['answers'],
    }))

    const initialValues: FormValues = questions.reduce((acc, question) => {
        const value = hasCompletedQuiz
            ? question.answers.find((answer) => answer?.isCorrect)?.value || ''
            : ''
        return {...acc, [question.key]: value}
    }, {})

    const validationSchema = yup.object().shape(
        questions.reduce(
            (acc, {key, answers}) => ({
                ...acc,
                [key]: yup
                    .string()
                    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                    .required('missing answer')
                    .test(
                        `incoreect-answer-${key}`,
                        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                        'incorrect answer',
                        (value) => value === answers.find((answer) => answer?.isCorrect)?.value,
                    ),
            }),
            {},
        ),
    )

    return (
        <Formik
            initialValues={initialValues}
            initialStatus={hasCompletedQuiz ? 'correct' : 'initial'}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
            validationSchema={validationSchema}
            onSubmit={() => {}}
        >
            {(props) => {
                const {
                    errors,
                    status,
                    touched,
                    values,
                    resetForm,
                    setFieldError,
                    setFieldTouched,
                    setFieldValue,
                    validateForm,
                } = props

                const handleChange = (key: keyof FormValues, value: string) => {
                    setFieldValue(key, value)
                    setFieldError(key, undefined)
                    setFieldTouched(key, true, false)
                }

                const checkAnswers = async (e: MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault()
                    const validationErrors = await validateForm()
                    const hasErrors = Object.keys(validationErrors).length > 0
                    const newStatus = !hasErrors ? 'correct' : 'error'

                    resetForm({
                        errors: validationErrors,
                        status: newStatus,
                        values,
                        touched: {},
                    })

                    if (newStatus === 'correct') {
                        setHasCompletedQuiz(true)
                        tracking.partnerAcademy({action: 'quiz_completed', quiz: quizKey})
                        localStorage.setItem(LOCAL_STORAGE_KEY, 'true')
                    }
                }
                return (
                    <Form>
                        <Stack space={32}>
                            {questions.map(
                                ({key, scenario, question, explanation, answers}, index) => {
                                    const error = errors[key]
                                    const isInitial = status === 'initial'
                                    const isCorrect =
                                        status === 'correct' ||
                                        (!isInitial && !error && !touched[key])
                                    const totalQuestions = questions.length

                                    return (
                                        <Field as="radiogroup" key={key} name={key}>
                                            <FormControl>
                                                <FormControl.Label>
                                                    <Stack space={16}>
                                                        <Text
                                                            as="h6"
                                                            weight="semibold"
                                                            variant="large-accent"
                                                        >
                                                            <Trans>
                                                                Question {index + 1} of{' '}
                                                                {totalQuestions}
                                                            </Trans>
                                                        </Text>
                                                        {scenario && <Text>{scenario}</Text>}
                                                        <Text weight="semibold">{question}</Text>
                                                    </Stack>
                                                </FormControl.Label>
                                                <FormControl.RadioGroup
                                                    id={key}
                                                    defaultValue={values[key]}
                                                    onValueChange={(value) =>
                                                        handleChange(key, value)
                                                    }
                                                >
                                                    {answers.map(
                                                        (answer) =>
                                                            answer && (
                                                                <RadioButton
                                                                    disabled={isCorrect}
                                                                    key={answer.label}
                                                                    value={answer.value}
                                                                    label={answer.label}
                                                                />
                                                            ),
                                                    )}
                                                </FormControl.RadioGroup>
                                                <Box pt={4} />
                                                {(!!error || isCorrect) && (
                                                    <FeedbackCallout
                                                        explanation={explanation}
                                                        isCorrect={isCorrect}
                                                    />
                                                )}
                                            </FormControl>
                                        </Field>
                                    )
                                },
                            )}
                            <Button variant="secondary" onClick={checkAnswers}>
                                <Trans>Check answers</Trans>
                            </Button>
                        </Stack>
                    </Form>
                )
            }}
        </Formik>
    )
}

const FeedbackCallout = ({explanation, isCorrect}: {explanation?: string; isCorrect: boolean}) => (
    <Callout variant={isCorrect ? 'positive' : 'warning'} Icon={isCorrect ? HighFive : Attention}>
        <Callout.Content>
            <Callout.ContentHeading>
                {isCorrect ? <Trans>Correct answer</Trans> : <Trans>Please try again</Trans>}
            </Callout.ContentHeading>
            {explanation && <Callout.Text>{explanation}</Callout.Text>}
        </Callout.Content>
    </Callout>
)
