import * as React from 'react'
import {Navigate, Route} from 'react-router-dom'

import {roleSets} from '@product-web/shared--user'

const PurchaseOrdersPage = React.lazy(async () => import('./components/purchase-orders-page'))
const EditPurchaseOrder = React.lazy(async () => import('./screens/details/edit-purchase-order'))
const PurchaseOrders = React.lazy(async () => import('./screens/dashboard/purchase-orders'))
const CreatePurchaseOrder = React.lazy(
    async () => import('./screens/details/create-purchase-order'),
)
const PurchaseOrderReviewRequestWrapper = React.lazy(
    async () => import('./screens/details/purchase-order-review-request-wrapper'),
)

export const routes = (
    <Route
        element={<PurchaseOrdersPage />}
        handle={{auth: true, allowedRoles: roleSets.companyOnly}}
    >
        <Route index element={<Navigate to="dashboard" replace />} />
        <Route path="dashboard" element={<PurchaseOrders />} />
        <Route path="create" element={<CreatePurchaseOrder />} />
        <Route path="edit/:purchaseOrderId" element={<EditPurchaseOrder />} />
        <Route path="review/:purchaseOrderId" element={<PurchaseOrderReviewRequestWrapper />} />
    </Route>
)
