import {reportError} from '@product-web/shared--error/report'

import type {HubspotConsentPreferences} from './api.types'

import {updateGtagConsent} from '../analytics/analytics'
import type {ConsentParams, GtagConsentValue} from '../analytics/analytics.types'

// Map boolean consent values to 'granted' | 'denied'
const mapToConsentStatus = (hasGrantedConsent: boolean): GtagConsentValue => {
    return hasGrantedConsent ? 'granted' : 'denied'
}

/**
 * Maps the consent preferences from Hubspot to our gtag consent
 *
 *  Read more here:
 *  https://developers.hubspot.com/beta-docs/reference/api/analytics-and-events/cookie-banner/cookie-banner-api
 */
const mapHubspotConsentToGtag = ({
    categories: {advertisement, analytics, functionality, necessary},
}: HubspotConsentPreferences): ConsentParams => {
    return {
        // Consent for personalized advertising
        ad_personalization: mapToConsentStatus(advertisement),

        // Consent for sending user data related to advertising
        ad_user_data: mapToConsentStatus(advertisement),

        // Enables storage related to advertising
        ad_storage: mapToConsentStatus(advertisement),

        // Enables storage related to analytics (e.g., visit duration)
        analytics_storage: mapToConsentStatus(analytics),

        // Enables storage that supports website/app functionality (e.g., language settings)
        functionality_storage: mapToConsentStatus(functionality),

        // Enables storage related to personalization (e.g., video recommendations)
        personalization_storage: mapToConsentStatus(functionality),

        // Enables storage related to security (e.g., authentication, fraud prevention)
        security_storage: mapToConsentStatus(necessary),
    } satisfies ConsentParams
}

/**
 * Applies the consent preferences from Hubspot to our gtag consent
 *
 * @param consentPreferences - The consent preferences from Hubspot
 */
export function updateMappedGtagConsent(consentPreferences: HubspotConsentPreferences) {
    try {
        // Map Hubspot consent preferences to gtag consent parameters
        const mappedConsentParams = mapHubspotConsentToGtag(consentPreferences)

        // Update Gtag consent preferences
        updateGtagConsent(mappedConsentParams)
    } catch (error) {
        reportError(error)
    }
}
