import Portal from '@reach/portal'
import React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

export const ProductPanelsPortal = React.forwardRef<HTMLDivElement, {children: React.ReactNode}>(
    (props, ref) => {
        return (
            <Portal>
                <RightBottomCorner ref={ref} {...props}>
                    {props.children}
                </RightBottomCorner>
            </Portal>
        )
    },
)

// eslint-disable-next-line string-to-lingui/missing-lingui-transformation
ProductPanelsPortal.displayName = 'ProductPanelsPortal'

const RightBottomCorner = styled.div`
    position: fixed;
    right: ${tokens.spacing20};
    bottom: ${tokens.spacing20};
    transition: ${tokens.smoothInOut};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`
