import {Plural, plural, t, Trans} from '@lingui/macro'
import {Link as RouterLink} from 'react-router-dom'
import styled from 'styled-components'

import {
    Card,
    Inline,
    Link,
    ProgressBar,
    Skeleton,
    Stack,
    Text,
    tokens,
    Tooltip,
} from '@pleo-io/telescope'

import {dayjs} from '@product-web/shared--dates/dayjs'

import {bff} from '../../../../bff-hooks'
import {GenericError} from '../../../../components/generic-error'
import type {PartnerTierType} from '../../../../lib/partner-tier'
import {PartnerTierBadge} from '../partner-tier-badge'

export const PartnerTierCard = () => {
    const {data, isLoading, isError} = bff.partnerTierCard.getPartnerTierCard.useQuery()

    if (isLoading) {
        return <PartnerTierCardSkeleton />
    }

    if (isError) {
        return <GenericError />
    }

    const onboardingPeriodEnd = dayjs(data.onboardingPeriodEnd).format('DD MMMM YYYY')
    const partnershipYearStart = dayjs(data.partnershipYearStart).format('DD.MM.YYYY')
    const partnershipYearEnd = dayjs(data.partnershipYearEnd).format('DD.MM.YYYY')

    return (
        <Card>
            <CardHeader partnerTier={data.isDuringOnboarding ? 'TRIAL' : data.currentTierType}>
                <Inline alignItems="center" justifyContent="space-between">
                    {data.isDuringOnboarding ? (
                        <>
                            <Stack>
                                <Text variant="2xlarge-accent">
                                    <Trans>Welcome to your onboarding period</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Trial ends on {onboardingPeriodEnd}. Your tier will be
                                        unlocked based on your referrals.
                                    </Trans>
                                </Text>
                            </Stack>
                            <PartnerTierBadge tier="TRIAL" />
                        </>
                    ) : (
                        <>
                            <Stack>
                                <Text variant="2xlarge-accent">
                                    <Trans>
                                        {partnerTiersNames()[data.currentTierType]} partner
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Partnership year {partnershipYearStart} -{' '}
                                        {partnershipYearEnd}
                                    </Trans>
                                </Text>
                            </Stack>
                            <PartnerTierBadge tier={data.currentTierType} />
                        </>
                    )}
                </Inline>
            </CardHeader>
            <Card.Divider />
            <Card.Body>
                <Stack stretch>
                    <Inline mt={8} justifyContent="space-between" alignItems="center">
                        <Text variant="large-accent" space={10}>
                            <Plural
                                one={`# paid user referred`}
                                other={`# paid users referred`}
                                value={data.paidUsersReferred}
                            />
                        </Text>
                        <Text variant="small-subtle">
                            <Trans>{data.usersToNextTier} remaining</Trans>
                        </Text>
                    </Inline>
                    <Tooltip
                        content={getTooltipContent(
                            data.usersToNextTier,
                            data.clientsToNextTier,
                            data.nextTierType,
                        )}
                    >
                        <ProgressBar
                            aria-label={t`Partner Program Tier progression percentage`}
                            variant="slim"
                            percent={data.tierProgressPercentage}
                            bgColor="shade300"
                            fillColor="shade900"
                        />
                    </Tooltip>
                    <Inline mt={8} justifyContent="space-between">
                        <Text variant="small-subtle">
                            {partnerTiersNames()[data.currentTierType]}
                        </Text>
                        {data.nextTierType && (
                            <Text variant="small-subtle">
                                {partnerTiersNames()[data.nextTierType]}
                            </Text>
                        )}
                    </Inline>
                </Stack>
            </Card.Body>
            <Card.Divider />
            <Card.Footer hasSpacingTop>
                <Inline alignContent="center" justifyContent="center">
                    <Link as={RouterLink} to="?showTiersModal=true">
                        <Trans>See benefits for each tier</Trans>
                    </Link>
                </Inline>
            </Card.Footer>
        </Card>
    )
}

function PartnerTierCardSkeleton() {
    return (
        <Card>
            <Card.Header hasSpacingBottom>
                <Inline stretchChildren>
                    <Skeleton borderRadius={8} height="55px" />
                </Inline>
            </Card.Header>
            <Card.Divider />
            <Card.Body>
                <Inline stretchChildren>
                    <Skeleton borderRadius={8} height="80px" />
                </Inline>
            </Card.Body>
            <Card.Divider />
            <Card.Footer hasSpacingTop>
                <Inline stretchChildren>
                    <Skeleton borderRadius={8} height="20px" />
                </Inline>
            </Card.Footer>
        </Card>
    )
}

function partnerTiersNames(): Record<PartnerTierType, string> {
    return {
        TRIAL: t`New partner`,
        BRONZE: t`Bronze`,
        SILVER: t`Silver`,
        GOLD: t`Gold`,
        GOLD_DACH: t`Gold`,
        PLATINUM: t`Platinum`,
        PLATINUM_DACH: t`Platinum`,
    }
}

function getTooltipContent(
    usersToNextTier: number,
    clientsToNextTier: number,
    nextTier?: PartnerTierType,
) {
    if (nextTier) {
        const tierName = partnerTiersNames()[nextTier]
        if (usersToNextTier <= 0) {
            return plural(clientsToNextTier, {
                one: `Refer # more client to unlock your ${tierName} benefits`,
                other: `Refer # more clients to unlock your ${tierName} benefits`,
            })
        } else if (usersToNextTier > 0 && clientsToNextTier > 0) {
            return plural(clientsToNextTier, {
                one: plural(usersToNextTier, {
                    one: `Refer 1 paid user and 1 more client to unlock your ${tierName} benefits`,
                    other: `Refer ${usersToNextTier} paid users and 1 more client to unlock your ${tierName} benefits`,
                }),
                other: plural(usersToNextTier, {
                    one: `Refer 1 paid user and ${clientsToNextTier} more clients to unlock your ${tierName} benefits`,
                    other: `Refer ${usersToNextTier} paid users and ${clientsToNextTier} more clients to unlock your ${tierName} benefits`,
                }),
            })
        } else if (clientsToNextTier <= 0) {
            return plural(usersToNextTier, {
                one: `Refer # paid user to unlock your ${tierName} benefits`,
                other: `Refer # paid users to unlock your ${tierName} benefits`,
            })
        }
    }
    return t`Great job - you're officially top tier. Feels good, doesn't it?`
}

const tierColors: Record<PartnerTierType, string> = {
    TRIAL: tokens.colorBackgroundPresentationalPink,
    BRONZE: 'rgba(245, 233, 230, 1)',
    SILVER: '#f5f5f5',
    GOLD: '#fef6cd',
    GOLD_DACH: '#fef6cd',
    PLATINUM: 'rgba(226, 227, 229, 1)',
    PLATINUM_DACH: 'rgba(226, 227, 229, 1)',
}

const CardHeader = styled(Card.Header).attrs({hasSpacingBottom: true})<{
    partnerTier: PartnerTierType
}>`
    background-color: ${({partnerTier}) => tierColors[partnerTier]};
`
