import {t} from '@lingui/macro'

import {PlanTypeName} from '@product-web/shared--plan-presentation/pricing-plans.helper'
import {exhaustiveCheck} from '@product-web/shared--utils'
import type {CpqRatePlanType} from '@shared/bff--moons/generated/beyond'

import type {WizardStep} from '../components/regular-flow-wizard'

type getWizardHeadingArgs = {
    step: WizardStep
    selectedPlanType: {planType: CpqRatePlanType; isUpgrade: boolean} | null
    isTrialLastDay: boolean
    isTrialAlmostOver: boolean
    isLegacyPlan: boolean
    hasUserPickedTrialPlanFlag: boolean
    isBeforeTrial: boolean
}

export const getWizardHeading = ({
    step,
    selectedPlanType,
    isTrialLastDay,
    isTrialAlmostOver,
    isLegacyPlan,
    hasUserPickedTrialPlanFlag,
    isBeforeTrial,
}: getWizardHeadingArgs): string => {
    switch (step) {
        case 'plans':
            if (isTrialLastDay) {
                return t`Your free trial has ended`
            } else if (isTrialAlmostOver) {
                return t`Your free trial is almost over...`
            }

            return t`Select a new plan`
        case 'changes': {
            // We should always have a selected plan here, but we check just in case and to narrow the type
            if (!selectedPlanType) {
                return t`Select plan`
            }

            const planName = PlanTypeName[selectedPlanType.planType]

            if (hasUserPickedTrialPlanFlag && isBeforeTrial && selectedPlanType.isUpgrade) {
                return t`Select ${planName}`
            }

            return selectedPlanType.isUpgrade || isLegacyPlan
                ? t`Upgrade to ${planName}`
                : t`Downgrade to ${planName}`
        }
        case 'done':
            return t`Done`
        default:
            return exhaustiveCheck(step)
    }
}
