import {Suspense} from 'react'
import {Outlet} from 'react-router'

import {LoadingPage} from '@pleo-io/telescope'

import {AdoptionPromptProvider} from '@product-web/feature--help-centre/adoption-prompt-context'
import {HelpCentreProvider} from '@product-web/feature--help-centre/help-centre-context'
import {HubspotConsentProvider} from '@product-web/shared--consent/hubspot'
import {LaunchDarklyProvider} from '@product-web/shared--flags'
import {StatsigProviderWrapper} from '@product-web/shared--gates'
import {NavigationProvider} from '@product-web/shared--navigation/navigation-context'
import {CustomColorSchemeTokensGlobalStyle} from '@product-web/shared--styles/custom-tokens'
import {ToasterProvider} from '@product-web/shared--toaster'
import {UserProvider} from '@product-web/shared--user'
import {AsyncExportProvider} from '@product-web/sub-app--export/components/async-export/async-export-context'
import {AsyncVendorSyncProvider} from '@product-web/sub-app--export/components/vendor-status/vendor-status-context'

import {OauthProvider} from './auth/oauth/provider'
import {SessionProvider} from './auth/session/provider'
import {AuthRouteProvider} from './auth-route'
import {BffProvider} from './bff-provider'
import {ErrorBoundary} from './error/error-boundary'
import {useEnhanceSentry} from './error/sentry'
import {useEnhancedGrafanaFaro} from './grafana'
import {I18nProvider} from './i18n/i18n-loader'
import {BaseLayout} from './layout'
import {StyledComponentsProviders} from './styled-components'
import {TelescopeProvider, TelescopeTranslationsLoader} from './telescope-provider'

export function Providers() {
    return (
        <StyledComponentsProviders>
            <CustomColorSchemeTokensGlobalStyle />
            <TelescopeProvider>
                <BaseLayout>
                    <ErrorBoundary>
                        <SessionProvider>
                            <BffProvider>
                                <LibrarySetup />
                                <StatsigProviderWrapper>
                                    <LaunchDarklyProvider>
                                        <OauthProvider>
                                            <UserProvider>
                                                <I18nProvider>
                                                    <TelescopeTranslationsLoader>
                                                        <NavigationProvider>
                                                            <ToasterProvider>
                                                                <HelpCentreProvider>
                                                                    <AdoptionPromptProvider>
                                                                        <Suspense
                                                                            fallback={
                                                                                <LoadingPage />
                                                                            }
                                                                        >
                                                                            <AuthRouteProvider>
                                                                                <AsyncExportProvider>
                                                                                    <AsyncVendorSyncProvider>
                                                                                        <HubspotConsentProvider>
                                                                                            <Outlet />
                                                                                        </HubspotConsentProvider>
                                                                                    </AsyncVendorSyncProvider>
                                                                                </AsyncExportProvider>
                                                                            </AuthRouteProvider>
                                                                        </Suspense>
                                                                    </AdoptionPromptProvider>
                                                                </HelpCentreProvider>
                                                            </ToasterProvider>
                                                        </NavigationProvider>
                                                    </TelescopeTranslationsLoader>
                                                </I18nProvider>
                                            </UserProvider>
                                        </OauthProvider>
                                    </LaunchDarklyProvider>
                                </StatsigProviderWrapper>
                            </BffProvider>
                        </SessionProvider>
                    </ErrorBoundary>
                </BaseLayout>
            </TelescopeProvider>
        </StyledComponentsProviders>
    )
}

function LibrarySetup() {
    useEnhanceSentry()
    useEnhancedGrafanaFaro()
    return null
}
