import type {PropsWithChildren, ReactElement} from 'react'
import {useEffect} from 'react'
import {createPortal} from 'react-dom'
import styled from 'styled-components'

import {Button, ButtonGroup, Card, NakedButton, px, Stack, Text, tokens} from '@pleo-io/telescope'

import * as tracking from '@product-web/shared--analytics'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {useAdoptionPrompt} from './adoption-prompt-context'
import {useHelpCentre} from './help-centre-context'

export type PromptSize = 'small' | 'medium'

export type AdoptionPromptProps = {
    /**
     * `name` is a unique identifier which is used to control
     * which one of active prompts is currently visible
     */
    name: string
    title: string | ReactElement
    text: string | ReactElement
    size: PromptSize
    imageSrc?: string
} & (
    | {
          dismissText: string
          onDismiss: () => void
      }
    | {
          dismissText?: undefined
          onDismiss?: undefined
      }
) &
    (
        | {
              completeText: string
              onComplete: () => void
          }
        | {
              completeText?: undefined
              onComplete?: undefined
          }
    )

const PROMPT_SIZES = {
    small: 230,
    medium: 290,
}

const IMAGE_SIZES = {
    small: 63,
    medium: 82,
}

/**
 * A reusable adoption prompt component that shows a popup
 * in the bottom right corner of the screen above the ? widget
 */
export const AdoptionPrompt = ({
    name,
    title,
    text,
    completeText,
    dismissText,
    imageSrc,
    onComplete,
    onDismiss,
    size,
}: PropsWithChildren<AdoptionPromptProps>) => {
    const {activePrompt, addActivePrompt, removeActivePrompt, portal} = useAdoptionPrompt()

    const isTabletMedUp = useMediaQuery(`(min-width: ${breakpoints.tabletMedUp})`)
    const {isHelpWidgetOpen} = useHelpCentre()

    const isActive = activePrompt?.name === name

    useEffect(() => {
        addActivePrompt({name})
        return () => {
            removeActivePrompt({name})
        }
    }, [addActivePrompt, name, removeActivePrompt])

    useEffect(() => {
        if (isActive) {
            tracking.adoptionPromptActioned({
                prompt_name: name,
                action: 'viewed',
            })
        }
    }, [isActive, name])

    const handleComplete = () => {
        tracking.adoptionPromptActioned({
            prompt_name: name,
            action: 'completed',
        })
        onComplete?.()
    }

    const handleDismiss = () => {
        tracking.adoptionPromptActioned({
            prompt_name: name,
            action: 'dismissed',
        })
        onDismiss?.()
    }

    if (!isTabletMedUp || isHelpWidgetOpen || !portal) {
        return null
    }

    return createPortal(
        <AdoptionPromptPortalWrapper>
            <StyledCard size={size} data-testid="adoption-prompt" space={12} p={16}>
                {imageSrc && <HeadingImage src={imageSrc} size={size} />}

                <Stack space={4}>
                    <Text as="h5" color="colorContentStatic" variant="large-accent">
                        {title}
                    </Text>

                    <Text color="colorContentStaticQuiet" variant="small-subtle">
                        {text}
                    </Text>
                </Stack>

                {(completeText || dismissText) && (
                    <ButtonGroup>
                        {completeText && (
                            <Button variant="primary" onClick={handleComplete}>
                                {completeText}
                            </Button>
                        )}
                        {dismissText && (
                            <DismissActionButton onClick={handleDismiss}>
                                {dismissText}
                            </DismissActionButton>
                        )}
                    </ButtonGroup>
                )}
            </StyledCard>
        </AdoptionPromptPortalWrapper>,
        portal,
    )
}

const AdoptionPromptPortalWrapper = styled.div`
    position: fixed;
    right: ${tokens.spacing20};
    bottom: 104px;
    transition: ${tokens.smoothInOut};
`

const StyledCard = styled(Card)<{size: PromptSize}>`
    box-shadow: ${tokens.shadowElevate};
    max-width: ${({size}) => px(PROMPT_SIZES[size])};
    animation: fadeInLeft ${tokens.slowOut};

    @keyframes fadeInLeft {
        from {
            transform: translateX(100%);
        }

        to {
            transform: translateX(0%);
        }
    }
`

const HeadingImage = styled.img<{size: PromptSize}>`
    width: 100%;
    height: ${({size}) => IMAGE_SIZES[size]}px;
`

const DismissActionButton = styled(NakedButton)`
    font-size: ${tokens.fontMedium};
    color: ${tokens.colorContentInteractiveQuiet};
`
