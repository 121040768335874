import {t, Trans} from '@lingui/macro'
import {useState} from 'react'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Button, Inline, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft, ArrowRight, Clock} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'

import {ModuleOverview} from './module-overview'

import {SplitContent} from '../../../../../components/split-content'
import {QuizForm, type QuizQuestion} from '../../../components/quiz-form/quiz-form'

export const Quiz = () => {
    const [canContinue, setCanContinue] = useState(false)

    const questions: QuizQuestion[] = [
        {
            answers: [
                {label: t`It eliminates the need for real-time visibility into spending.`},
                {
                    isCorrect: true,
                    label: t`It reduces errors, streamlines workflows, and saves time by consolidating tools.`,
                },
                {label: t`It replaces the need for compliance processes and approvals.`},
                {label: t`It focuses only on automating reimbursements.`},
            ],
            explanation: t`Centralising spend management simplifies operations, reduces duplicate efforts, and gives teams time back to focus on high-value tasks instead of manual admin work.`,
            question: t`How would you explain the importance of centralising spend management?`,
            scenario: t`A client uses multiple disconnected tools for spend management, which has resulted in duplicate entries, errors, and wasted time reconciling expenses. They ask why centralising their spend management matters.`,
        },
        {
            answers: [
                {
                    isCorrect: true,
                    label: t`Implement automated reconciliations to reduce errors and free up time for strategic tasks.`,
                },
                {
                    label: t`Make reconciliation a monthly task rather than a daily one to save time.`,
                },
                {label: t`Train employees to check transactions manually for accuracy.`},
                {label: t`Replace reconciliations entirely with manual spot-checking.`},
            ],
            explanation: t`Automating reconciliations eliminates repetitive tasks, reduces errors, and allows finance teams to focus on forecasting and budgeting instead of admin work.`,
            question: t`What would you recommend to solve their challenge, and why?`,
            scenario: t`A client tells you their finance team spends excessive time manually reconciling transactions at month-end, delaying financial reporting and impacting timely decision-making.`,
        },
        {
            answers: [
                {
                    isCorrect: true,
                    label: t`Poor data accuracy causes errors in reports, compliance risks, and wasted time. Automation enables real-time syncing and categorisation to maintain accurate records.`,
                },
                {
                    label: t`Poor data accuracy is a minor inconvenience that can be fixed manually. Automation helps reduce errors in some cases.`,
                },
                {label: t`Data accuracy isn't critical; automation is more about convenience.`},
                {
                    label: t`Inaccurate data impacts admin work only, but automation resolves this through monthly reporting.`,
                },
            ],
            explanation: t`Poor data accuracy disrupts reporting, wastes time correcting mistakes, and risks non-compliance. Automation enables accurate, up-to-date data, reducing errors and increases better decision-making.`,
            question: t`How would you explain the risks of poor data accuracy and the role of automation in mitigating them?`,
            scenario: t`A small business client is frustrated with errors in their financial reports caused by poor data accuracy. They're unsure if automating data categorisation will help.`,
        },
        {
            answers: [
                {label: t`They increased time spent on admin tasks to handle client needs faster.`},
                {label: t`They eliminated client referrals by focusing on internal efficiency.`},
                {
                    isCorrect: true,
                    label: t`They gained happier clients who referred new business and improved operational efficiency.`,
                },
                {label: t`They replaced approvals with a simpler manual process.`},
            ],
            explanation: t`Automating workflows saved Surrey Hills time, improved client satisfaction, and created opportunities for growth through referrals, demonstrating how efficiency drives business success.`,
            question: t`What broader impact did this have on their practice?`,
            scenario: t`Surrey Hills Accountancy saved time and grew their business by automating expense workflows with Pleo. They reduced manual reconciliation, improved data quality, and strengthened client satisfaction.`,
        },
    ]

    return (
        <>
            <Helmet>
                <title>{t`Quiz: Simplify Bookkeeping Workflow`}</title>
            </Helmet>
            <Page.Header css={{backgroundColor: tokens.colorBackgroundInteractive}}>
                <Page.Backlink>
                    <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                        <Trans>Back to overview</Trans>
                    </Link>
                </Page.Backlink>
                <Page.Title>
                    <Trans>Simplify Bookkeeping Workflow</Trans>
                </Page.Title>
                <Inline alignItems="center" space={6} mb={24}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>6 mins</Trans>
                    </Text>
                </Inline>
            </Page.Header>
            <Stack my={24} space={8}>
                <Text as="h2" variant="2xlarge-accent">
                    <Trans>Quiz</Trans>
                </Text>
                <Text variant="large-accent">
                    <Trans>
                        Complete questions to test your new knowledge. Try as many times as you
                        need.
                    </Trans>
                </Text>
            </Stack>
            <SplitContent>
                <SplitContent.Main flex={4}>
                    <QuizForm
                        quizKey="simplify_bookkeeping_workflow"
                        quizQuestions={questions}
                        setCanContinue={setCanContinue}
                    />
                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                            <Trans>Back</Trans>
                        </Link>
                        <Button
                            variant="primary"
                            as={RouterLink}
                            to="../../transform-client-finances"
                            disabled={!canContinue}
                            IconRight={ArrowRight}
                        >
                            <Trans>Continue</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <ModuleOverview />
                </SplitContent.Right>
            </SplitContent>
        </>
    )
}
