import {Outlet, Route} from 'react-router'

import {PageNotFound} from '@product-web/feature--ui-page-not-found/page-not-found'
import {roleSets} from '@product-web/shared--user'

import {Paywall} from './components/paywall'
import {APIKeysAPIContext, APIKeysContext} from './contexts/api-keys'
import {useAPIKeysBackend} from './hooks/use-api-keys-backend'
import {APIKeysCreateScreen, APIKeysIndexScreen} from './screens'

const WithAPIKeysContext = () => {
    const {data, actions} = useAPIKeysBackend()

    return (
        <APIKeysContext.Provider value={data}>
            <APIKeysAPIContext.Provider value={actions}>
                <Outlet />
            </APIKeysAPIContext.Provider>
        </APIKeysContext.Provider>
    )
}

export default (
    <Route
        handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
        element={
            <Paywall>
                <WithAPIKeysContext />
            </Paywall>
        }
    >
        <Route index element={<APIKeysIndexScreen />} />
        <Route path="create" element={<APIKeysCreateScreen />} />
        <Route path="*" element={<PageNotFound />} />
    </Route>
)
