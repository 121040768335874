import styled from 'styled-components'

import {px, tokens} from '@pleo-io/telescope'

interface Props {
    label: string
    flag: string
}

const Wrapper = styled.span`
    position: relative;

    & span {
        position: absolute;
        color: ${tokens.colorContentInteractive};
        font-size: ${px(10)};
        font-weight: ${tokens.fontWeightBold};
        letter-spacing: ${px(1)};
        line-height: ${tokens.lineHeight2};
        text-align: left;
        top: ${px(-6)};
        margin-left: ${tokens.spacing4};
    }
`

const FlaggedLabel = ({label, flag}: Props) => (
    <Wrapper>
        {label}
        <span>{flag.toUpperCase()}</span>
    </Wrapper>
)

export default FlaggedLabel
