import {t, Trans} from '@lingui/macro'
import * as React from 'react'
import Helmet from 'react-helmet'
import {Navigate, Outlet, Route, useNavigate} from 'react-router-dom'

import {Banner, Button, Link} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page/page'
import {getHelpCentreArticleLink} from '@product-web/shared--locale/helpers'

import {InviteClientContextProvider} from './components/invite-client-context'

const ReferralDetails = React.lazy(
    async () => import('./screens/referral-details/referral-details'),
)
const CompanyLookup = React.lazy(async () => import('./screens/company-lookup/company-lookup'))
const CompanyDetails = React.lazy(async () => import('./screens/company-details/company-details'))

export const routes = (
    <>
        <Route index element={<Navigate to="./referral-details" replace />} />
        <Route element={<InviteClientLayout />}>
            <Route path="referral-details" element={<ReferralDetails />} />
            <Route path="company-lookup" element={<CompanyLookup />} />
            <Route path="company-details" element={<CompanyDetails />} />
        </Route>
    </>
)

function InviteClientLayout() {
    const navigate = useNavigate()
    return (
        <>
            <Banner variant="info">
                <Banner.Content>
                    <Banner.Text>
                        <Trans>
                            Is your client already using Pleo? They must invite you as an external
                            bookkeeper, so the company shows in your Partner Portal.{' '}
                            <Link
                                inherit
                                target="_blank"
                                rel="noopener noreferrer"
                                href={getHelpCentreArticleLink(103000254861)}
                            >
                                Here is how.
                            </Link>
                        </Trans>
                    </Banner.Text>
                </Banner.Content>
            </Banner>
            <Page layout="wide" showNav>
                <Helmet>
                    <title>{t`Invite client`}</title>
                </Helmet>

                <Page.Header>
                    <Page.Backlink>
                        <Button
                            IconLeft={ArrowLeft}
                            onClick={() => navigate(-1)}
                            variant="tertiary"
                        >
                            <Trans>Back</Trans>
                        </Button>
                    </Page.Backlink>
                    <Page.Title>
                        <Trans>Invite client</Trans>
                    </Page.Title>
                </Page.Header>

                <InviteClientContextProvider>
                    <Outlet />
                </InviteClientContextProvider>
            </Page>
        </>
    )
}
