import {t} from '@lingui/macro'
import type {FC} from 'react'
import type {KnownTarget} from 'styled-components/dist/types'

import type {ExpenseViewType} from '@pleo-io/deimos'
import {AccountCategoryTypeKey, PurchaseType} from '@pleo-io/deimos'
import type {AvatarProps} from '@pleo-io/telescope'
import {Avatar, tokens} from '@pleo-io/telescope'
import {Car, CashSingle, CategoryTravel} from '@pleo-io/telescope-icons'

import {Categories, CategoryIcon} from '@product-web/feature--ui-account-categories/category-icon'

import {CashIcon} from '../cash-icon/cash-icon'

const AvatarComponent: FC<React.PropsWithChildren<{as?: KnownTarget} & AvatarProps>> = ({
    as,
    ...props
}) => {
    const Component = as || Avatar

    return <Component {...props} />
}

const MileageAvatar: FC<
    React.PropsWithChildren<{as?: KnownTarget; label: string} & AvatarProps>
> = ({size, label, children, ...props}) => (
    <AvatarComponent
        size={size}
        name={label}
        bg={tokens.colorBackgroundPresentationalGreen}
        icon={<Car />}
        data-testid="mileage-avatar"
        {...props}
    >
        {children}
    </AvatarComponent>
)

const PerDiemAvatar: FC<
    React.PropsWithChildren<{as?: KnownTarget; label: string} & AvatarProps>
> = ({size, label, children, ...props}) => (
    <AvatarComponent
        size={size}
        name={label}
        bg={tokens.colorBackgroundPresentationalPurple}
        icon={<CategoryTravel />}
        data-testid="perdiem-avatar"
        {...props}
    >
        {children}
    </AvatarComponent>
)

const ATMAvatar: FC<React.PropsWithChildren<{as?: KnownTarget; label: string} & AvatarProps>> = ({
    size,
    label,
    children,
    ...props
}) => (
    <AvatarComponent
        size={size}
        name={label}
        bg={tokens.colorBackgroundPresentationalGreen}
        icon={<CashSingle />}
        data-testid="perdiem-avatar"
        {...props}
    >
        {children}
    </AvatarComponent>
)

type ExpenseDefaultAvatarProps = {
    as?: KnownTarget
    label: string
    merchantId: string
    merchantName: string
    accountCategoryTypeKey?: AccountCategoryTypeKey | null
    expenseViewType?: ExpenseViewType | null
    accountId?: string | null
    showCategoryBadge?: boolean
    isPersonal: boolean
} & AvatarProps

export const ExpenseDefaultAvatar: FC<React.PropsWithChildren<ExpenseDefaultAvatarProps>> = ({
    size,
    label,
    accountCategoryTypeKey,
    expenseViewType,
    accountId,
    showCategoryBadge,
    isPersonal,
    children,
    ...props
}) => {
    const hasImageSource = !!props.src
    const showCategoryIcon = !hasImageSource
    const isUnknownCategory = accountId === 'no_suitable_account'
    const categoryTypeKeyToDisplay = isUnknownCategory
        ? AccountCategoryTypeKey.NO_SUITABLE_CATEGORY
        : accountCategoryTypeKey
    const category = Categories[categoryTypeKeyToDisplay ?? 'empty']
    const categoryLabel = category.label ? category.label() : undefined

    return (
        <AvatarComponent
            name={label}
            size={size}
            bg={category.background}
            icon={
                showCategoryIcon ? (
                    <CategoryIcon
                        imgkey={categoryTypeKeyToDisplay ?? 'empty'}
                        expenseViewType={expenseViewType}
                    />
                ) : undefined
            }
            data-testid="expense-default-avatar"
            {...props}
        >
            {showCategoryBadge && categoryTypeKeyToDisplay && !isPersonal && hasImageSource && (
                <Avatar.Badge
                    visuallyHiddenText={`${t`Expense category`}: ${categoryLabel}`}
                    icon={
                        <CategoryIcon
                            imgkey={categoryTypeKeyToDisplay}
                            expenseViewType={expenseViewType}
                        />
                    }
                />
            )}
            {children}
        </AvatarComponent>
    )
}

export const ManualTransferAvatar: FC<
    React.PropsWithChildren<
        {
            as?: KnownTarget
            label: string
            isForDetail?: boolean
            itemType?: ExpenseViewType
        } & AvatarProps
    >
> = ({size, label, itemType, children, isForDetail, ...props}) => (
    <AvatarComponent
        size={size}
        name={label}
        bg={tokens.colorBackgroundPresentationalGray}
        icon={<CashIcon type={itemType} size={isForDetail ? 32 : 24} />}
        data-testid="manual-transfer-avatar"
        {...props}
    >
        {children}
    </AvatarComponent>
)

const getAvatarPropsForExpenseType = (expenseType?: PurchaseType, isForDetail = false) => {
    switch (expenseType) {
        case PurchaseType.MILEAGE:
            return {
                name: t`New mileage`,
                icon: <Car color={tokens.colorContentStatic} size={isForDetail ? 32 : 24} />,
                bg: tokens.colorBackgroundPresentationalGreen,
            }
        case PurchaseType.PER_DIEM:
            return {
                name: t`New per diem`,
                icon: (
                    <CategoryTravel
                        color={tokens.colorContentStatic}
                        size={isForDetail ? 32 : 24}
                    />
                ),
                bg: tokens.colorBackgroundPresentationalPurple,
            }
        case PurchaseType.MANUAL:
        default:
            return {
                name: t`New expense`,
                icon: <CashIcon color={tokens.colorContentStatic} size={isForDetail ? 32 : 24} />,
                bg: tokens.colorBackgroundPresentationalPink,
            }
    }
}

export const NewExpenseAvatar: FC<
    React.PropsWithChildren<
        {
            isForDetail?: boolean
            expenseType?: PurchaseType | undefined
        } & AvatarProps
    >
> = ({size, children, isForDetail, expenseType, ...props}) => {
    const {name, icon, bg} = getAvatarPropsForExpenseType(expenseType, isForDetail)

    return (
        <Avatar
            size={size}
            name={name}
            bg={bg}
            icon={icon}
            data-testid="new-expense-avatar"
            {...props}
        >
            {children}
        </Avatar>
    )
}

export interface AvatarExpenseProps {
    as?: KnownTarget
    isPersonalExpense: boolean
    merchantName?: string | null
    merchantId?: string | null
    purchaseType?: PurchaseType | null
    label: string
    accountCategoryTypeKey?: AccountCategoryTypeKey | null
    expenseViewType?: ExpenseViewType | null
    accountId?: string | null
    showCategoryBadge?: boolean
}

export const AvatarExpense: FC<React.PropsWithChildren<AvatarExpenseProps & AvatarProps>> = ({
    isPersonalExpense,
    purchaseType,
    merchantName,
    merchantId,
    showCategoryBadge,
    ...props
}) => {
    if (purchaseType === PurchaseType.MILEAGE) {
        return <MileageAvatar {...props} />
    }
    if (purchaseType === PurchaseType.PER_DIEM) {
        return <PerDiemAvatar {...props} />
    }
    if (purchaseType === PurchaseType.ATM) {
        return <ATMAvatar {...props} />
    }

    return (
        <ExpenseDefaultAvatar
            merchantId={merchantId || ''}
            merchantName={merchantName || ''}
            isPersonal={isPersonalExpense}
            showCategoryBadge={showCategoryBadge}
            {...props}
        />
    )
}
