import {useCompanyAccountingSystemDetails} from '@product-web/shared--api-deimos/company-settings'
import {useFlags} from '@product-web/shared--flags'

import {bff} from '../../bff-hooks'
/**
 * Represents the current state of vendor tagging for the logged in company:
 *
 * ENABLED:
 *   Vendor tagging is enabled in the target ERP system and the company has assigned a generic vendor.
 *
 * AVAILABLE:
 *   Vendor tagging is enabled in the target system but the company has **not yet** assigned a generic vendor.
 *
 * NON-ELIGIBLE:
 *   The company does not fulfill the requirements for vendor tagging.
 */
type VendorTaggingEnabledState = 'ENABLED' | 'AVAILABLE' | 'NON-ELIGIBLE'

export function useVendorTagging() {
    const system = useCompanyAccountingSystemDetails()
    const {vendorTaggingEnabledSystems, vendorBookkeeping} = useFlags()
    const {data: vendorTaggingEnabledForCompany} =
        bff.accountingSettings.accountingSetup.externalSetup.getVendorTaggingEnablement.useQuery()
    const {data: hasVendorListed} =
        bff.accountingSettings.accountingSetup.externalSetup.hasVendorsList.useQuery()

    if (!system) {
        return 'NON-ELIGIBLE'
    }

    const enabledSystems = vendorTaggingEnabledSystems ? vendorTaggingEnabledSystems.split(',') : []

    const isVendorTaggingEnabled = Boolean(
        enabledSystems.includes(system.value) &&
            vendorTaggingEnabledForCompany &&
            hasVendorListed &&
            vendorBookkeeping,
    )

    const isVendorTaggingAvailable = Boolean(
        enabledSystems.includes(system.value) &&
            !vendorTaggingEnabledForCompany &&
            hasVendorListed &&
            vendorBookkeeping,
    )

    const vendorTaggingState: VendorTaggingEnabledState = isVendorTaggingEnabled
        ? 'ENABLED'
        : isVendorTaggingAvailable
          ? 'AVAILABLE'
          : 'NON-ELIGIBLE'

    return vendorTaggingState
}
