import * as tracking from '@product-web/shared--analytics'

export function trackPromoCardActioned({
    action,
    message,
    variation,
}: {
    action: 'viewed' | 'abandoned' | 'completed'
    message: string
    variation?: string
}) {
    tracking.promoCardActioned({action, message, variation, placement: 'sidebar'})
}
