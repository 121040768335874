import {Trans} from '@lingui/macro'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import {Avatar, focusRing, Inline, px, Stack, Text, tokens, Tooltip} from '@pleo-io/telescope'
import {Check} from '@pleo-io/telescope-icons'

import {AddAccountScreenName} from '@product-web/feature--multi-entity/add-account-screen-name'
import tracking from '@product-web/shared--analytics'
import type {GetUserNavigationResponse} from '@product-web/shared--api-deimos/user-navigation'
import {useUserNavigation} from '@product-web/shared--api-deimos/user-navigation'
import {OtpType} from '@product-web/shared--api-types/otp'
import type {LoggedInAccount, SwitchAccountPayload} from '@product-web/shared--auth--accounts'
import {checkEmail} from '@product-web/shared--auth--otp/api'
import {reportError} from '@product-web/shared--error/report'
import {getIsOrganizationAdmin, useUser} from '@product-web/shared--user'
import {getFullName} from '@product-web/shared--utils'

import {getSwitchAccountRedirectLocation} from '../get-switch-account-redirect-location'

interface LoggedOutAccount {
    email: string
    trused?: boolean
}
interface NavigationAccountItemProps {
    email: string
    accessToken?: string
    loggedOutAccount?: LoggedOutAccount
    isCurrentLoggedInAccount?: boolean
    activeAccount?: LoggedInAccount
    numberOfActiveAccounts?: number
    switchAccount: (payload: SwitchAccountPayload) => void
}

const NavigationAccountItem = ({
    email,
    accessToken,
    loggedOutAccount,
    isCurrentLoggedInAccount,
    activeAccount,
    numberOfActiveAccounts,
    switchAccount,
}: NavigationAccountItemProps) => {
    const user = useUser()
    const isOrganizationAdmin = getIsOrganizationAdmin(user)
    const navigate = useNavigate()
    const location = useLocation()
    // TODO: move this to BFF, https://linear.app/pleo/issue/ME-4057/story-1-bff-user-navigation-does-not-loop-through-the-response
    const {data: item} = useUserNavigation({accessToken, email})

    const getItemLabel = (account?: GetUserNavigationResponse | null) => {
        if (!account) {
            return ''
        }
        if (account && loggedOutAccount) {
            return <Trans>Logged out</Trans>
        }
        const isOrganization = account?.organizationId
        if (!isOrganization && account) {
            return account.companies[0].name
        }
        return account?.organizationName
    }

    const avatarData = {
        fullName: getFullName(item ?? undefined) === '' ? email : getFullName(item ?? undefined),
        organizationOrCompanyName: item ? (
            getItemLabel(item)
        ) : (
            <Text color="colorContentInteractiveQuiet" italic>
                <Trans>Logged out</Trans>
            </Text>
        ),
        url: item?.companies.find((company) => company.avatar !== null)?.avatar?.url,
    }

    const [isTextTruncated, setIsTextTruncated] = useState<boolean | null>(false)
    const [isEmailTruncated, setIsEmailTruncated] = useState<boolean | null>(false)

    const textRef = useRef<HTMLInputElement | null>(null)
    const emailRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        const isTextRefTruncated =
            !!textRef.current && textRef.current.scrollWidth > textRef.current.clientWidth

        const isEmailRefTruncated =
            !!emailRef.current && emailRef.current.scrollWidth > emailRef.current.clientWidth

        setIsTextTruncated(isTextRefTruncated)
        setIsEmailTruncated(isEmailRefTruncated)
    }, [email])

    const onSwitchAccount = async (accountEmail: string, companyId?: string | null) => {
        if (loggedOutAccount) {
            await addAccount(loggedOutAccount)
        }

        // prevent trying to switch to current account
        if (activeAccount && activeAccount.email === accountEmail) {
            return
        }

        const redirectTo = getSwitchAccountRedirectLocation(location, {
            isSpendingEntity: user?.isSpendingEntity,
            isOrganizationAdmin,
        })
        switchAccount({email: accountEmail, companyId, location: redirectTo})
        tracking.switchAccount({
            numberOfActiveAccounts: numberOfActiveAccounts ?? 0,
        })
    }

    const addAccount = async (connectedAccount: {email: string; trusted?: boolean}) => {
        try {
            const response = await checkEmail(email)
            const otpType = response.googleAuth ? OtpType.GOOGLE_AUTH : OtpType.PHONE
            const addAccountLocation = connectedAccount.trusted ? '/access' : '/add-account'

            navigate(addAccountLocation, {
                state: {
                    ...(connectedAccount.trusted ? {reAuthenticateAccount: true} : {}),
                    otpType,
                    email: connectedAccount.email,
                    currentScreen: AddAccountScreenName.PASSCODE,
                    redirect: getSwitchAccountRedirectLocation(location, {
                        isSpendingEntity: user?.isSpendingEntity,
                        isOrganizationAdmin,
                    }),
                },
            })
        } catch (err) {
            reportError(err)
        }
    }

    return (
        <AccountWrapper
            onClick={async () => onSwitchAccount(email)}
            key={email}
            data-testid={`nav-account-${email}`}
        >
            <Inline px={16} alignY={'center'}>
                <NavAvatar
                    size={24}
                    name={avatarData.fullName}
                    src={avatarData?.url ?? undefined}
                />
                <Stack>
                    {isTextTruncated ? (
                        <Tooltip content={avatarData.organizationOrCompanyName} portalled={false}>
                            <NoWrapFontWeightMedium>
                                {avatarData.organizationOrCompanyName}
                            </NoWrapFontWeightMedium>
                        </Tooltip>
                    ) : (
                        <NoWrapFontWeightMedium ref={textRef}>
                            {avatarData.organizationOrCompanyName}
                        </NoWrapFontWeightMedium>
                    )}
                    {isEmailTruncated ? (
                        <Tooltip content={email} portalled={false}>
                            <NoWrap variant="small-subtle">{email}</NoWrap>
                        </Tooltip>
                    ) : (
                        <NoWrap ref={emailRef} variant="small-subtle">
                            {email}
                        </NoWrap>
                    )}
                </Stack>
                {isCurrentLoggedInAccount && (
                    <Check
                        size={16}
                        color={tokens.colorContentInteractiveQuiet}
                        data-testid={`nav-account-${email}-active`}
                    />
                )}
            </Inline>
        </AccountWrapper>
    )
}

const NavAvatar = styled(Avatar)`
    margin: ${tokens.spacing16} ${tokens.spacing8} ${tokens.spacing16} 0;
`

const AccountWrapper = styled(DropdownMenu.Item)`
    ${focusRing('inset')}
    &:hover {
        background: ${tokens.colorBackgroundInteractiveQuietHover};
        cursor: pointer;

        p {
            color: ${tokens.colorContentInteractiveHover};
        }

        svg {
            fill: ${tokens.colorContentInteractiveHover};
        }
    }
`

export const NoWrapFontWeightMedium = styled(Text).attrs({
    variant: 'medium-default',
    as: 'p',
    truncate: true,
    color: 'colorContentInteractive',
    weight: 'medium',
})`
    width: ${px(190)};
`

export const NoWrap = styled(Text).attrs({
    as: 'p',
    truncate: true,
    color: 'colorContentInteractive',
})`
    width: ${px(190)};
`

export default NavigationAccountItem
