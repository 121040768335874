import styled, {css} from 'styled-components'

import {Drawer, focusRing, NakedButton, tokens} from '@pleo-io/telescope'
import {Close} from '@pleo-io/telescope-icons'

import {useAdoptionCentre} from './adoption-centre-context'

export const AdoptionCentreWidget = () => {
    const {isDrawerOpen, setDrawerOpen, DRAWER_ID} = useAdoptionCentre()

    const onClick = () => {
        setDrawerOpen(!isDrawerOpen)
    }

    return (
        <Drawer.Trigger drawerId={DRAWER_ID}>
            <ToggleButton
                data-testid="adoption-centre-widget"
                onClick={onClick}
                $open={isDrawerOpen}
                $dark={isDrawerOpen}
            >
                {isDrawerOpen ? <Close size={24} /> : '?'}
            </ToggleButton>
        </Drawer.Trigger>
    )
}

const lightToggle = css`
    color: ${tokens.colorContentInteractive};
    background-color: ${tokens.colorBackgroundInteractive};
    border: ${tokens.borderStatic};
    fill: black;

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
        background-color: ${tokens.colorBackgroundInteractiveHover};
        box-shadow: ${tokens.shadowElevateQuiet};
    }
`

const darkToggle = css`
    color: ${tokens.colorContentInteractiveInverse};
    background-color: ${tokens.colorBackgroundInteractiveInverse};

    &:hover {
        color: ${tokens.colorContentInteractiveInverseHover};
        background-color: ${tokens.colorBackgroundInteractiveInverseHover};
        box-shadow: ${tokens.shadowElevateQuiet};
    }
`

const ToggleButton = styled(NakedButton)<{$open: boolean; $dark: boolean}>`
    ${focusRing('inset')};
    ${({$dark}) => ($dark ? darkToggle : lightToggle)}
    font-size: 24px;
    font-weight: ${tokens.fontWeightMedium};
    width: 60px;
    height: 60px;
    padding: 0;
    text-align: center;
    cursor: pointer;
    border-radius: ${tokens.circle};
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: ${tokens.smoothIn};

    ${({$open}) =>
        $open &&
        css`
            /* stylelint-disable-next-line declaration-property-value-allowed-list */
            margin-right: 416px;
        `}
`
