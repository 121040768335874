import {color, duration, opacity, px, radius, transition, zIndex} from '@pleo-io/telescope'

// Helper functions are set for deprecation

export const getBreakpoint =
    (name: keyof typeof theme.breakpoints) => (props: {theme?: typeof theme}) =>
        (props.theme && props.theme.breakpoints[name]) || ''

// Breakpoints
export const pxBreakpoints = {
    largeDesktop: 2300,
    desktopLrgUp: 1331,
    desktopMedUp: 1164,
    desktopUp: 1025,
    tabletMedUp: 919,
    tabletUp: 768,
    mediumTabletUp: 680,
    smallTabletUp: 580,
    mobileLrgUp: 425,
    mobileUp: 321,
}

type Breakpoints = Record<keyof typeof pxBreakpoints, string>

export const breakpoints: Breakpoints = Object.entries(pxBreakpoints).reduce(
    (acc, [key, value]) => {
        return {
            ...acc,
            [key]: px(value),
        }
    },
    {} as Breakpoints,
)

interface ContainerDimensions {
    width: number
}

export interface ContainerQueries {
    parent?: ContainerDimensions
    named?: {[key: string]: ContainerDimensions}
}

export const theme = {
    colors: {
        ...color,

        // NB! Interim RGB values are set to change, please reach out to designOps prior to using.
        // pink400rgb: '255, 200, 208', // used for the loader
    },
    transition,
    radius,
    duration,
    breakpoints,
    opacity,
    zIndex: {
        surface: zIndex.zIndexSurface,
        overlay: zIndex.zIndexOverlay,
        window: zIndex.zIndexWindow,
        dropdown: zIndex.zIndexDropdown,
        tooltip: zIndex.zIndexTooltip,
        navigation: zIndex.zIndexNavigation,
        modal: zIndex.zIndexModal,
        popover: zIndex.zIndexPopover,
    },
    space: [0],
    containerQueries: {} as ContainerQueries,
}
