import {t} from '@lingui/macro'
import {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'

import {IconButton, Inline, Stack, Text, VisuallyHidden} from '@pleo-io/telescope'
import {Close, Plus, Search as SearchIcon} from '@pleo-io/telescope-icons'

import {RequestEntitiesModal} from '@product-web/feature--multi-entity/self-initiated-expansion/request-entities-modal'
import tracking from '@product-web/shared--analytics'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {Search} from './search/search'

import type {VerificationStatus} from '../../lib/use-verification-status.ts'
import {EntityCardsGroup} from '../entity-cards-group.tsx'
import {GroupTitle} from '../group-title.tsx'
import {SelfExpansionEntityCard} from '../self-expansion-entity-card/self-expansion-entity-card.tsx'

type SearchSectionProps = {
    query: string
    onValueChange: (value: string) => void
    onClose: () => void
    searchInputRef: React.RefObject<HTMLInputElement>
}

const SearchHeaderActions = ({
    query,
    onValueChange,
    onClose,
    searchInputRef,
}: SearchSectionProps) => (
    <Inline space={8} alignItems="flex-end" flexGrow={1}>
        <Search ref={searchInputRef} value={query} onValueChange={onValueChange} />
        <IconButton
            Icon={Close}
            variant="secondary"
            size="small"
            tooltipProps={{
                'aria-label': t`Close and leave search mode`,
                content: t`Close search`,
            }}
            onClick={onClose}
        />
    </Inline>
)

type HeaderActionsProps = {
    hasMoreThanOneEntity: boolean
    canAddEntities: boolean
    switchDisabled: boolean
    searchButtonRef: React.RefObject<HTMLButtonElement>
    onSearchButtonClick: () => void
    onAddEntitiesButtonClick: () => void
}

const HeaderActions = ({
    hasMoreThanOneEntity,
    canAddEntities,
    switchDisabled,
    searchButtonRef,
    onSearchButtonClick,
    onAddEntitiesButtonClick,
}: HeaderActionsProps) => (
    <Inline space={8} alignItems="flex-end">
        {hasMoreThanOneEntity && (
            <>
                <HiddenFromUserAndAssistiveTechnology>
                    <label htmlFor="search-button">{t`Search`}</label>
                    <span id="search-button-description">{t`Enter search mode`}</span>
                </HiddenFromUserAndAssistiveTechnology>
                <IconButton
                    id="search-button"
                    ref={searchButtonRef}
                    Icon={SearchIcon}
                    variant="secondary"
                    size="small"
                    tooltipProps={{content: t`Search`}}
                    aria-describedby="search-button-description"
                    onClick={onSearchButtonClick}
                />
            </>
        )}
        {canAddEntities && (
            <IconButton
                disabled={switchDisabled}
                size="small"
                onClick={onAddEntitiesButtonClick}
                Icon={Plus}
                variant="secondary"
                aria-label={t`Add entity`}
                tooltipProps={{content: t`Add entity`}}
                data-testid={'add-entities-button'}
            />
        )}
    </Inline>
)

const EmptySearchResults = () => (
    <Stack marginY={16}>
        <Text>{t`No results`}</Text>
        <Text variant="small-subtle">{t`Try different search terms `}</Text>
    </Stack>
)

export interface Entity {
    companyId: string
    companyName?: string
    walletBalance?: {value?: number; currency?: string}
    exportData?: {
        missingReceiptsCount?: number
        exportQueuedCount?: number
    }
    verificationStatus?: VerificationStatus
    role: 'bookkeeper' | 'owner' | 'member'
    isActive?: boolean
    isSpendingEntity?: boolean
}

export interface EntitiesGroup {
    id: string
    type: 'organization' | 'company'
    name: string
    entities: Entity[]
}

type NonBookkeeperGroupProps = {
    groupedEntities: EntitiesGroup[]
    selectedEntityId: string | null
    onEntityCardClick: (companyId: string) => void
    onVerifyClick: (companyId: string) => void
    containsOrganizationAdmin: boolean
    containsNonOrgAdmin: boolean
    switchDisabled: boolean
}
export const NonBookkeeperGroup = ({
    groupedEntities,
    selectedEntityId,
    onEntityCardClick,
    onVerifyClick,
    containsOrganizationAdmin,
    containsNonOrgAdmin,
    switchDisabled,
}: NonBookkeeperGroupProps) => {
    const isTabletOrSmaller = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)
    const [isRequestEntitiesModalOpen, setIsRequestEntitiesModalOpen] = useState<boolean>(false)
    const [inSearchMode, setInSearchMode] = useState<boolean>(false)
    const [searchQuery, setSearchQuery] = useState<string>('')

    const searchButtonRef = useRef<HTMLButtonElement>(null)
    const searchInputRef = useRef<HTMLInputElement>(null)

    const title = containsOrganizationAdmin ? t`Entities` : t`Your company`
    const canAddEntities = containsOrganizationAdmin || containsNonOrgAdmin

    const nonBookKeeperEntities = groupedEntities
        .filter((entitiesGroup) => entitiesGroup.entities[0].role !== 'bookkeeper')
        .flatMap((group) => group.entities)

    const filteredNonBookKeeperEntities = nonBookKeeperEntities.filter((entity) =>
        entity.companyName?.toLowerCase().includes(searchQuery.toLowerCase()),
    )

    useEffect(() => {
        if (inSearchMode) {
            searchInputRef.current?.focus()
        } else {
            searchButtonRef.current?.focus()
        }
    }, [inSearchMode])

    const handleAddEntitiesButtonClick = (isNewEntityCard?: boolean) => {
        tracking.selfExpansionModalOpened({
            source: isNewEntityCard ? 'new-entity-card' : 'plus-icon',
            hasOrgAccess: containsOrganizationAdmin,
        })
        setIsRequestEntitiesModalOpen(true)
    }

    const handleSearchButtonClick = () => {
        setInSearchMode(true)

        tracking.entitySwitchPanelActioned({
            action: 'open_search',
            number_of_entities: nonBookKeeperEntities.length,
            variant: isTabletOrSmaller ? 'mobile' : 'desktop',
        })
    }

    const handleCloseSearchButtonClick = () => {
        setInSearchMode(false)
        setSearchQuery('')

        tracking.entitySwitchPanelActioned({
            action: 'close_search',
            number_of_entities: nonBookKeeperEntities.length,
            variant: isTabletOrSmaller ? 'mobile' : 'desktop',
        })
    }

    return (
        <Stack stretch>
            {nonBookKeeperEntities.length > 0 && (
                <>
                    <GroupTitle title={inSearchMode ? '' : title}>
                        {inSearchMode ? (
                            <SearchHeaderActions
                                query={searchQuery}
                                onValueChange={(value) => setSearchQuery(value)}
                                onClose={handleCloseSearchButtonClick}
                                searchInputRef={searchInputRef}
                            />
                        ) : (
                            <HeaderActions
                                hasMoreThanOneEntity={nonBookKeeperEntities.length > 1}
                                canAddEntities={canAddEntities}
                                switchDisabled={switchDisabled}
                                searchButtonRef={searchButtonRef}
                                onSearchButtonClick={handleSearchButtonClick}
                                onAddEntitiesButtonClick={handleAddEntitiesButtonClick}
                            />
                        )}
                    </GroupTitle>
                    {inSearchMode && filteredNonBookKeeperEntities.length === 0 ? (
                        <EmptySearchResults />
                    ) : (
                        <EntityCardsGroup
                            entities={
                                inSearchMode ? filteredNonBookKeeperEntities : nonBookKeeperEntities
                            }
                            activeCompanyId={selectedEntityId}
                            onClick={onEntityCardClick}
                            onVerifyClick={onVerifyClick}
                            switchDisabled={switchDisabled}
                        />
                    )}
                </>
            )}

            {containsNonOrgAdmin && (
                <SelfExpansionEntityCard
                    onClick={() => handleAddEntitiesButtonClick(true)}
                    disabled={switchDisabled}
                />
            )}
            <RequestEntitiesModal
                isOrganizationAdmin={containsOrganizationAdmin}
                isOpen={isRequestEntitiesModalOpen}
                setIsOpen={setIsRequestEntitiesModalOpen}
            />
        </Stack>
    )
}

const HiddenFromUserAndAssistiveTechnology = styled(VisuallyHidden)`
    display: none;
`
