import {lazy, Suspense} from 'react'
import {Route} from 'react-router-dom'

import {LoadingPage} from '@pleo-io/telescope'

import {roleSets} from '@product-web/shared--user'

const ReimbursementsPage = lazy(async () => import('./screens/reimbursements'))
const HistoryPage = lazy(async () => import('./screens/history/history'))
const BalanceDetails = lazy(async () => import('./screens/balance-details/balance-details'))
const EntryDetails = lazy(async () => import('./screens/balance-details/entry-details'))

export const BALANCES_ROUTE = 'reimbursements'

export const routes = (
    <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}>
        <Route index element={<ReimbursementsPage />} />
        <Route path=":balanceId" element={<ReimbursementsPage />}>
            <Route
                index
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <BalanceDetails />
                    </Suspense>
                }
            />
            <Route
                path=":entryId"
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <EntryDetails />
                    </Suspense>
                }
            />
        </Route>
        <Route path="history" element={<HistoryPage />}>
            <Route
                path=":reimbursementId"
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <EntryDetails />
                    </Suspense>
                }
            >
                <Route
                    path=":entryId"
                    element={
                        <Suspense fallback={<LoadingPage />}>
                            <EntryDetails />
                        </Suspense>
                    }
                />
            </Route>
        </Route>
    </Route>
)
