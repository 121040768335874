import {useLocation} from 'react-router'

import {useInvoiceAdoption} from '@product-web/feature--adoption/invoice-adoption-prompt/use-invoice-adoption'
import {usePocketAdoption} from '@product-web/feature--adoption/pocket-adoption-prompt/use-pocket-adoption'
import {getIsAdoptionCentreVisible} from '@product-web/feature--help-centre/get-is-adoption-centre-visible'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useHasAllowedRole} from '@product-web/shared--user'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {bff} from '../bff-hooks'

export const useShowAdoptionCentre = () => {
    const isOwnerOrBookkeeper = useHasAllowedRole(['owner', 'bookkeeper-extended'])

    const {data: companyGuides = []} = bff.adoptionCentre.getCompanyGuides.useQuery(undefined, {
        enabled: isOwnerOrBookkeeper,
    })
    const {shouldShowPocketAdoptionPrompt} = usePocketAdoption()
    const {shouldShowInvoiceAdoptionPrompt} = useInvoiceAdoption()

    const isDesktop = useMediaQuery(`(min-width: ${breakpoints.tabletMedUp})`)

    const location = useLocation()

    return {
        shouldShowAdoptionCentre:
            !!companyGuides.length &&
            !shouldShowPocketAdoptionPrompt &&
            !shouldShowInvoiceAdoptionPrompt &&
            getIsAdoptionCentreVisible({
                isDesktop,
                companyGuides,
                pathname: location.pathname,
            }),
    }
}
