import {t} from '@lingui/macro'

import {reportError} from '@product-web/shared--error/report'
import {useToaster} from '@product-web/shared--toaster'

import {featureFundsManagementBff} from './bff-hooks'
import {useSubmitPleoReserveChanges} from './credit/pleo-reserve-api'
import {useVrpAutoTopUpStorage} from './use-vrp-auto-top-up-storage'

export function useUpdateStoredAutoTopUpSettings() {
    const {showToast} = useToaster()

    const {savedVrpAutoTopUpSettings} = useVrpAutoTopUpStorage()

    const {mutateAsync: createVrpTransferRuleOrAutoTopUp} =
        featureFundsManagementBff.utils.createVrpTransferRuleOrAutoTopUp.useMutation({
            useErrorBoundary: true,
        })

    const {submitPleoReserveChanges} = useSubmitPleoReserveChanges()

    return async function (options?: {onSuccess: () => void}) {
        try {
            if (!savedVrpAutoTopUpSettings) {
                reportError(
                    'VRP Auto Top-up (ATU) creation: have no saved ATU settings after sending the consent token',
                )

                showToast(t`There was an error while setting up your recurring Top-up`, {
                    title: t`Have no saved Auto Top-up settings`,
                    level: 'error',
                })

                return
            }

            await createVrpTransferRuleOrAutoTopUp({
                currency: savedVrpAutoTopUpSettings.currency,
                autoTopUpFlow: savedVrpAutoTopUpSettings.hasTransferRulesEligibility
                    ? 'TRANSFER_RULE'
                    : 'AUTO_TOP_UP',
                autoTopUpStatus:
                    savedVrpAutoTopUpSettings.autoTopupStatus === 'ENABLED' ? 'ENABLED' : 'PENDING',
                threshold: savedVrpAutoTopUpSettings.autoTopUpThreshold,
                amount: savedVrpAutoTopUpSettings.autoTopUpAmount,
                sourceOfFunds:
                    savedVrpAutoTopUpSettings.preFilledTransferRuleConfiguration?.sourceOfFunds,
                skipTopUpSettings:
                    savedVrpAutoTopUpSettings.preFilledTransferRuleConfiguration?.skipTopUpSettings,
            })

            if (typeof savedVrpAutoTopUpSettings.pleoReserveEnabled !== 'undefined') {
                // in case we're enabling both Pleo Reserve and ATU, we need to call the ATU endpoint first
                await submitPleoReserveChanges({
                    pleoReserveEnabled: savedVrpAutoTopUpSettings.pleoReserveEnabled,
                    autoTopUpAmount: savedVrpAutoTopUpSettings.autoTopUpAmount,
                    autoTopUpThreshold: savedVrpAutoTopUpSettings.autoTopUpThreshold,
                })
            }

            options?.onSuccess()
        } catch (err: any) {
            reportError(err, `Error while enabling VRP Auto Top-up: "${err.message}"`)

            showToast(t`There was an error while setting up your recurring Top-up`, {
                title: t`Saving Auto Top-up settings failed`,
                level: 'error',
            })
        }
    }
}
