import {t, Trans} from '@lingui/macro'
import styled from 'styled-components'

import {Box, ProgressBar, Stack, Text, tokens} from '@pleo-io/telescope'

import {breakpoints} from '@product-web/shared--styles/theme'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {RECURRING_SPEND_CARD_KEY} from './announcement-modal'

interface ProgressCardProps {
    percent: number
    goToSetupGuide: () => void
}
export const ProgressCard = ({percent, goToSetupGuide}: ProgressCardProps) => {
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)

    if (localStorage.getItem(RECURRING_SPEND_CARD_KEY) === 'dismissed') {
        return null
    }

    return (
        <Wrapper
            onClick={goToSetupGuide}
            $isTablet={isTablet}
            data-testid="spend-activation-progress-card"
        >
            <StyledBox $isTablet={isTablet} paddingTop={12} paddingX={16} paddingBottom={16}>
                <Stack space={8}>
                    <Text>
                        <Trans>Set up recurring spend</Trans>
                    </Text>

                    <StyledProgressBar
                        aria-label={t`progress bar showing percentage of recurring spend setup`}
                        variant="tall"
                        percent={percent}
                    />
                </Stack>
            </StyledBox>
        </Wrapper>
    )
}

const Wrapper = styled.div<{$isTablet?: boolean}>`
    cursor: pointer;
    justify-self: stretch;
    margin: ${({$isTablet}) =>
        $isTablet
            ? `-${tokens.spacing4} ${tokens.spacing48}`
            : `-${tokens.spacing4} ${tokens.spacing16} 0 ${tokens.spacing16}`};
`

const StyledBox = styled(Box)<{$isTablet?: boolean}>`
    background-color: ${({$isTablet}) =>
        $isTablet ? tokens.colorBackgroundInteractiveLoud : tokens.colorBackgroundInteractive};
    border-radius: ${tokens.arc8};
`

//  Pseudo-class usage should be replaced with the fillColor prop once it supports more colours
const StyledProgressBar = styled(ProgressBar)`
    &::before {
        background-color: ${tokens.colorBackgroundPositive};
    }
`
