import type {CardType, ProviderName} from '@pleo-io/deimos'
import type {CardStatus} from '@pleo-io/deimos'

import {callApi, request} from '@product-web/shared--api'
import {RequestScope} from '@product-web/shared--api'
import type {EncryptedCardDetails} from '@product-web/shared--api-types/card-details'
import config from '@product-web/shared--config'

import type {Employee} from '../employee'

const baseUrl = config.endpoints.api

export enum CanChangePin {
    NEVER = 'NEVER',
    ATM = 'ATM',
    APP = 'APP',
}

export interface CompanyCard {
    companyId: string
    embossedName: string | null
    employee: Employee | null
    employeeId: string | null
    enabled: boolean
    expiration: string
    id: string
    meta?: {
        disabledByLimit?: boolean
        blockedByTriesExceeded?: boolean
        storebox?: boolean
    }
    linked: boolean
    pan: string
    status: CardStatus
    storebox: boolean
    type: CardType
    velocity: string
    walletId: string
    disabledByAdmin: boolean
    pinTriesExceeded: boolean
    daysToExpiration: number
    aboutToExpire: boolean
    canChangePin: CanChangePin
    providerName: ProviderName
}

export type UpdateCardRequest = {status: CardStatus}

export async function updateCard(id: string, payload: UpdateCardRequest) {
    return request(`${baseUrl}/rest/v1/cards/${id}`, {
        auth: 'elevated',
        scope: RequestScope.CARD,
        method: 'PUT',
        body: payload,
        elevatedResourceIds: [id],
    })
}

export async function createCard(employeeId: string, token?: string) {
    return request(`${baseUrl}/rest/v1/cards`, {
        auth: token ? null : 'elevated',
        bearer: token || null,
        scope: RequestScope.CARD,
        method: 'POST',
        body: {employeeId},
    })
}

export async function resetPinTries(cardId: string, token?: string) {
    return request(`${baseUrl}/rest/v1/cards/${cardId}/pin/tries`, {
        auth: token ? null : 'elevated',
        bearer: token || null,
        scope: RequestScope.CARD,
        method: 'DELETE',
    })
}

export async function pairCard(accessToken: string, employeeId: string, pan: string) {
    return request(`${baseUrl}/rest/v1/cards`, {
        bearer: accessToken,
        method: 'PUT',
        scope: RequestScope.CARD,
        body: {pan, employeeId},
    })
}

export async function getDetails(
    cardId: string,
    publicKey: string,
    token?: string,
): Promise<EncryptedCardDetails> {
    return callApi(`/rest/v1/cards/${cardId}/details`, {
        auth: token ? null : 'elevated',
        bearer: token || null,
        scope: RequestScope.CARD_DETAILS,
        method: 'POST',
        body: {publicKey},
    })
}

export async function getPin(cardId: string, token?: string) {
    return request(`${baseUrl}/rest/v1/cards/${cardId}/pin`, {
        auth: token ? null : 'elevated',
        bearer: token || null,
        scope: RequestScope.CARD,
        method: 'GET',
    })
}
