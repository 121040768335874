import {Trans} from '@lingui/macro'
import type {FC} from 'react'
import styled from 'styled-components'

import {Box, Button, Inline, Modal, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import {paywallActioned} from '@product-web/shared--analytics'
import {useSupportChat} from '@product-web/shared--freshchat/use-support-chat'
import {getHelpCentreArticleLink} from '@product-web/shared--locale/helpers'
import {breakpoints} from '@product-web/shared--styles/theme'
import CallToAction from '@product-web/shared--telescope-lab/call-to-action/call-to-action'
import {useUser} from '@product-web/shared--user'

import GlobeIcon from './images/globe.svg'
import PaywallIllustration from './images/illustration-paywall-modal.svg'
import AirplaneIcon from './images/paper-airplane.svg'
import {getPaywallTrackingView} from './paywall'
import {
    IllustrationImage,
    IllustrationPanel,
    LeftPanelContainer,
    TwoPanelContainer,
} from './paywall.styles'
import {PaywallView, usePaywallContext} from './paywall-context'

const FlexContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`
const StyledCallToAction = styled(CallToAction)`
    width: 100%;
    text-align: left;
    background-color: ${tokens.colorBackgroundInteractive};
`

const BackButton = styled(Button).attrs({
    variant: 'tertiary',
    IconLeft: ArrowLeft,
})`
    position: absolute;
    top: ${tokens.spacing40};
    left: ${tokens.spacing40};

    @media (max-width: ${breakpoints.tabletUp}) {
        top: ${tokens.spacing10};
        left: ${tokens.spacing10};
    }
`

interface Props {
    onClose: () => void
    isDismissible: boolean
}

const ModalHelpScreen: FC<Props> = ({onClose, isDismissible}) => {
    const user = useUser()
    const languageCode = user?.language || 'en'
    const supportChat = useSupportChat()
    const {setView} = usePaywallContext()
    const trackingView = getPaywallTrackingView(PaywallView.GET_HELP, !isDismissible)
    const showSupportChat = (): void => {
        if (trackingView) {
            paywallActioned({
                action: 'support_chat',
                is_dismissible: isDismissible,
                view: trackingView,
            })
        }
        onClose()
        supportChat.show()
    }

    const showHelpCenter = (): void => {
        const helpCentreLink = getHelpCentreArticleLink(undefined, languageCode)
        if (trackingView) {
            paywallActioned({
                action: 'link_clicked',
                is_dismissible: isDismissible,
                view: trackingView,
                target_url: helpCentreLink,
                link_text: 'help-centre',
            })
        }
        const helpCenterWindow = window.open(helpCentreLink, '_blank', 'noopener,noreferrer')

        if (helpCenterWindow) {
            helpCenterWindow.opener = null
        }
    }

    return (
        <TwoPanelContainer>
            <LeftPanelContainer>
                <FlexContainer>
                    <BackButton type="button" onClick={() => setView(PaywallView.INITIAL)}>
                        <Trans>Back</Trans>
                    </BackButton>
                    <Modal.Title>
                        <Text align="left" variant="3xlarge-accent">
                            <Trans>Get help from Pleo</Trans>
                        </Text>
                        <Text align="left" variant="medium-default">
                            <Trans>Let us get you back on track.</Trans>
                        </Text>
                    </Modal.Title>
                    <Modal.Content>
                        <Stack space={20}>
                            <StyledCallToAction
                                data-testid="support-chat-cta"
                                image={AirplaneIcon}
                                onClick={() => showSupportChat()}
                                to="/export"
                            >
                                <Inline alignY="center" space={8}>
                                    <Text variant="large-accent">
                                        <Trans>Chat to the support team</Trans>
                                    </Text>
                                </Inline>
                                <Text as="div" color="colorContentInteractiveQuiet">
                                    <Trans>We usually reply in under 10 minutes</Trans>
                                </Text>
                            </StyledCallToAction>

                            <StyledCallToAction
                                data-testid="help-center-cta"
                                image={GlobeIcon}
                                onClick={() => showHelpCenter()}
                            >
                                <Inline alignY="center" space={8}>
                                    <Text variant="large-accent">
                                        <Trans>Browse the help center</Trans>
                                    </Text>
                                </Inline>
                                <Text as="div" color="colorContentInteractiveQuiet">
                                    <Trans>Advice and answers from the Pleo Team</Trans>
                                </Text>
                            </StyledCallToAction>
                        </Stack>
                    </Modal.Content>
                </FlexContainer>
            </LeftPanelContainer>

            <IllustrationPanel>
                <IllustrationImage src={PaywallIllustration} />
            </IllustrationPanel>
        </TwoPanelContainer>
    )
}

export default ModalHelpScreen
