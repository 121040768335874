import type {AccountCategory} from '@pleo-io/deimos'

import {RepresentationLevels} from '@product-web/shared--api-deimos/representation'

import type {Expense} from './index.bff'

export const getShouldShowReceiptUpload = (expense: Expense) => {
    const showReceiptUpload =
        (expense.isCashWithdrawal && expense.isManualExpense === false) || !expense.isCashWithdrawal
    return showReceiptUpload
}

export const hasRepresentation = (representationLevel: RepresentationLevels) =>
    [RepresentationLevels.Full, RepresentationLevels.Limited].includes(representationLevel)

export const getImgKey = (value: string | null, accountCategories: AccountCategory[]) => {
    const newImgKey = accountCategories.find((accountCategory) =>
        accountCategory?.accounts?.find((account) => account.id === value),
    )?.typeKey
    return newImgKey ?? 'empty'
}
