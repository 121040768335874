import {t, Trans} from '@lingui/macro'
import * as React from 'react'
import {Link as RouterLink, useNavigate} from 'react-router-dom'
import styled, {css} from 'styled-components'
import {v4 as uuid} from 'uuid'

import {Badge, Box, Button, Card, Inline, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import type {Props as IconProps} from '@pleo-io/telescope-icons'
import {
    BankPlus,
    CheckCircleFilled,
    Clock,
    EmotionDisappointed,
    SendMoney,
    Shield,
    ThumbUp,
    Wallet,
    Warning,
} from '@pleo-io/telescope-icons'

import {useNavigateToDirectDebitCreation} from '@product-web/feature--funds-management/direct-debit-navigation'
import {StaticTransferDetailsModal} from '@product-web/feature--funds-management/static-transfer-details-modal/static-transfer-details'
import * as tracking from '@product-web/shared--analytics'
import {useTermsDocuments} from '@product-web/shared--country-configuration/features/terms/terms'
import {formatCurrency} from '@product-web/shared--locale/currency'
import {
    Container,
    containerQuery,
} from '@product-web/shared--telescope-lab/container-queries/container'
import {getIsFdd, useCompanyUser} from '@product-web/shared--user'

import {bff} from '../bff-hooks'
import type {GetGetStartedScreenData} from '../index.bff'
import type {MilestoneName, MilestoneStatus} from '../universal/types'

const MOBILE_BREAKPOINT = 400
const CONTAINER_QUERY_NAME = 'MilestoneCard'

//#region Components

type Amount = {
    value: number
    currency: string
}

const headingLevels = ['h2', 'h3', 'h4', 'h5', 'h6'] as const

type OverdraftApplicationState = NonNullable<
    GetGetStartedScreenData['overdraftApplicationData']
>['applicationState']

type OverdraftActivationData = NonNullable<GetGetStartedScreenData['overdraftActivationData']>

type MilestoneCardProps = {
    milestone: MilestoneName
    status: MilestoneStatus
    headingLevel: Exclude<(typeof headingLevels)[number], 'h6'>
    shouldShowReserve?: boolean
    shouldShowOverdraftApplication?: boolean
    shouldShowOverdraftActivation?: boolean
    reserveLimit?: Amount
    overdraftLimit?: Amount | null
    overdraftActivationData?: OverdraftActivationData
    overdraftApplicationState?: OverdraftApplicationState
    isFromMultiEntitySignupFlow?: boolean
}

export const MilestoneCard = ({
    milestone,
    status,
    headingLevel,
    shouldShowReserve,
    shouldShowOverdraftApplication,
    shouldShowOverdraftActivation,
    reserveLimit,
    overdraftLimit,
    overdraftApplicationState,
    isFromMultiEntitySignupFlow,
    overdraftActivationData,
}: MilestoneCardProps) => {
    const {mutate: startTask} =
        bff.companyOnboarding.getStarted.startCompanyOnboardingTask.useMutation()
    const navigate = useNavigate()
    const user = useCompanyUser()
    const {navigateToDirectDebitCreation} = useNavigateToDirectDebitCreation({
        product: 'auto-top-up',
    })
    const [shouldShowTransferDetailsModal, setShouldShowTransferDetailsModal] =
        React.useState(false)

    const isFdd = getIsFdd(user)
    const reserveLimitString = formatCurrency(reserveLimit?.value, reserveLimit?.currency, {
        format: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
    })

    switch (milestone) {
        case 'GET_VERIFIED': {
            const getDescription = () => {
                switch (status) {
                    case 'AWAITING_REVIEW':
                        return (
                            <Trans>
                                You'll get an email once it's verified.{' '}
                                <Link as={RouterLink} to="/company-verification/status">
                                    Check status
                                </Link>
                            </Trans>
                        )

                    default:
                        if (isFromMultiEntitySignupFlow) {
                            return t`Get ready to spend with Pleo.`
                        }

                        return t`Upload your documents to start spending with Pleo.`
                }
            }

            const getCta = () => {
                switch (status) {
                    case 'IN_PROGRESS':
                    case 'INCOMPLETE':
                        return (
                            <Button
                                variant="primary"
                                onClick={() => {
                                    startTask('GET_VERIFIED')
                                    tracking.companyOnboardingMilestoneActioned({
                                        action: 'started',
                                        milestone,
                                        task: 'GET_VERIFIED',
                                    })
                                    navigate('/company-verification')
                                }}
                            >
                                {status === 'IN_PROGRESS' ? t`Continue` : t`Get verified`}
                            </Button>
                        )

                    default:
                        return null
                }
            }

            const getBadgeText = () => {
                switch (status) {
                    case 'AWAITING_REVIEW':
                        return t`Reviewing`

                    default:
                        return null
                }
            }

            return (
                <MilestoneCardInternal
                    status={status}
                    headingLevel={headingLevel}
                    Icon={Shield}
                    heading={
                        isFromMultiEntitySignupFlow
                            ? t`Verify your new entity`
                            : t`Verify your company`
                    }
                    description={getDescription()}
                    cta={getCta()}
                    badgeText={getBadgeText()}
                />
            )
        }
        case 'LOAD_WALLET': {
            const disabled = !isFdd && !shouldShowReserve && status === 'INCOMPLETE'
            const isBankTransferDisabled = !isFdd

            const getCta = () => {
                switch (status) {
                    case 'INCOMPLETE':
                        return (
                            <Button
                                variant="primary"
                                disabled={disabled}
                                onClick={() => {
                                    startTask('LOAD_WALLET')
                                    tracking.companyOnboardingMilestoneActioned({
                                        action: 'started',
                                        milestone,
                                        task: 'LOAD_WALLET',
                                    })
                                    navigate('/onboarding/topup-wallet')
                                }}
                            >
                                <Trans>Add funds</Trans>
                            </Button>
                        )

                    default:
                        return null
                }
            }

            const getBadgeText = () => {
                switch (status) {
                    case 'PENDING_TRANSFER':
                        return t`Awaiting transfer`

                    case 'PENDING_OPEN_BANKING':
                    case 'PENDING_RESERVE':
                        return t`Pending`

                    default:
                        return null
                }
            }

            const getDescription = () => {
                switch (status) {
                    case 'PENDING_TRANSFER':
                        return (
                            <Trans>
                                Usually it takes 2-3 days for transfer to arrive. Haven't made the
                                transfer yet?{' '}
                                {/* @temp-button-migrations: May look off, due to inline use, when tertiary button styling is updated */}
                                <Button
                                    variant="tertiary"
                                    onClick={() => setShouldShowTransferDetailsModal(true)}
                                >
                                    View transfer details
                                </Button>
                            </Trans>
                        )

                    case 'PENDING_OPEN_BANKING':
                        return (
                            <Trans>
                                Your transfer is on the way. It could take a few days to arrive.
                            </Trans>
                        )

                    case 'PENDING_RESERVE':
                        return t`Credit will arrive after your direct debit agreement has been processed.`

                    default:
                        if (disabled) {
                            return t`You need to be verified to do this.`
                        }

                        if (!shouldShowReserve) {
                            return t`Transfer any amount to start spending.`
                        }

                        return null
                }
            }

            return (
                <>
                    {shouldShowTransferDetailsModal && (
                        <StaticTransferDetailsModal
                            isOpen={shouldShowTransferDetailsModal}
                            onClose={() => setShouldShowTransferDetailsModal(false)}
                        />
                    )}

                    <MilestoneCardInternal
                        status={status}
                        headingLevel={headingLevel}
                        Icon={Wallet}
                        heading={t`Add funds to your wallet`}
                        description={getDescription()}
                        cta={getCta()}
                        badgeText={getBadgeText()}
                    >
                        {(shouldShowReserve ||
                            shouldShowOverdraftApplication ||
                            shouldShowOverdraftActivation) &&
                            status === 'INCOMPLETE' && (
                                <Stack space={16} stretch px={24} pb={24}>
                                    <MilestoneCardInternal
                                        headingLevel={
                                            headingLevels[headingLevels.indexOf(headingLevel) + 1]
                                        }
                                        Icon={SendMoney}
                                        heading={t`Bank transfer`}
                                        description={
                                            isBankTransferDisabled
                                                ? t`You need to be verified to do this.`
                                                : t`Transfer any amount to start spending.`
                                        }
                                        isChildCard
                                        cta={
                                            <Button
                                                variant="secondary"
                                                disabled={isBankTransferDisabled}
                                                onClick={() => {
                                                    startTask('LOAD_WALLET')
                                                    tracking.companyOnboardingMilestoneActioned({
                                                        action: 'started',
                                                        milestone,
                                                        task: 'LOAD_WALLET',
                                                    })
                                                    navigate('/onboarding/topup-wallet')
                                                }}
                                            >
                                                <Trans>Add funds</Trans>
                                            </Button>
                                        }
                                    />
                                    {shouldShowReserve && (
                                        <MilestoneCardInternal
                                            headingLevel={
                                                headingLevels[
                                                    headingLevels.indexOf(headingLevel) + 1
                                                ]
                                            }
                                            Icon={BankPlus}
                                            heading={t`Credit`}
                                            description={t`Start with ${reserveLimitString} interest-free.`}
                                            cta={
                                                <Button
                                                    variant="secondary"
                                                    onClick={() => {
                                                        startTask('APPLY_FOR_CREDIT')
                                                        tracking.companyOnboardingMilestoneActioned(
                                                            {
                                                                action: 'started',
                                                                milestone,
                                                                task: 'APPLY_FOR_CREDIT',
                                                            },
                                                        )
                                                        navigateToDirectDebitCreation({
                                                            preFilledAutoTopUpSettings: {
                                                                autoTopupStatus: 'ENABLED',
                                                                autoTopupType: 'LOW_BALANCE',
                                                                paymentRail: 'DD',
                                                                lowBalanceTopup: {
                                                                    amount:
                                                                        reserveLimit?.value ?? 0,
                                                                    threshold: 0,
                                                                },
                                                            },
                                                            backToUrl: '/onboarding/pleo-guide',
                                                        })
                                                    }}
                                                >
                                                    <Trans>Set up credit</Trans>
                                                </Button>
                                            }
                                            isChildCard
                                        />
                                    )}
                                    {shouldShowOverdraftApplication &&
                                        overdraftApplicationState && (
                                            <OverdraftApplicationCard
                                                headingLevel={headingLevel}
                                                applicationState={overdraftApplicationState}
                                                overdraftLimit={overdraftLimit}
                                                isFdd={isFdd}
                                            />
                                        )}
                                    {shouldShowOverdraftActivation && overdraftActivationData && (
                                        <OverdraftActivationCard
                                            headingLevel={headingLevel}
                                            limit={overdraftActivationData.eligibleLimit}
                                            currency={overdraftActivationData.currency}
                                            isPending={Boolean(
                                                overdraftActivationData?.isPendingOverdraftApplication,
                                            )}
                                        />
                                    )}
                                </Stack>
                            )}
                    </MilestoneCardInternal>
                </>
            )
        }

        default:
            return null
    }
}

type MilestoneCardInternalProps = {
    status?: MilestoneCardProps['status']
    headingLevel: (typeof headingLevels)[number]
    Icon: (props: IconProps) => JSX.Element
    heading: string
    description?: React.ReactNode
    children?: React.ReactNode
    isChildCard?: boolean
    cta?: React.ReactNode
    badgeText?: string | null
    badgeVariant?: Parameters<typeof Badge>[0]['variant']
}

const MilestoneCardInternal = ({
    status,
    isChildCard,
    headingLevel,
    Icon,
    heading,
    description,
    children,
    cta,
    badgeText,
    badgeVariant = 'info',
}: MilestoneCardInternalProps) => {
    const headingId = uuid()
    const checkmarkId = uuid()
    const statusBadgeId = uuid()

    return (
        <Container name={CONTAINER_QUERY_NAME}>
            <OuterWrapper
                role="region"
                aria-labelledby={headingId}
                aria-describedby={[statusBadgeId, checkmarkId].join(' ')}
                $isChildCard={isChildCard}
                $isComplete={status === 'COMPLETE'}
            >
                <div>
                    <Main $isParentCard={!!children}>
                        <Content>
                            {status !== 'COMPLETE' && (
                                <Box py={2}>
                                    <Icon size={24} />
                                </Box>
                            )}

                            <Inline space={16} alignY="center">
                                {status === 'COMPLETE' && (
                                    <CheckCircleFilled
                                        color={tokens.colorBorderPositive}
                                        id={checkmarkId}
                                        aria-label={t`Completed`}
                                    />
                                )}

                                <Stack space={4}>
                                    <Inline space={8}>
                                        <Text
                                            as={headingLevel}
                                            variant={isChildCard ? 'large-accent' : 'xlarge-accent'}
                                            weight="medium"
                                            color={
                                                status === 'COMPLETE'
                                                    ? 'colorContentPositive'
                                                    : 'colorContentStatic'
                                            }
                                            id={headingId}
                                        >
                                            {heading}
                                        </Text>
                                    </Inline>

                                    {description && status !== 'COMPLETE' && (
                                        <Text variant="medium-default">{description}</Text>
                                    )}
                                </Stack>
                            </Inline>
                        </Content>

                        {!children && status !== 'COMPLETE' && !!cta && cta}

                        {!children && status !== 'COMPLETE' && !cta && !!badgeText && (
                            <Badge
                                id={statusBadgeId}
                                variant={badgeVariant}
                                Icon={getBadgeIcon(badgeVariant)}
                            >
                                {badgeText}
                            </Badge>
                        )}
                    </Main>

                    {children}
                </div>
            </OuterWrapper>
        </Container>
    )
}

const OverdraftActivationCard = ({
    headingLevel,
    limit,
    currency,
    isPending,
}: {
    headingLevel: MilestoneCardProps['headingLevel']
    limit: number
    currency: string
    isPending: boolean
}) => {
    const formattedLimit = formatCurrency(limit, currency)
    const {overdraftTerms} = useTermsDocuments()

    return (
        <MilestoneCardInternal
            headingLevel={headingLevels[headingLevels.indexOf(headingLevel) + 1]}
            Icon={BankPlus}
            heading={t`Credit`}
            description={
                <Trans>
                    You are eligible for {formattedLimit} credit.{' '}
                    <Link href={overdraftTerms} target="_blank" rel="noopener noreferrer">
                        See terms
                    </Link>
                </Trans>
            }
            cta={!isPending && <OverdraftActivationCta />}
            badgeText={isPending ? t`Pending` : undefined}
            badgeVariant={isPending ? 'info' : undefined}
        />
    )
}

const OverdraftActivationCta = () => {
    const {mutate: startTask} =
        bff.companyOnboarding.getStarted.startCompanyOnboardingTask.useMutation()
    const navigate = useNavigate()

    return (
        <Button
            variant="secondary"
            onClick={() => {
                startTask('ACTIVATE_OVERDRAFT')
                tracking.companyOnboardingMilestoneActioned({
                    action: 'started',
                    milestone: 'LOAD_WALLET',
                    task: 'ACTIVATE_OVERDRAFT',
                })
                navigate('/wallet/overdraft/activate')
            }}
        >
            {t`Activate`}
        </Button>
    )
}

const OverdraftApplicationCard = ({
    headingLevel,
    applicationState,
    overdraftLimit,
    isFdd,
}: {
    headingLevel: MilestoneCardProps['headingLevel']
    applicationState: OverdraftApplicationState
    overdraftLimit?: Amount | null
    isFdd: boolean
}) => {
    const overdraftLimitString = formatCurrency(overdraftLimit?.value, overdraftLimit?.currency, {
        format: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
    })

    return (
        <MilestoneCardInternal
            headingLevel={headingLevels[headingLevels.indexOf(headingLevel) + 1]}
            Icon={BankPlus}
            heading={t`Credit`}
            description={getOverdraftDescription({
                applicationState,
                overdraftLimit: overdraftLimitString,
                isFdd,
            })}
            cta={
                applicationState === 'available' || (applicationState === 'approved' && isFdd) ? (
                    <OverdraftApplicationCta applicationState={applicationState} isFdd={isFdd} />
                ) : null
            }
            isChildCard
            badgeText={getOverdraftBadgeText({
                applicationState,
                isFdd,
            })}
            badgeVariant={getOverdraftBadgeVariant({
                applicationState,
                isFdd,
            })}
        />
    )
}

const OverdraftApplicationCta = ({
    applicationState,
    isFdd,
}: {
    applicationState: OverdraftApplicationState
    isFdd: boolean
}) => {
    const {mutate: startTask} =
        bff.companyOnboarding.getStarted.startCompanyOnboardingTask.useMutation()
    const navigate = useNavigate()

    return (
        <Button
            variant="secondary"
            onClick={() => {
                if (applicationState === 'available') {
                    startTask('APPLY_FOR_OVERDRAFT')
                    tracking.companyOnboardingMilestoneActioned({
                        action: 'started',
                        milestone: 'LOAD_WALLET',
                        task: 'APPLY_FOR_OVERDRAFT',
                    })
                }

                if (applicationState === 'available' || !isFdd) {
                    navigate('/onboarding/overdraft/eligibility-application')
                }

                if (applicationState === 'approved') {
                    navigate('/wallet/overdraft/activate')
                }
            }}
        >
            {applicationState === 'available' && t`Apply`}
            {applicationState === 'approved' && isFdd && t`Activate`}
        </Button>
    )
}

//#endregion Components

//#region Helpers

const getOverdraftDescription = ({
    applicationState,
    overdraftLimit,
    isFdd,
}: {
    applicationState: OverdraftApplicationState
    overdraftLimit: string
    isFdd: boolean
}) => {
    switch (applicationState) {
        case 'available':
            return t`You might be eligible for ${overdraftLimit} credit.`

        case 'pending':
            return t`You applied for ${overdraftLimit} credit.`

        case 'infoRequired':
            return (
                <>
                    <Trans>You applied for {overdraftLimit} credit.</Trans>{' '}
                    <Link
                        to={'/onboarding/overdraft/eligibility-application/info-required'}
                        as={RouterLink}
                    >
                        <Trans>See details</Trans>
                    </Link>
                </>
            )
        case 'rejected':
            return (
                <>
                    <Trans>You applied for {overdraftLimit} credit.</Trans>{' '}
                    <Link to={'/onboarding/overdraft-application-rejection-info'} as={RouterLink}>
                        <Trans>See details</Trans>
                    </Link>
                </>
            )

        case 'approved':
            if (!isFdd) {
                return t`You need to be verified to activate.`
            }
            return t`You are eligible for ${overdraftLimit} credit.`
    }
    return undefined
}

const getOverdraftBadgeText = ({
    applicationState,
    isFdd,
}: {
    applicationState: OverdraftApplicationState
    isFdd: boolean
}) => {
    switch (applicationState) {
        case 'pending':
            return t`Reviewing`

        case 'infoRequired':
            return t`Action required`

        case 'rejected':
            return t`Not qualified`

        case 'approved':
            if (!isFdd) {
                return t`Limit approved`
            }
    }
    return undefined
}

const getOverdraftBadgeVariant = ({
    applicationState,
    isFdd,
}: {
    applicationState: OverdraftApplicationState
    isFdd: boolean
}) => {
    switch (applicationState) {
        case 'pending':
            return 'info'

        case 'infoRequired':
            return 'warning'

        case 'rejected':
            return 'negative'

        case 'approved':
            if (!isFdd) {
                return 'positive'
            }
    }
    return undefined
}

const getBadgeIcon = (variant: Parameters<typeof Badge>[0]['variant']) => {
    switch (variant) {
        case 'info':
            return Clock

        case 'warning':
            return Warning

        case 'negative':
            return EmotionDisappointed

        case 'positive':
            return ThumbUp
    }
    return undefined
}

//#endregion Helpers

//#region Styles

const outerWrapperChildCardStyles = css`
    border-radius: ${tokens.arc8};
`

const outerWrapperCompleteStyles = css`
    background: ${tokens.colorBackgroundPositiveQuiet};
    border-color: transparent;
`

type OuterWrapperProps = {
    $isChildCard?: boolean
    $isComplete?: boolean
}

const OuterWrapper = styled(Card)<OuterWrapperProps>`
    border-radius: ${tokens.arc12};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({$isChildCard}) => $isChildCard && outerWrapperChildCardStyles}
    ${({$isComplete}) => $isComplete && outerWrapperCompleteStyles}
`

const mainParentCardStyles = css`
    min-height: 98px;
`

type MainProps = {
    $isParentCard: boolean
}

const Main = styled.div<MainProps>`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: ${tokens.spacing24};
    row-gap: ${tokens.spacing24};
    padding: ${tokens.spacing24};
    align-items: center;
    justify-items: start;

    ${({$isParentCard}) => $isParentCard && mainParentCardStyles}

    ${containerQuery(
        {name: CONTAINER_QUERY_NAME, minWidth: MOBILE_BREAKPOINT},
        css`
            grid-template-columns: 1fr auto;
        `,
    )}
`

const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    column-gap: ${tokens.spacing16};
    row-gap: ${tokens.spacing8};

    ${containerQuery(
        {name: CONTAINER_QUERY_NAME, minWidth: MOBILE_BREAKPOINT},
        css`
            grid-template-columns: auto 1fr;
        `,
    )}
`

//#endregion Styles
