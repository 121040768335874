/* eslint-disable string-to-lingui/text-restrictions */

import {t, Trans} from '@lingui/macro'
import * as React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import {Button, Modal, Text} from '@pleo-io/telescope'

import {useUserPickedTrialPlanFlag} from '@product-web/shared--plan-presentation/use-user-picked-trial-plan-flag'

import highFiveIllustration from './high-five.svg'

import {bff} from '../bff-hooks'

export const StarterActivationModal = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [hasBeenDismissed, setHasBeenDismissed] = React.useState(false)
    const {data} = bff.featureOnboarding.starterActivationModal.getData.useQuery()
    const {mutateAsync: markModalAsDismissed} =
        bff.featureOnboarding.starterActivationModal.markModalAsDismissed.useMutation()

    if (!data) {
        return null
    }

    // We don't want to show the modal during accounting setup
    // so it doesn't interrupt users on their way back from integrating with
    // their accounting software and continuing that setup
    const isAccountingRoute = ['/onboarding/setup-accounting', '/settings/accounting/setup'].some(
        (path) => location.pathname.startsWith(path),
    )

    const dismiss = async () => {
        setHasBeenDismissed(true)

        await markModalAsDismissed()
    }

    const shouldShowModal =
        !hasBeenDismissed &&
        data.hasWalletLoads &&
        data.selectedPlan === 'STARTER' &&
        !isAccountingRoute

    const title = t`Your Pleo account is live!`

    return (
        <Modal
            isOpen={shouldShowModal}
            onDismiss={dismiss}
            data-testid="starter-activation-modal"
            aria-label={title}
        >
            <Modal.Close onClick={dismiss} />

            <Modal.Illustration>
                <Illustration src={highFiveIllustration} alt="" />
            </Modal.Illustration>

            <Modal.Title>{title}</Modal.Title>

            <Modal.Content>
                <Text>
                    <Trans>
                        Your Starter plan includes all the basics for a small team. You can upgrade
                        your plan anytime in
                    </Trans>{' '}
                    <Button
                        variant="tertiary"
                        onClick={async () => {
                            dismiss()
                            navigate('/settings/general/billing?choosePlan=true')
                        }}
                    >
                        <Trans>Settings</Trans>
                    </Button>
                    .
                </Text>
            </Modal.Content>

            <Modal.Actions>
                <Button variant="primary" onClick={dismiss}>
                    <Trans>Let's get started</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export const StarterActivationModalContainer = () => {
    const {data} = bff.featureOnboarding.starterActivationModal.getContainerData.useQuery()
    const {hasUserPickedTrialPlanFlag, isLoadingUserPickedTrialPlanFlag} =
        useUserPickedTrialPlanFlag()

    if (
        !data ||
        isLoadingUserPickedTrialPlanFlag ||
        !hasUserPickedTrialPlanFlag ||
        data.hasDismissedModal
    ) {
        return null
    }

    return <StarterActivationModal />
}

const Illustration = styled.img`
    width: 100%;
    max-width: 320px;
`
