import {absurd} from '@product-web/shared--utils'

import type {SamlProvider} from '../contexts/saml-provider-context'

type Case<T> = T extends string ? T : never
type Cases<T, P, R> = Record<Case<T>, (param: P) => R>
type SwitchWithParam<T> = <P, R>(cases: Cases<T, P, R>) => (c: Case<T>, param: P) => R
type Switch<T> = <R>(cases: Cases<T, undefined, R>) => (c: Case<T>) => R

export const switchSamlIntegrationWithParam: SwitchWithParam<SamlProvider['slug']> =
    ({custom, entra, google, okta}) =>
    (slug, param) => {
        switch (slug) {
            case 'entra':
                return entra(param)
            case 'okta':
                return okta(param)
            case 'google':
                return google(param)
            case 'custom':
                return custom(param)

            default:
                return absurd(slug)
        }
    }

export const switchSamlIntegration: Switch<SamlProvider['slug']> = (cases) => (slug) =>
    switchSamlIntegrationWithParam(cases)(slug, undefined)
