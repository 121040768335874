import {Trans} from '@lingui/macro'

import {OverviewRight, UsefulMaterials} from '../../../components/overview-right/overview-right'
import {LessonNavigation} from '../lesson-navigation'

export const ModuleOverview = () => (
    <OverviewRight>
        <LessonNavigation />
        <UsefulMaterials>
            <UsefulMaterials.Document url="https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/nKLtLLpTcyHQVT6d?sellerPreviewing=true#/content/223e2970-e56a-4b0a-a692-24f68373b56a">
                <Trans>Infographic</Trans>
            </UsefulMaterials.Document>
        </UsefulMaterials>
    </OverviewRight>
)
