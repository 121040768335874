import {Trans} from '@lingui/macro'
import styled from 'styled-components'

import {Badge, Inline, Switch, Text} from '@pleo-io/telescope'

const Heading = styled(Text).attrs({
    variant: '3xlarge-accent',
    align: 'center',
    as: 'h2',
})`
    flex: 1;
`

export const WizardHeader = ({
    heading,
    isBillingAnnual,
    onBillingTypeChange,
    isSwitchVisible = true,
    showRecommendedBadge,
}: {
    heading: string
    isBillingAnnual: boolean
    onBillingTypeChange: (isAnnual: boolean) => void
    isSwitchVisible?: boolean
    showRecommendedBadge?: boolean
}) => {
    const savePercentage = 10

    return (
        <>
            <Inline space={8} alignY="center" justifyContent="center">
                <Heading css={{flex: '0 1 auto'}}>{heading}</Heading>
                {showRecommendedBadge && (
                    <Badge variant="discover">
                        <Trans>Recommended</Trans>
                    </Badge>
                )}
            </Inline>
            {isSwitchVisible && (
                <Inline justifyContent="center" space={8} paddingTop={16}>
                    <Switch
                        checked={isBillingAnnual}
                        onCheckedChange={onBillingTypeChange}
                        data-testid="annual-billing-switch"
                    >
                        <Text color="colorContentInteractive">
                            <Trans>Annual billing (Up to {savePercentage}% discount)</Trans>
                        </Text>
                    </Switch>
                </Inline>
            )}
        </>
    )
}
