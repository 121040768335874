import {t, Trans} from '@lingui/macro'

import {LessonNav} from '../../components/overview-right/overview-right'

export const LessonNavigation = () => (
    <LessonNav>
        <LessonNav.Link
            ariaLabel={t`Simplify your bookkeeping`}
            path="/partner/academy/smarter-spend-management/simplify-bookkeeping-workflow"
        >
            <Trans>Simplify your bookkeeping</Trans>
        </LessonNav.Link>
        <LessonNav.Link
            ariaLabel={t`Streamline clients' finances`}
            path="/partner/academy/smarter-spend-management/transform-client-finances"
        >
            <Trans>Streamline clients' finances</Trans>
        </LessonNav.Link>
        <LessonNav.Link
            ariaLabel={t`The Partner Programme`}
            path="/partner/academy/smarter-spend-management/unlock-growth"
        >
            <Trans>The Partner Programme</Trans>
        </LessonNav.Link>
        <LessonNav.Link
            ariaLabel={t`Get started with the Partner Portal`}
            path="/partner/academy/smarter-spend-management/manage-clients-and-team"
        >
            <Trans>Get started with the Partner Portal</Trans>
        </LessonNav.Link>
    </LessonNav>
)
