import {t, Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import styled from 'styled-components'

import {Button, Inline, Stack, Text, tokens} from '@pleo-io/telescope'
import {NewTab} from '@pleo-io/telescope-icons'

interface CardProps {
    description: ReactNode
    icon: ReactNode
    title: string
}

export const Card = ({description, icon, title}: CardProps) => (
    <CardWrapper>
        {icon}
        <Title>{title}</Title>
        <Text>{description}</Text>
    </CardWrapper>
)

interface DemoCardProps {
    demoUrl: string
    image: string
    title: string
}

export const DemoCard = ({demoUrl, image, title}: DemoCardProps) => (
    <DemoWrapper>
        <Inline p={24} alignX="center" css={{width: '100%'}}>
            <Title>{title}</Title>
        </Inline>
        <DemoImageWrapper>
            <DemoImage src={image} alt={title} />
            <DemoButton
                aria-label={t`Launch demo for ${title}`}
                variant="secondary"
                IconRight={NewTab}
                href={demoUrl}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Trans>Launch demo</Trans>
            </DemoButton>
        </DemoImageWrapper>
    </DemoWrapper>
)

const CardWrapper = styled(Stack).attrs({space: 12})`
    padding: ${tokens.spacing24} ${tokens.spacing16};
    border: ${tokens.borderPrimary};
    border-radius: ${tokens.arc8};
`
const Container = styled.div<{minChildWidth?: number}>`
    display: grid;
    ${({minChildWidth = 320}) =>
        `grid-template-columns: repeat(auto-fill, minmax(${minChildWidth}px, 1fr));`}
    gap: ${tokens.spacing24};
`
const DemoButton = styled(Button).attrs({variant: 'secondary'})`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
const DemoImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 0 0 ${tokens.arc8} ${tokens.arc8};
`
const DemoImageWrapper = styled.div`
    position: relative;
    border-radius: 0 0 ${tokens.arc8} ${tokens.arc8};
`
const DemoWrapper = styled(Stack)`
    border: ${tokens.borderPrimary};
    border-radius: ${tokens.arc8};
`
const Title = styled(Text).attrs({as: 'h5', variant: 'large-accent'})`
    font-weight: ${tokens.fontWeightSemibold};
`

interface CardGroupProps {
    children: ReactNode
    minChildWidth?: number
}
export const CardGroup = (props: CardGroupProps) => <Container {...props} />
