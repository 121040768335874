import {t, Trans} from '@lingui/macro'

import {useAcademyContext} from '../../components/academy-context/academy-context'
import {LessonNav, UsefulMaterials} from '../../components/overview-right/overview-right'

export const LessonNavigation = () => {
    const {country} = useAcademyContext()

    const getUrl = () => {
        switch (country) {
            case 'DE':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/pT4-eOzWoFyLUCvU#/content/5e379cd3-b25b-4573-8034-f73cef2e54be'
            case 'SE':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/zfIDiA-z2hCp8Nyr#/content/b2ddb560-c434-405a-ae1c-d968b0d58e6f'
            default:
                // default is GB/English
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/VrNiy3RHUlDWoLsF#/content/2fb6c6fd-137e-4de8-b26e-57ff57c30db3'
        }
    }

    return (
        <>
            <LessonNav>
                <LessonNav.Link
                    ariaLabel={t`Introduction`}
                    path="/partner/academy/introduction-to-pleo/introduction"
                >
                    <Trans>Introduction (3 mins)</Trans>
                </LessonNav.Link>
                <LessonNav.Link
                    ariaLabel={t`Employee experience`}
                    path="/partner/academy/introduction-to-pleo/employee-experience"
                >
                    <Trans>Employee experience (2 mins)</Trans>
                </LessonNav.Link>
                <LessonNav.Link
                    ariaLabel={t`Pleo for your clients`}
                    path="/partner/academy/introduction-to-pleo/pleo-for-your-clients"
                >
                    <Trans>Pleo for your clients (2 mins)</Trans>
                </LessonNav.Link>
            </LessonNav>
            <UsefulMaterials>
                <UsefulMaterials.Document url={getUrl()}>
                    <Trans>What is Pleo?</Trans>
                </UsefulMaterials.Document>
                <UsefulMaterials.Document url="https://www.pleo.io/pricing">
                    <Trans>Pleo price plans</Trans>
                </UsefulMaterials.Document>
            </UsefulMaterials>
        </>
    )
}
