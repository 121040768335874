import {useCallback} from 'react'
import {useLocation, useNavigate} from 'react-router'

import type {
    AgreementStatus,
    FreeFieldsAgreementFormValues,
} from '@product-web/shared--api-types/direct-debit'
import type {AutoTopUpSettings} from '@product-web/shared--api-types/wallet'

export interface AgreementCreationNavigationState {
    agreementStepIndex?: number
    preFilledSourceOfFundsDetails?: FreeFieldsAgreementFormValues
    // todo: replace "AutoTopUpSettings" type by TransferRule schema,
    //  once we switch completely to Transfer Rule (to replace Auto top-up)
    //  More details here: https://linear.app/pleo/issue/WALLE-5597
    preFilledAutoTopUpSettings?: AutoTopUpSettings
    preFilledTransferRuleConfiguration?: {
        sourceOfFunds?: {accountId: string; mandateId: string}
        skipTopUpSettings?: {
            isSkipTopUpConditionEnabled: boolean
            sourceAccountBalanceThreshold: number
        }
    }
    preFilledReserveEnabled?: boolean
    preFilledReserveLimit?: number
    needToRemovePrevMandate?: boolean
    agreementCreationStatus?: AgreementStatus | null
    wasCreationSuccessful?: boolean
    wasReserveActivationSuccessful?: boolean
    backToUrl?: string
}

export function useDirectDebitNavigationState(country?: string): AgreementCreationNavigationState {
    const location = useLocation()

    const {
        wasCreationSuccessful = country === 'DK' || undefined,
        agreementCreationStatus = country === 'DK' ? 'PENDING' : null,
        agreementStepIndex,
        preFilledSourceOfFundsDetails,
        preFilledAutoTopUpSettings,
        preFilledTransferRuleConfiguration,
        preFilledReserveEnabled,
        preFilledReserveLimit,
        needToRemovePrevMandate,
    } = (location.state ?? {}) as AgreementCreationNavigationState

    return {
        wasCreationSuccessful,
        agreementCreationStatus,
        agreementStepIndex,
        preFilledSourceOfFundsDetails,
        preFilledAutoTopUpSettings,
        preFilledTransferRuleConfiguration,
        preFilledReserveEnabled,
        preFilledReserveLimit,
        needToRemovePrevMandate,
    }
}

type DirectDebitProduct = 'auto-top-up' | 'overdraft' | 'direct-debit'

export const useNavigateToDirectDebitCreation = (params?: {
    product?: DirectDebitProduct
    isCashManagementPage?: boolean
}) => {
    // by default, we create a Direct Debit agreement without any additional product (such as Auto Top-up or Overdraft)
    const product = params?.product ?? 'direct-debit'

    const navigate = useNavigate()

    const navigateToDirectDebitCreation = useCallback(
        (state?: AgreementCreationNavigationState) => {
            if (params?.isCashManagementPage) {
                navigate(`/cash-management/direct-debit/create/${product}`, {state})
            } else {
                navigate(`/wallet/direct-debit/${product}`, {state})
            }
        },
        [navigate, params?.isCashManagementPage, product],
    )

    return {
        navigateToDirectDebitCreation,
    }
}
