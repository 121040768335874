import {t} from '@lingui/macro'

import {PreTrialPlanBreakdownContainer} from '@product-web/shared--plan-presentation/pre-trial-plan-breakdown'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useToaster} from '@product-web/shared--toaster'
import {getCountry, useUser} from '@product-web/shared--user'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'
import type {CpqRatePlanType} from '@shared/bff--moons/generated/beyond'

import {bff} from '../../bff-hooks'
import type {PlansData} from '../../index.bff'
import type {getSortedPlans} from '../helpers/get-sorted-plans'

const getIsDowngrade = (currentPlan: CpqRatePlanType, targetPlan: CpqRatePlanType) => {
    const planPriority: Record<CpqRatePlanType, number> = {
        STARTER: 1,
        ESSENTIAL: 2,
        ADVANCED: 3,
        BEYOND: 4,
    }

    return planPriority[targetPlan] < planPriority[currentPlan]
}

export const PreTrialPlanBreakdownWrapper = ({
    selectedPlanData,
    isBillingAnnual,
    currency,
    currentPlan,
    isCompanyVerified,
    planLimitsByPlanType,
    onConfirm,
}: {
    selectedPlanData: ReturnType<typeof getSortedPlans>[0]
    isBillingAnnual: boolean
    currency: string
    currentPlan: CpqRatePlanType
    isCompanyVerified: boolean
    planLimitsByPlanType: PlansData['planLimitsByPlanType']
    onConfirm: () => void
}) => {
    const {showToast} = useToaster()
    const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints.desktopMedUp})`)
    const user = useUser()
    const {mutateAsync: selectTrialPlan, isLoading} =
        bff.paywall.planFeatures.selectTrialPlan.useMutation()

    const targetBillingType = isBillingAnnual ? 'YEAR' : 'MONTH'

    const additionalUserBilling = selectedPlanData.additionalUserBilling[targetBillingType]
    const maxAllowedEmployees = selectedPlanData.planName
        ? planLimitsByPlanType[selectedPlanData.planName].maxAllowedEmployees
        : undefined
    const numMaxAdditionalUsers =
        !maxAllowedEmployees || !additionalUserBilling?.freeUserLimit
            ? undefined
            : maxAllowedEmployees - additionalUserBilling.freeUserLimit

    const handleSelectPlanClick = async () => {
        try {
            await selectTrialPlan({
                selectedPlanType: selectedPlanData.planName,
                billingPeriodType: isBillingAnnual ? 'YEAR' : 'MONTH',
                countryCode: getCountry(user),
            })

            onConfirm()
        } catch (error) {
            showToast(t`An error occurred. Please try again later or contact support.`, {
                level: 'error',
            })
        }
    }

    return (
        <div
            css={{
                width: '100%',
                maxWidth: isSmallScreen ? 'unset' : '360px',
            }}
        >
            <PreTrialPlanBreakdownContainer
                plan={selectedPlanData.planName}
                isAnnualBilling={isBillingAnnual}
                planHasFreeTrial={selectedPlanData.planName !== 'STARTER'}
                planPrice={selectedPlanData[isBillingAnnual ? 'YEAR' : 'MONTH']}
                currency={currency}
                onSelectPlanClick={handleSelectPlanClick}
                numIncludedUsers={additionalUserBilling?.freeUserLimit}
                numMaxAdditionalUsers={numMaxAdditionalUsers}
                additionalUsersPrice={additionalUserBilling?.pricePerUser?.value}
                isDowngrade={
                    !!currentPlan && getIsDowngrade(currentPlan, selectedPlanData.planName)
                }
                trialPopoverProps={{
                    stepsToShowNames: ['sign-up', 'verify-company', 'add-funds', 'trial-starts'],
                    currentStep: isCompanyVerified ? 'add-funds' : 'verify-company',
                }}
                isLoading={isLoading}
            />
        </div>
    )
}
