import {Trans} from '@lingui/macro'

import {Button, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import type {DispatchActions, Step} from '@product-web/feature--ui-guides/guide'

import {AddOrGroupStepContent} from './add-or-group-step-form'

import AddEntityIllustration from '../../../../images/add-entity-monopoly.svg'

export const useAddOrGroupStep = ({isOrganizationAdmin}: {isOrganizationAdmin: boolean}): Step => ({
    key: 'add-or-group',
    title: (
        <Text variant="4xlarge-accent" as="h3" align="left">
            <Trans>Add an entity to your organisation</Trans>
        </Text>
    ),
    color: tokens.colorBackgroundPresentationalPink,
    illustrationBgColor: tokens.colorBackgroundPresentationalPink,
    sections: [
        {
            key: 'add-or-group-content',
            content: (dispatchActions: DispatchActions) => (
                <AddOrGroupStepContent {...dispatchActions} />
            ),
        },
    ],
    illustration: <img src={AddEntityIllustration} alt="" />,
    actions: [
        {
            key: 'back',
            action: ({onPreviousStep}: DispatchActions) => {
                return (
                    !isOrganizationAdmin && (
                        <Button variant="secondary" IconLeft={ArrowLeft} onClick={onPreviousStep}>
                            <Trans>Back</Trans>
                        </Button>
                    )
                )
            },
        },
    ],
})
