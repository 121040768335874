import {Trans} from '@lingui/macro'
import {Link as RouterLink} from 'react-router-dom'

import {Button, LoadingPage} from '@pleo-io/telescope'
import {ArrowRight, Wand} from '@pleo-io/telescope-icons'

import {LandingPages} from '@product-web/feature--adoption/landing-pages/landing-pages'
import {defineClientContext} from '@product-web/shared--bff-context'

import {bff} from '../../../bff'
import {useTagBudgetTracking} from '../../../lib/use-tag-budget-tracking'

export const ViewDemoButton = ({
    onOpenDrawer,
    alternativeCopy,
}: {
    onOpenDrawer: (id: string) => void
    alternativeCopy?: boolean
}) => {
    const tracking = useTagBudgetTracking()
    return (
        <Button
            variant="secondary"
            IconRight={alternativeCopy ? undefined : Wand}
            data-testid="view-demo-budget-button"
            onClick={() => {
                tracking.demoBudgetActioned({
                    action: 'clicked',
                    source: 'view_demo_budget_button',
                })
                onOpenDrawer('demo')
            }}
        >
            {alternativeCopy ? <Trans>View demo</Trans> : <Trans>View demo budget</Trans>}
        </Button>
    )
}

export const TagBudgetsEmptyState = ({onOpenDrawer}: {onOpenDrawer: (id: string) => void}) => {
    const {data: hasTags, isLoading} = bff.tagBudgetsEmptyState.getHasTagsInfo.useQuery(undefined, {
        trpc: {
            context: defineClientContext({
                skipBatch: true,
            }),
        },
    })

    if (isLoading) {
        return <LoadingPage />
    }

    const EntitledAction = () =>
        hasTags ? (
            <Button variant="primary" as={RouterLink} to="/budgets/create">
                <Trans>Create budget</Trans>
            </Button>
        ) : (
            <Button
                variant="primary"
                as={RouterLink}
                to="/settings/accounting/tags"
                IconRight={ArrowRight}
            >
                <Trans>Create tag</Trans>
            </Button>
        )

    return (
        <LandingPages
            pageOrigin="budgets"
            topAction={<ViewDemoButton onOpenDrawer={onOpenDrawer} alternativeCopy />}
            entitledCTA={<EntitledAction />}
        />
    )
}
