import {t, Trans} from '@lingui/macro'
import {useState} from 'react'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Box, Button, Inline, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft, ArrowRight, Clock, Email, HighFive} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'
import * as tracking from '@product-web/shared--analytics'
import {getHelpCentreArticleLink} from '@product-web/shared--locale/helpers'
import {InlineWistiaVideo} from '@product-web/shared--telescope-lab/wistia-video/inline-wistia-video'

import {InvoicesModal} from './invoices-modal'
import {LessonNavigation} from './lesson-navigation'

import {SplitContent} from '../../../../components/split-content'
import {useAcademyContext} from '../../components/academy-context/academy-context'
import {OverviewRight, UsefulMaterials} from '../../components/overview-right/overview-right'
import {Card, CardGroup} from '../../components/snapshot'

export const Invoices = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleModal = () => setIsOpen(!isOpen)
    const {country} = useAcademyContext()

    const getVideo = () => {
        switch (country) {
            case 'AT':
            case 'DE':
                return {
                    id: 'rzcltb3lh7',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/a4b1b2f6ef0c0bb24f0d7f1103fd694f0777d3a8.jpg?video_still_time=40',
                }
            case 'ES':
                return {
                    id: '7vssyhy9y7',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/5ae95a2f3d71ced686d29ef371535da5e4e61163.jpg?video_still_time=32.5',
                }
            case 'FR':
                return {
                    id: 'k7qz4s7fky',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/49f1f45bbb0ad61baa1ed20e91c222c9741de8a4.jpg?video_still_time=32',
                }
            case 'NL':
                // NL video is in English ??
                return {
                    id: 'lz5xkovyqa',
                    thumbnail:
                        'https://embed.wistia.com/deliveries/ad76c6fa76caa0612ad7b01d3feec8f9a4dd50e8.jpg?video_still_time=32',
                }
            default:
                // default is GB
                return {
                    id: 'djmkclbibj',
                    thumbnail:
                        'https://embed-ssl.wistia.com/deliveries/ec9142ff0a4323f113f6047ca05683214713a5fc.jpg?video_still_time=32',
                }
        }
    }

    const getInvoicesUrl = () => {
        switch (country) {
            case 'DE':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/pT4-eOzWoFyLUCvU#/content/774e2a20-5455-44db-bb07-7824b66bfe96'
            default:
                // Default is EN
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/VrNiy3RHUlDWoLsF#/content/492f0c44-68bb-4272-ade5-4eae69c6effb'
        }
    }

    return (
        <>
            <Helmet>
                <title>{t`The Pleo Product`}</title>
            </Helmet>
            <Page.Header>
                <Page.Backlink>
                    <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                        <Trans>Back to overview</Trans>
                    </Link>
                </Page.Backlink>
                <Page.Title>
                    <Trans>The Pleo Product</Trans>
                </Page.Title>
                <Inline alignItems="center" space={6} mb={8}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>2 mins</Trans>
                    </Text>
                </Inline>
                <SplitContent>
                    <SplitContent.Main>
                        <Text>
                            <Trans>
                                Give your clients the full picture on all their spend, with the
                                ability to process, approve and pay supplier invoices directly from
                                their Pleo Wallet.
                            </Trans>
                        </Text>
                        <Box mb={24} />
                    </SplitContent.Main>
                    <SplitContent.Right></SplitContent.Right>
                </SplitContent>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main flex={4}>
                    <Stack space={16}>
                        <Text as="h2" variant="2xlarge-accent" space={4}>
                            <Trans>Invoices</Trans>
                        </Text>
                        <Text>
                            <Trans>
                                Save time and avoid costly errors by automating your accounts
                                payable. Capture, process, approve, pay, and bookkeep your invoices,
                                all in one place.
                            </Trans>
                        </Text>
                        <Button variant="tertiary" onClick={toggleModal}>
                            <Trans>What can I do with Invoices?</Trans>
                        </Button>
                        <InlineWistiaVideo
                            ariaLabel={t`Play video - Invoices`}
                            thumbnail={{src: getVideo().thumbnail}}
                            videoId={getVideo().id}
                            trackingCallback={(action) =>
                                tracking.partnerAcademy({action, video: 'invoices'})
                            }
                            autoPlay={false}
                            imgMinHeight={348}
                        />
                        <Stack mb={12} />
                        <Text as="h2" variant="2xlarge-accent">
                            <Trans>Easily capture invoices with Pleo</Trans>
                        </Text>
                        <CardGroup>
                            <Card
                                icon={<Email />}
                                title={t`Forward invoices to Pleo`}
                                description={
                                    <Text>
                                        <Trans>
                                            Create a new forwarding email address for easier
                                            management of all of your Invoices in your Pleo account.{' '}
                                            <Link
                                                href={getHelpCentreArticleLink(103000285539)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Find out more
                                            </Link>
                                            .
                                        </Trans>
                                    </Text>
                                }
                            />
                            <Card
                                icon={<HighFive />}
                                title={t`Share it with your team and suppliers`}
                                description={t`Admins can settle and reimburse employees, or choose to let employees reimburse themselves for approved expenses.`}
                            />
                        </CardGroup>
                    </Stack>
                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link
                            as={RouterLink}
                            to="../reimbursements-and-mileage"
                            IconLeft={ArrowLeft}
                        >
                            <Trans>Back</Trans>
                        </Link>
                        <Button
                            variant="primary"
                            as={RouterLink}
                            to="../vendor-cards"
                            IconRight={ArrowRight}
                        >
                            <Trans>Next</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <OverviewRight>
                        <LessonNavigation />
                        <UsefulMaterials>
                            <UsefulMaterials.Document url={getInvoicesUrl()}>
                                <Trans>How do invoices work?</Trans>
                            </UsefulMaterials.Document>
                            <UsefulMaterials.HelpCenterArticle articleId={103000285539}>
                                <Trans>Help centre: Invoices</Trans>
                            </UsefulMaterials.HelpCenterArticle>
                        </UsefulMaterials>
                    </OverviewRight>
                </SplitContent.Right>
            </SplitContent>
            <InvoicesModal isOpen={isOpen} close={toggleModal} />
        </>
    )
}
