import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import styled, {css, keyframes} from 'styled-components'

import {focusRing, px, tokens} from '@pleo-io/telescope'

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(${px(10)});
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const StyledDropdownMenuTrigger = styled(RadixDropdownMenu.Trigger)`
    transition: none;
`

export const StyledDropdownMenuContent = styled(RadixDropdownMenu.Content)`
    padding: ${tokens.spacing6} 0;
    background: ${tokens.colorBackgroundInteractive};
    border: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderInteractiveQuiet};
    border-radius: ${tokens.arc8};
    box-shadow: ${tokens.shadowElevateQuiet};
    box-sizing: border-box;
    animation: ${fadeInUp} ${tokens.smoothInOut};
    animation-name: ${fadeInUp};
    animation-duration: ${tokens.smooth};
`

const BaseDropdownMenuItem = css`
    ${focusRing('inset')}
    color: ${tokens.colorContentInteractive};
    font-size: ${tokens.fontMedium};
    user-select: none;
    cursor: pointer;
    transition: background-color ${tokens.fastIn};
    box-sizing: border-box;

    &:hover {
        background-color: ${tokens.colorBackgroundInteractiveHover};
    }
`

export const StyledDropdownMenuItem = styled(RadixDropdownMenu.Item)`
    ${BaseDropdownMenuItem}
    min-width: ${px(150)};
    padding: ${tokens.spacing6} ${tokens.spacing16};

    &[aria-disabled] {
        color: ${tokens.colorContentInteractiveDisabled};
        cursor: not-allowed;
    }
`

export const NakedDropdownMenuItem = styled(RadixDropdownMenu.Item)`
    ${BaseDropdownMenuItem}
`
