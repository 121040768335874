import {t, Trans} from '@lingui/macro'
import type {PropsWithChildren} from 'react'

import {Box, Inline, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {Check, Clock} from '@pleo-io/telescope-icons'

import {useTermsDocuments} from '@product-web/shared--country-configuration/features/terms/terms'
import {SupportedLanguage} from '@product-web/shared--i18n'
import {formatMinorNumberToCurrency} from '@product-web/shared--plan-presentation/currency-formatter'
import {
    getPlanContentConfiguration,
    renderUpToCashbackLine,
    renderUpToCreditPrice,
} from '@product-web/shared--plan-presentation/pricing-plans.helper'
import {useUser} from '@product-web/shared--user'
import type {BillingInfoCurrency, CpqRatePlanType} from '@shared/bff--moons/generated/beyond'

import {usePreApprovedOverdraftInfo} from '../../overdraft/use-pre-approved-overdraft-info'

export type EligibleEntitlementsSectionProps = {
    targetPlanType: CpqRatePlanType
    currency: BillingInfoCurrency
    isBillingAnnual: boolean
    hasCurrentSubscriptionCashback: boolean
}

export type EligibleEntitlements = 'CASHBACK' | 'OVERDRAFT'

export const EligibleEntitlementsSection = ({
    targetPlanType,
    currency,
    isBillingAnnual,
    hasCurrentSubscriptionCashback,
    children,
}: PropsWithChildren<EligibleEntitlementsSectionProps>) => {
    const user = useUser()
    const locale = user?.language ?? SupportedLanguage.EN

    const {overdraftTerms} = useTermsDocuments()

    const preApprovedOverdraft = usePreApprovedOverdraftInfo()

    const targetPlanConfiguration = getPlanContentConfiguration(targetPlanType)
    const cashbackPercentage =
        isBillingAnnual && targetPlanConfiguration.cashback ? targetPlanConfiguration.cashback : 0
    const defaultOverdraft = targetPlanConfiguration.overdraft?.[currency] ?? 0

    // Get the eligible entitlements limits for the target plan as a number or a string
    const eligibleEntitlementsLimits = getEligibleEntitlementLimits({
        currency,
        locale,
        hasCurrentSubscriptionCashback,
        overdraft: defaultOverdraft,
        cashback: cashbackPercentage,
        preApprovedOverdraft,
        overdraftTerms,
    })

    // If there are no eligible entitlements, don't render the section
    if (eligibleEntitlementsLimits.size === 0) {
        return null
    }

    return (
        <>
            {children}
            <Box data-testid="eligible-entitlements">
                <Stack space={12}>
                    <Text variant="large-accent" weight="medium">
                        <Trans>Cash management</Trans>
                    </Text>
                    {Array.from(eligibleEntitlementsLimits.entries()).map(
                        ([key, {title, icon, label}]) =>
                            CashManagementItem({key, title, icon, label}),
                    )}
                </Stack>
            </Box>
        </>
    )
}

const CashManagementItem = ({
    key,
    title,
    icon,
    label,
}: {
    key: string
    title: string
    icon: React.ReactNode
    label: React.ReactNode
}) => (
    <Inline key={key} space={8} alignContent="start" css={{maxWidth: '400px'}}>
        {icon}
        <Stack space={4}>
            <Text color="colorContentStatic" css={{lineHeight: 'normal'}}>
                {title}
            </Text>
            <Text color="colorContentStaticQuiet" variant="small-subtle">
                {label}
            </Text>
        </Stack>
    </Inline>
)

const getEligibleEntitlementLimits = ({
    currency,
    locale,
    hasCurrentSubscriptionCashback,
    overdraft,
    cashback,
    preApprovedOverdraft,
    overdraftTerms,
}: {
    currency: BillingInfoCurrency
    locale: SupportedLanguage
    hasCurrentSubscriptionCashback?: boolean
    hasPreApprovedOverdraft?: boolean
    overdraft?: number
    cashback?: number
    preApprovedOverdraft?: ReturnType<typeof usePreApprovedOverdraftInfo>
    overdraftTerms?: string
}) => {
    const overdraftValue = preApprovedOverdraft?.isEligible
        ? preApprovedOverdraft.maxLimit
        : overdraft

    const formattedCreditLineAmount = overdraftValue
        ? formatMinorNumberToCurrency({
              value: overdraftValue,
              options: {
                  currency,
                  maximumFractionDigits: 1,
                  notation: 'compact',
              },
              locale,
          })
        : ''

    const eligibleEntitlementsLimits = new Map()

    if (
        formattedCreditLineAmount &&
        !preApprovedOverdraft?.isEligible &&
        !preApprovedOverdraft?.isFetching
    ) {
        eligibleEntitlementsLimits.set('OVERDRAFT', {
            title: renderUpToCreditPrice(formattedCreditLineAmount),
            icon: <Clock size={16} color={tokens.colorContentStaticQuiet} />,
            label: <Trans>Contact us to check eligibility after upgrade.</Trans>,
        })
    }

    if (
        formattedCreditLineAmount &&
        preApprovedOverdraft?.isEligible &&
        !preApprovedOverdraft.isFetching
    ) {
        eligibleEntitlementsLimits.set('OVERDRAFT', {
            title: t`${formattedCreditLineAmount} pre-approved overdraft`,
            icon: <Check size={16} color={tokens.colorContentPositive} />,
            label: (
                <Trans>
                    Activate after you upgrade for an annual fee of{' '}
                    {preApprovedOverdraft.activationFeePercent}%. Interest applies if not repaid by
                    month-end.{' '}
                    <Link href={overdraftTerms} target="_blank" rel="noopener noreferrer" inherit>
                        Read more
                    </Link>
                </Trans>
            ),
        })
    }

    if (cashback) {
        eligibleEntitlementsLimits.set('CASHBACK', {
            title: renderUpToCashbackLine(cashback),
            icon: hasCurrentSubscriptionCashback ? (
                <Check size={16} color={tokens.colorContentPositive} />
            ) : (
                <Clock size={16} color={tokens.colorContentStaticQuiet} />
            ),
            label: <Trans>Contact us to check eligibility after upgrade.</Trans>,
        })
    }

    return eligibleEntitlementsLimits
}
