import {lazy, Suspense} from 'react'
import {Navigate, Route} from 'react-router-dom'

import {LoadingPage} from '@pleo-io/telescope'

import {useFlags} from '@product-web/shared--flags'
import {roleSets} from '@product-web/shared--user'

const VendorCardPage = lazy(async () => import('./screens/vendor-cards'))
const VendorCardDetailsDrawer = lazy(
    async () => import('@product-web/feature--vendor-cards/vendor-card-details-drawer'),
)

const VendorCardTemplatePage = lazy(
    async () =>
        import(
            '@product-web/feature--vendor-cards/spend-activation-templates/vendor-card-template-page/vendor-card-template-page'
        ),
)

const ShowVendorCardTemplatePage = () => {
    const {popularVendorCardsLandingPage} = useFlags()

    if (!popularVendorCardsLandingPage) {
        return <Navigate to="/vendor-cards" replace />
    }

    return (
        <Suspense fallback={<LoadingPage />}>
            <VendorCardTemplatePage />
        </Suspense>
    )
}

export const routes = (
    <>
        <Route
            path="/vendor-cards"
            handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
            element={<VendorCardPage />}
        >
            <Route
                path=":cardId"
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <VendorCardDetailsDrawer />
                    </Suspense>
                }
            >
                <Route
                    path=":expenseId"
                    element={
                        <Suspense fallback={<LoadingPage />}>
                            <VendorCardDetailsDrawer />
                        </Suspense>
                    }
                />
            </Route>
        </Route>
        <Route
            path="create"
            element={<VendorCardPage />}
            handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
        />
        <Route
            path="popular"
            element={<ShowVendorCardTemplatePage />}
            handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
        />
    </>
)
