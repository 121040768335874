import {useEffect, useRef} from 'react'

/**
 * Hook to manage initialisation-dependent actions.
 *
 * This hook will execute the `onInitialised` callback once the context is initialised.
 *
 * @param onInitialised Callback to execute when the context is initialised
 */
export const useInitialisedAction = ({onInitialised}: {onInitialised: () => void}) => {
    const isInitialisedRef = useRef(false)

    useEffect(() => {
        // Do nothing if the context is already initialised
        if (isInitialisedRef.current) {
            return
        }

        // Set the context as initialised
        isInitialisedRef.current = true

        // Execute the onInitialised callback
        onInitialised()
    }, [onInitialised])
}
