import {t, Trans} from '@lingui/macro'
import {useState} from 'react'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Box, Button, Inline, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {
    ArrowLeft,
    ArrowRight,
    Clock,
    HighFive,
    ShoppingBag,
    User,
    Wand,
} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'
import {getHelpCentreArticleLink} from '@product-web/shared--locale/helpers'

import {InvoicesModal} from './invoices-modal'
import {LessonNavigation} from './lesson-navigation'

import {SplitContent} from '../../../../components/split-content'
import {useAcademyContext} from '../../components/academy-context/academy-context'
import {OverviewRight, UsefulMaterials} from '../../components/overview-right/overview-right'
import {Card, CardGroup} from '../../components/snapshot'
import vendorCardsGbGif from '../../images/vendor-cards.gif'
import vendorCardsDeGif from '../../images/vendor-cards-de.gif'
import vendorCardsEsGif from '../../images/vendor-cards-es.gif'
import vendorCardsEurGif from '../../images/vendor-cards-eur.gif'
import vendorCardsFrGif from '../../images/vendor-cards-fr.gif'

export const VendorCards = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleModal = () => setIsOpen(!isOpen)
    const {country, hideInvoices} = useAcademyContext()

    const getGif = () => {
        switch (country) {
            case 'AT':
            case 'DE':
                return vendorCardsDeGif
            case 'ES':
                return vendorCardsEsGif
            case 'FR':
                return vendorCardsFrGif
            case 'GB':
                return vendorCardsGbGif
            // TODO get gifs for DK, NL & SE
            case 'SE':
            case 'DK':
            case 'GL':
            case 'NL':
            default:
                // default is EUR
                return vendorCardsEurGif
        }
    }

    return (
        <>
            <Helmet>
                <title>{t`The Pleo Product`}</title>
            </Helmet>
            <Page.Header>
                <Page.Backlink>
                    <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                        <Trans>Back to overview</Trans>
                    </Link>
                </Page.Backlink>
                <Page.Title>
                    <Trans>The Pleo Product</Trans>
                </Page.Title>
                <Inline alignItems="center" space={6} mb={8}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>2 mins</Trans>
                    </Text>
                </Inline>
                <SplitContent>
                    <SplitContent.Main>
                        <Text>
                            <Trans>
                                Help your clients easily manage recurring payments with Pleo's
                                Vendor cards.
                            </Trans>
                        </Text>
                        <Box mb={24} />
                    </SplitContent.Main>
                    <SplitContent.Right></SplitContent.Right>
                </SplitContent>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main flex={4}>
                    <Stack space={16}>
                        <Text as="h2" variant="2xlarge-accent" space={4}>
                            <Trans>Vendor Cards</Trans>
                        </Text>
                        <Text>
                            <Trans>
                                Zoom licenses or Slack subscriptions? Control your client finances
                                and eliminate nasty surprises with a Vendor card.
                            </Trans>
                        </Text>
                        <Link
                            href={getHelpCentreArticleLink(103000254446)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Trans>How do I create, edit or delete a Vendor card?</Trans>
                        </Link>
                        <img
                            src={getGif()}
                            alt=""
                            css={{
                                width: '100%',
                                height: 'auto',
                                display: 'block',
                                minHeight: '348px',
                            }}
                        />
                        <Stack mb={12} />
                        <Text as="h2" variant="2xlarge-accent">
                            <Trans>Vendor cards in a snapshot</Trans>
                        </Text>
                        <CardGroup>
                            <Card
                                icon={<Wand />}
                                title={t`Keep things tidy`}
                                description={t`Separate your personal spend limits from your business spend. Centralise your most frequent payments with everything in a comprehensive overview.`}
                            />
                            <Card
                                icon={<User />}
                                title={t`Never miss a payment`}
                                description={t`Got an employee that has left? Reassign your cards to new owners so that you don't miss a payment.`}
                            />
                            <Card
                                icon={<HighFive />}
                                title={t`Save time and money `}
                                description={t`Assign accounts, tax codes, and cost centres in advance. Just set and forget.`}
                            />
                            <Card
                                icon={<ShoppingBag />}
                                title={t`Control where to spend`}
                                description={t`If needed, you can limit spend to one or a selected group of vendors.`}
                            />
                        </CardGroup>
                    </Stack>
                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link
                            as={RouterLink}
                            to={hideInvoices ? '../reimbursements-and-mileage' : '../invoices'}
                            IconLeft={ArrowLeft}
                        >
                            <Trans>Back</Trans>
                        </Link>
                        <Button
                            variant="primary"
                            as={RouterLink}
                            to="../multi-entities"
                            IconRight={ArrowRight}
                        >
                            <Trans>Next</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <OverviewRight>
                        <LessonNavigation />
                        <UsefulMaterials>
                            <UsefulMaterials.HelpCenterArticle articleId={103000254446}>
                                <Trans>Help centre: Vendor cards</Trans>
                            </UsefulMaterials.HelpCenterArticle>
                        </UsefulMaterials>
                    </OverviewRight>
                </SplitContent.Right>
            </SplitContent>
            <InvoicesModal isOpen={isOpen} close={toggleModal} />
        </>
    )
}
