import {t} from '@lingui/macro'
import {type ChangeEvent, forwardRef, type RefObject, useId} from 'react'
import styled from 'styled-components'

import {Input, tokens, VisuallyHidden} from '@pleo-io/telescope'
import {Search as SearchIcon} from '@pleo-io/telescope-icons'

interface SearchProps {
    value: string
    onValueChange: (value: string) => void
}

export const Search = forwardRef<HTMLInputElement, SearchProps>(({value, onValueChange}, ref) => {
    const id = useId()

    return (
        <SearchContainer role="search">
            <VisuallyHidden>
                <label htmlFor={id}>{t`Filter entities by name.`}</label>
            </VisuallyHidden>
            <StyledSearchInput
                innerRef={ref as RefObject<HTMLInputElement>}
                id={id}
                name={id}
                type="search"
                variant="underlined"
                value={value}
                placeholder={t`Search`}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                    onValueChange(evt.target.value)
                }}
                prefix={<SearchIcon color={tokens.shade600} aria-hidden focusable={false} />}
            />
        </SearchContainer>
    )
})

// eslint-disable-next-line string-to-lingui/missing-lingui-transformation
Search.displayName = 'Search'

const SearchContainer = styled.div`
    width: 100%;
    height: 48px;
`

const StyledSearchInput = styled(Input)`
    * {
        background-color: transparent;
    }

    &,
    > * {
        height: 100%;
    }

    input {
        padding-left: ${tokens.spacing8};

        &::-webkit-search-cancel-button {
            appearance: none;
        }
    }
`
