import {t, Trans} from '@lingui/macro'
import {useState} from 'react'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Button, Inline, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft, ArrowRight, Clock} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'

import {ModuleOverview} from './module-overview'

import {SplitContent} from '../../../../../components/split-content'
import {QuizForm, type QuizQuestion} from '../../../components/quiz-form/quiz-form'

export const Quiz = () => {
    const [canContinue, setCanContinue] = useState(false)

    const questions: QuizQuestion[] = [
        {
            answers: [
                {
                    isCorrect: true,
                    label: t`New partners start with six months of Platinum-level benefits. As they grow, they unlock additional perks like revenue share, client discounts, and premium marketing opportunities.`,
                },
                {
                    label: t`All partners receive the same benefits regardless of tier.`,
                },
                {
                    label: t`Higher tiers like Gold and Platinum focus only on revenue share, while lower tiers provide marketing and training resources.`,
                },
                {
                    label: t`The tiers are based only on the number of clients onboarded, without additional benefits as you progress.`,
                },
            ],
            explanation: t`Disconnected tools delay reporting, increase the risk of errors, and limit visibility into cash flow, making it harder for clients to make timely financial decisions.`,
            question: t`How would you explain the progression through the Pleo Partner tiers and the benefits of reaching higher levels?`,
            scenario: t`The Partner Programme is structured to support growth at every stage. New partners receive comprehensive support to help them get started, while progressing through the tiers unlocks additional benefits that help firms expand their practice and attract more clients.`,
        },
        {
            answers: [
                {
                    label: t`It automatically tracks referrals, helping partners progress through the tiers.`,
                },
                {
                    label: t`Clients gain access to exclusive Pleo discounts only through the Partner Portal.`,
                },
                {
                    label: t`It ensures that you have access to the right training and onboarding support.`,
                },
                {
                    isCorrect: true,
                    label: t`All of the above`,
                },
            ],
            explanation: t`Referring clients through the Partner Portal makes sure the referrals are linked to your account, so you get credit toward tier progression and can unlock benefits like revenue share and discounts for your clients. Plus, the portal tracks everything automatically, making the whole process much easier for you.`,
            question: t`What key points should you include in your explanation?`,
            scenario: t`A team member asks why they should refer clients through the Partner Portal instead of simply sending clients a link to sign up. They're not sure how the portal adds value.`,
        },
    ]

    return (
        <>
            <Helmet>
                <title>{t`Quiz: The Partner Programme`}</title>
            </Helmet>
            <Page.Header css={{backgroundColor: tokens.colorBackgroundInteractive}}>
                <Page.Backlink>
                    <Link as={RouterLink} to="../../" IconLeft={ArrowLeft}>
                        <Trans>Back to overview</Trans>
                    </Link>
                </Page.Backlink>
                <Page.Title>
                    <Trans>The Partner Programme</Trans>
                </Page.Title>
                <Inline alignItems="center" space={6} mb={24}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>6 mins</Trans>
                    </Text>
                </Inline>
            </Page.Header>
            <Stack my={24} space={8}>
                <Text as="h2" variant="2xlarge-accent">
                    <Trans>Quiz</Trans>
                </Text>
                <Text variant="large-accent">
                    <Trans>
                        Complete questions to test your new knowledge. Try as many times as you
                        need.
                    </Trans>
                </Text>
            </Stack>
            <SplitContent>
                <SplitContent.Main flex={4}>
                    <QuizForm
                        quizKey="unlock_growth"
                        setCanContinue={setCanContinue}
                        quizQuestions={questions}
                    />
                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                            <Trans>Back</Trans>
                        </Link>
                        <Button
                            variant="primary"
                            as={RouterLink}
                            to="../../manage-clients-and-team"
                            disabled={!canContinue}
                            IconRight={ArrowRight}
                        >
                            <Trans>Continue</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <ModuleOverview />
                </SplitContent.Right>
            </SplitContent>
        </>
    )
}
