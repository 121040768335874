import {t, Trans} from '@lingui/macro'
import type {RefObject} from 'react'
import {useEffect, useRef, useState} from 'react'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'
import styled from 'styled-components'

import {
    Box,
    Button,
    IconButton,
    Inline,
    Link,
    List,
    ListItem,
    Stack,
    Tabs,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {
    ArrowLeft,
    ArrowRight,
    ChevronUp,
    Clock,
    Eye,
    FlashOn,
    Heart,
    NewTab,
    Rocket,
} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'
import * as tracking from '@product-web/shared--analytics'
import {InlineWistiaVideo} from '@product-web/shared--telescope-lab/wistia-video/inline-wistia-video'

import {ModuleOverview} from './module-overview'

import {SplitContent} from '../../../../../components/split-content'
import {AcademyTabs} from '../../../components/academy-tabs'
import {Card, CardGroup} from '../../../components/snapshot'

export const UnlockGrowth = () => {
    const [currentTab, setCurrentTab] = useState('takeaways')
    const [hasOverlap, setHasOverlap] = useState(false)
    const [showBackToTop, setShowBackToTop] = useState(false)

    const stickyContainerRef = useRef<HTMLDivElement>(null)
    const takeawaysRef = useRef<HTMLDivElement>(null)
    const introductionRef = useRef<HTMLDivElement>(null)
    const splitMainRef = useRef<HTMLDivElement>(null)

    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    const leeMaughan = 'Lee Maughan'
    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
    const greenAndPurple = 'Green & Purple'

    useEffect(() => {
        const handleScroll = () => {
            if (stickyContainerRef.current && takeawaysRef.current) {
                const stickyContainerRect = stickyContainerRef.current.getBoundingClientRect()
                const takeawaysRect = takeawaysRef.current.getBoundingClientRect()
                setHasOverlap(stickyContainerRect.bottom > takeawaysRect.top)
            }

            if (stickyContainerRef.current && introductionRef.current) {
                const stickyContainerRect = stickyContainerRef.current.getBoundingClientRect()
                const introductionRect = introductionRef.current.getBoundingClientRect()
                setHasOverlap(stickyContainerRect.bottom > introductionRect.top)
            }

            const scrollY = Math.round(window.scrollY)
            setShowBackToTop(scrollY > 40)
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    })

    const scrollToSection = (sectionRef: RefObject<HTMLDivElement>) => {
        if (sectionRef.current && stickyContainerRef.current) {
            const stickyRect = stickyContainerRef.current.getBoundingClientRect()
            const targetPosition =
                stickyRect.top + sectionRef.current.offsetTop - stickyRect.bottom - 20

            window.scrollTo({top: targetPosition, behavior: 'smooth'})
        }
    }

    return (
        <>
            <Helmet>
                <title>{t`How to unlock growth with a best-in-class Partner Programme`}</title>
            </Helmet>
            <Page.Header css={{backgroundColor: tokens.colorBackgroundInteractive}}>
                <Page.Backlink>
                    <Link as={RouterLink} to="../" IconLeft={ArrowLeft}>
                        <Trans>Back to overview</Trans>
                    </Link>
                </Page.Backlink>
                <Page.Title>
                    <Trans>How to unlock growth with a best-in-class Partner Programme</Trans>
                </Page.Title>
                <Inline alignItems="center" space={6} mb={24}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>10 mins</Trans>
                    </Text>
                </Inline>
                <Text as="h3" variant="xlarge-accent">
                    <Trans>Module overview</Trans>
                </Text>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main flex={4} ref={splitMainRef}>
                    <Tabs
                        value={currentTab}
                        onValueChange={(newValue) => {
                            setCurrentTab(newValue)
                            if (newValue === 'takeaways') {
                                scrollToSection(takeawaysRef)
                                return
                            }
                            scrollToSection(introductionRef)
                        }}
                    >
                        <Text>
                            <Trans>
                                Learn how Pleo's Partner Programme can accelerate your growth and
                                unlock new opportunities for your business. We'll also explore how
                                support from the right partner allows you to focus on the work that
                                matters.
                            </Trans>
                        </Text>
                        <Box mt={24}>
                            <InlineWistiaVideo
                                ariaLabel={t`Play video: Best in class Partner Programme`}
                                thumbnail={{
                                    src: 'https://embed-ssl.wistia.com/deliveries/e5855f7928ff0dcdc4ccb5f4eff518ff1adaf0ee.jpg?video_still_time=1.6',
                                }}
                                videoId="clet2n2jgh"
                                autoPlay={false}
                                trackingCallback={(action) =>
                                    tracking.partnerAcademy({action, video: 'unlock_growth'})
                                }
                            />
                        </Box>
                        <StickyContainer ref={stickyContainerRef} $hasOverlap={hasOverlap}>
                            <AcademyTabs />
                        </StickyContainer>
                        <Tabs.Content value="takeaways">
                            <div ref={takeawaysRef}>
                                <CardGroup>
                                    <Card
                                        icon={<Rocket />}
                                        title={t`Grow your practice`}
                                        description={t`Gain the tools and support to onboard more clients and grow your business with confidence.`}
                                    />
                                    <Card
                                        icon={<Heart />}
                                        title={t`Get tailored support`}
                                        description={t`Enjoy personalised onboarding, training, and VIP support to make growth easy.`}
                                    />
                                    <Card
                                        icon={<Eye />}
                                        title={t`Elevate your brand`}
                                        description={t`Stand out with co-branded campaigns, exclusive events, and Partner Directory placement.`}
                                    />
                                    <Card
                                        icon={<FlashOn />}
                                        title={t`Scale smarter`}
                                        description={t`Progress through the tiers with tools and rewards to help you scale.`}
                                    />
                                </CardGroup>
                            </div>
                        </Tabs.Content>
                        <Tabs.Content value="article">
                            <Stack mt={24} space={16} ref={introductionRef}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>Introduction</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        In this module, we'll explore how to balance business growth
                                        while delivering a great service to your clients. We'll also
                                        discover how joining a Partner Programme like Pleo's can
                                        support your goals and help you expand your practice.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>In this session you will learn:</Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            The benefits of joining a best-in-class Partner
                                            Programme
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            How the Pleo Partner Programme is structured to support
                                            your long-term business growth
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            How to maximise the Programme's resources, tools, and
                                            support to deliver more value to your clients
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>Let's dive in.</Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>
                                        Why joining a top-tier Partner Programme makes growth easier
                                        for you and and your clients
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Growing a successful accounting practice means more than
                                        balancing the books—it's about managing relationships,
                                        staying efficient, and prioritising time to grow the
                                        business.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Without the right support, many firms struggle to:
                                    </Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            Find the bandwidth to explore new technologies
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>Discover effective ways to reach clients</Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            Implement best practices for streamlining operations
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        It's hard to stay competitive when you're solving everything
                                        on your own.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        In fact, only 61% of business owners are completely
                                        satisfied with the breadth of services their accountant
                                        offers. Imagine how much more you could achieve with the
                                        right programme supporting you to deliver greater value to
                                        clients?
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Here's why it matters: choosing the right partner helps you
                                        focus on growth while delivering more value to your clients.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Let's explore how a well-designed Partner Programme can help
                                        you and your clients work smarter, save time, and streamline
                                        spend management.
                                    </Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>Why Pleo's Partner Programme?</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Pleo's Partner Programme goes beyond support — it's a full
                                        partnership to help firms grow faster, streamline
                                        operations, and deliver better client results.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Here's what partners love about the Partner Programme:
                                    </Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>A clear growth path:</strong> Five tiers
                                            offering progressively valuable benefits
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Early access to tools:</strong> Six months of
                                            Platinum-level benefits when you join, including a free
                                            Pleo account and dedicated onboarding support
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Tailored resources:</strong> On-demand training,
                                            marketing opportunities, and VIP support to help you
                                            onboard more clients confidently
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            <strong>Revenue potential:</strong> As you grow, unlock
                                            benefits like client discounts and revenue share to
                                            increase your profitability
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        Pleo helps partners access new revenue streams, excel as
                                        strategic advisors to clients and deliver more services that
                                        simplify spend management.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        For example, Platinum Partner {leeMaughan} from{' '}
                                        {greenAndPurple} co-hosted a webinar with Pleo that reached
                                        over 400 participants, driving brand visibility and
                                        generating new leads.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        How could the right partnership help you reach your next
                                        growth milestone?
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Let's take a look at how the Pleo Partner Programme works at
                                        each tier and the benefits you can unlock along the way.
                                    </Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>Unlock growth at every stage in the Programme</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Growing without the right resources can lead to missed
                                        opportunities. That's why Pleo's Partner Programme includes
                                        five tiers — Trial, Bronze, Silver, Gold, and Platinum —
                                        unlocking greater benefits as your practice expands.
                                    </Trans>
                                </Text>
                                <Link
                                    IconRight={NewTab}
                                    href="https://drive.google.com/file/d/1ZNjzDJQZFm-o1XynXO9V2Tiqbn4U3iAS/view?usp=sharing"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Trans>Partner Programme Overview</Trans>
                                </Link>
                                <Text>
                                    <Trans>
                                        Whether you're just getting started or already managing a
                                        thriving client base, there's a clear path for everyone.
                                    </Trans>
                                </Text>
                                <Stack space={6}>
                                    <Text>
                                        <strong>
                                            <Trans>New Partner (First six months)</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            The first few months of starting a new tech partnership
                                            can feel uncertain. That's why we provide six months of
                                            Platinum-level benefits to make sure you're fully
                                            supported from the start.
                                        </Trans>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            After six months, you will be ready to join a tier based
                                            on the number of clients and users you've onboarded—so
                                            you're set up for long-term success.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Stack space={6}>
                                    <Text>
                                        <strong>
                                            <Trans>Bronze tier</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            As you grow your practice, it's important to keep
                                            momentum. The Bronze tier is designed to empower you to
                                            keep building with ongoing resources and support.
                                        </Trans>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            This foundation keeps you connected to the partner
                                            community, learning, and supported as you expand your
                                            client base. The bigger benefits—like revenue share and
                                            client discounts—unlock as you progress further.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Stack space={6}>
                                    <Text>
                                        <strong>
                                            <Trans>Silver tier</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            As your practice grows and your clients streamline spend
                                            management, the Silver tier is designed to support that
                                            progress by providing tools and incentives that help you
                                            and your clients keep growing. To qualify, you'll need
                                            to onboard at least 10 users.
                                        </Trans>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            Silver tier benefits help you continue to expand while
                                            staying focused on providing high-quality service to
                                            clients.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Stack space={6}>
                                    <Text>
                                        <strong>
                                            <Trans>Gold tier</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            Scaling while keeping service personal can be
                                            challenging as your practice grows. The Gold tier is
                                            designed to help you expand quickly while staying
                                            closely connected to your clients. To qualify, you'll
                                            need five clients and 40 users.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Stack space={6}>
                                    <Text>
                                        <strong>
                                            <Trans>Platinum tier</Trans>
                                        </strong>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            Managing a growing practice comes with its own
                                            challenges — staying innovative, keeping clients
                                            engaged, and scaling strategically. Platinum is for
                                            firms ready to lead the way in advisory excellence. To
                                            qualify, you'll need to onboard 10 clients and 100
                                            users.
                                        </Trans>
                                    </Text>
                                    <Text>
                                        <Trans>
                                            Platinum also means greater visibility for your firm,
                                            with priority placement in our{' '}
                                            <Link
                                                href="https://www.pleo.io/partner-directory"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Partner Directory
                                            </Link>
                                            , making it easier for potential clients to find and
                                            trust your services.
                                        </Trans>
                                    </Text>
                                </Stack>
                                <Text>
                                    <Trans>
                                        With the right resources and expert support at every stage,
                                        you can grow faster, deliver more value to your clients, and
                                        build a scalable, future-ready practice.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        What could your practice achieve with a Partner Programme
                                        designed to support you at every stage of growth?
                                    </Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>Increase profitability with new revenue streams</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Growing an accounting firm often feels like a balancing
                                        act—delivering exceptional service while balancing profit.
                                        But relying solely on client fees can limit revenue
                                        potential.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Pleo's revenue share model, available to Gold and Platinum
                                        partners, helps solve this by offering a way to increase
                                        profitability while empowering your clients with smarter
                                        spend management.
                                    </Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>
                                        Maximize your partner benefits with the Partner Portal
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Growing your business should be rewarding and you deserve
                                        recognition for every client you introduce to Pleo. That's
                                        why we created the Partner Portal:
                                    </Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>Designed to help you track referrals</Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>Make sure your efforts are fully credited</Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>Here's why using the Partner Portal is important:</Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            It connects every referral to your account automatically
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            Your clients gain access to exclusive perks and
                                            discounts
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            It helps you reach higher partner tiers faster
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        Making the portal your go-to for every referral is the
                                        smartest way to maximise your rewards and give your clients
                                        the best Pleo experience.
                                    </Trans>
                                </Text>
                            </Stack>

                            <Stack mt={24} space={16}>
                                <Text as="h3" variant="2xlarge-accent">
                                    <Trans>Closing Thoughts</Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Growing your practice and delivering exceptional client
                                        results starts with the right support, tools, and training.
                                    </Trans>
                                </Text>
                                <Text>
                                    <Trans>
                                        Here's how Pleo's Partner Programme sets you up for success:
                                    </Trans>
                                </Text>
                                <List listStyle="bullet" css={{marginLeft: tokens.spacing16}}>
                                    <ListItem>
                                        <Trans>
                                            <strong>Personalised guidance</strong> to help your
                                            clients effectively streamline spend management.
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            Growth tiers structured to support your{' '}
                                            <strong>long term business goals</strong>
                                        </Trans>
                                    </ListItem>
                                    <ListItem>
                                        <Trans>
                                            Opportunities to{' '}
                                            <strong>highlight your expertise</strong> and build your
                                            brand
                                        </Trans>
                                    </ListItem>
                                </List>
                                <Text>
                                    <Trans>
                                        Ready to explore what's next? Let's dive into Module 4 and
                                        take a tour of the Partner Portal.
                                    </Trans>
                                </Text>
                            </Stack>
                        </Tabs.Content>
                    </Tabs>

                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link
                            as={RouterLink}
                            to="../transform-client-finances"
                            IconLeft={ArrowLeft}
                        >
                            <Trans>Back</Trans>
                        </Link>
                        <Button
                            variant="primary"
                            as={RouterLink}
                            to="./quiz"
                            IconRight={ArrowRight}
                        >
                            <Trans>Take the quiz</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <SplitContent.Sticky>
                        <ModuleOverview />
                    </SplitContent.Sticky>
                </SplitContent.Right>
            </SplitContent>
            {showBackToTop && (
                <IconButton
                    aria-label={t`Back to top`}
                    tooltipProps={{content: t`Back to top`}}
                    variant="secondary"
                    Icon={ChevronUp}
                    onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                    css={{
                        position: 'fixed',
                        bottom: 40,
                        left: (splitMainRef.current?.getBoundingClientRect()?.right ?? 0) + 80,
                        padding: tokens.spacing24,
                        border: tokens.borderInteractive,
                    }}
                />
            )}
        </>
    )
}

const StickyContainer = styled(Box).attrs({mb: 24})<{$hasOverlap: boolean}>`
    position: sticky;
    top: 0;
    padding-top: ${tokens.spacing20};
    background-color: ${tokens.colorBackgroundInteractive};
    transition: box-shadow ${tokens.smoothInOut};

    ${({$hasOverlap}) => $hasOverlap && `box-shadow: ${tokens.shadowElevate};`}
`
