import {t, Trans} from '@lingui/macro'
import React from 'react'
import styled from 'styled-components'

import type {BannerVariant} from '@pleo-io/telescope'
import {Banner, Inline, Link, tokens} from '@pleo-io/telescope'

import {breakpoints} from '@product-web/shared--styles/theme'

import type {OutageComponentName, OutageComponentStatus} from './status-page-client.bff'

type OutageComponentInfo = {
    isReported: boolean
    name: OutageComponentName
    status: OutageComponentStatus
}

type OutageBannerProps = {
    components: OutageComponentInfo[]
}

export function OutageBanner(props: OutageBannerProps) {
    const [isClosed, close] = React.useReducer(() => true, false)

    if (isClosed || !props.components.length) {
        return null
    }

    const bannerVariant = getOutageBannerVariant(props.components)

    return (
        <OutageBannerContainer data-testId="status-page-info">
            <Banner variant={bannerVariant} loud>
                <Banner.Content>
                    <Inline alignItems="center" space={10}>
                        <Inline css={{flex: 1}} space={10} alignItems="center">
                            <OutageMessageText components={props.components} />
                        </Inline>
                        <Link
                            target="_blank"
                            href="https://status.pleo.io"
                            rel="noopener noreferrer"
                            css={{whiteSpace: 'nowrap'}}
                        >
                            <Trans>See status</Trans>
                        </Link>
                    </Inline>
                </Banner.Content>
                <Banner.CloseButton onClick={close} aria-label={t`Close`} />
            </Banner>
        </OutageBannerContainer>
    )
}

// We take the most severe status in all outage components and base the banner accent color
// on it - i.e. if there is at least one component with a major outage, the message is red.
function getOutageBannerVariant(components: OutageComponentInfo[]) {
    const outageStatusBannerVariant: Record<OutageComponentStatus, BannerVariant> = {
        degraded_performance: 'warning',
        partial_outage: 'warning',
        full_outage: 'negative',
        under_maintenance: 'warning',
    }
    const statuses = components.map((component) => component.status)
    if (statuses.includes('full_outage')) {
        return outageStatusBannerVariant.full_outage
    }
    if (statuses.includes('partial_outage')) {
        return outageStatusBannerVariant.partial_outage
    }
    if (statuses.includes('under_maintenance')) {
        return outageStatusBannerVariant.under_maintenance
    }
    return outageStatusBannerVariant.degraded_performance
}

// The message text view logic is:
// - Show a detailed message (component name, description, status) for every component registered
//   to be reported that is currently experiencing issues
// - Show a single generic message for any number of non-reported components that currently
//   experience issues. If there are also reported components, show both.
function OutageMessageText(props: {components: OutageComponentInfo[]}) {
    const componentsInfo = getComponentsInfo()
    const outageStatusMessages = getOutageStatusMessages()

    const reportedComponentsInfo = props.components
        .filter((component) => component.isReported)
        .map((component) => {
            const statusMessage = outageStatusMessages[component.status]
            const [componentTitle, componentDescription] = componentsInfo[component.name] || [
                component.name,
                t`Ability to use ${component.name} functionality.`,
            ]

            return {
                componentName: component.name,
                statusMessage,
                componentTitle,
                componentDescription,
            }
        })

    return (
        <Banner.Text>
            {reportedComponentsInfo.map((info) => (
                <div key={info.componentName}>
                    {info.componentTitle} ({info.componentDescription}):{' '}
                    <strong>{info.statusMessage}</strong>
                </div>
            ))}
            {reportedComponentsInfo.length < props.components.length ? (
                <div>
                    {reportedComponentsInfo.length ? (
                        <Trans>
                            Some other functionalities are currently unavailable due to a technical
                            issue.
                        </Trans>
                    ) : (
                        <Trans>
                            Some functionalities are currently unavailable due to a technical issue.
                        </Trans>
                    )}
                </div>
            ) : null}
        </Banner.Text>
    )
}

const OutageBannerContainer = styled(Inline)`
    font-size: ${tokens.fontMedium};

    @media (max-width: ${breakpoints.tabletMedUp}) {
        position: fixed;
        bottom: 0;
        font-size: ${tokens.fontSmall};
        background: ${tokens.shade000};
        z-index: ${tokens.zIndexSurface};
        width: 100%;
    }
`

const getOutageStatusMessages: () => Record<OutageComponentStatus, string> = () => ({
    degraded_performance: t`Degraded performance`,
    partial_outage: t`Partial outage`,
    full_outage: t`Full outage`,
    under_maintenance: t`Under maintenance`,
})

/**
 * Component name/description mapping for the status page.
 * To refresh, see steps in 'status-page-client.bff.ts'.
 */
const getComponentsInfo: () => Record<OutageComponentName, [string, string] | undefined> = () => ({
    Analytics: [t`Analytics`, t`Ability to report on company spendings`],
    'Pleo API': [t`Pleo API`, t`Pleo's API used by integration partners`],
    Card: [t`Cards`, t`Ability to manage cards or create new cards`],
    Datev: [t`DATEV`, t`Ability to export expenses to DATEV`],
    Dinero: [t`Dinero`, t`Ability to export expenses to Dinero`],
    'e-conomic': [t`e-conomic`, t`Ability to export expenses to e-conomic`],
    'Expense Creation': [t`Expense Creation`, t`Ability to see new transactions in the app`],
    Integrations: [t`Integrations`, t`Ability to export expenses to third-party services`],
    Invoices: [
        // This string refers to the Pleo Invoices brand and should not be translated
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        'Invoices',
        t`Invoices payments`,
    ],
    'Mobile application': [t`Mobile App`, t`Pleo mobile application`],
    'Partner Portal': [t`Partner Portal`, t`Ability to access Partner Portal features`],
    'Out-of-pocket spend functionality': [
        t`Reimbursements`,
        t`Adding and editing Pleo out-of-pocket expenses. Employee balance calculation`,
    ],
    'Push notifications': [
        t`Push notifications`,
        t`Ability to send push notifications to mobile apps`,
    ],
    Quickbooks: [t`Quickbooks`, t`Ability to Export expenses to Quickbooks Online`],
    Reimbursements: [
        t`Reimbursements`,
        t`Reimbursements to personal cards and employee balance settlement by admins/bookkeepers`,
    ],
    'Sign up': [t`Sign up`, t`Ability to on-board new companies`],
    Transactions: [t`Transactions`, t`Ability to complete transactions with a Pleo card`],
    Wallet: [t`Wallet`, t`Ability to add funds to your Pleo wallet and general card management`],
    'Web application': [t`Web App`, t`Pleo web application`],
    Xero: [t`Xero`, t`Ability to export expenses to Xero`],
    Webhooks: [
        t`Webhooks`,
        t`Ability to configure and manage webhook integrations for real-time updates`,
    ],
    'Fetch: Forward to Pleo': [
        t`Fetch: Forward to Pleo`,
        t`Ability to forward electronic receipts to Pleo.`,
    ],
    'Help page': [
        t`Help page`,
        t`Ability to see our knowledge base for questions around the product.`,
    ],
    'Billy Online': [
        // This string refers to the Billy Online brand and should not be translated
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        `Billy Online`,
        t`Ability to export expenses to Billy Online`,
    ],
    'Business Central 365': [
        // This string refers to the Business Central 365 brand and should not be translated
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        `Business Central 365`,
        t`Ability to export expenses to Business Central 365`,
    ],
    'Cegid Loop': [
        // This string refers to the Cegid Loop brand and should not be translated
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        `Cegid Loop`,
        t`Ability to export expenses to Cegid Loop`,
    ],
    'Exact Online': [
        // This string refers to the Exact Online brand and should not be translated
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        `Exact Online`,
        t`Ability to export expenses to Exact Online`,
    ],
    Fortnox: [
        // This string refers to the Fortnox brand and should not be translated
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        `Fortnox`,
        t`Ability to export expenses to Fortnox`,
    ],
    Inventio: [
        // This string refers to the Inventio brand and should not be translated
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        `Inventio`,
        t`Ability to export expenses to Inventio`,
    ],
    'Holded Online': [
        // This string refers to the Holded Online brand and should not be translated
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        `Holded Online`,
        t`Ability to export expenses to Holded Online`,
    ],
    'Lex Office': [
        // This string refers to the Lex Office brand and should not be translated
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        `Lex Office`,
        t`Ability to export expenses to Lex Office`,
    ],
    Procountor: [
        // This string refers to the Procountor brand and should not be translated
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        `Procountor`,
        t`Ability to export expenses to Procountor`,
    ],
    'Visma eEkonomi': [
        // This string refers to the Visma eEkonomi brand and should not be translated
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        `Visma eEkonomi`,
        t`Ability to export expenses to Visma eEkonomi`,
    ],
})
