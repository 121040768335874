import {t} from '@lingui/macro'
import * as React from 'react'
import {useNavigate} from 'react-router'

import {useSubjectURN} from '@product-web/feature--integrations/helpers/use-subject-urn'
import {reportError} from '@product-web/shared--error/report'
import {useToaster} from '@product-web/shared--toaster'
import {useCompanyUser} from '@product-web/shared--user'
import type {ApplicationResponse} from '@shared/bff--moons/generated/endymion'

import {bffHooks} from './bff-hooks'
import type {
    InstalledApplicationAPIContext,
    InstalledApplicationContext,
} from './contexts/active-installation'

const bff = bffHooks.settingsIntegrations.peopleManagement

type FullContext = InstalledApplicationContext & InstalledApplicationAPIContext

const REFETCH_INSTALLED_APPLICATION_INTERVAL = 60000

export function useInstalledApplicationBackend(): FullContext {
    const navigate = useNavigate()

    const subjectURN = useSubjectURN()

    const installedApplicationResponse = bff.getPeopleManagementInstalledApplication.useQuery(
        {
            subjectURN,
        },
        {refetchInterval: REFETCH_INSTALLED_APPLICATION_INTERVAL},
    )
    const installedApplication = installedApplicationResponse.data ?? null
    const appName = installedApplication?.name ?? ''

    const connectMutation = bff.connectPeopleManagementIntegration.useMutation()
    const disconnectMutation = bff.disconnectPeopleManagementApplication.useMutation()

    const {companyId} = useCompanyUser()

    const [isConnecting, setIsConnecting] = React.useState(false)

    const showConnectError = useErrorToaster(
        t`Couldn't open the connection page`,
        t`Check your internet connection and try again.`,
    )
    const showDisconnectError = useErrorToaster(
        t`Couldn't disconnect ${appName} app`,
        t`Check your internet connection and try again.`,
    )
    const showDisconnectSuccess = useSuccessToaster(
        appName && appName.length > 0 ? t`${appName} app disconnected` : t`App disconnected`,
        t`You have successfully disconnected your people management integration`,
    )

    const connect = React.useCallback(
        async (integration: ApplicationResponse) => {
            setIsConnecting(true)
            connectMutation.mutate(
                {id: integration.id, companyId},
                {
                    onSuccess: (redirectURI) => {
                        window.location.replace(redirectURI)
                    },
                    onError: (error) => {
                        setIsConnecting(false)
                        showConnectError(error)
                    },
                },
            )
        },
        [connectMutation, companyId, showConnectError],
    )

    const disconnect = React.useCallback(async () => {
        if (!installedApplication || !installedApplication.installation) {
            return
        }

        disconnectMutation.mutate(
            {installationId: installedApplication.installation.id},
            {
                onSuccess: () => {
                    navigate('/settings/integrations/people-management', {replace: true})
                    showDisconnectSuccess()
                },
                onError: showDisconnectError,
            },
        )
    }, [
        appName,
        disconnectMutation,
        installedApplication,
        navigate,
        showDisconnectError,
        showDisconnectSuccess,
    ])

    return {
        installedApplication,
        isLoading: installedApplicationResponse.isLoading,
        isError: installedApplicationResponse.isError,
        isConnecting,
        isDisconnecting: disconnectMutation.isLoading,
        connect,
        disconnect,
    }
}

function useErrorToaster(title: string, message: string) {
    const toaster = useToaster()
    return React.useCallback(
        (error: unknown) => {
            reportError(error)
            toaster.showToast(message, {title, level: 'error'})
        },
        [toaster, title, message],
    )
}

function useSuccessToaster(title: string, message: string) {
    const toaster = useToaster()
    return React.useCallback(() => {
        toaster.showToast(message, {level: 'success', title})
    }, [toaster, message, title])
}
