import type {ReactNode} from 'react'
import styled from 'styled-components'

import {Inline, px, Text, tokens} from '@pleo-io/telescope'

import {customColorSchemeTokens} from '@product-web/shared--styles/custom-tokens'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

interface GroupTitleProps {
    title: string
    children?: ReactNode
}

export const GroupTitle = ({title, children}: GroupTitleProps) => {
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp}`)

    return (
        <GroupTitleText alignY="flex-end" justifyContent="space-between">
            {title && <Text variant={isTablet ? 'xlarge-accent' : 'large-accent'}>{title}</Text>}
            {children}
        </GroupTitleText>
    )
}

const GroupTitleText = styled(Inline)`
    position: sticky;
    top: 0;
    /* 48px for the search field and 20px for the padding to avoid jumps when switching to search mode */
    min-height: ${px(48 + 20)};
    padding-block: ${tokens.spacing10};
    background-color: ${customColorSchemeTokens.colorBackgroundEntitySwitcherPanel};
    z-index: ${tokens.zIndexSurface};
`
