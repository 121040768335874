import tracking from '@product-web/shared--analytics'
import type {GetInstitutionsByCompanyPurpose} from '@shared/bff--moons/generated/kari'

import type {OpenBankingCallBackStatus, OpenBankingCallBackType} from './types/open-banking'

export enum TrackOpenBankingTopUpAction {
    OPEN_MODAL = 'open modal',
    SHOW_SCREEN = 'show screen',
    ABANDONED = 'abandoned',
    NAVIGATE_TO_BANK_TRANSFER_DETAILS = 'navigate to bank transfer details',
    SELECT_BANK = 'select bank',
    CHANGE_AMOUNT = 'change amount',
    CHANGE_BANK_SEARCH_QUERY = 'change bank search query',
    CLICK_BACK = 'click back',
    CLICK_NEXT = 'click next',
    SUBMIT = 'submit',
    SUBMIT_VRP_FLOW = 'submit VRP flow',
    CLICK_YAPILY_TERMS_AND_CONDITIONS = 'click yapily terms and conditions',
    CLICK_SETUP_ATU_WITH_DIRECT_DEBIT = 'click setup ATU with Direct Debit',
    REDIRECT_TO_OPEN_BANKING_AUTHORISATION = 'redirect to open banking authorisation',
    SEND_CONSENT_TOKEN = 'send consent token',
    HANDLE_CONSENT_TOKEN_CALLBACK_STATUS = 'handle consent token callback status',
}

type OpenBankingScreenName =
    | 'beforeYouContinue'
    | 'bankSelection'
    | 'amountInput'
    | 'summary'
    | 'vrpBankAccountDetails'

type TrackOpenBankingTopUpActionParams = {
    action:
        | TrackOpenBankingTopUpAction.OPEN_MODAL
        | TrackOpenBankingTopUpAction.CLICK_YAPILY_TERMS_AND_CONDITIONS
        | TrackOpenBankingTopUpAction.CLICK_SETUP_ATU_WITH_DIRECT_DEBIT
}

type TrackOpenBankingTopUpWithScreenParams = {
    action: TrackOpenBankingTopUpAction.SHOW_SCREEN | TrackOpenBankingTopUpAction.ABANDONED
    screenName: OpenBankingScreenName | 'bankAccountDetails'
}

type TrackOpenBankingClickBackParams = {
    action: TrackOpenBankingTopUpAction.CLICK_BACK
    screenName: OpenBankingScreenName | 'bankTransferDetails'
}

type TrackOpenBankingTopUpBankSelectionParams = {
    action: TrackOpenBankingTopUpAction.SELECT_BANK
    institutionId: string
}

type TrackOpenBankingTopUpChangeAmountParams = {
    action: TrackOpenBankingTopUpAction.CHANGE_AMOUNT
    amount: number
    currency: string
}

type TrackOpenBankingChangeBankSearchQueryParams = {
    action: TrackOpenBankingTopUpAction.CHANGE_BANK_SEARCH_QUERY
    bankSearchQuery: string
    totalBankItemsCount: number
    purpose: GetInstitutionsByCompanyPurpose
}

type TrackOpenBankingTopUpClickNextParams = {
    action: TrackOpenBankingTopUpAction.CLICK_NEXT
    screenName: OpenBankingScreenName
    amount: number
    currency: string
    iban?: string
}

type TrackOpenBankingTopUpSubmitParams = {
    action: TrackOpenBankingTopUpAction.SUBMIT
    institutionId: string
    amount: number
    currency: string
}

type TrackOpenBankingTopUpSubmitVrpParams = {
    action: TrackOpenBankingTopUpAction.SUBMIT_VRP_FLOW
    institutionId: string
    autoTopUpAmount: number
    autoTopUpThreshold: number
    currency: string
}

type TrackOpenBankingRedirectToAuthorisationParams = {
    action: TrackOpenBankingTopUpAction.REDIRECT_TO_OPEN_BANKING_AUTHORISATION
    redirectUrl: string
}

type TrackOpenBankingConsentTokenParams = {
    action: TrackOpenBankingTopUpAction.SEND_CONSENT_TOKEN
    institutionId: string
    tokenStage: 'initiate' | 'success' | 'fail'
}

type TrackOpenBankingConsentTokenCallbackStatusParams = {
    action: TrackOpenBankingTopUpAction.HANDLE_CONSENT_TOKEN_CALLBACK_STATUS
    callbackType: OpenBankingCallBackType
    callbackStatus: OpenBankingCallBackStatus
}

type TrackOpenBankingNavigateToBankTransferDetailsParams = {
    action: TrackOpenBankingTopUpAction.NAVIGATE_TO_BANK_TRANSFER_DETAILS
    isFooterLink?: boolean
    isEmptyResultButton?: boolean
    bankSearchQuery?: string
}

type TrackOpenBankingParams =
    | TrackOpenBankingTopUpActionParams
    | TrackOpenBankingTopUpWithScreenParams
    | TrackOpenBankingClickBackParams
    | TrackOpenBankingTopUpBankSelectionParams
    | TrackOpenBankingTopUpChangeAmountParams
    | TrackOpenBankingChangeBankSearchQueryParams
    | TrackOpenBankingTopUpClickNextParams
    | TrackOpenBankingTopUpSubmitParams
    | TrackOpenBankingTopUpSubmitVrpParams
    | TrackOpenBankingRedirectToAuthorisationParams
    | TrackOpenBankingConsentTokenParams
    | TrackOpenBankingConsentTokenCallbackStatusParams
    | TrackOpenBankingNavigateToBankTransferDetailsParams

export const trackOpenBankingModal = (params: TrackOpenBankingParams) =>
    tracking.openBankingModalActioned(params)
