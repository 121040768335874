import {t} from '@lingui/macro'

import type {Expense, ExpenseViewData} from '@pleo-io/deimos'
import {ExpenseFamily, ExpenseViewType, TransactionType} from '@pleo-io/deimos'

import type {EmployeeBalanceEntry} from '@product-web/feature--reimbursements/index.bff'
import {dayjs} from '@product-web/shared--dates/dayjs'
import type {PerDiemCreateErrorResponseMessage} from '@shared/bff--moons/generated/pandia'

export const isRefund = (expense: Expense | ExpenseViewData | EmployeeBalanceEntry['expense']) => {
    if (!expense) {
        return false
    }

    const {transactionType, family, amount} = expense

    if (transactionType === TransactionType.REFUND) {
        return true
    }

    // CHARGE_BACK can have negative value, which means it's a CHARGE_BACK_REVERSAL
    // CHARGE_BACK_REVERSAL - Pleo decides that the CHARGE_BACK claim of the customer was not valid and reverses it
    if (transactionType === TransactionType.CHARGE_BACK) {
        return amount.value > 0
    }

    if (family === ExpenseFamily.EMPLOYEE_BANK_TRANSFER) {
        const {bill} = expense as Expense
        return (bill?.value || 0) > 0
    }
    return false
}

export const isInvoice = ({transactionType}: Expense | ExpenseViewData) => {
    return [TransactionType.BILL_INVOICE].includes(transactionType as TransactionType)
}

export function isPocketTransfer(
    item: Expense | ExpenseViewData | EmployeeBalanceEntry['expense'],
) {
    if (!item) {
        return false
    }

    return [
        ExpenseFamily.MANUAL_TRANSFER,
        ExpenseFamily.PERSONAL_TRANSFER,
        ExpenseFamily.EMPLOYEE_BANK_TRANSFER,
    ].includes(item.family)
}

export function getExpenseViewType(
    item: Expense | EmployeeBalanceEntry['expense'],
): ExpenseViewType {
    if (!item) {
        return ExpenseViewType.EXPENSE
    }

    const isItemManualTransfer = isPocketTransfer(item)
    const isRefundTransfer = isRefund(item)

    if (isRefundTransfer) {
        return ExpenseViewType.TRANSFER_REFUND
    }

    if (isItemManualTransfer) {
        if (item.family === ExpenseFamily.MANUAL_TRANSFER && (item.bill?.value ?? 0) < 0) {
            return ExpenseViewType.CASH_RETURNED
        }
        return ExpenseViewType.REIMBURSEMENT
    }

    return (item as Expense).purchaseType === 'ATM'
        ? ExpenseViewType.ATM_WITHDRAWAL
        : ExpenseViewType.EXPENSE
}

function getLabelByType(viewType: ExpenseViewType | null) {
    switch (viewType) {
        case ExpenseViewType.ATM_WITHDRAWAL:
            return t`Cash withdrawal`
        case ExpenseViewType.CASH_RETURNED:
            return t`Cash returned`
        case ExpenseViewType.REIMBURSEMENT:
            return t`Reimbursement`
        case ExpenseViewType.TRANSFER_REFUND:
            return t`Refund`
        default:
            return ''
    }
}

export function getItemViewType(
    expense?: Expense | ExpenseViewData | EmployeeBalanceEntry['expense'],
    pocketExpenseAmount = 0,
) {
    const isRefundTransfer = isRefund(expense as any)

    if (!expense) {
        return ExpenseViewType.EXPENSE
    }
    if (isRefundTransfer) {
        return ExpenseViewType.TRANSFER_REFUND
    }
    if (expense?.family === ExpenseFamily.MANUAL_TRANSFER) {
        if (pocketExpenseAmount && pocketExpenseAmount > 0) {
            return ExpenseViewType.CASH_RETURNED
        }
        return ExpenseViewType.REIMBURSEMENT
    }
    if (
        [ExpenseFamily.PERSONAL_TRANSFER, ExpenseFamily.EMPLOYEE_BANK_TRANSFER].includes(
            expense?.family,
        )
    ) {
        return ExpenseViewType.REIMBURSEMENT
    }

    return 'expenseViewType' in expense ? expense.expenseViewType : getExpenseViewType(expense)
}

export function getItemLabel(
    expense: Expense | ExpenseViewData | EmployeeBalanceEntry['expense'],
    pocketExpenseAmount = 0,
) {
    let label = ''
    if (!expense) {
        return label
    }
    if (
        [ExpenseFamily.CARD, ExpenseFamily.MANUAL, ExpenseFamily.PLEO_INVOICE].includes(
            expense.family,
        )
    ) {
        label = expense.merchantName ?? ''
    } else {
        const expenseViewType = getItemViewType(expense, pocketExpenseAmount)
        label = getLabelByType(expenseViewType)
    }
    return label
}

export function getLabelByViewType(viewType: ExpenseViewType | null) {
    switch (viewType) {
        case ExpenseViewType.ATM_WITHDRAWAL:
            return t`Cash withdrawal`
        case ExpenseViewType.CASH_RETURNED:
            return t`Cash returned`
        case ExpenseViewType.EXPENSE:
        case ExpenseViewType.REIMBURSEMENT:
            return t`Reimbursement`
        case ExpenseViewType.TRANSFER_REFUND:
            return t`Refund`
        default:
            return undefined
    }
}

export enum ExpenseClientErrors {
    UNSAVED_EXPENSE = 'unsaved_expense',
}

export const getErrorConfig = (error?: ExpenseClientErrors | PerDiemCreateErrorResponseMessage) => {
    switch (error) {
        case 'trip_shorter_than_allowed_min_length':
            return {
                title: t`Your trip's not long enough`,
                message: t`Your trip should be longer than 8 hours. It is not eligible for per diem - sorry`,
                options: {
                    confirmLabel: t`Edit trip`,
                    cancelLabel: t`Discard trip`,
                },
            }
        case ExpenseClientErrors.UNSAVED_EXPENSE:
            return {
                title: t`Discard trip?`,
                message: t`You haven't finished your expense yet. Any change in your trip will be lost`,
                options: {
                    confirmLabel: t`Discard trip`,
                    cancelLabel: t`Cancel`,
                },
            }
        default:
            return {
                title: t`Double check your dates and times`,
                message: t`Trips should be longer than a day, shorter than 3 months and not have gaps of a day or more between stops. Double check your dates and try again`,
                options: {
                    confirmLabel: t`Edit trip`,
                    cancelLabel: t`Discard trip`,
                },
            }
    }
}

export function formatEmployee(
    employee?: {firstName: string; lastName: string} | null,
): string | null {
    if (employee) {
        return `${employee.firstName} ${employee.lastName}`
    }
    return null
}

export function formatPerformedDate(performed: string | number | Date) {
    return dayjs(performed).format('HH:mm')
}
