import {Trans} from '@lingui/macro'

import {Inline, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {NewTab} from '@pleo-io/telescope-icons'

interface Props {
    author: string
    companyPosition: string
    quote: string
    url?: string
}

export const Testimonial = ({author, companyPosition, quote, url}: Props) => {
    return (
        <>
            <Stack
                pl={40}
                py={12}
                space={12}
                css={{borderLeft: `2px solid ${tokens.colorBorderInteractiveLoud}`}}
            >
                <Text variant="large-accent">"{quote}"</Text>
                <Inline space={4}>
                    <Text>
                        <strong>{author}</strong>,
                    </Text>
                    <Text>{companyPosition}</Text>
                </Inline>
            </Stack>
            {url && (
                <Link
                    IconRight={NewTab}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    css={{fontSize: tokens.fontLarge}}
                >
                    <Trans>Read the full case study</Trans>
                </Link>
            )}
        </>
    )
}
