import type {FC} from 'react'
import type React from 'react'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import styled, {css} from 'styled-components'

import {focusRing, Inline, px, tokens} from '@pleo-io/telescope'

import FlaggedLabel from './flagged-label'
import type {useNavigationItems} from './use-navigation-items'

export function NavigationLink({
    item,
    onClickItem,
    reloadDocument,
}: {
    item: ReturnType<typeof useNavigationItems>['items'][number]
    onClickItem?: () => void
    reloadDocument?: boolean
}) {
    const pathname = useLocation().pathname

    const menuItem = (
        <NavItemInline alignItems="center">
            <CompactNavItemLink
                draggable={false}
                match={item.match}
                onClick={onClickItem}
                pathname={pathname}
                reloadDocument={reloadDocument}
                to={item.to}
            >
                {item.newFeature ? (
                    <NewFeatureSpan>
                        {item.flag ? (
                            <FlaggedLabel label={item.label} flag={item.flag} />
                        ) : (
                            item.label
                        )}
                    </NewFeatureSpan>
                ) : (
                    item.label
                )}
                {item.extraNode?.()}
            </CompactNavItemLink>
        </NavItemInline>
    )

    return menuItem
}

interface LinkProps {
    to: string
    pathname: string
    exact?: boolean
    match?: string
    children: React.ReactNode | string
    [key: string]: any
}

const Link: FC<LinkProps> = ({to, pathname, exact, match, children, ...rest}) => {
    const pattern = match ? match : to
    const regex = exact ? new RegExp(`^${pattern}$`) : new RegExp(`^${pattern}`)
    const isActive = regex.test(pathname)

    return (
        <GlobalNavigationLink
            $isActive={isActive}
            to={to}
            aria-current={isActive ? 'page' : undefined}
            {...rest}
        >
            {children}
        </GlobalNavigationLink>
    )
}

const NewFeatureSpan = styled.span`
    position: relative;
`

const CompactNavItemLink = styled(Link)`
    margin-top: 0;
    margin-bottom: 0;
`

const NavItemInline = styled(Inline)`
    position: relative;
`

const GlobalNavigationLink = styled(RouterLink)<{$isActive?: boolean}>`
    width: 86%;
    display: flex;
    min-height: ${px(38)};
    box-sizing: border-box;
    margin: ${tokens.spacing2} ${tokens.spacing16};
    font-family: inherit;
    font-size: ${px(14)};
    color: ${tokens.colorContentInteractive};
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    align-items: center;
    border-radius: ${tokens.arc4};
    ${focusRing('offset')};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
        border-radius: ${tokens.arc4};
    }

    ${(props) =>
        props.$isActive &&
        css`
            position: relative;
            font-weight: ${tokens.fontWeightBold};
        `}
`
