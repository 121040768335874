import {Trans} from '@lingui/macro'

import {OverviewRight, UsefulMaterials} from '../../../components/overview-right/overview-right'
import {LessonNavigation} from '../lesson-navigation'

export const ModuleOverview = () => (
    <OverviewRight>
        <LessonNavigation />
        <UsefulMaterials>
            <UsefulMaterials.Document url="https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/nKLtLLpTcyHQVT6d?sellerPreviewing=true#/content/1052b7ee-02ec-485d-8f2b-1c603ed7aba7">
                <Trans>Infographic</Trans>
            </UsefulMaterials.Document>
            <UsefulMaterials.Document url="https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/nKLtLLpTcyHQVT6d?sellerPreviewing=true#/content/2b438f5c-319f-4a8d-b098-057979ecdadf">
                <Trans>One pager</Trans>
            </UsefulMaterials.Document>
        </UsefulMaterials>
    </OverviewRight>
)
