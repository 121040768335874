import {bff} from './bff-hooks'

// Temporary shared hook to be used where we don't already have BFFs setup for
// the picked trial plan experiment
export const useUserPickedTrialPlanFlag = () => {
    const {data, isLoading} = bff.planPresentation.userPickedTrialPlanFlag.getData.useQuery(
        undefined,
        {
            // We don't refetch this as it should never change after the company is signed up
            staleTime: Infinity,
        },
    )

    return {
        hasUserPickedTrialPlanFlag: data?.hasUserPickedTrialPlan,
        isLoadingUserPickedTrialPlanFlag: isLoading,
    }
}
